import React from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

import { update } from "firebase_config";
import { Tables } from "constants/index";

const MedicalRequestsComponent = ({
  currentUser,
  options,
  setOptions,
  searchValue,
}) => {
  const history = useHistory();

  const handleChange = (key, value) => {
    setOptions({
      ...options,
      [key]: value,
    });
  };

  const saveCertificate = async () => {
    options.assigned = !!options.doctorId;
    const res = await update(Tables.MEDICAL_REQUESTS, searchValue, options);

    if (res.status === 200) {
      toast.success(`Request updated. ${searchValue}`);
      history.goBack();
    } else {
      toast.error("Something went wrong. Refresh the page");
    }
  };

  return (
    <>
      <div>
        <div className="mt-4">
          <h6>Payment Status</h6>
          <select
            className="form-control font-small"
            aria-label=".form-select-sm"
            value={options.paymentStatus}
            onChange={(e) => handleChange("paymentStatus", e.target.value)}
          >
            <option value="pending">Pending</option>
            <option value="completed">Completed</option>
          </select>
        </div>
        <div className="mt-4">
          <h6>Give access to upload ID </h6>
          <select
            className="form-control font-small"
            aria-label=".form-select-sm"
            value={options.isEditRequired}
            onChange={(e) =>
              handleChange("isEditRequired", e.target.value === "true")
            }
          >
            <option value="false" defaultValue>
              No
            </option>
            <option value="true">Yes</option>
          </select>
        </div>
        <div
          className="btn btn-violet text-center btn-sm mt-3"
          onClick={saveCertificate}
        >
          Update Payment Request
        </div>
      </div>
    </>
  );
};

export default MedicalRequestsComponent;
