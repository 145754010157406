import React from "react";

const AssignDoctorComponent = ({
  currentUser,
  options,
  setOptions,
  handleChange,
}) => {
  return (
    <>
      <div className="mt-4">
        <h6>Assigned Doctor</h6>
        <select
          className="form-control font-small"
          aria-label=".form-select-sm"
          value={options.doctorId}
          onChange={(e) => handleChange("doctorId", e.target.value)}
        >
          <option value="">None</option>
          <option value="HXi6OfsaYwx26yyc0bXA">Clinton Dsouza</option>
          <option value="pui63u2n6YriQB5LaE4a">Jayprakash Waghmore</option>
        </select>
      </div>
    </>
  );
};

export default AssignDoctorComponent;
