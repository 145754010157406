import React, { useState, useEffect } from "react";
import { Link, useHistory, withRouter } from "react-router-dom";
import { Helmet } from "react-helmet";
import {
  FacebookIcon,
  FacebookShareButton,
  WhatsappIcon,
  WhatsappShareButton,
  LinkedinIcon,
} from "react-share";
import axios from "axios";
import $ from "jquery";
import "./style.css";

import { StaticHeaderComponent } from "components";
import { Blogs, Routes } from "constants/index";
import { add, remove, firestore, getQuery } from "firebase_config";

const BlogTemplateComponent = (props) => {
  const [name, setName] = useState(props.match.params.blogName);
  const [blog, setBlog] = useState("");
  const [priorityBlogs, setPriorityBlogs] = useState("");
  const [follower, setFollower] = useState("");
  const [followerLength, setFollowerLength] = useState(0);
  const [ip, setIp] = useState("");
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  useEffect(() => {
    window.scrollTo(0, 0);

    /* load how many people given a heart */
    async function loadBlogFollowers(n) {
      /* Evaulate based on n */
      /* To handle browser back button */
      if (!n) return null;
      setLoading(true);
      const res = await axios.get("https://geolocation-db.com/json/");
      setIp(res.data.IPv4);
      const blogFollowers = await getQuery(
        firestore.collection("blogFollowers").where("name", "==", n).get()
      );
      setFollowerLength(blogFollowers.length);
      const isFollower = blogFollowers.find((bf) => bf.ip === res.data.IPv4);
      if (isFollower) {
        setFollower(isFollower);
      } else {
        setFollower("");
      }
      setLoading(false);
    }

    /* load the blog which user is in view */
    function loadBlog(n) {
      const b = Blogs.find((blog) => blog.name === n);
      setBlog(b.template);
      /* Pass n to loadBlogFollowers Function */
      /* To handle browser back button */
      if (!b) {
        loadBlogFollowers(n);
      }
    }

    /* load the priority blogs a.k.a suggested blogs */
    function loadPriorityBlogs(n) {
      const local = [];
      Blogs.map((b) => {
        if (local.length === 3 || b.name === n) {
          return null;
        }
        local.push(b.template);
        setPriorityBlogs([...local]);
        return b;
      });
    }
    if (!blog) loadBlog(name);
    if (!priorityBlogs) loadPriorityBlogs(name);

    /* To handle browser back button */
    /* Ref: https://www.codegrepper.com/code-examples/javascript/how+to+update+state+on+back+button+react+router+hooks */
    return history.listen((location) => {
      const n = location.pathname.split("/")[2];
      if (n && n[1]) {
        loadBlog(n);
        loadPriorityBlogs(n);
        setName(n);
      }
    });
  }, [blog, name, priorityBlogs]);

  const redirectTo = (name) => {
    setBlog("");
    setPriorityBlogs("");
    history.push(`/blogs/${name}`);
  };

  const handleFollowerStatus = async () => {
    if (follower) {
      await remove("blogFollowers", follower.id);
      setFollower("");
      setFollowerLength(followerLength - 1);
    } else {
      const res = await add("blogFollowers", {
        name: blog.name,
        ip,
      });
      setFollower(res.data);
      setFollowerLength(followerLength + 1);
      $(`.icon-heart`).addClass("scaleInImgFollow");
    }
    setTimeout(() => {
      $(`.icon-heart`).removeClass("scaleInImgFollow");
      $(`.icon-heart`).removeClass("scaleInImgUnfollow");
    }, 7000);
  };

  const openLinkedinUrl = () => {
    window.open(
      "https://www.linkedin.com/sharing/share-offsite/?mini=true&url=https://medoc.life/blogs/"
    );
  };

  const renderShareButtons = () => (
    <div className="text-center mt-3">
      <FacebookShareButton
        url={`https://medoc.life/blogs/${name}`}
        title={blog.title}
        quote={blog.category}
        hashtag="#letsgtok_blogs"
        className="socialMediaButton"
      >
        <FacebookIcon size={36} />
      </FacebookShareButton>
      <WhatsappShareButton
        url={`https://medoc.life/blogs/${name}`}
        title={blog.title}
        separator=" "
        className="socialMediaButton"
      >
        <WhatsappIcon size={36} />
      </WhatsappShareButton>
      <a
        href="#"
        onClick={openLinkedinUrl}
        target=""
        className="socialMediaButton"
      >
        <LinkedinIcon size={36} />
      </a>
    </div>
  );

  const consultNowButton = () => (
    <div className="text-center my-3">
      <Link to={Routes.LOGIN} className="btn btn-violet text-white">
        Get Certificate Now
      </Link>
    </div>
  );

  return (
    blog && (
      <>
        <Helmet>
          <title>Medoc | {blog.title}</title>
          <meta name="title" content={blog.title} />
          <meta name="description" content={blog.meta_description} />
          <meta
            name="keywords"
            content={`${blog.meta_keywords}`}
            data-react-helmet="true"
          />
          <meta name="theme-color" content="#6930db" data-react-helmet="true" />

          <meta property="og:type" content="website" />
          <meta
            property="og:url"
            content={`https://medoc.life/blogs/${blog.name}`}
          />
          <meta property="og:title" content={blog.title} />
          <meta property="og:description" content={blog.meta_description} />
          <meta property="og:image" content={blog.banner_url} />

          <meta property="twitter:card" content="summary_large_image" />
          <meta
            property="twitter:url"
            content={`https://medoc.life/blogs/${blog.name}`}
          />
          <meta property="twitter:title" content={blog.title} />
          <meta
            property="twitter:description"
            content={blog.meta_description}
          />
          <meta property="twitter:image" content={blog.banner_url} />
          <link rel="canonical" href="https://medoc.life/blogs" />
        </Helmet>
        <StaticHeaderComponent />
        <div className="container blogs-wrapper px-3 px-sm-0">
          <div className="row align-items-center">
            <div className="col-12 col-sm-6">
              <img
                alt={blog.meta_keywords}
                className="blog-img pinned"
                src={blog.banner_url}
              />
            </div>
            <div className="col-12 col-sm-6 d-none d-sm-block">
              <span className="btn btn-violet-outline blog-category">
                {blog.category}
              </span>
              <h3 className="mt-4 blog-header">{blog.title}</h3>
              <p>
                {blog.author && (
                  <small
                    dangerouslySetInnerHTML={{ __html: blog.author }}
                  ></small>
                )}
                <small>{blog.date}</small>
                <br />
              </p>
            </div>

            <div className="d-sm-none">
              <div className="mx-auto d-flex flex-column align-items-center">
                <span className="btn btn-violet-outline blog-category  mt-3">
                  {blog.category}
                </span>
                <h3 className="mt-4 blog-header d-none d-sm-flex">
                  {blog.title}
                </h3>
                <h3 className="mt-4 blog-header d-sm-none text-center">
                  {blog.title}
                </h3>
                <p className="text-center">
                  {blog.author && (
                    <small
                      dangerouslySetInnerHTML={{ __html: blog.author }}
                    ></small>
                  )}
                  <small>{blog.date}</small>
                  <br />
                </p>
              </div>
            </div>
          </div>
          {/* col-3 blogs */}
          <div className="row mt-5 d-none d-sm-flex">
            <div className="col-9 offset-sm-2">
              <p dangerouslySetInnerHTML={{ __html: blog.body }}></p>
              {consultNowButton()}
              <div className="follow-blog" onClick={handleFollowerStatus}>
                {loading ? (
                  <div className="spinner-border spinner-border-sm"></div>
                ) : (
                  <div>
                    <div className="mb-1">
                      <img
                        className={`icon-heart mb-3 ${follower && "active"}`}
                        src={
                          follower
                            ? require("assets/svgs/HeartActive.svg").default
                            : require("assets/svgs/Heart.svg").default
                        }
                        alt={blog.title}
                      />
                      <span className="follower-length">
                        {followerLength > 0 && followerLength}
                      </span>
                    </div>
                    <span className="text-violet">
                      {follower
                        ? "So sweet! Thanks for giving a heart"
                        : "Give a heart to our writer"}
                    </span>
                  </div>
                )}
              </div>
              {renderShareButtons()}
            </div>
            <div className="col-3">
              <div className="d-none flex-row">
                {priorityBlogs[0] &&
                  priorityBlogs.map((b, idx) => (
                    <div
                      className="card mt-5"
                      key={idx}
                      onClick={(e) => redirectTo(b.name, e)}
                    >
                      <img
                        alt={b.title}
                        className="card-img-top blog-img"
                        src={b.banner_url}
                      />
                      <span className="btn btn-violet-outline badge-category">
                        {b.category}
                      </span>
                      <div className="card-body">
                        <p className="blog-header">{b.title}</p>
                        <div className="small">{b.date}</div>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>

          <div className="row mobile d-sm-none">
            <div className="col-11 mx-auto">
              <p dangerouslySetInnerHTML={{ __html: blog.body }}></p>
              {consultNowButton()}
              <div className="follow-blog" onClick={handleFollowerStatus}>
                {loading ? (
                  <div className="spinner-border spinner-border-sm"></div>
                ) : (
                  <div>
                    <div className="mb-1">
                      <img
                        className={`icon-heart mb-3 ${follower && "active"}`}
                        src={
                          follower
                            ? require("assets/svgs/HeartActive.svg").default
                            : require("assets/svgs/Heart.svg").default
                        }
                        alt={blog.title}
                      />
                      <span className="follower-length">
                        {followerLength > 0 && followerLength}
                      </span>
                    </div>
                    <span className="text-violet">
                      {follower
                        ? "So sweet! Thanks for giving a heart"
                        : "Give a heart to our writer"}
                    </span>
                  </div>
                )}
              </div>
              {renderShareButtons()}
            </div>
            <div className="col-11 mx-auto">
              <div className="d-flex flex-column">
                {priorityBlogs[0] &&
                  priorityBlogs.map((b, idx) => (
                    <div
                      className="card mt-5 my-2"
                      key={idx}
                      onClick={(e) => redirectTo(b.name, e)}
                    >
                      <img
                        alt={b.title}
                        className="card-img-top blog-img"
                        src={b.banner_url}
                      />
                      <span className="btn btn-violet-outline badge-category">
                        {b.category}
                      </span>
                      <div className="card-body">
                        <div className="blog-header">{b.title}</div>
                        <small>{b.date}</small>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </>
    )
  );
};

export default withRouter(BlogTemplateComponent);
