import React, { useState } from "react";
import { useHistory } from "react-router-dom";

import { getQuery, firestore } from "firebase_config";
import { Tables } from "constants/index";

function GetUsersComponent({ currentUser, records, setRecords, setLoading }) {
  const [filters, setFilters] = useState({
    id: "",
    role: "",
    email: "",
    name: "",
  });
  const history = useHistory();

  const reloadRecords = async () => {
    const req = await getQuery(
      firestore.collection(Tables.USERS).orderBy("updatedAt", "desc").get()
    );
    return req.sort((a, b) => b.updatedAt - a.updatedAt);
  };

  const searchRecords = async () => {
    setLoading(true);
    let filteredRecords = await reloadRecords();
    if (filters.id) {
      filteredRecords = filteredRecords.filter(
        (req) => req.id === filters.id.trim()
      );
    }
    if (filters.role) {
      filteredRecords = filteredRecords.filter((req) =>
        req.roles.includes(filters.role)
      );
    }
    if (filters.email) {
      filteredRecords = filteredRecords.filter(
        (req) => req.email === filters.email.toLowerCase().trim()
      );
    }
    if (filters.name) {
      filteredRecords = filteredRecords.filter((req) =>
        req.displayName
          .toLowerCase()
          .includes(filters.name.toLowerCase().trimEnd())
      );
    }
    setRecords(filteredRecords);
    setLoading(false);
  };

  const handleChange = (key, value) => {
    setFilters((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const cancelSearch = () => {
    history.goBack();
  };

  return (
    <div className="">
      <h3>Get Users</h3>
      <div className="card px-3 pb-4 my-3">
        <div className="row mt-4">
          <div className="col-12 col-sm-6 my-2">
            <label>Enter ID</label>
            <input
              type="text"
              className="form-control"
              placeholder="Enter ID"
              value={filters.id}
              onChange={(e) => handleChange("id", e.target.value)}
            />
          </div>
          <div className="col-12 col-sm-6 my-2">
            <label>Enter email</label>
            <input
              type="email"
              className="form-control"
              placeholder="Enter Email"
              value={filters.email}
              onChange={(e) => handleChange("email", e.target.value)}
            />
          </div>
          <div className="col-12 col-sm-6 my-2">
            <label>Enter name</label>
            <input
              type="text"
              className="form-control"
              placeholder="Enter Name"
              value={filters.name}
              onChange={(e) => handleChange("name", e.target.value)}
            />
          </div>
          <div className="col-12 col-sm-6 my-2">
            <h6 htmlFor="form-question">Role</h6>
            <select
              className="form-control font-small"
              aria-label=".form-select-sm"
              value={filters.role}
              onChange={(e) => handleChange("role", e.target.value)}
            >
              <option value="">Select Role</option>
              <option value="user">Users</option>
              <option value="admin">Admins</option>
              <option value="levelOne">Virtual Clinic Doctors</option>
              <option value="levelTwo">Medoc Doctors</option>
            </select>
          </div>
        </div>
        <div className="d-flex flex-row mt-2">
          <div className="btn btn-violet btn-sm" onClick={searchRecords}>
            Search
          </div>
          <div
            className="btn btn-outline-danger btn-sm ml-2"
            onClick={cancelSearch}
          >
            Cancel
          </div>
        </div>
      </div>
    </div>
  );
}

export default GetUsersComponent;
