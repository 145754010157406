export const ReportOptions = [
  {
    key: "copyright",
    title: "Copyrights Violated",
  },
  {
    key: "spam",
    title: "Spam / Promotional",
  },
  {
    key: "hate_speech",
    title: "Hate Speech",
  },
  {
    key: "violence",
    title: "Violence",
  },
  {
    key: "inappropriate",
    title: "Inappropriate",
  },
  {
    key: "other",
    title: "Other",
  },
];
