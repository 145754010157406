import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

import { update, uploadFile, removeFile } from "firebase_config";
import { Tables } from "constants/index";
import PaymentStatusComponent from "../payment_status/component";
import DoctorRemarksComponent from "../doctor_remarks/component";
import AssignDoctorComponent from "../assign_doctor/component";

const UpdateForm1aComponent = ({
  currentUser,
  options,
  setOptions,
  searchValue,
  setSearchValue,
}) => {
  const [savingCertificate, setSavingCertificate] = useState(false);
  const [error, setError] = useState({
    message: "",
    key: "",
  });
  const [optionalDocLoading, setOptionalDocLoading] = useState(false);
  const [files, setFiles] = useState(options.files);
  const history = useHistory();

  const handleChange = (key, value, additionalField) => {
    if (additionalField) {
      setOptions({
        ...options,
        [key]: {
          ...options[key],
          [additionalField]: value,
        },
      });
    } else {
      setOptions({
        ...options,
        [key]: value,
      });
    }
  };

  const uploadImage = async (file, key, event) => {
    if (!file) {
      toast.error("New image required");
      return null;
    }
    setOptionalDocLoading(true);
    await uploadFile(file, "image", async (url, err) => {
      if (err) {
        alert(err);
        return null;
      }
      event.target.value = null;
      if (key === "certLink") {
        setOptions((prev) => ({
          ...options,
          certLink: url,
        }));
      } else {
        setFiles([
          ...files,
          {
            name: file.name,
            type: file.type,
            url,
          },
        ]);
      }
      setOptionalDocLoading(false);
    });
  };

  const deleteImage = async (url, key, event) => {
    if (window.confirm("Are you sure to remove file?")) {
      setOptionalDocLoading(true);

      await removeFile(url);

      if (key === "certLink") {
        setOptions((prev) => ({
          ...options,
          certLink: "",
          certLinkShort: "",
        }));
      } else {
        const f = files.filter((f) => f.url !== url);
        setFiles(f);
      }

      setOptionalDocLoading(false);
    }
  };

  const showErrors = () => {
    let error;
    if (!options.firstName) {
      error = {
        message: "First name is required",
        key: "firstName",
      };
    } else if (!options.lastName) {
      error = {
        message: "Last name is required",
        key: "lastName",
      };
    } else if (!options.dob) {
      error = {
        message: "Date of birth is required",
        key: "dob",
      };
    } else if (!options.age) {
      error = {
        message: "Age is required",
        key: "age",
      };
    } else if (!options.email) {
      error = {
        message: "Email is required",
        key: "email",
      };
    } else if (!options.mobile) {
      error = {
        message: "Mobile number is required",
        key: "mobile",
      };
    } else if (options.address && !options.address.door) {
      error = {
        message: "Door number is required",
        key: "door",
      };
    } else if (options.address && !options.address.streetName) {
      error = {
        message: "Street name is required",
        key: "streetName",
      };
    } else if (options.address && !options.address.city) {
      error = {
        message: "City is required",
        key: "city",
      };
    } else if (options.address && !options.address.state) {
      error = {
        message: "State is required",
        key: "state",
      };
    } else if (options.address && !options.address.zipcode) {
      error = {
        message: "Postal / Zip code is required ",
        key: "zipcode",
      };
    } else if (files && files.length < 3) {
      error = {
        message:
          "Upload Govt. ID (both front and back) and a Passport size photo",
        key: "files",
      };
    } else if (!options.reasonDescription) {
      error = {
        message: "Birth marks required",
        key: "reasonDescription",
      };
    }
    setError(error);
    return error;
  };

  const saveRequest = async () => {
    const err = await showErrors();
    if (err && err.message) {
      toast.error(err.message);
      return;
    }
    setSavingCertificate(true);
    if (files.length > 0) {
      options.files = files;
    }
    options.assigned = !!options.doctorId;

    const res = await update(Tables.MEDICAL_REQUESTS, searchValue, options);
    setSavingCertificate(false);
    if (res.status === 200) {
      toast.success(`Request updated. ${searchValue}`);
      history.goBack();
    } else {
      setError("Something went wrong. Refresh the page");
    }
  };

  return (
    <>
      <div className="dashboard-content">
        <div className="container create-post-wrapper">
          <div className="card p-2 p-sm-4 my-sm-5">
            <div className="font-small text-violet fw-500">
              Note: Online Certificates are not valid in the state of
              Maharashtra for Form 1A. Please check the list of outlined Doctors
              by RTO for your zipcode.
            </div>
            <hr />
            <div className="mt-2">
              <h6>
                Full name of the person * <br />
                <small className="text-secondary fs-10">
                  *As per Govt ID proof
                </small>
              </h6>
              <div className="row">
                <div className="col-12 col-sm-6">
                  <input
                    type="text"
                    className={`form-control font-small ${
                      error?.key === "firstName" && "error-border"
                    }`}
                    name="form-question"
                    placeholder="First name"
                    value={options.firstName}
                    onChange={(e) => handleChange("firstName", e.target.value)}
                  />
                  {error?.key === "firstName" ? (
                    <small className="text-danger fs-10">{error.message}</small>
                  ) : (
                    <small className="text-secondary fs-10">First name</small>
                  )}
                </div>
                <div className="col-12 col-sm-6 mt-4 mt-sm-0">
                  <input
                    type="text"
                    className={`form-control font-small ${
                      error?.key === "lastName" && "error-border"
                    }`}
                    name="form-question"
                    placeholder="Last name"
                    value={options.lastName}
                    onChange={(e) => handleChange("lastName", e.target.value)}
                  />
                  {error?.key === "lastName" ? (
                    <small className="text-danger fs-10">{error.message}</small>
                  ) : (
                    <small className="text-secondary fs-10">Last name</small>
                  )}
                </div>
              </div>
            </div>
            <div className="row mt-4">
              <div className="col-12 col-sm-6">
                <h6>Date of birth *</h6>
                <input
                  type="date"
                  className={`form-control ${
                    error?.key === "dob" && "error-border"
                  }`}
                  name="form-question"
                  placeholder="dob"
                  value={options.dob}
                  onChange={(e) => handleChange("dob", e.target.value)}
                />
                {error?.key === "dob" && (
                  <small className="text-danger fs-10">{error.message}</small>
                )}
              </div>
              <div className="col-12 col-sm-6 mt-3 mt-sm-0">
                <h6>Age *</h6>
                <input
                  type="number"
                  className={`form-control ${
                    error?.key === "age" && "error-border"
                  }`}
                  name="form-question"
                  placeholder="Age"
                  value={options.age}
                  onChange={(e) => handleChange("age", e.target.value)}
                />
                {error?.key === "age" && (
                  <small className="text-danger fs-10">{error.message}</small>
                )}
              </div>
            </div>
            <div className="mt-4">
              <h6>Gender *</h6>
              <select
                className="form-control font-small"
                aria-label=".form-select-sm"
                value={options.gender}
                onChange={(e) => handleChange("gender", e.target.value)}
              >
                <option value="male">Male</option>
                <option value="female">Female</option>
                <option value="other">Other</option>
              </select>
            </div>
            <div className="row mt-4">
              <div className="col-12 col-sm-6">
                <h6 htmlFor="form-question">Email *</h6>
                <input
                  type="email"
                  className={`form-control ${
                    error?.key === "email" && "error-border"
                  }`}
                  name="form-question"
                  placeholder="Email"
                  value={options.email}
                  onChange={(e) => handleChange("email", e.target.value)}
                />
                {error?.key === "email" && (
                  <small className="text-danger fs-10">{error.message}</small>
                )}
              </div>
              <div className="col-12 col-sm-6 mt-4 mt-sm-0">
                <h6 htmlFor="form-question">Mobile *</h6>
                <input
                  type="number"
                  className={`form-control ${
                    error?.key === "mobile" && "error-border"
                  }`}
                  name="form-question"
                  placeholder="917890789090"
                  value={options.mobile}
                  onChange={(e) => handleChange("mobile", e.target.value)}
                />
                <small className="text-secondary fs-10">
                  Country code is mandatory. e.g. 917890789078.
                </small>
                {error?.key === "mobile" && (
                  <div className="text-danger fs-10">{error.message}</div>
                )}
              </div>
              <div className="col-12 mt-4">
                <h6>
                  Address *<br />
                  <small className="text-secondary fs-10">
                    *As per Govt ID proof.
                  </small>
                </h6>
                <div className="row">
                  <div className="col-12 col-sm-6 my-2">
                    <input
                      type="text"
                      className={`form-control ${
                        error?.key === "door" && "error-border"
                      }`}
                      name="form-question"
                      placeholder="Door no."
                      value={options.address && options.address.door}
                      onChange={(e) =>
                        handleChange("address", e.target.value, "door")
                      }
                    />
                    {error?.key === "door" ? (
                      <small className="text-danger fs-10">
                        {error.message}
                      </small>
                    ) : (
                      <small className="text-secondary fs-10">
                        Door number
                      </small>
                    )}
                  </div>
                  <div className="col-12 col-sm-6 my-2">
                    <input
                      type="text"
                      className={`form-control ${
                        error?.key === "streetName" && "error-border"
                      }`}
                      name="form-question"
                      placeholder="Street name"
                      value={options.address && options.address.streetName}
                      onChange={(e) =>
                        handleChange("address", e.target.value, "streetName")
                      }
                    />
                    {error?.key === "streetName" ? (
                      <small className="text-danger fs-10">
                        {error.message}
                      </small>
                    ) : (
                      <small className="text-secondary fs-10">
                        Street name
                      </small>
                    )}
                  </div>
                  <div className="col-12 col-sm-6 my-2">
                    <input
                      type="text"
                      className={`form-control ${
                        error?.key === "city" && "error-border"
                      }`}
                      name="form-question"
                      placeholder="City"
                      value={options.address && options.address.city}
                      onChange={(e) =>
                        handleChange("address", e.target.value, "city")
                      }
                    />
                    {error?.key === "city" ? (
                      <small className="text-danger fs-10">
                        {error.message}
                      </small>
                    ) : (
                      <small className="text-secondary fs-10">City</small>
                    )}
                  </div>
                  <div className="col-12 col-sm-6 my-2">
                    <input
                      type="text"
                      className={`form-control ${
                        error?.key === "state" && "error-border"
                      }`}
                      name="form-question"
                      placeholder="State / Region / Province"
                      value={options.address && options.address.state}
                      onChange={(e) =>
                        handleChange("address", e.target.value, "state")
                      }
                    />
                    {error?.key === "state" ? (
                      <small className="text-danger fs-10">
                        {error.message}
                      </small>
                    ) : (
                      <small className="text-secondary fs-10">
                        State / Region / Province
                      </small>
                    )}
                  </div>
                  <div className="col-12 col-sm-6 my-2">
                    <input
                      type="text"
                      className={`form-control ${
                        error?.key === "zipcode" && "error-border"
                      }`}
                      name="form-question"
                      placeholder="Postal/Zip Code"
                      value={options.address && options.address.zipcode}
                      onChange={(e) =>
                        handleChange("address", e.target.value, "zipcode")
                      }
                    />
                    {error?.key === "zipcode" ? (
                      <small className="text-danger fs-10">
                        {error.message}
                      </small>
                    ) : (
                      <small className="text-secondary fs-10">
                        Postal / Zip code
                      </small>
                    )}
                  </div>
                  <div className="col-12 col-sm-6 my-2">
                    <select
                      className="form-control font-small"
                      aria-label=".form-select-sm"
                      value={options.address && options.address.country}
                      onChange={(e) =>
                        handleChange("address", e.target.value, "country")
                      }
                    >
                      <option value="india" defaultValue>
                        India
                      </option>
                    </select>
                    <small className="text-secondary fs-10">Country</small>
                  </div>
                </div>
              </div>
            </div>
            {/* documents */}
            <div className="mt-4">
              <h6>
                Add documents *
                <div className="text-secondary fs-10 pt-2 fw-400">
                  Upload Govt. issued ID proof (both front and back), Passport
                  size photo, Form 1A from RTO website.
                </div>
              </h6>
              {files &&
                files.map((file, idx) => (
                  <div
                    className="alert alert-secondary px-2 py-2 align-items-center"
                    key={idx}
                  >
                    <span className="font-small">{file.name}</span>
                    <i
                      className="fa fa-trash pull-right text-danger pt-1"
                      onClick={(e) => deleteImage(file.url, "files", e)}
                    />
                  </div>
                ))}
              {optionalDocLoading ? (
                <i className="fa fa-spinner fa-spin" />
              ) : (
                <div>
                  <label
                    htmlFor="optionalDoc"
                    className="pointer btn btn-violet-outline btn-sm"
                  >
                    <i className="fa fa-plus" />
                  </label>
                  <input
                    type="file"
                    className="form-control-plaintext d-none"
                    id="optionalDoc"
                    onChange={(e) =>
                      uploadImage(e.target.files[0], "optionalDoc", e)
                    }
                    accept="application/*,image/*,video/*"
                  />
                </div>
              )}
              {error?.key === "files" && (
                <small className="text-danger fs-10 mb-2">
                  {error.message}
                </small>
              )}
            </div>
            <div className="mt-4">
              <h6>
                Birth marks (moles) *
                <div className="text-secondary fs-10 pt-2 fw-400">
                  Two birth marks (moles) are supposed to be outlined in the
                  Form 1A.
                </div>
              </h6>
              <textarea
                className={`form-control fs-12 ${
                  error?.key === "reasonDescription" && "error-border"
                }`}
                name="form-question"
                rows="2"
                placeholder="Type here."
                value={options.reasonDescription}
                onChange={(e) =>
                  handleChange("reasonDescription", e.target.value)
                }
              />
              {error?.key === "reasonDescription" && (
                <small className="text-danger fs-10">{error.message}</small>
              )}
            </div>
          </div>
          <div className={`${currentUser.isDoctor && "d-none"}`}>
            <AssignDoctorComponent
              handleChange={handleChange}
              options={options}
              setOptions={setOptions}
            />
            <PaymentStatusComponent
              handleChange={handleChange}
              options={options}
            />
            <DoctorRemarksComponent
              handleChange={handleChange}
              options={options}
              setOptions={setOptions}
            />
            <div className="mt-4">
              <h6>Upload Form 1A Certificate</h6>
              {options.certLink && (
                <div className="alert alert-secondary px-2 py-2 align-items-center">
                  <span className="font-small">Certificate</span>
                  <i
                    className="fa fa-trash pull-right text-danger pt-1"
                    onClick={(e) =>
                      deleteImage(options.certLink, "certLink", e)
                    }
                  />
                </div>
              )}
              {optionalDocLoading ? (
                <i className="fa fa-spinner fa-spin" />
              ) : (
                <div>
                  <label
                    htmlFor="certLink"
                    className="pointer btn btn-violet-outline btn-sm"
                  >
                    <i className="fa fa-plus" />
                  </label>
                  <input
                    type="file"
                    className="form-control-plaintext d-none"
                    id="certLink"
                    onChange={(e) =>
                      uploadImage(e.target.files[0], "certLink", e)
                    }
                    accept="application/pdf"
                  />
                </div>
              )}
              {error?.key === "certLink" && (
                <small className="text-danger fs-10 mb-2">
                  {error.message}
                </small>
              )}
            </div>
          </div>
          <div
            className="complete-payment btn btn-violet text-center"
            onClick={saveRequest}
          >
            {savingCertificate ? (
              <i className="fa fa-spinner fa-spin" />
            ) : (
              <span>Save Record</span>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default UpdateForm1aComponent;
