import React, { useState } from "react";
import { Link } from "react-router-dom";

import { StaticHeaderComponent } from "components";
import { sendForgotPassword } from "firebase_config";

const ForgotPasswordComponent = () => {
  const [email, setEmail] = useState("");
  const [btnSave, setBtnSave] = useState("Send");
  const [result, setResult] = useState({});

  const handleForm = async (e) => {
    e.preventDefault();
    setBtnSave("Sending...");
    const res = await sendForgotPassword(email);
    setResult(res);
    if (res.status === 200) setBtnSave("Sent");
    else setBtnSave("Send");
  };

  return (
    <div className="forgot-pw-page" id="forgot_pw">
      <StaticHeaderComponent />
      <div className="container login-form">
        <div className="row">
          <div className="col-12 right-block mb-5">
            <div className="header">
              <p className="h5 mb-3">Forgot password</p>
            </div>
            <div className="body">
              <div className="input-group mb-3">
                <input
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  name="email"
                  type="email"
                  className="form-control"
                  placeholder="Email"
                  autoFocus={true}
                  aria-label="email"
                  aria-describedby="basic-addon1"
                />
              </div>
              <div>
                <button
                  className="btn login-btn col-12"
                  disabled={btnSave !== "Send" || !email}
                  onClick={(e) => handleForm(e)}
                >
                  {btnSave}
                </button>
              </div>
              <div className="text-center pt-2">
                <Link to="/login" className="text-violet fw-500">
                  Go back
                </Link>
              </div>
              {result.status && (
                <div
                  className={`text-center mt-3 ${
                    result.status === 200 ? "text-violet" : "text-danger"
                  }`}
                >
                  {result.message}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPasswordComponent;
