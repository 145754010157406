import React, { useState } from "react";
import { toast } from "react-toastify";

import { HeaderComponent, LoadingComponent } from "components";
import FiltersComponent from "./filters/component";

function GetUsersComponent({ currentUser }) {
  const [loading, setLoading] = useState(false);
  const [records, setRecords] = useState();

  const copyToClipboard = (val) => {
    navigator.clipboard.writeText(val);
    toast.success(`Copied value: ${val}`);
  };

  return (
    <div className="">
      <HeaderComponent />
      <div className="container settings-wrapper desktop-align-center pt-5">
        <FiltersComponent
          currentUser={currentUser}
          records={records}
          setRecords={setRecords}
          setLoading={setLoading}
        />
        <table className="table table-bordered table-responsive">
          <thead>
            <tr>
              <th>Clinic ID</th>
              <th>Doctor ID</th>
              <th>Name</th>
              <th>Email</th>
              <th>Mobile</th>
              <th>Qualification</th>
              <th>Clinic URL</th>
            </tr>
          </thead>
          <tbody>
            {records?.length &&
              records.map((req) => (
                <tr key={req.id}>
                  <td onClick={(e) => copyToClipboard(req.id)}>{req.id}</td>
                  <td onClick={(e) => copyToClipboard(req.userId)}>
                    {req.userId}
                  </td>
                  <td onClick={(e) => copyToClipboard(req.name)}>{req.name}</td>
                  <td onClick={(e) => copyToClipboard(req.email)}>
                    {req.email}
                  </td>
                  <td onClick={(e) => copyToClipboard(req.mobile)}>
                    {req.mobile}
                  </td>
                  <td onClick={(e) => copyToClipboard(req.qualification)}>
                    {req.qualification}
                  </td>
                  <td
                    onClick={(e) =>
                      window.open(`https://medoc.life/dr/${req.username}`)
                    }
                    className="text-violet fs-12 pointer"
                  >
                    https://medoc.life/dr/{req.username}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
        {loading && <LoadingComponent />}
      </div>
    </div>
  );
}

export default GetUsersComponent;
