import React from "react";
import { Link } from "react-router-dom";

import { HeaderComponent, PublicDoctorHomeComponent } from "components";
import { Routes } from "constants/index";

const AssignRequestToDoctorComponent = ({ currentUser }) => {
  return (
    <div className="dashboard-content mt-5 pt-5">
      <HeaderComponent />
      <div className="text-center mt-3 col-12">
        <Link to={Routes.APP_DOCTOR} className="btn btn-violet m-2">
          <i className="fa fa-file-text-o pr-2" /> Assign Request
        </Link>
        <Link to={Routes.APP_REQUESTS} className="btn btn-violet m-2">
          My Certificates
        </Link>
        <div className="btn btn-violet col-6 m-2 d-none">Report an issue</div>
      </div>
      <hr />
      <PublicDoctorHomeComponent currentUser={currentUser} />
    </div>
  );
};

export default AssignRequestToDoctorComponent;
