import React from "react";

const MedicalRequestsComponent = ({ currentUser, options, handleChange }) => {
  return (
    <>
      <div>
        <div className="mt-4">
          <h6>Payment Status</h6>
          <select
            className="form-control font-small"
            aria-label=".form-select-sm"
            value={options.paymentStatus}
            onChange={(e) => handleChange("paymentStatus", e.target.value)}
          >
            <option value="pending">Pending</option>
            <option value="completed">Completed</option>
          </select>
        </div>
        <div className="mt-4">
          <h6>Give access to upload ID </h6>
          <select
            className="form-control font-small"
            aria-label=".form-select-sm"
            value={options.isEditRequired}
            onChange={(e) =>
              handleChange("isEditRequired", e.target.value === "true")
            }
          >
            <option value="false" defaultValue>
              No
            </option>
            <option value="true">Yes</option>
          </select>
        </div>
        <div className="mt-4">
          <h6>Request Status </h6>
          <select
            className="form-control font-small"
            aria-label=".form-select-sm"
            value={options.status}
            onChange={(e) => handleChange("status", e.target.value)}
          >
            <option value="pending" defaultValue>
              Pending
            </option>
            <option value="accepted">Accepted</option>
            <option value="rejected">Rejected</option>
          </select>
        </div>
      </div>
    </>
  );
};

export default MedicalRequestsComponent;
