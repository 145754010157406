import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { Helmet } from "react-helmet";
import { Metadata, Routes } from "constants/index";
import { gtokFavicon } from "images";
import "./style.css";

const StaticHeaderComponent = ({ routes, bottomFooter }) => {
  const [metaDetails, setMetaDetails] = useState({});
  useEffect(() => {
    const path = window.location.pathname;
    Object.keys(Metadata).map((key) => {
      if (key.includes(path)) {
        setMetaDetails(Metadata[key]);
      }
      return key;
    });
  }, [metaDetails]);

  return (
    <div className="static-header">
      <Helmet>
        <title>{metaDetails.title}</title>
        <meta name="description" content={metaDetails.description} />
        <meta name="keywords" content={metaDetails.keywords} />
        <link rel="icon" type="image/png" href={gtokFavicon} sizes="16x16" />
      </Helmet>
      <Link
        to={Routes.LOGIN}
        className={
          bottomFooter === "false" ? "d-none" : "add-on-text fixed-bottom"
        }
      >
        <small>&#128994;</small> Doctor Buddies are online
      </Link>
      <nav className="navbar navbar-expand-lg navbar-light fixed-top">
        <div className="container">
          <div className="navbar-brand">
            <Link to={Routes.LOGIN} className="home-page-title fw-600">
              medoc
            </Link>
          </div>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav ml-auto align-items-center">
              <li className="nav-item">
                <div className="nav-link fw-500">
                  <a
                    href={Routes.ABOUT_US}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-violet"
                  >
                    About Us
                  </a>
                </div>
              </li>
              <li className="nav-item px-3">
                <div className="nav-link fw-500">
                  <a
                    href={Routes.CONTACT_US}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-violet"
                  >
                    Contact Us
                  </a>
                </div>
              </li>
              <li className="nav-item px-3 d-none">
                <div className="nav-link fw-500">
                  <Link to={Routes.LOGIN} className="text-violet">
                    Blog
                  </Link>
                </div>
              </li>
              <li className="nav-item d-none">
                <div className="nav-link">
                  <Link to={Routes.LOGIN} className="btn btn-violet py-2 px-4">
                    Seek Advice
                  </Link>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default StaticHeaderComponent;
