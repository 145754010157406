// returns 0-6 where 0 is Sunday, 1 is Monday, 2 is Tues, 3 - Wed, 4 - Thu, 5 - Fri, 6 - Sat
// Ref: https://www.geeksforgeeks.org/moment-js-moment-day-method/

export const Doctors = [
  {
    id: "pui63u2n6YriQB5LaE4a",
    displayName: "Jayaprakash",
    email: "jayaprakash@medoc.life",
    mobile: "+918484953095",
    availableDays: [0, 1, 2, 3, 4, 5, 6],
  },
  {
    id: "4LRBHp0qshk1BLUCuTka",
    displayName: "Vijith",
    email: "vijith@medoc.life",
    mobile: "+916361014511",
    availableDays: [],
  },
  {
    id: "U5U7HH4KSrsg5I3e3eZa",
    displayName: "Rinki",
    email: "rinki@medoc.life",
    mobile: "+9174997794741",
    availableDays: [],
  },
];
