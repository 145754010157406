import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import moment from "moment";

import { update, getQuery, firestore } from "firebase_config";
import { Tables } from "constants/index";
import { post } from "services";

function GetFiltersComponent({
  currentUser,
  records,
  setRecords,
  setLoading,
  clinic,
  setClinic,
}) {
  const [earnings, setEarnings] = useState({
    total: 0,
    textType: 0,
    audioType: 0,
    videoType: 0,
  });
  const [filters, setFilters] = useState({
    email: "",
    name: "",
    appointmentId: "",
    doctorId: "",
    startDate: "",
    endDate: "",
  });
  const history = useHistory();

  const reloadRecords = async () => {
    const req = await getQuery(
      firestore
        .collection(Tables.DC_PUBLIC_APPOINTMENTS)
        .where("paymentStatus", "==", "completed")
        .orderBy("updatedAt", "desc")
        .get()
    );
    return req.sort((a, b) => b.updatedAt - a.updatedAt);
  };

  const searchRecords = async () => {
    const startDate = new Date(filters?.startDate).getTime();
    const endDate = new Date(filters?.endDate).getTime();
    if (filters.startDate && filters.endDate && startDate > endDate) {
      toast.error("Start Date must be less than End Date");
      return;
    }

    setLoading(true);
    let filteredRecords = await reloadRecords();
    if (filters.appointmentId) {
      filteredRecords = filteredRecords.filter(
        (req) => req.id === filters.appointmentId.trim()
      );
    }
    if (filters.doctorId) {
      filteredRecords = filteredRecords.filter(
        (req) => req.doctorId === filters.doctorId.trim()
      );
    }
    if (filters.email) {
      filteredRecords = filteredRecords.filter(
        (req) => req.email === filters.email?.toLowerCase().trim()
      );
    }
    if (filters.name) {
      filteredRecords = filteredRecords.filter((req) =>
        req.name.toLowerCase().includes(filters.name?.toLowerCase().trim())
      );
    }
    if (filters.startDate) {
      filteredRecords = filteredRecords.filter(
        (req) => req.createdAt >= startDate
      );
    }
    if (filters.endDate) {
      filteredRecords = filteredRecords.filter(
        (req) => req.createdAt <= endDate
      );
    }

    if (filteredRecords?.length) {
      setEarnings({
        total: filteredRecords.reduce(function (acc, obj) {
          return acc + (+obj.product.cost || +obj.product.price);
        }, 0),
        textType: filteredRecords.filter(
          (rec) => rec.type === "text" || rec.key === "text"
        ).length,
        audioType: filteredRecords.filter(
          (rec) => rec.type === "audio" || rec.key === "audio"
        ).length,
        videoType: filteredRecords.filter(
          (rec) => rec.type === "video" || rec.key === "video"
        ).length,
        textTypeEarnings: filteredRecords
          .filter((rec) => rec.type === "text" || rec.key === "text")
          .reduce(function (acc, obj) {
            return acc + (+obj.product.cost || +obj.product.price);
          }, 0),
        audioTypeEarnings: filteredRecords
          .filter((rec) => rec.type === "audio" || rec.key === "audio")
          .reduce(function (acc, obj) {
            return acc + (+obj.product.cost || +obj.product.price);
          }, 0),
        videoTypeEarnings: filteredRecords
          .filter((rec) => rec.type === "video" || rec.key === "video")
          .reduce(function (acc, obj) {
            return acc + (+obj.product.cost || +obj.product.price);
          }, 0),
      });
    }

    setRecords(filteredRecords);
    setLoading(false);
  };

  const handleChange = (key, value) => {
    setFilters((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const cancelSearch = () => {
    history.goBack();
  };

  const completePayment = async () => {
    if (!filters.doctorId || !filters.startDate || !filters.endDate) {
      toast.error("Doctor ID, Start Date, End Date are required");
      return;
    }
    let payments;
    let cd = await getQuery(
      firestore
        .collection(Tables.DC_PUBLIC_PROFILE)
        .where("userId", "==", filters.doctorId.trim())
        .get()
    );
    if (!cd || !cd[0]) {
      toast.error("No Clinic found");
      return;
    }
    cd = cd[0];
    const newPayment = {
      startDate: filters.startDate,
      endDate: filters.endDate,
      totalAppointments: records.length,
      totalAmount: earnings.total,
      tdsAmount: 0, // Need to be dynamic
      serviceChargeAmount: (earnings.total * 0.1).toFixed(2), // Need to be dynamic
      netAmount: (earnings.total * 0.9).toFixed(2), // Need to be dynamic
      types: [
        {
          key: "audio",
          title: "Audio Call",
          records: earnings.audioType || 0,
          amount: earnings.audioTypeEarnings || 0,
        },
        {
          key: "video",
          title: "Video Call",
          records: earnings.videoType || 0,
          amount: earnings.videoTypeEarnings || 0,
        },
        {
          key: "text",
          title: "Text Query",
          records: earnings.textType || 0,
          amount: earnings.textTypeEarnings || 0,
        },
      ],
      paymentTransferDate: moment().format("DD MM YYYY"),
    };

    if (cd.payments) {
      payments = [newPayment, ...cd.payments];
    } else {
      payments = [newPayment];
    }
    setClinic(cd);
    if (window.confirm("Are you sure to complete payment?")) {
      const res = await update(Tables.DC_PUBLIC_PROFILE, cd.id, { payments });
      await post("/send_email", {
        templateName: "virtual_clinic_payment_receipt.html",
        email: cd.email,
        subject: "Your Virtual Clinic Monthly Payment Arrived!",
        displayName: cd.name,
        paymentMonth: moment(newPayment.startDate).format("MMM YYYY"),
        newPayment,
      });
      if (res.status === 200) {
        toast.success("Successfully updated");
      }
    }
  };

  return (
    <div className="">
      <h3>Get Clinic Appointments</h3>
      <div className="card px-3 pb-4 my-3">
        <div className="row mt-4">
          <div className="col-12 col-sm-6 my-2">
            <label>Enter Appointment ID</label>
            <input
              type="text"
              className="form-control"
              placeholder="Enter Appointment ID"
              value={filters.appointmentId}
              onChange={(e) => handleChange("appointmentId", e.target.value)}
            />
          </div>
          <div className="col-12 col-sm-6 my-2">
            <label>Enter Doctor ID</label>
            <input
              type="text"
              className="form-control"
              placeholder="Enter Doctor ID"
              value={filters.doctorId}
              onChange={(e) => handleChange("doctorId", e.target.value)}
            />
          </div>
          <div className="col-12 col-sm-6 my-2">
            <label>Enter email</label>
            <input
              type="email"
              className="form-control"
              placeholder="Enter Email"
              value={filters.email}
              onChange={(e) => handleChange("email", e.target.value)}
            />
          </div>
          <div className="col-12 col-sm-6 my-2">
            <label>Enter name</label>
            <input
              type="text"
              className="form-control"
              placeholder="Enter Name"
              value={filters.name}
              onChange={(e) => handleChange("name", e.target.value)}
            />
          </div>
          <div className="col-12 col-sm-6 my-2">
            <label>Start Date</label>
            <input
              type="date"
              className="form-control"
              placeholder="DD/MM/YYYY"
              max={filters.startDate}
              value={filters.startDate}
              onChange={(e) => handleChange("startDate", e.target.value)}
            />
          </div>
          <div className="col-12 col-sm-6 my-2">
            <label>End Date</label>
            <input
              type="date"
              className="form-control"
              placeholder="DD/MM/YYYY"
              min={filters.startDate}
              value={filters.endDate}
              onChange={(e) => handleChange("endDate", e.target.value)}
            />
          </div>
        </div>
        <div className="d-flex flex-row mt-2">
          <div className="btn btn-violet btn-sm" onClick={searchRecords}>
            Search
          </div>
          <div
            className="btn btn-outline-danger btn-sm ml-2"
            onClick={cancelSearch}
          >
            Cancel
          </div>
        </div>
      </div>
      {filters.doctorId &&
        filters.startDate &&
        filters.endDate &&
        records?.length && (
          <div className="card p-3">
            <div>Start Date: {filters.startDate || "-"}</div>
            <div>End Date: {filters.endDate || "-"}</div>
            <div>Total Appointments: {records.length}</div>
            <div>Total Earnings: {earnings.total} INR</div>
            <div>TDS Deductions: 0 INR</div>
            <div>
              Service Charge Deductions (10%):{" "}
              {(earnings.total * 0.1).toFixed(2)} INR
            </div>
            <div>
              Net Amount (after deductions): {(earnings.total * 0.9).toFixed(2)}{" "}
              INR
            </div>
            <div>Audio Type: {earnings.audioType}</div>
            <div>Video Type: {earnings.videoType}</div>
            <div>Text Type: {earnings.textType}</div>
            <div>Audio Type Earnings: {earnings.audioTypeEarnings || 0}</div>
            <div>Video Type Earnings: {earnings.videoTypeEarnings || 0}</div>
            <div>Text Type Earnings: {earnings.textTypeEarnings || 0}</div>
            <div
              className="btn btn-violet btn-sm col-3"
              onClick={completePayment}
            >
              Complete Payment
            </div>
          </div>
      )}
    </div>
  );
}

export default GetFiltersComponent;
