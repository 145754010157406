import React, { useState } from "react";
import { toast } from "react-toastify";
import { ENV } from "constants/env_keys";

function PayoutComponent({
  currentUser,
  wallet,
  setStepNumber,
  withdrawAmount,
  setWithdrawAmount,
  accountName,
  setAccountName,
  accountNumber,
  setAccountNumber,
  ifscCode,
  setIfscCode,
  loading,
  goBack,
}) {
  const [withdrawAmountAlerts, setWithdrawAmountAlerts] = useState("");

  const handleSubmit = () => {
    if (withdrawAmount > +currentUser.withdrawAmount) {
      setWithdrawAmountAlerts({
        message: "Insufficient withdrawal amount",
        classname: "withdraw-danger-alert",
      });
    } else if (withdrawAmount < +ENV.MIN_DEPOSIT_AMOUNT) {
      setWithdrawAmountAlerts({
        message: `Minimum withdrawal amount is ${ENV.CURRENCY}${ENV.MIN_DEPOSIT_AMOUNT}`,
        classname: "withdraw-danger-alert",
      });
    } else if (withdrawAmount > +ENV.MIN_DEPOSIT_AMOUNT) {
      setWithdrawAmountAlerts({
        message: "10% service fee & other taxes will be included",
        classname: "withdraw-success-alert",
      });
    } else if (withdrawAmount > +ENV.MAX_WITHDRAW_AMOUNT) {
      setWithdrawAmountAlerts({
        message: `You cannot withdraw more than ${ENV.CURRENCY}${ENV.MAX_WITHDRAW_AMOUNT} in a single withdrawal request`,
        classname: "withdraw-danger-alert",
      });
    } else if (ifscCode.length > 8) {
      toast.error("Sort code is incorrect");
      return null;
    } else {
      setWithdrawAmountAlerts("");
      setStepNumber(2);
    }
  };

  const handleIfscCode = (e) => {
    const reg = /^\d+$/;
    let val = ifscCode;

    if (reg.test(e.key)) {
      if (val.length === 2 || val.length === 5) {
        val += "-" + e.key;
      } else {
        val += e.key;
      }
    } else if (e.key === "Backspace") {
      if (val.length === 4 || val.length === 7) {
        val = ifscCode.slice(0, ifscCode.length - 2);
      } else {
        val = ifscCode.slice(0, ifscCode.length - 1);
      }
    }

    setIfscCode(val);
  };

  return (
    <div className="container desktop-align-center">
      <div className="wallet-withdraw-wrapper">
        <div className="">
          <div className="">
            <div className="form-group">
              <label className="form-label pb-1">Currency</label>
              <select
                className="form-control font-small"
                aria-label=".form-select-sm"
              >
                <option defaultValue>British Pound (£)</option>
              </select>
            </div>
            <div className="form-group">
              <label className="form-label pb-1">Withdraw</label>
              <input
                type="number"
                className="form-control"
                placeholder={ENV.MIN_DEPOSIT_AMOUNT}
                onChange={(e) => setWithdrawAmount(e.target.value)}
                value={withdrawAmount}
              />
              {!!withdrawAmountAlerts && (
                <div className={`${withdrawAmountAlerts.classname}`}>
                  {withdrawAmountAlerts.message}
                </div>
              )}
            </div>
            <h6 className="my-4">Payee details</h6>
            <div className="form-group">
              <label className="form-label">Account name</label>
              <input
                type="text"
                className="form-control"
                placeholder="James Holder"
                onChange={(e) => setAccountName(e.target.value)}
                value={accountName}
              />
            </div>
            <div className="form-group">
              <label className="form-label">Account number</label>
              <input
                type="number"
                className="form-control"
                placeholder="12345678"
                onChange={(e) => setAccountNumber(e.target.value)}
                value={accountNumber}
              />
            </div>
            <div className="form-group">
              <label className="form-label">Sort code</label>
              <input
                type="text"
                className="form-control"
                placeholder="00-00-00"
                onKeyDown={(e) => handleIfscCode(e)}
                value={ifscCode}
                autoComplete="none"
                maxLength="8"
              />
            </div>
          </div>
          <div className="text-center mt-4">
            <button
              className="btn btn-sm btn-violet-outline pointer col-12 col-sm-5 m-sm-3 mt-2 py-2"
              disabled={
                !withdrawAmount || !accountName || !accountNumber || !ifscCode
              }
              onClick={handleSubmit}
            >
              {loading ? (
                <div className="spinner-border spinner-border-sm" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              ) : (
                <span>Next</span>
              )}
            </button>
            <button className="btn btn-sm btn-outline-secondary pointer col-12 col-sm-5 mx-0 mx-sm-2 my-4 py-2">
              <span>Cancel</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PayoutComponent;
