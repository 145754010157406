import React, { useState } from "react";
import { toast } from "react-toastify";

import { HeaderComponent, LoadingComponent } from "components";
import FiltersComponent from "./filters/component";

function GetAppointmentsComponent({ currentUser }) {
  const [loading, setLoading] = useState(false);
  const [records, setRecords] = useState();
  const [clinic, setClinic] = useState();

  const copyToClipboard = (val) => {
    navigator.clipboard.writeText(val);
    toast.success(`Copied value: ${val}`);
  };

  return (
    <div className="">
      <HeaderComponent />
      <div className="container settings-wrapper desktop-align-center pt-5">
        <FiltersComponent
          currentUser={currentUser}
          records={records}
          setRecords={setRecords}
          clinic={clinic}
          setClinic={setClinic}
          setLoading={setLoading}
        />
        <table className="table table-bordered table-responsive">
          <thead>
            <tr>
              <th>Appointment ID</th>
              <th>Doctor ID</th>
              <th>Patient Name</th>
              <th>Email</th>
              <th>Mobile</th>
              <th>Type</th>
              <th>Payment Status</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {records?.length &&
              records.map((req) => (
                <tr key={req.id}>
                  <td onClick={(e) => copyToClipboard(req.id)}>{req.id}</td>
                  <td onClick={(e) => copyToClipboard(req.doctorId)}>
                    {req.doctorId}
                  </td>
                  <td onClick={(e) => copyToClipboard(req.name)}>{req.name}</td>
                  <td>{req.email}</td>
                  <td>{req.mobile}</td>
                  <td>{req.product.type}</td>
                  <td>{req.paymentStatus}</td>
                  <td>{req.status}</td>
                </tr>
              ))}
          </tbody>
        </table>
        {loading && <LoadingComponent />}
      </div>
    </div>
  );
}

export default GetAppointmentsComponent;
