import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import moment from "moment";

import HeaderComponent from "../header";
import {
  add,
  update,
  uploadFile,
  removeFile,
  getQuery,
  firestore,
} from "firebase_config";
import { Tables, Routes, Products, Doctors, ENV } from "constants/index";
import { post } from "services";

const currency = "INR";
const products = Products.callDoctor;

const CallDoctorComponent = ({ currentUser }) => {
  const [options, setOptions] = useState({
    status: "pending",
    name: "",
    dob: "",
    mobile: "",
    email: "",
    message: "",
    gender: "male",
    userId: currentUser.id,
    assigned: false,
    product: products[0],
    paymentStatus: "pending",
    files: [],
    doctorRemarks: [],
    isPrescriptionRequired: false,
    doctorId: Doctors[0].id,
    type: "text",
    promocode: "",
  });
  const history = useHistory();
  const [smallDevice, setSmallDevice] = useState(false);
  const [countChars, setCountChars] = useState(25);
  const [savingCertificate, setSavingCertificate] = useState(false);
  const [error, setError] = useState({
    message: "",
    key: "",
  });
  const [optionalDocLoading, setOptionalDocLoading] = useState(false);
  const [applyDiscount, setApplyDiscount] = useState();
  const [discountPrice, setDiscountPrice] = useState();
  const [files, setFiles] = useState([]);

  useEffect(() => {
    if (window.innerWidth <= 576) {
      setSmallDevice(true);
    } else {
      setSmallDevice(false);
    }
  });

  const handleChange = (key, value, additionalField) => {
    if (key === "otherReason") {
      const max = 20;
      setCountChars(max - value.length);
    }
    if (additionalField) {
      setOptions({
        ...options,
        [key]: {
          ...options[key],
          [additionalField]: value,
        },
      });
    } else {
      setOptions({
        ...options,
        [key]: value,
      });
    }
    if (key === "product") {
      verifyPromoCode(value);
    }
  };

  const uploadImage = async (file, key, event) => {
    if (!file) {
      toast.error("New image required");
      return null;
    }
    setOptionalDocLoading(true);
    await uploadFile(file, "image", async (url, err) => {
      if (err) {
        alert(err);
        return null;
      }
      event.target.value = null;
      setFiles([
        ...files,
        {
          name: file.name,
          type: file.type,
          url,
        },
      ]);
      setOptionalDocLoading(false);
    });
  };

  const deleteImage = async (url, key, event) => {
    if (window.confirm("Are you sure to remove file?")) {
      setOptionalDocLoading(true);
      await removeFile(url);

      const f = files.filter((f) => f.url !== url);
      setFiles(f);

      setOptionalDocLoading(false);
    }
  };

  const redirectToGuildelines = () => {
    if (
      window.confirm("Your form data will be lost. Are you sure to redirect?")
    ) {
      history.push({
        pathname: Routes.APP_GUIDELINES_SLC,
        state: {
          options,
        },
      });
    }
  };

  const showErrors = () => {
    let error;
    if (!options.name) {
      error = {
        message: "Full name is required",
        key: "name",
      };
    } else if (!options.email) {
      error = {
        message: "Email is required",
        key: "email",
      };
    } else if (!options.mobile) {
      error = {
        message: "Mobile number is required",
        key: "mobile",
      };
    } else if (options.mobile && options.mobile.length !== 10) {
      error = {
        message: "Mobile number should contain 10-digits",
        key: "mobile",
      };
    } else if (!options.dob) {
      error = {
        message: "Date of birth is required",
        key: "dob",
      };
    } else if (!options.message) {
      error = {
        message: "Message is required",
        key: "message",
      };
    } else if (!options.product) {
      error = {
        message: "Choose a product",
        key: "product",
      };
    }
    setError(error);
    return error;
  };

  const saveRequest = async () => {
    const err = await showErrors();
    if (err && err.message) {
      toast.error(err.message);
      return;
    }
    setSavingCertificate(true);
    options.priority = options.product.priority;
    options.mobile = "91" + options.mobile;
    options.closeRequestTime = moment().valueOf() + 24 * 60 * 60 * 1000;
    if (discountPrice) {
      options.product = {
        ...options.product,
        price: discountPrice,
      };
    }
    if (files.length > 0) {
      options.files = files;
    }

    await saveCheckout();
  };

  const saveCheckout = async () => {
    setSavingCertificate(true);
    const res = await post("/razorpay/create_order", {
      amount: options.product?.price,
      currency: "INR",
      requestId: "",
    });

    await razorpayCheckout({
      order: res.data?.order,
    });
    setSavingCertificate(false);
  };

  const razorpayCheckout = ({ order }) => {
    const opts = {
      key: ENV.RAZORPAY_KEY_ID, // Enter the Key ID generated from the Dashboard
      amount: order.amount,
      currency: order.currency,
      name: "Medoc",
      description: options.product?.name,
      image:
        "https://storage.googleapis.com/medoc-live.appspot.com/images/razorpay_logo.jpeg",
      order_id: order.id,
      handler: async (response) => {
        options.paymentStatus = "completed";
        await add(Tables.DC_PUBLIC_APPOINTMENTS, options);

        toast.success(
          "We received your query. Soon our doctor will email/call you."
        );
        if (process.env.NODE_ENV !== "development") {
          await post("/send_email", {
            sender: "naga",
            templateName: "text_appointment_confirmation.html",
            email: options.email,
            bcc: [Doctors[0].email, "contact@medoc.life"],
            subject: `Thank you for contacting us. We will respond to your request soon`,
            doctorName: "Medoc",
          });
        }
        history.push(Routes.APP_POSTS);
      },
      prefill: {
        name: options.name,
        email: options.email,
        contact: options.mobile,
      },
      notes: {
        displayName: options.name,
        userId: currentUser.id,
        doctorId: options.doctorId,
        type: options.type,
      },
      theme: {
        color: "#684490",
      },
    };
    const rzPopup = new window.Razorpay(opts);
    rzPopup.on("payment.failed", function (response) {
      console.log("resp", response);
    });
    rzPopup.open();
  };

  const verifyPromoCode = async (val) => {
    const cc = await getQuery(
      firestore
        .collection(Tables.PROMOCODES)
        .where("code", "==", options.promocode)
        .get()
    );
    const pcode = cc[0];
    const unixDate = moment().unix() * 1000;
    if (
      pcode &&
      pcode.code === options.promocode &&
      unixDate < pcode.expiresOn
    ) {
      if (pcode.type === "limited" && pcode.count > 0) {
        // limited promocodes
        update(Tables.PROMOCODES, pcode.id, {
          count: pcode.count - 1,
        });
        let dp = options.product.price * (1 - pcode.discountPercent);
        if (val) {
          dp = val.price * (1 - pcode.discountPercent);
        }
        setApplyDiscount(pcode);
        setDiscountPrice(dp);
        setError({
          key: "",
          message: "",
        });
      } else if (pcode.type !== "limited") {
        // if type is influencer
        let dp = options.product.price * (1 - pcode.discountPercent);
        if (val) {
          dp = val.price * (1 - pcode.discountPercent);
        }
        setApplyDiscount(pcode);
        setDiscountPrice(dp);
        setError({
          key: "",
          message: "",
        });
      } else {
        setError({
          key: "promocode",
          message: "Code is invalid",
        });
      }
    } else {
      setError({
        key: "promocode",
        message: "Code is invalid",
      });
    }
  };

  return (
    <>
      <HeaderComponent headerText="Ask Doctor" />
      <div className="dashboard-content">
        <div className="container create-post-wrapper pt-5 mt-sm-5">
          <div className="card p-2 p-sm-4 my-sm-5">
            <p className="fs-12">
              Ask queries about sick leave, fitness, and all other medical
              certificates related. No waiting time. Instant response from
              doctor. Get 100% confirmation on your certificate request.
            </p>
            <div className="mt-2">
              <h6>Full name *</h6>
              <div className="row">
                <div className="col-12 col-sm-12">
                  <input
                    type="text"
                    className={`form-control font-small ${
                      error?.key === "name" && "error-border"
                    }`}
                    name="form-question"
                    placeholder="Full name"
                    onChange={(e) => handleChange("name", e.target.value)}
                  />
                  {error?.key === "name" && (
                    <small className="text-danger fs-10">{error.message}</small>
                  )}
                </div>
                <div className="col-12 col-sm-6 mt-4 mt-sm-0 d-none">
                  <input
                    type="text"
                    className={`form-control font-small ${
                      error?.key === "lastName" && "error-border"
                    }`}
                    name="form-question"
                    placeholder="Last name"
                    onChange={(e) => handleChange("lastName", e.target.value)}
                  />
                  {error?.key === "lastName" ? (
                    <small className="text-danger fs-10">{error.message}</small>
                  ) : (
                    <small className="text-secondary fs-10">Last name</small>
                  )}
                </div>
              </div>
            </div>
            <div className="row mt-4">
              <div className="col-12 col-sm-6">
                <h6>Date of birth *</h6>
                <input
                  type="date"
                  className={`form-control ${
                    error?.key === "dob" && "error-border"
                  }`}
                  name="form-question"
                  placeholder="dob"
                  onChange={(e) => handleChange("dob", e.target.value)}
                />
                {error?.key === "dob" && (
                  <small className="text-danger fs-10">{error.message}</small>
                )}
              </div>
              <div className="col-12 col-sm-6 mt-3 mt-sm-0">
                <h6>Gender *</h6>
                <select
                  className="form-control font-small"
                  aria-label=".form-select-sm"
                  onChange={(e) => handleChange("gender", e.target.value)}
                >
                  <option value="male">Male</option>
                  <option value="female">Female</option>
                  <option value="other">Other</option>
                </select>
              </div>
            </div>
            <div className="row mt-4">
              <div className="col-12 col-sm-6">
                <h6 htmlFor="form-question">Email *</h6>
                <input
                  type="email"
                  className={`form-control ${
                    error?.key === "email" && "error-border"
                  }`}
                  name="form-question"
                  placeholder="Email"
                  onChange={(e) => handleChange("email", e.target.value)}
                />
                {error?.key === "email" && (
                  <small className="text-danger fs-10">{error.message}</small>
                )}
              </div>
              <div className="col-12 col-sm-6 mt-4 mt-sm-0">
                <h6 htmlFor="form-question">Mobile *</h6>
                <input
                  type="number"
                  className={`form-control ${
                    error?.key === "mobile" && "error-border"
                  }`}
                  name="form-question"
                  placeholder="7890789090"
                  onChange={(e) => handleChange("mobile", e.target.value)}
                />
                <small className="text-secondary fs-10">
                  Only India(+91) numbers accepted. e.g. 7890789078.
                </small>
                {error?.key === "mobile" && (
                  <div className="text-danger fs-10">{error.message}</div>
                )}
              </div>
            </div>
            {/* documents */}
            <div className="mt-4">
              <h6>
                Add documents
                <div className="text-secondary fs-10 pt-2 fw-400">
                  Prescriptions, discharge papers, doctor's letter, test
                  reports, etc
                </div>
              </h6>
              {files &&
                files.map((file) => (
                  <div
                    className="alert alert-secondary px-2 py-2 align-items-center"
                    key={file.name}
                  >
                    <span className="font-small">{file.name}</span>
                    <i
                      className="fa fa-trash pull-right text-danger pt-1"
                      onClick={(e) => deleteImage(file.url, "files", e)}
                    />
                  </div>
                ))}
              {optionalDocLoading ? (
                <i className="fa fa-spinner fa-spin" />
              ) : (
                <div>
                  <label
                    htmlFor="optionalDoc"
                    className="pointer btn btn-violet-outline btn-sm"
                  >
                    <i className="fa fa-plus" />
                  </label>
                  <input
                    type="file"
                    className="form-control-plaintext d-none"
                    id="optionalDoc"
                    onChange={(e) =>
                      uploadImage(e.target.files[0], "optionalDoc", e)
                    }
                    accept="application/*,image/*,video/*"
                  />
                </div>
              )}
            </div>
            <div className="mt-4">
              <h6>What would you like to ask? *</h6>
              <div className={`${countChars && "d-none"}`}></div>
              <textarea
                className={`form-control fs-12 ${
                  error?.key === "message" && "error-border"
                }`}
                name="form-question"
                rows="4"
                placeholder="Type here."
                onChange={(e) => handleChange("message", e.target.value)}
              />
              {error?.key === "message" && (
                <small className="text-danger fs-10">{error.message}</small>
              )}
            </div>
            {/* products */}
            <div className="mt-4">
              <h6>My products *</h6>
              <div className="row">
                {products.map(
                  (product, idx) =>
                    product.display && (
                      <div key={idx} className="col-12 my-2">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="product"
                          id={`product-${idx}`}
                          onChange={(e) => handleChange("product", product)}
                          checked={options.product.name === product.name}
                        />
                        <label
                          className="form-check-label"
                          htmlFor={`product-${idx}`}
                        >
                          <div className="media">
                            <div className="media-body">
                              <div className="fw-500">
                                {product.name} {smallDevice && <div></div>}
                                <div
                                  className={
                                    smallDevice ? "my-2" : "pull-right"
                                  }
                                >
                                  {product.price.toFixed(2)} {currency} &nbsp;
                                  <strike className="text-orange-600 fw-500 mt-2 fs-12">
                                    {product.totalPrice.toFixed(2)} {currency}
                                  </strike>
                                </div>
                              </div>
                              <div className="mt-1 fs-12">
                                {product.description}
                              </div>
                            </div>
                          </div>
                        </label>
                      </div>
                    )
                )}
              </div>
              <div className="mt-4 ml-4 d-none">
                <div className="form-question fs-12">Have a Referral Code?</div>
                <div className="input-group col-12 col-sm-6 pl-0">
                  <input
                    type="text"
                    className={`form-control fs-12 ${
                      error?.key === "promocode" && "error-border"
                    }`}
                    placeholder="Referral code"
                    aria-label="Referral code"
                    aria-describedby="promo-code"
                    onChange={(e) => handleChange("promocode", e.target.value)}
                    disabled={discountPrice}
                  />
                  <div
                    className="input-group-append"
                    onClick={(e) => verifyPromoCode()}
                  >
                    <span
                      className="btn btn-violet btn-sm px-3"
                      id="promo-code"
                      disabled={discountPrice}
                    >
                      {applyDiscount ? "Applied!" : "Apply"}
                    </span>
                  </div>
                </div>
                <small className="text-secondary fs-10">e.g. MEDOC_20D</small>
                {error?.key === "promocode" && (
                  <div className="text-danger fs-10">{error.message}</div>
                )}
              </div>
              <div className="text-secondary fs-12 text-center mt-3 d-none">
                Note: This service solely provides healthcare advice and does
                not give certificates, referral letters, prescriptions, or
                anything else.
              </div>
              <div className="mt-4 text-center fs-16 fw-500">
                Total cost:{" "}
                <span className="text-violet">
                  {discountPrice
                    ? discountPrice.toFixed(2)
                    : options.product.price.toFixed(2)}{" "}
                  {currency}
                  {discountPrice && options.product.price && (
                    <strike className="text-orange-600 pl-2">
                      {options.product.price.toFixed(2)} {currency}
                    </strike>
                  )}
                </span>
              </div>
              <div className="text-center text-secondary fs-12">
                *No refunds provided
              </div>
              {discountPrice && (
                <div className="text-orange-600 text-center pt-3 fs-12">
                  Hurray! You saved extra {applyDiscount.discountPercent * 100}%
                </div>
              )}
              <div className="tnc d-none">
                <div className="pt-2">
                  <input
                    className="form-check-input ml-1 mt-2"
                    type="checkbox"
                    name="confirmGuidelines"
                    id="confirmGuidelines"
                    onChange={(e) => handleChange("confirmGuidelines", true)}
                    checked={options.confirmGuidelines}
                  />
                  <label
                    className={`form-check-label ml-4 text-justify ${
                      error?.key === "confirmGuidelines" && "error-color"
                    }`}
                    htmlFor="confirmGuidelines"
                  >
                    I adhere to all our{" "}
                    <span
                      className="text-violet px-0 mx-0"
                      onClick={redirectToGuildelines}
                    >
                      medoc guidelines.
                    </span>{" "}
                    Our doctors are available from 9am to 9pm IST.
                  </label>
                </div>
              </div>
              <div className="tnc d-none">
                <div className="pt-2">
                  <input
                    className="form-check-input ml-1"
                    type="checkbox"
                    name="confirmTnc"
                    id="confirmTnc"
                    onChange={(e) => handleChange("confirmTnc", true)}
                    checked={options.confirmTnc}
                  />
                  <label
                    className={`form-check-label ml-4 text-justify ${
                      error?.key === "confirmGuidelines" && "error-color"
                    }`}
                    htmlFor="confirmTnc"
                  >
                    I confirm that I am not seriously unwell and I do NOT have
                    any of the following symptoms: chest pain, shortness of
                    breath, unable to swallow fluids or saliva, weakness or
                    numbness down one side, and slurred speech. I confirm I do
                    not think I need to see a General Practitioner. If symptoms
                    are related to a COVID vaccine, I will consult my General
                    Practitioner or the medical professional who provided the
                    immunization. I agree that the issuance of any medication is
                    based on the discretion of the consulting doctor. I confirm
                    that I am not using the consultation and medication for
                    others or for any medico-legal purposes. The refund will not
                    be provided in any case. I confirm that this online
                    consultation is not a replacement for a doctor's visit. I
                    have read, understood, and agree with the information in the
                    terms of service and consent to the use of information in
                    accordance with the privacy policy.
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div
            className="complete-payment btn btn-violet text-center"
            onClick={saveRequest}
          >
            {savingCertificate ? (
              <i className="fa fa-spinner fa-spin" />
            ) : (
              <span>Complete Payment</span>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default CallDoctorComponent;
