import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import moment from "moment";
import axios from "axios";
import "./style.css";

import { Tables, Routes } from "constants/index";
import { getQuery, update, firestore } from "firebase_config";
import { capitalizeFirstLetter } from "helpers";
import { HeaderComponent } from "components";
import AcceptComponent from "./accept/component";
import RejectComponent from "./reject/component";
import AmiComponent from "./ami/component";

const DoctorComponent = ({ currentUser }) => {
  const [loading, setLoading] = useState(false);
  const [calling, setCalling] = useState(false);
  const [request, setRequest] = useState();
  const [approveFlag, setApproveFlag] = useState(false);
  const [rejectFlag, setRejectFlag] = useState(false);
  const [amiFlag, setAmiFlag] = useState(false);
  const [options, setOptions] = useState({
    reason: "",
    leaveDateTo: "",
    leaveDateFrom: "",
    extraDetails: {
      customerVerification: "id_not_verified",
      customerVisitedVia: "nothing",
      askedCustomerFeedback: "no",
    },
  });
  const history = useHistory();

  useEffect(async () => {
    setLoading(true);
    const params = {
      assigned: true,
      doctorId: currentUser.id,
      hideCancelButton: true,
    };

    let req = await getQuery(
      firestore
        .collection(Tables.MEDICAL_REQUESTS)
        .where("doctorId", "==", currentUser.id)
        .where("status", "==", "pending")
        .where("assigned", "==", true)
        .where("additionalInfoRequired", "==", false)
        .where("isEditRequired", "==", false)
        .orderBy("createdAt", "asc")
        .limit(1)
        .get()
    );

    if (req.length === 0) {
      req = await getQuery(
        firestore
          .collection(Tables.MEDICAL_REQUESTS)
          .where("status", "==", "pending")
          .where("assigned", "==", false)
          .where("paymentStatus", "==", "completed")
          .where("additionalInfoRequired", "==", false)
          .where("isEditRequired", "==", false)
          .orderBy("createdAt", "asc")
          .limit(1)
          .get()
      );
      if (req.length) {
        await update(Tables.MEDICAL_REQUESTS, req[0].id, params);
      }
    }

    if (req.length) {
      setRequest({ ...req[0], ...params });
      setOptions({
        ...options,
        prescription: req[0].prescription,
        prescriptionLink: req[0].prescriptionLink,
        reason: req[0].reason === "Other" ? req[0].otherReason : req[0].reason,
        leaveDateTo: req[0].leaveDateTo,
        leaveDateFrom: req[0].leaveDateFrom,
        defaultMessage: req[0].defaultMessage,
      });
    }
    setLoading(false);
  }, []);

  // const approvalFlow = async () => {
  //   if (request.type === "form_1a") {
  //     // setApproveFlag(false);
  //     approveRequest();
  //   } else {
  //     setApproveFlag(true);
  //   }
  // };

  const callPatient = async () => {
    setCalling(true);
    const headers = { "Content-Type": "application/x-www-form-urlencoded" };
    const companyNumber = process.env.REACT_APP_TWILIO_NUMBER;
    // Sample mobile numbers: 919246146364, 447438461617
    const data = `To="${currentUser.mobile}"&From=${companyNumber}&Parameters={"patient_number":"${request.mobile}","patient_name": "${request.firstName}"}`;
    const flowUrl =
      "https://studio.twilio.com/v2/Flows/FW72e68dfea557b4132dfb0b59337131ee/Executions";
    if (companyNumber && data) {
      toast.success(
        "You will receive a call in 15 secs. Please check your phone."
      );
      const res = await axios.post(flowUrl, data, {
        headers,
        auth: {
          username: process.env.REACT_APP_TWILIO_USER_NAME,
          password: process.env.REACT_APP_TWILIO_PASSWORD,
        },
      });
      if (res.status === 201) {
        setTimeout(() => {
          toast.success("We're connecting your call to patient...");
        }, 5000);
      }
    }
    setCalling(false);
  };

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
    toast.success("Copied text from clipboard.");
  };

  const deletePrescription = async () => {
    if (window.confirm("Are you sure to delete prescription?")) {
      await update(Tables.MEDICAL_REQUESTS, request.id, {
        prescription: {},
        prescriptionLink: "",
      });
      setOptions({
        prescription: {},
        prescriptionLink: "",
      });
    }
  };

  const redirectToCreatePage = () => {
    history.push({
      pathname: Routes.APP_CREATE_DOCTOR_PRESCRIPTION,
      state: {
        request,
      },
    });
  };

  const redirectToUpdatePage = () => {
    history.push({
      pathname: Routes.APP_UPDATE_MEDICAL_REQUEST,
      state: {
        request,
      },
    });
  };

  return loading ? (
    <div className="dashboard-content text-center mt-5 pt-5">
      <div className="spinner-border"></div>
    </div>
  ) : (
    <div className="dashboard-content mt-5 pt-5">
      <HeaderComponent />
      {request ? (
        <div className="card p-3">
          <div className="row">
            <div
              className={`col-12 pointer text-right ${
                request.type === "fitness_certificate" || !request.type
                  ? "text-violet"
                  : "d-none"
              }`}
              onClick={redirectToUpdatePage}
            >
              <u>
                <i className="fa fa-pencil mr-2" />
                Edit request
              </u>
            </div>
            <div className="mt-3 col-12 col-sm-6">
              <div className="font-small fw-500 text-secondary">
                Request number
              </div>
              <div>
                {request.id}
                <i
                  className="fa fa-copy fs-12 pointer ml-2 btn btn-violet-outline btn-sm"
                  title="Copy"
                  onClick={(e) => copyToClipboard(request.id)}
                />
              </div>
            </div>
            <div className="mt-3 col-12 col-sm-6">
              <div className="font-small fw-500 text-secondary">
                Request type
              </div>
              <div>
                {request.type
                  ? capitalizeFirstLetter(request.type.replace(/_/g, " "))
                  : "Sick leave certificate"}
              </div>
            </div>
            <div className="mt-3 col-12 col-sm-6">
              <div className="font-small fw-500 text-secondary">
                Last updated
              </div>
              <div>{moment(request.updatedAt).format("DD-MM-YYYY, HH:mm")}</div>
            </div>
            <div className="mt-3 col-12">
              <div className="font-small fw-500 text-secondary">Name</div>
              <div>
                {capitalizeFirstLetter(request.firstName)} {request.lastName}
              </div>
            </div>
            <div className="mt-3 col-12 col-sm-6">
              <div className="font-small fw-500 text-secondary">Email</div>
              <div>
                {request.email}{" "}
                <i
                  className="fa fa-copy fs-12 pointer"
                  title="Copy"
                  onClick={(e) => copyToClipboard(request.email)}
                />
              </div>
            </div>
            <div className="mt-3 col-12 col-sm-6">
              <div className="font-small fw-500 text-secondary">Mobile</div>
              <div>
                {request.mobile}{" "}
                <i
                  className="fa fa-copy fs-12 pointer"
                  title="Copy"
                  onClick={(e) => copyToClipboard(request.mobile)}
                />
              </div>
            </div>
            {request.address && (
              <div className="mt-3 col-12">
                <div className="font-small fw-500 text-secondary">Address</div>
                <div>
                  {request.address.door}, {request.address.streetName},{" "}
                  {request.address.city}, {request.address.state},{" "}
                  {request.address.zipcode}
                </div>
              </div>
            )}
            {request.age && (
              <div className="mt-3 col-12 col-sm-6">
                <div className="font-small fw-500 text-secondary">Age</div>
                <div>{request.age} years old</div>
              </div>
            )}
            <div className="mt-3 col-12 col-sm-6">
              <div className="font-small fw-500 text-secondary">
                Date of birth
              </div>
              <div>{moment(request.dob).format("DD-MM-YYYY")}</div>
            </div>
            <div className="mt-3 col-12 col-sm-6">
              <div className="font-small fw-500 text-secondary">Gender</div>
              <div>{capitalizeFirstLetter(request.gender)}</div>
            </div>
            {request.height && (
              <div className="mt-3 col-12 col-sm-6">
                <div className="font-small fw-500 text-secondary">Height</div>
                <div>
                  {request.height} {request.heightMeasurement}
                </div>
              </div>
            )}
            {request.weight && (
              <div className="mt-3 col-12 col-sm-6">
                <div className="font-small fw-500 text-secondary">Weight</div>
                <div>
                  {request.weight} {request.weightMeasurement}
                </div>
              </div>
            )}
            {!request.type && (
              <div className="mt-3 col-12 col-sm-6">
                <div className="font-small fw-500 text-secondary">
                  Leave reason
                </div>
                <div>{request.leaveReason}</div>
              </div>
            )}
            {!request.type && (
              <div className="mt-3 col-12 col-sm-6">
                <div className="font-small fw-500 text-secondary">
                  Leave from
                </div>
                <div>{request.leaveFrom}</div>
              </div>
            )}
            {request.leaveDateFrom && (
              <div className="mt-3 col-6">
                <div className="font-small fw-500 text-secondary">
                  {request.type === "fitness_certificate"
                    ? "Fitness date from"
                    : "From"}
                </div>
                <div>{moment(request.leaveDateFrom).format("DD-MM-YYYY")}</div>
              </div>
            )}
            {request.leaveDateTo && (
              <div className="mt-3 col-6">
                <div className="font-small fw-500 text-secondary">To</div>
                <div>{moment(request.leaveDateTo).format("DD-MM-YYYY")}</div>
              </div>
            )}
            {request.careOf && (
              <>
                <h5 className="col-12 mt-3 text-center">Care of</h5>
                <hr />
                <div className="col-12">
                  <div className="font-small fw-500 text-secondary">
                    Relationship
                  </div>
                  <div>{request.careOf.relationship}</div>
                </div>
                <div className="col-12 mt-3">
                  <div className="font-small fw-500 text-secondary">Name</div>
                  <div>
                    {capitalizeFirstLetter(request.careOf.firstName)}{" "}
                    {request.careOf.lastName}
                  </div>
                </div>
                <div className="mt-3 col-12 col-sm-6">
                  <div className="font-small fw-500 text-secondary">
                    Date of birth
                  </div>
                  <div>{moment(request.careOf.dob).format("DD-MM-YYYY")}</div>
                </div>
                <div className="mt-3 col-12 col-sm-6">
                  <div className="font-small fw-500 text-secondary">Gender</div>
                  <div>{capitalizeFirstLetter(request.careOf.gender)}</div>
                </div>
                {request.address && (
                  <div className="mt-3 col-12">
                    <div className="font-small fw-500 text-secondary">
                      Address
                    </div>
                    <div>
                      {request.careOf.address_door},{" "}
                      {request.careOf.address_streetName},{" "}
                      {request.careOf.address_city},{" "}
                      {request.careOf.address_state},{" "}
                      {request.careOf.address_zipcode}
                    </div>
                  </div>
                )}
              </>
            )}
            {request.reason && (
              <div className="mt-3 col-12">
                <div className="font-small fw-500 text-secondary">
                  {request.type === "recovery_certificate"
                    ? "Recovered from"
                    : "Reason for certificate"}
                </div>
                <div>
                  {request.reason === "Other"
                    ? request.otherReason
                    : request.reason}
                </div>
              </div>
            )}
            {request.reasonDescription && (
              <div className="mt-3 col-12">
                <div className="font-small fw-500 text-secondary">
                  Reason description
                </div>
                <div>{request.reasonDescription}</div>
              </div>
            )}
            {request.orgName && (
              <div className="mt-3 col-12">
                <div className="font-small fw-500 text-secondary">
                  Certificate submitted to (Organisation name & address)
                </div>
                <div>
                  {request.orgName} <br />
                  {request.orgAddress}
                </div>
              </div>
            )}
            {request.idFront && request.idBack && (
              <div className="mt-3 col-12">
                <div className="font-small fw-500 text-secondary">Photo Id</div>
                <a
                  href={request.idFront}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-violet"
                >
                  Front side
                </a>
                &nbsp; &nbsp; &nbsp;
                <a
                  href={request.idBack}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-violet"
                >
                  Rear side
                </a>
              </div>
            )}
            {request.videoDoc && (
              <div className="mt-3 col-12">
                <div className="font-small fw-500 text-secondary">
                  Video evidence
                </div>
                <a
                  href={request.videoDoc}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-violet"
                >
                  Click here
                </a>
              </div>
            )}
            {request.optionalDoc && (
              <div className="mt-3 col-12">
                <div className="font-small fw-500 text-secondary">
                  {!request.type
                    ? "Optional Document"
                    : "Photo proof of blood pressure & pulse"}
                </div>
                <a
                  href={request.optionalDoc}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-violet"
                >
                  Click here
                </a>
              </div>
            )}
            {request.files && request.files.length > 0 && (
              <>
                <div className="mt-2 col-12">
                  <div className="font-small fw-500 text-secondary">
                    Optional Document
                  </div>
                  {request.files.map((file, i) => (
                    <div className="mt-2" key={i}>
                      <a
                        href={file.url}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-violet"
                      >
                        Document {i + 1}
                      </a>
                    </div>
                  ))}
                </div>
              </>
            )}
            {request.type === "consult_doctor" && (
              <>
                <div className="mt-2 col-12">
                  <div className="font-small fw-500 text-secondary">
                    Choosen Product
                  </div>
                  <div>
                    {request.product.name}
                    <br /> <small> {request.product.description} </small>
                  </div>
                </div>
              </>
            )}
            {request.doctorRemarks && request.doctorRemarks.length > 0 && (
              <div className="mt-3 col-12">
                <div className="font-small fw-500 text-secondary">
                  Doctor remarks
                </div>
                {request.doctorRemarks.map((remark, idx) => (
                  <div className="my-1" key={idx}>
                    {remark.d2p && (
                      <div>
                        <b>doctor to patient</b>: {remark.d2p}{" "}
                        <a
                          href={remark.doc}
                          target="_blank"
                          rel="noopener noreferrer"
                          className={`${!remark.doc && "d-none"}`}
                        >
                          <i className="fa fa-paperclip text-violet fs-20 mr-2"></i>
                        </a>
                        <span className="text-secondary fs-10">
                          {moment(remark.createdAt).format("hh:mm DD/MM/YY")}
                        </span>
                      </div>
                    )}
                    {remark.p2d && (
                      <div>
                        <b>patient to doctor</b>: {remark.p2d}{" "}
                        <a
                          href={remark.doc}
                          target="_blank"
                          rel="noopener noreferrer"
                          className={`${!remark.doc && "d-none"}`}
                        >
                          <i className="fa fa-paperclip text-violet fs-20 mr-2"></i>
                        </a>
                        <span className="text-secondary fs-10">
                          {moment(remark.createdAt).format("hh:mm DD/MM/YY")}
                        </span>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            )}
            {request.certLink && (
              <div className="mt-3 col-12 col-sm-6">
                <div className="font-small fw-500 text-secondary">
                  Certificate Link
                </div>
                <a
                  href={request.certLink}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-violet fw-500"
                >
                  Click here <i className="fa fa-arrow-circle-o-down" />
                </a>
              </div>
            )}
            {request.isPrescriptionRequired && (
              <div className="col-12 ml-0 text-right text-violet pointer">
                {options.prescriptionLink ? (
                  <div>
                    <a
                      href={options.prescriptionLink}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-violet"
                    >
                      <i className="fa fa-paperclip text-violet fs-16" />{" "}
                      Prescription Attached
                    </a>
                    <i
                      className="fa fa-trash text-danger ml-2 fs-14"
                      onClick={deletePrescription}
                    />
                  </div>
                ) : (
                  <div onClick={redirectToCreatePage}>
                    <i className="fa fa-plus pr-2" />
                    Create Prescription
                  </div>
                )}
              </div>
            )}
            {approveFlag && (
              <>
                <AcceptComponent
                  currentUser={currentUser}
                  request={request}
                  approveFlag={approveFlag}
                  setApproveFlag={setApproveFlag}
                  options={options}
                  setOptions={setOptions}
                />
              </>
            )}
            {rejectFlag && (
              <RejectComponent
                currentUser={currentUser}
                request={request}
                setRejectFlag={setRejectFlag}
              />
            )}
            {amiFlag && (
              <AmiComponent
                currentUser={currentUser}
                request={request}
                setAmiFlag={setAmiFlag}
              />
            )}

            {!approveFlag && !rejectFlag && !amiFlag && (
              <>
                <div className="text-center mt-3 col-12">
                  <button
                    className={`btn btn-violet btn-sm px-3 my-2 col-6 ${
                      request.type === "consult_doctor" && "d-none"
                    }`}
                    onClick={(e) => setApproveFlag(true)}
                    disabled={loading}
                  >
                    Approve
                  </button>{" "}
                  <br
                    className={`${
                      request.type === "consult_doctor" && "d-none"
                    }`}
                  />
                  <button
                    className={`btn btn-violet btn-sm px-3 my-2 col-6 ${
                      request.type === "consult_doctor" && "d-none"
                    }`}
                    onClick={(e) => setRejectFlag(true)}
                    disabled={loading}
                  >
                    Reject
                  </button>{" "}
                  <br
                    className={`${
                      request.type === "consult_doctor" && "d-none"
                    }`}
                  />
                  <button
                    className="btn btn-violet-outline btn-sm px-3 my-2 col-6 col-sm-4"
                    onClick={(e) => setAmiFlag(true)}
                    disabled={loading}
                  >
                    {request.type === "consult_doctor"
                      ? "Share Feedback"
                      : "Ask More Information"}
                  </button>
                </div>
                <hr />
                <div className="text-center mt-3 col-12">
                  <button
                    className="btn btn-violet-outline btn-sm px-3 my-2 col-sm-4 py-2"
                    onClick={callPatient}
                    disabled={calling}
                  >
                    Call Patient
                  </button>{" "}
                  <br />
                  <a
                    href="https://meet.google.com/new?hs=180&amp;authuser=0"
                    target="_blank"
                    rel="noreferrer"
                    className="btn btn-violet-outline btn-sm px-3 my-2 col-sm-4 py-2"
                    title="Start a meeting"
                    aria-label="Start a meeting"
                    draggable="false"
                  >
                    Start Video Call (Gmeet)
                  </a>{" "}
                </div>
              </>
            )}
          </div>
        </div>
      ) : (
        <div className="text-center">No requests yet</div>
      )}
    </div>
  );
};

export default DoctorComponent;
