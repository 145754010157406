import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { toast } from "react-toastify";

import HeaderComponent from "./header";
import { Tables, Routes } from "constants/index";
import { add, update } from "firebase_config";
import { post } from "services";

const timeSlots = [
  "13:30",
  "14:00",
  "14:30",
  "15:00",
  "15:30",
  "16:00",
  "16:30",
  "17:00",
  "17:30",
  "18:00",
  "18:30",
];

const EditForm1aComponent = ({ currentUser }) => {
  const { state } = useLocation();
  const [options, setOptions] = useState({
    appointment: {
      date: "",
      startTime: "",
      endTime: "",
      duration: 30, // in minutes
      eventPlace: "online",
      eventHost: "gmeet",
      eventLocation: "https://meet.google.com/rru-chsx-afq",
    },
  });
  const [error, setError] = useState({
    message: "",
    key: "",
  });
  const [savingCertificate, setSavingCertificate] = useState(false);
  const history = useHistory();

  useEffect(() => {
    const tomorrow = new Date();
    tomorrow.setDate(new Date().getDate() + 1);
    const minDate = tomorrow.toISOString().split("T")[0];
    window.document
      .getElementsByName("appointmentDate")[0]
      .setAttribute("min", minDate);
  }, []);

  const handleChange = (key, value, additionalField) => {
    if (additionalField) {
      setOptions({
        ...options,
        [key]: {
          ...options[key],
          [additionalField]: value,
        },
      });
    } else {
      setOptions({
        ...options,
        [key]: value,
      });
    }
  };

  const showErrors = () => {
    let error;
    if (options.appointment && !options.appointment.date) {
      error = {
        message: "Date of appointment is required",
        key: "appointmentDate",
      };
    } else if (options.appointment && !options.appointment.startTime) {
      error = {
        message: "Time Slot is required",
        key: "appointmentStartTime",
      };
    }
    setError(error);
    return error;
  };

  const saveCertificate = async () => {
    const err = await showErrors();
    if (err && err.message) {
      toast.error(err.message);
      return;
    }
    setSavingCertificate(true);

    options.assigned = true; // this prevents to assign request immediately
    options.doctorId = "naga"; // assign to admin id
    options.isEditRequired = false;

    const startTimeIdx = timeSlots.findIndex(
      (idx) => idx === options.appointment.startTime
    );
    options.appointment.endTime = timeSlots[startTimeIdx + 1];

    const res = await update(
      Tables.MEDICAL_REQUESTS,
      state.request.id,
      options
    );
    await add(Tables.APPOINTMENTS, {
      ...options.appointment,
      requestId: state.request.id,
      doctorId: "naga",
    });

    if (!currentUser.email.includes("example")) {
      await post("/send_email", {
        templateName: "form1a_appointment_scheduled.html",
        email: state.request.email,
        bcc: ["hemanth.vja@gmail.com"],
        displayName: state.request.firstName,
        requestId: state.request.id,
        ...options.appointment,
      });
    }

    if (res.status === 200) {
      toast.success("Your request has been sent to doctor.");
      history.push({
        pathname: `${Routes.APP_POSTS}/${state.request.id}`,
      });
    }
    setSavingCertificate(false);
  };

  return (
    <>
      <HeaderComponent
        headerText="Form1a Certificate"
        reqId={state.request.id}
      />
      <div className="dashboard-content">
        <div className="container create-post-wrapper pt-5 mt-sm-5">
          <div className="card p-2 p-sm-4 my-sm-5">
            <div className="fs-14">
              Book your{" "}
              <span className="fw-600">Form 1A: Eye/Ear online test</span>{" "}
              appointment
            </div>
            <hr />
            <div className="row">
              <div className="col-12 col-sm-6">
                <h6>Date of appointment *</h6>
                <input
                  type="date"
                  className={`form-control ${
                    error?.key === "appointmentDate" && "error-border"
                  }`}
                  name="appointmentDate"
                  id="appointmentDate"
                  placeholder="Appointment Date"
                  value={options.appointment && options.appointment.date}
                  onChange={(e) =>
                    handleChange("appointment", e.target.value, "date")
                  }
                />
                {error?.key === "appointmentDate" && (
                  <small className="text-danger fs-10">{error.message}</small>
                )}
              </div>
              <div className="col-12 col-sm-6">
                <h6>Select Time Slot *</h6>
                <select
                  className="form-control font-small"
                  aria-label=".form-select-sm"
                  onChange={(e) =>
                    handleChange("appointment", e.target.value, "startTime")
                  }
                >
                  <option value=""></option>
                  {timeSlots.map((time) => (
                    <option value={time} key={time}>
                      {time}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <div
            className="complete-payment btn btn-violet-rounded px-3 py-2 fw-500 text-center"
            onClick={saveCertificate}
          >
            {savingCertificate ? (
              <i className="fa fa-spinner fa-spin" />
            ) : (
              <span>Send to Doctor</span>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default EditForm1aComponent;
