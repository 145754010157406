import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

import { add } from "firebase_config";
import { post } from "services";
import { Tables, Routes } from "constants/index";

const RejectComponent = ({ request, setRejectFlag }) => {
  const [loading, setLoading] = useState(false);
  const [doctorRemarks, setDoctorRemarks] = useState();
  const [rejectReason, setRejectReason] = useState("");
  const history = useHistory();

  const rejectRequest = async () => {
    if (!doctorRemarks) {
      toast.error("Feedback to patient is required");
      return;
    }
    if (!rejectReason) {
      toast.error("Rejection reason is required");
      return;
    }
    setLoading(true);
    // send a BE request
    const res = await post("/request_reject", {
      status: "rejected",
      requestId: request.id,
      doctorRemarks,
      rejectReason,
    });

    if (res.status === 201) {
      toast.success("Request is rejected.");
      add(Tables.LOGS, {
        text: "Sorry, we can't help this time. Your refund is in progress!",
        receiverId: request.userId,
        actionLink: `/app/posts/${request.id}`,
        unread: true,
      });
      add(Tables.LOGS, {
        text: "Give feedback and win 20% discount on your next certificate!",
        receiverId: request.userId,
        actionLink: `/app/posts/${request.id}`,
        unread: true,
      });
      history.push(Routes.APP_REQUESTS);
    } else {
      toast.error(res.data.message);
    }
    setLoading(false);
  };

  return (
    <>
      <div className="mt-3 col-12">
        <div className="font-small fw-500 text-secondary">
          Feedback to patient:
        </div>
        <div>
          <textarea
            className="form-control font-small mt-1"
            placeholder="Feedback to patient"
            onChange={(e) => setDoctorRemarks(e.target.value)}
          />
        </div>
      </div>
      <div className="mt-2 col-12 col-sm-6">
        <div className="font-small fw-500 text-secondary">
          Rejection Reason:
        </div>
        <select
          className="form-control font-small"
          aria-label=".form-select-sm"
          onChange={(e) => setRejectReason(e.target.value)}
        >
          <option value="">-- Select Reason --</option>
          <option value="calls_not_attending">User not attending calls</option>
          <option value="fake_medical_condition">
            User’s ailment claims are found incorrect/illegal
          </option>
          <option value="contradicts_medical_condition">
            User contradicts their own medical condition
          </option>
          <option value="not_enough_documents">
            User fails to produce enough online documents
          </option>
          <option value="real">Other</option>
        </select>
      </div>
      <div className="mt-3 col-12 text-center">
        <button
          className="btn btn-violet btn-sm px-3 my-2 col-6"
          onClick={rejectRequest}
          disabled={loading}
        >
          Send Feedback
        </button>{" "}
        <button
          className="btn btn-danger-outline btn-sm px-3 my-2 col-6"
          onClick={(e) => setRejectFlag(false)}
          disabled={loading}
        >
          Cancel
        </button>{" "}
      </div>
    </>
  );
};

export default RejectComponent;
