import React from "react";
import { Link, useHistory } from "react-router-dom";

import { Routes } from "constants/index";
import { toast } from "react-toastify";

const AcceptTermsComponent = ({
  options,
  setOptions,
  setStepNumber,
  refundAmount,
  handleChange,
}) => {
  const history = useHistory();

  const redirectToGuildelines = () => {
    if (
      window.confirm("Your form data will be lost. Are you sure to redirect?")
    ) {
      history.push({
        pathname: Routes.APP_GUIDELINES_SLC,
        state: {
          options,
        },
      });
    }
  };

  const saveTnc = () => {
    if (!options.confirmTnc) {
      toast.error("Please confirm all Terms and Conditions");
      return;
    } else if (!options.confirmTnc2) {
      toast.error("Please confirm all Terms and Conditions");
      return;
    } else if (!options.confirmGuidelines) {
      toast.error("Please confirm all Medoc Guidelines");
      return;
    }
    setStepNumber(2);
  };

  return (
    <>
      <div className="dashboard-content">
        <div className="container pt-5 mt-sm-5">
          <div className="mt-sm-5"></div>
          <div className="card py-4 px-2 p-sm-3">
            <h6 className="text-black text-center">Accept Terms</h6>
            <div className="tnc">
              <div className="pt-2">
                <input
                  className="form-check-input ml-1 mt-2"
                  type="checkbox"
                  name="confirmGuidelines"
                  id="confirmGuidelines"
                  onChange={(e) => handleChange("confirmGuidelines", true)}
                  checked={options.confirmGuidelines}
                />
                <label
                  className="form-check-label ml-4 text-justify"
                  htmlFor="confirmGuidelines"
                >
                  Our doctors are available from 8am to 10pm IST. I adhere to
                  all{" "}
                  <span
                    className="text-violet px-0 mx-0 fw-500 pointer"
                    onClick={redirectToGuildelines}
                  >
                    medoc guidelines.
                  </span>{" "}
                  I hereby acknowledge that I will provide Medoc and their
                  medical professionals with all the information they ask,
                  whether it's before or after payment.
                </label>
              </div>
            </div>
            <div className="tnc">
              <div className="pt-2">
                <input
                  className="form-check-input ml-1"
                  type="checkbox"
                  name="confirmTnc2"
                  id="confirmTnc2"
                  onChange={(e) => handleChange("confirmTnc2", true)}
                  checked={options.confirmTnc2}
                />
                <label
                  className="form-check-label ml-4 text-justify"
                  htmlFor="confirmTnc2"
                >
                  I agree that the issuance of a medical certificate,
                  prescription and its duration is based on the discretion of
                  the consulting doctor and I fully comply with their accept or
                  reject decision. If I require a sick leave certificate for
                  more than 7 days, I understand that I must provide required
                  medical reports.
                </label>
              </div>
            </div>
            <div className="tnc">
              <div className="pt-2">
                <input
                  className="form-check-input ml-1"
                  type="checkbox"
                  name="confirmTnc"
                  id="confirmTnc"
                  onChange={(e) => handleChange("confirmTnc", true)}
                  checked={options.confirmTnc}
                />
                <label
                  className="form-check-label ml-4 text-justify"
                  htmlFor="confirmTnc"
                >
                  I confirm that I am not seriously unwell and I do NOT have any
                  of the following symptoms: chest pain, shortness of breath,
                  unable to swallow fluids or saliva, weakness or numbness down
                  one side, and slurred speech. I confirm I do not think I need
                  to see a General Practitioner. If symptoms are related to a
                  COVID vaccine, I will consult my General Practitioner or the
                  medical professional who provided the immunization. I confirm
                  that I am not using the certificate for any medico-legal
                  purposes. In case of non-issuance of the certificate due to
                  valid reason, a service charge of up to 500 INR/- will be
                  deducted. Kindly, read our{" "}
                  <Link to="/rp" target="_blank" className="text-violet fw-500">
                    Refund and cancellation policy{" "}
                  </Link>
                  for complete details. I have read, understood, and agree with
                  the information in the terms of service and consent to the use
                  of information in accordance with the privacy policy.
                </label>
              </div>
            </div>
            <p className="fs-12 text-center mt-3 mx-sm-4">
              Note: If our medical team discovers that the information you
              provided is inaccurate or you requested a fake certificate, your
              account will be added to a blacklist, and{" "}
              <b>no refunds will be issued</b>.
            </p>
            <div className="text-center mt-1">
              <button className="btn btn-violet-rounded px-4" onClick={saveTnc}>
                Next
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AcceptTermsComponent;
