export default [
  {
    name: "Fast: Sent via email on same day (7am - 9pm)",
    description:
      "This will be reviewed by Indian doctor. Then a simple online eye and ear test is performed. Your Form-1A Fitness Certificate will then be signed, attested, and sent by email within minutes.",
    price: 1300,
    totalPrice: 2500,
    discountPercent: 50,
    priority: "high",
    display: true,
  },
  {
    name: "Slow: Sent via email in 7 working days",
    description:
      "This will be reviewed by Indian doctor. Then a simple online eye and ear test is performed. Your Form-1A Fitness Certificate will then be signed, attested, and sent by email in seven working days.",
    price: 1100,
    totalPrice: 2500,
    discountPercent: 50,
    priority: "high",
    display: true,
  },
];
