import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import "./style.css";

import HeaderComponent from "./header";
import { signin, googleSignin, add } from "firebase_config";
import { SetReload } from "store/actions";
import { convertToSnakeCase, isIosDevice } from "helpers";
import { Tables } from "constants/db_tables";
import { post } from "services";

const LoginComponent = ({ bindReload }) => {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [btnSave, setBtnSave] = useState("Submit");
  const [error, setErrors] = useState("");
  const [eyeIcon, setEyeIcon] = useState("fa-eye");
  const history = useHistory();
  const isIOS = isIosDevice();

  const handleKeyDown = (event) => {
    if (event.keyCode === 13) {
      handleForm(event);
    }
  };

  const handleForm = async (e) => {
    e.preventDefault();

    setBtnSave("Submitting...");
    const result = await signin({ email, password });
    setBtnSave("Submit");
    if (result.status !== 200) {
      setErrors(result.message);
      return;
    }
    bindReload(true);
    history.push("/app/posts");
  };

  const showPassword = () => {
    const input = document.getElementById("loginPass");
    if (input.type === "password") {
      setEyeIcon("fa-eye-slash");
      input.type = "text";
    } else {
      setEyeIcon("fa-eye");
      input.type = "password";
    }
  };

  const handleGoogleSignIn = async () => {
    setLoading(true);
    setBtnSave("Submitting...");
    const res = await googleSignin();
    setBtnSave("Submit");

    if (res?.data?.additionalUserInfo?.isNewUser) {
      const userData = {
        email: res.data.email,
        username: convertToSnakeCase(res.data.displayName),
        displayName: res.data.displayName,
        permissions: {
          tnc: true,
          recordPageVisits: true,
          locationAccess: true,
          emailUpdates: true,
        },
        photoURL: null,
        depositAmount: 0,
        withdrawAmount: 0,
        roles: ["user"],
      };
      const createDbUser = await add(Tables.USERS, userData);
      if (createDbUser.status !== 200) {
        setErrors(createDbUser.message);
        return null;
      } else {
        post("/send_email", {
          sender: "naga",
          templateName: "welcome_new_user.html",
          email: res.data.email,
          subject: "Welcome to Medoc",
          displayName: res.data.displayName,
        });
      }
    } else if (res.status !== 200) {
      setErrors(res.message);
      setLoading(false);
      return;
    }
    setLoading(false);
    history.push("/app/posts");
  };

  return (
    <div className="login-page" onKeyDown={(e) => handleKeyDown(e)} id="login">
      <HeaderComponent bottomFooter="false" />
      <div className="container text-center pt-5 mt-5 px-3 pt-sm-0 mt-sm-0">
        <div className="pt-5 pt-sm-3">
          <img
            src="https://storage.googleapis.com/medoc-live.appspot.com/images/nmc_digital_india.png"
            className="img-nmc-logo img-responsive"
            alt="Medoc NMC digital india"
          />{" "}
        </div>
        <div className="fs-12 mt-1">
          Every certificate adheres to guidelines outlined by{" "}
          <a
            href="https://www.nmc.org.in/nmc-act/"
            target="_blank"
            rel="nooopener noreferrer"
            className="text-violet"
          >
            <u>The National Medical Commission Act, 2019</u>
          </a>
        </div>
      </div>

      <div className="container login-form py-5 mb-5">
        <div className="d-flex justify-content-center">
          <div className="col-12 order-1 order-sm-2 col-lg-6 right-block">
            <div className="text-center">
              <img
                src="https://storage.googleapis.com/medoc-live.appspot.com/images/favicon_four.png"
                className="header img-medoc-logo img-responsive"
                alt="Medoc.logo"
              />{" "}
            </div>
            <div></div>
            <div className="body mt-3">
              {loading && <span className="spinner-border"></span>}
              {error && (
                <div className="text-danger text-center my-3">{error}</div>
              )}
              <div className="input-group mb-2">
                <input
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  name="email"
                  type="email"
                  className="form-control"
                  placeholder="Email"
                  autoFocus={true}
                  aria-label="email"
                  aria-describedby="basic-addon1"
                />
              </div>
              <div className="input-group mb-2">
                <input
                  onChange={(e) => setPassword(e.target.value)}
                  name="password"
                  value={password}
                  type="password"
                  className="form-control"
                  id="loginPass"
                  placeholder="Password"
                  aria-label="password"
                  aria-describedby="basic-addon2"
                />
                <div className="input-group-append">
                  <span
                    className="input-group-text append"
                    id="basic-addon3"
                    onClick={(e) => showPassword()}
                  >
                    {eyeIcon === "fa-eye" ? (
                      <img
                        className="show-password"
                        src={require("assets/svgs/Eye.svg").default}
                        alt="Eye"
                      />
                    ) : (
                      <img
                        className="show-password"
                        src={require("assets/svgs/EyeOpen.svg").default}
                        alt="EyeOpen"
                      />
                    )}
                  </span>
                </div>
              </div>
              <div>
                <button
                  className="btn login-btn col-12"
                  disabled={btnSave !== "Submit"}
                  onClick={(e) => handleForm(e)}
                >
                  Login
                </button>
                <br />
                <Link to="/signup" className="text-violet fw-400">
                  New user? Sign up here
                </Link>
                <br />
                <div className="text-center pt-2">
                  <Link to="/forgot_password" className="text-violet fw-400">
                    Forgot password
                  </Link>
                </div>
              </div>
              <div className={`${isIOS && "d-none"}`}>
                <div className="mt-4 mb-2 separator">OR</div>
                <button
                  className="btn google-login-btn col-12 align-items-center"
                  onClick={handleGoogleSignIn}
                  disabled={loading}
                >
                  <i className="fa fa-google"></i> &nbsp;{" "}
                  <span className="fw-500">Login with Google</span>
                </button>
                <div className="caption-text d-none">
                  By Logging in, you are agreeing to our
                  <Link
                    to="/tnc"
                    className="text-violet pl-1"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Terms of service
                  </Link>
                  , and our{" "}
                  <Link
                    to="/privacy_policy"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-violet"
                  >
                    Privacy policy
                  </Link>
                  .
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container text-center">
        <a
          href="https://medoc.life/medical-certificate"
          target="_blank"
          rel="nooopener noreferrer"
          className="text-violet"
        >
          <u>www.medoc.life</u>
        </a>
        <div className="text-center mt-3">
          <a
            href="https://www.facebook.com/people/Medoclife/100089022580585/"
            target="_blank"
            rel="noreferrer noopener"
            className="text-violet px-2"
            title="Facebook"
          >
            <i className="fa fa-facebook fs-22" />
          </a>
          <a
            href="https://www.linkedin.com/company/medoc-offc"
            target="_blank"
            rel="noreferrer noopener"
            className="text-violet px-2"
            title="Linkedin"
          >
            <i className="fa fa-linkedin fs-22" />
          </a>
          <a
            href="https://www.instagram.com/medoc_life/"
            target="_blank"
            rel="noreferrer noopener"
            className="text-violet px-2"
            title="Instagram"
          >
            <i className="fa fa-instagram fs-22" />
          </a>
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    bindReload: (content) => dispatch(SetReload(content)),
  };
};

export default connect(null, mapDispatchToProps)(LoginComponent);
