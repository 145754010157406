export default [
  {
    name: "SUPERB: Digital Certificate in 60 minutes (recommends)",
    description:
      "This will be reviewed and signed by an Indian M.B.B.S doctor and sent via email, usually delivered within 60 mins. No wait time.",
    price: 510,
    studentPrice: 320,
    prescriptionPrice: 120,
    totalPrice: 650,
    abroadPrice: 1600,
    abroadTotalPrice: 2500,
    discountPercent: 40,
    priority: "high",
    display: true,
    assignsAfter: 0,
  },
  {
    name: "GOOD: Digital Certificate in 24 hours",
    description:
      "This will be reviewed and signed by an Indian M.B.B.S doctor and sent via email, usually delivered in 12-24 hours. The wait time can vary depending on the queue, but your request will be processed within 24 hours.",
    price: 450,
    studentPrice: 290,
    prescriptionPrice: 180,
    totalPrice: 650,
    discountPercent: 40,
    abroadPrice: 1400,
    abroadTotalPrice: 2500,
    priority: "high",
    display: true,
    assignsAfter: 12,
  },
  {
    name: "SLOW: Digital Certificate in 7 days",
    description:
      "This will be reviewed and signed by an Indian M.B.B.S doctor and sent via email, usually delivered in 7 working days. The wait time can vary depending on the queue, but your request will be processed within seven days.",
    price: 250,
    studentPrice: 250,
    prescriptionPrice: 250,
    totalPrice: 500,
    discountPercent: 40,
    abroadPrice: 1000,
    abroadTotalPrice: 2500,
    priority: "low",
    display: false,
    assignsAfter: 48,
  },
];
