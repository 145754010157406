export const Blogs = [
  {
    pin: true,
    name: "sick-leave-certificate",
    template: require("./blog_templates/sick_leave_certificate").default,
  },
  {
    name: "fitness-certificate",
    template: require("./blog_templates/fitness_certificate").default,
  },
  {
    name: "recovery-certificate",
    template: require("./blog_templates/recovery_certificate").default,
  },
];

// {
//   name: 'how-trading-of-feelings',
//   template: require('./how-trading-of-feelings').default
// },
