export default [
  {
    name: "ULTRA: Digital Certificate in 30 minutes (recommends)",
    description:
      "This will be reviewed and signed by an Indian M.B.B.S doctor and sent via email, usually delivered within 30 mins. No wait time.",
    price: 750,
    studentPrice: 320,
    prescriptionPrice: 120,
    totalPrice: 1200,
    abroadPrice: 3000,
    abroadTotalPrice: 5000,
    discountPercent: 40,
    priority: "high",
    display: true,
    assignsAfter: 0,
  },
  {
    name: "SUPERB: Digital Certificate in 90 minutes",
    description:
      "An Indian M.B.B.S doctor will review and sign the certificate before sending it via email. There is a 60-minute waiting time, but your certificate will be delivered within 90 minutes.",
    price: 510,
    studentPrice: 320,
    prescriptionPrice: 120,
    totalPrice: 950,
    abroadPrice: 2500,
    abroadTotalPrice: 5000,
    discountPercent: 40,
    priority: "high",
    display: true,
    assignsAfter: 1,
  },
  {
    name: "GOOD: Digital Certificate in 3 days",
    description:
      "An Indian M.B.B.S doctor will review and sign the certificate before sending it via email. There is a minimum 2-days waiting time, but your certificate will be delivered within 3 days.",
    price: 450,
    studentPrice: 290,
    prescriptionPrice: 180,
    totalPrice: 750,
    discountPercent: 40,
    abroadPrice: 2000,
    abroadTotalPrice: 5000,
    priority: "high",
    display: true,
    assignsAfter: 48,
  },
  {
    name: "SLOW: Digital Certificate in 7 days",
    description:
      "An Indian M.B.B.S doctor will review and sign the certificate before sending it via email. There is a minimum 5-day waiting time, but your certificate will be delivered within 7 days.",
    price: 250,
    studentPrice: 250,
    prescriptionPrice: 250,
    totalPrice: 500,
    discountPercent: 40,
    abroadPrice: 1500,
    abroadTotalPrice: 5000,
    priority: "low",
    display: true,
    assignsAfter: 120,
  },
];
