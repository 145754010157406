import React, { useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import "./style.css";

import HeaderComponent from "./header";
import { Routes, Tables } from "constants/index";
import { update } from "firebase_config";
import { post } from "services";

const CreatePrescriptionComponent = ({ currentUser }) => {
  const [error, setError] = useState({
    message: "",
    key: "",
  });
  const [options, setOptions] = useState({
    diagnosis: "",
    drugs: [],
  });
  const [drug, setDrug] = useState("");
  const [savingPrescription, setSavingPrescription] = useState(false);
  const { state } = useLocation();
  const history = useHistory();

  const showErrors = () => {
    let error;
    if (!options.diagnosis) {
      error = {
        message: "Diagnosis is required",
        key: "diagnosis",
      };
    } else if (drug && !drug.name) {
      error = {
        message: "Drug name is required",
        key: "drugName",
      };
    } else if (drug.strength && !drug.strengthUnit) {
      error = {
        message: "Strength Unit is required",
        key: "strengthUnit",
      };
    } else if (drug.dose && !drug.doseUnit) {
      error = {
        message: "Dose Unit is required",
        key: "doseUnit",
      };
    } else if (drug.duration && !drug.durationUnit) {
      error = {
        message: "Duration Unit is required",
        key: "durationUnit",
      };
    }

    setError(error);
    return error;
  };

  const handleChange = (key, value, additionalField) => {
    if (additionalField) {
      setOptions({
        ...options,
        [key]: {
          ...options[key],
          [additionalField]: value,
        },
      });
    } else {
      setOptions({
        ...options,
        [key]: value,
      });
    }
  };

  const handleDrugChange = (key, value) => {
    setDrug({
      ...drug,
      [key]: value,
    });
  };

  const addDrug = () => {
    setDrug({
      name: "",
      strength: "",
      strengthUnit: "",
      preparation: "",
      route: "",
      dose: "",
      doseUnit: "",
      direction: "",
      frequency: "",
      duration: "",
      durationUnit: "",
      quantity: "",
      instructions: "",
    });
  };

  const removeDrug = (idx) => {
    const drugs = options.drugs.filter((d, i) => i !== idx);
    setOptions({
      ...options,
      drugs,
    });
  };

  const concatDrugName = () => {
    let dn = drug.name.toUpperCase();
    if (drug.strength) {
      dn = `${dn} ${drug.strength}`;
    }
    if (drug.strengthUnit) {
      dn = `${dn}${drug.strengthUnit}`;
    }
    if (drug.preparation) {
      dn = `${drug.preparation.toUpperCase()}. ${dn}`;
    }
    if (drug.route) {
      dn = `${dn} ${drug.route.toUpperCase()}`;
    }
    if (drug.dose) {
      dn = `${dn} ${drug.dose}`;
    }
    if (drug.doseUnit) {
      dn = `${dn} ${drug.doseUnit.replace(/_/g, " ")}`;
    }
    if (drug.direction) {
      dn = `${dn} ${drug.direction.toUpperCase().replace(/_/g, " ")}`;
    }
    if (drug.frequency) {
      dn = `${dn} ${drug.frequency.toUpperCase().replace(/_/g, " ")}`;
    }
    if (drug.duration) {
      dn = `${dn} ${drug.duration.toUpperCase()}`;
    }
    if (drug.durationUnit) {
      dn = `${dn} ${drug.durationUnit.toUpperCase()}`;
    }
    if (drug.quantity) {
      dn = `${dn} --- ${drug.quantity.toUpperCase()}`;
    }
    if (drug.instructions) {
      dn = `${dn} ${drug.instructions.toUpperCase()}`;
    }
    return dn;
  };

  const saveDrug = () => {
    const err = showErrors();
    if (err) {
      toast.error(err.message);
      return;
    }
    drug.details = concatDrugName();
    setOptions({
      ...options,
      drugs: [...options.drugs, drug],
    });
    setDrug("");
  };

  const savePrescription = async () => {
    const err = showErrors();
    if (err) {
      toast.error(err.message);
      return;
    }
    if (drug) {
      toast.error("Add the drug before saving prescription...");
      return;
    }
    if (!state || !state.request) {
      toast.error("Something went wrong. Try again!");
      history.push(`${Routes.APP_DC_PUBLIC_APPOINTMENTS}/${state.request.id}`);
    }
    setSavingPrescription(true);
    const res = await update(Tables.DC_PUBLIC_APPOINTMENTS, state.request.id, {
      prescription: options,
    });
    if (res.status === 200) {
      await post("/create_prescription_public", {
        requestId: state.request.id,
      });
      toast.success("Prescription is successfully added");
    } else {
      toast.error("Something went wrong. Try again!");
    }
    history.push(`${Routes.APP_DC_PUBLIC_APPOINTMENTS}/${state.request.id}`);
    setSavingPrescription(false);
  };

  return (
    <>
      <HeaderComponent headerText="Create Prescription" id={state.request.id} />
      <div className="dashboard-content create-doctor-prescription">
        <div className="container create-post-wrapper pt-5 mt-sm-5">
          <div className="card p-2 p-sm-4 my-sm-5">
            <div className="mt-3">
              <h6>Diagnosis *</h6>
              <textarea
                className={`form-control fs-12 ${
                  error?.key === "diagnosis" && "error-border"
                }`}
                name="form-question"
                placeholder="Diagnosis (separate by comma)"
                onChange={(e) => handleChange("diagnosis", e.target.value)}
              />
              {error?.key === "diagnosis" && (
                <small className="text-danger fs-10">{error.message}</small>
              )}
            </div>
            <div className="mt-4">
              <h6>Complains of (C/O):</h6>
              <textarea
                className={`form-control fs-12 ${
                  error?.key === "complainsOf" && "error-border"
                }`}
                name="form-question"
                placeholder="Complains of (separate by comma)"
                onChange={(e) => handleChange("complainsOf", e.target.value)}
              />
              {error?.key === "complainsOf" && (
                <small className="text-danger fs-10">{error.message}</small>
              )}
            </div>
            <div className="mt-4">
              <h6>Advice:</h6>
              <textarea
                className={`form-control fs-12 ${
                  error?.key === "advice" && "error-border"
                }`}
                name="form-question"
                placeholder="Type here."
                onChange={(e) => handleChange("advice", e.target.value)}
              />
              {error?.key === "advice" && (
                <small className="text-danger fs-10">{error.message}</small>
              )}
            </div>
            <div className="mt-4 d-none">
              <h6>Extra notes:</h6>
              <textarea
                className={`form-control fs-12 ${
                  error?.key === "notes" && "error-border"
                }`}
                name="form-question"
                placeholder="Type here."
                onChange={(e) => handleChange("notes", e.target.value)}
              />
              {error?.key === "notes" && (
                <small className="text-danger fs-10">{error.message}</small>
              )}
            </div>
            <div className="mt-4">
              <h6>Add drugs:</h6>
              {options.drugs.length > 0 &&
                options.drugs.map((drug, drugIdx) => (
                  <div key={drugIdx}>
                    {drug.details}
                    <i
                      className="fa fa-trash pull-right"
                      onClick={(e) => removeDrug(drugIdx)}
                    />
                  </div>
                ))}
              {drug ? (
                <div className="card new-drug my-3">
                  <div className="col-12 my-2 mt-3">
                    <h6>Drug Name *</h6>
                    <input
                      type="text"
                      className={`form-control col-12 ${
                        error?.key === "name" && "error-border"
                      }`}
                      name="form-question"
                      placeholder="Drug Name"
                      value={drug.name}
                      onChange={(e) => handleDrugChange("name", e.target.value)}
                    />
                    {error?.key === "drugName" && (
                      <small className="text-danger fs-10">
                        {error.message}
                      </small>
                    )}
                  </div>
                  <div className="col-12 my-2">
                    <h6 htmlFor="form-question">Strength</h6>
                    <div className="input-group">
                      <input
                        type="number"
                        className={`form-control ${
                          error?.key === "strength" && "error-border"
                        }`}
                        name="form-question"
                        placeholder="Strength"
                        value={drug.strength}
                        onChange={(e) =>
                          handleDrugChange("strength", e.target.value)
                        }
                      />
                      <div className="input-group-append">
                        <select
                          className="form-control font-small"
                          aria-label=".form-select-sm"
                          onChange={(e) =>
                            handleDrugChange("strengthUnit", e.target.value)
                          }
                        >
                          <option defaultValue>Select Unit</option>
                          <option value="mg">mg</option>
                          <option value="gm">gm</option>
                          <option value="ng">ng</option>
                          <option value="mcg">mcg</option>
                        </select>
                      </div>
                    </div>
                    {error?.key === "strengthUnit" && (
                      <small className="text-danger fs-10">
                        {error.message}
                      </small>
                    )}
                  </div>
                  <div className="col-12 my-2">
                    <h6>Preparation</h6>
                    <select
                      className="form-control font-small"
                      aria-label=".form-select-sm"
                      onChange={(e) =>
                        handleDrugChange("preparation", e.target.value)
                      }
                    >
                      <option defaultValue>Select Option</option>
                      <option value="tab">Tab.</option>
                      <option value="cap">Cap.</option>
                      <option value="inj">Inj.</option>
                      <option value="syr">Syr.</option>
                      <option value="drops">Drops</option>
                      <option value="ointment">Ointment</option>
                    </select>
                  </div>
                  <div className="col-12 my-2">
                    <h6>Route</h6>
                    <select
                      className="form-control font-small"
                      aria-label=".form-select-sm"
                      onChange={(e) =>
                        handleDrugChange("route", e.target.value)
                      }
                    >
                      <option defaultValue>Select Option</option>
                      <option value="topical">Topical</option>
                      <option value="oral">Oral</option>
                      <option value="rectal">Rectal</option>
                      <option value="vaginal">Vaginal</option>
                      <option value="inhalation">Inhalation</option>
                      <option value="local">Local</option>
                      <option value="chew">Chew</option>
                      <option value="intradermal">Intradermal</option>
                      <option value="subcutaneous">Subcutaneous</option>
                      <option value="intramuscular">Intramuscular</option>
                      <option value="intravenous">Intravenous</option>
                      <option value="nasal">Nasal</option>
                      <option value="ear_drops">Ear Drops</option>
                      <option value="eye_drops">Eye Drops</option>
                    </select>
                  </div>
                  <div className="col-12 my-2">
                    <h6 htmlFor="form-question">Dose</h6>
                    <div className="input-group">
                      <input
                        type="number"
                        className={`form-control ${
                          error?.key === "dose" && "error-border"
                        }`}
                        name="form-question"
                        placeholder="Dose"
                        value={drug.dose}
                        onChange={(e) =>
                          handleDrugChange("dose", e.target.value)
                        }
                      />
                      <div className="input-group-append">
                        <select
                          className="form-control font-small"
                          aria-label=".form-select-sm"
                          onChange={(e) =>
                            handleDrugChange("doseUnit", e.target.value)
                          }
                        >
                          <option defaultValue>Select Unit</option>
                          <option value="ml">ml</option>
                          <option value="unit">unit</option>
                          <option value="table_spoon">table spoon</option>
                          <option value="tea_spoon">tea spoon</option>
                        </select>
                      </div>
                    </div>
                    {error?.key === "doseUnit" && (
                      <small className="text-danger fs-10">
                        {error.message}
                      </small>
                    )}
                  </div>
                  <div className="col-12 my-2">
                    <h6>Direction</h6>
                    <select
                      className="form-control font-small"
                      aria-label=".form-select-sm"
                      onChange={(e) =>
                        handleDrugChange("direction", e.target.value)
                      }
                    >
                      <option defaultValue>Select Option</option>
                      <option value="before_meals">Before Meals</option>
                      <option value="after_meals">After Meals</option>
                    </select>
                  </div>
                  <div className="col-12 my-2">
                    <h6>Frequency</h6>
                    <select
                      className="form-control font-small"
                      aria-label=".form-select-sm"
                      onChange={(e) =>
                        handleDrugChange("frequency", e.target.value)
                      }
                    >
                      <option defaultValue>Select Option</option>
                      <option value="if_required">If Required</option>
                      <option value="immediately">Immediately</option>
                      <option value="once_a_day">Once a day</option>
                      <option value="twice_daily">Twice daily</option>
                      <option value="thrice_daily">Thrice daily</option>
                      <option value="four_times_a_day">Four times a day</option>
                      <option value="every_hour">Every hour</option>
                      <option value="every_night_at_bedtime">
                        Every night at bedtime
                      </option>
                      <option value="every_day">Every day</option>
                      <option value="every_other_day">Every other day</option>
                      <option value="every_four_hours">Every four hours</option>
                      <option value="once_a_week">Once a week</option>
                      <option value="three_times_a_week">
                        Three times a week
                      </option>
                    </select>
                  </div>
                  <div className="col-12 my-2">
                    <h6 htmlFor="form-question">Duration</h6>
                    <div className="input-group">
                      <input
                        type="number"
                        className={`form-control ${
                          error?.key === "duration" && "error-border"
                        }`}
                        name="form-question"
                        placeholder="Duration"
                        value={drug.duration}
                        onChange={(e) =>
                          handleDrugChange("duration", e.target.value)
                        }
                      />
                      <div className="input-group-append">
                        <select
                          className="form-control font-small"
                          aria-label=".form-select-sm"
                          onChange={(e) =>
                            handleDrugChange("durationUnit", e.target.value)
                          }
                        >
                          <option defaultValue>Select Unit</option>
                          <option value="hours">hours</option>
                          <option value="days">days</option>
                          <option value="weeks">weeks</option>
                          <option value="years">years</option>
                        </select>
                      </div>
                    </div>
                    {error?.key === "durationUnit" && (
                      <small className="text-danger fs-10">
                        {error.message}
                      </small>
                    )}
                  </div>
                  <div className="col-12 my-2">
                    <h6>Total Quantity</h6>
                    <input
                      type="number"
                      className={`form-control ${
                        error?.key === "quantity" && "error-border"
                      }`}
                      name="form-question"
                      placeholder="Quantity"
                      value={drug.quantity}
                      onChange={(e) =>
                        handleDrugChange("quantity", e.target.value)
                      }
                    />
                    {error?.key === "quantity" && (
                      <small className="text-danger fs-10">
                        {error.message}
                      </small>
                    )}
                  </div>
                  <div className="col-12 my-2 d-none">
                    <h6>Other Instructions</h6>
                    <input
                      type="text"
                      className={`form-control ${
                        error?.key === "instructions" && "error-border"
                      }`}
                      name="form-question"
                      placeholder="Instructions"
                      value={drug.instructions}
                      onChange={(e) =>
                        handleDrugChange("instructions", e.target.value)
                      }
                    />
                    {error?.key === "instructions" && (
                      <small className="text-danger fs-10">
                        {error.message}
                      </small>
                    )}
                  </div>
                  <div className="text-right my-3">
                    <span
                      className="btn btn-danger-outline btn-sm pointer mx-2"
                      onClick={(e) => setDrug("")}
                    >
                      Remove
                    </span>
                    <span
                      className="btn btn-violet btn-sm pointer mr-3"
                      onClick={saveDrug}
                    >
                      Add
                    </span>
                  </div>
                </div>
              ) : (
                <div>
                  <div
                    className="pointer btn btn-violet-outline btn-sm"
                    onClick={addDrug}
                  >
                    <i className="fa fa-plus" />
                  </div>
                </div>
              )}
            </div>
          </div>
          <div
            className="complete-payment btn btn-violet text-center"
            onClick={savePrescription}
          >
            {savingPrescription ? (
              <i className="fa fa-spinner fa-spin" />
            ) : (
              <span>Save Prescription</span>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default CreatePrescriptionComponent;
