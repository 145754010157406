import React from "react";
import { ENV } from "constants/env_keys";

function InvoiceComponent({
  currentUser,
  wallet,
  setStepNumber,
  withdrawAmount,
  accountName,
  accountNumber,
  ifscCode,
  save,
  loading,
}) {
  const totalWithdrawAmount =
    withdrawAmount - +process.env.REACT_APP_SERVICE_CHARGE * withdrawAmount;

  return (
    <div className="container desktop-align-center">
      <div className="wallet-withdraw-wrapper">
        <h5 className="step-header">
          <div className="header-text">Invoice details</div>
        </h5>
        <div className="">
          <div className="">
            <div className="invoice-table">
              <div className="d-flex flex-column invoice-table-section">
                <div className="d-flex flex-row justify-content-between my-2">
                  <div className="key">Withdraw amount</div>
                  <div className="value">
                    {ENV.CURRENCY}
                    {parseInt(withdrawAmount).toFixed(2)}
                  </div>
                </div>
                <div className="d-flex flex-row justify-content-between">
                  <div className="key">Service Fees</div>
                  <div className="value">
                    - {ENV.CURRENCY}
                    {(ENV.SERVICE_CHARGE * withdrawAmount).toFixed(2)}
                  </div>
                </div>
              </div>
              <hr className="invoice-table-divider" />
              <div className="d-flex flex-row justify-content-between invoice-table-section font-bold">
                <div className="key">Total withdrawal amount</div>
                <div className="value">
                  {ENV.CURRENCY}
                  {totalWithdrawAmount.toFixed(2)}
                </div>
              </div>
              <hr className="invoice-table-divider" />
              <div className="d-flex flex-column invoice-table-section">
                <div className="d-flex flex-row justify-content-between">
                  <div className="col-header">Payee details</div>
                  <div
                    className="btn-sm btn-violet-outline value"
                    onClick={(e) => setStepNumber(1)}
                  >
                    Edit
                  </div>
                </div>
                <div className="d-flex flex-row">
                  <div className="value text-left text-uppercase">
                    {accountName}
                    <br />
                    {accountNumber} &middot; {ifscCode}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="text-center mt-4">
            <button
              className="btn btn-sm btn-violet-outline pointer col-12 col-sm-5 m-sm-3 mt-2 py-2"
              onClick={(e) => save(totalWithdrawAmount)}
              disabled={loading}
            >
              {loading ? (
                <div className="spinner-border spinner-border-sm" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              ) : (
                <span>Confirm</span>
              )}
            </button>
            <button
              className="btn btn-sm btn-outline-secondary pointer col-12 col-sm-5 mx-0 mx-sm-2 my-4 py-2"
              onClick={(e) => setStepNumber(1)}
            >
              <span>Cancel</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default InvoiceComponent;
