import React from "react";
import { Link } from "react-router-dom";

import { Routes } from "constants/index";

const AssignRequestToDoctorComponent = ({ currentUser }) => {
  return (
    <div className="dashboard-content">
      <div className="text-center mt-3 col-12">
        <Link
          to={Routes.APP_DC_PUBLIC_APPOINTMENTS}
          className="btn btn-violet m-2"
        >
          Show Personal Appointments
        </Link>
        <div className="btn btn-violet col-6 m-2 d-none">Report an issue</div>
      </div>
    </div>
  );
};

export default AssignRequestToDoctorComponent;
