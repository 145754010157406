import React from "react";
import { useHistory } from "react-router-dom";

import { Routes } from "constants/index";

const CallDoctorPopupComponent = () => {
  const history = useHistory();

  const redirectToPostPage = () => {
    history.push(`${Routes.APP_CALL_DOCTOR}`);
  };

  return (
    <div className="text-center mx-3 mx-sm-0 mt-sm-2">
      <button
        className={`btn btn-sm btn-violet-rounded px-3 py-2 text-white`}
        onClick={redirectToPostPage}
      >
        <i className="fa fa-phone pr-4" />
        Ask Doctor &nbsp; &nbsp; &nbsp;✨
      </button>
    </div>
  );
};

export default CallDoctorPopupComponent;
