import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

import { add } from "firebase_config";
import { Routes, Tables } from "constants/index";
import HeaderComponent from "./header";

function LeaveFeedbackComponent(props) {
  const [comment, setComment] = useState("");
  const history = useHistory();

  const saveFeedback = async () => {
    await add(Tables.MEDICAL_REQUEST_FEEDBACKS, {
      type: "like",
      comment,
      userId: props.currentUser.id,
    });
    toast.success("Thank you for sharing feedback!");
    setComment("");
    history.push(Routes.APP_REQUESTS);
  };

  return (
    <>
      <HeaderComponent />
      <div className="p-3 mt-5 fs-10">
        <textarea
          className="form-control font-small col-12 col-5 mt-2"
          placeholder="Write a comment!"
          onChange={(e) => setComment(e.target.value)}
        />
        <div className="mt-2">
          <button className="btn btn-violet mr-2" onClick={saveFeedback}>
            Submit
          </button>
          <span className="d-none">
            * Use promocode <b>MEDOC_20D</b> to avail 20% discount on your next
            purchase!
          </span>
        </div>
      </div>
    </>
  );
}

export default LeaveFeedbackComponent;
