import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

import { update, uploadFile, removeFile } from "firebase_config";
import { Tables } from "constants/index";
import PaymentStatusComponent from "../payment_status/component";
import DoctorRemarksComponent from "../doctor_remarks/component";
import AssignDoctorComponent from "../assign_doctor/component";

const UpdateFcComponent = ({
  currentUser,
  options,
  setOptions,
  searchValue,
  setSearchValue,
}) => {
  const [savingCertificate, setSavingCertificate] = useState(false);
  const [error, setError] = useState({
    message: "",
    key: "",
  });
  const [loaders, setLoaders] = useState({
    videoDoc: false,
    optionalDoc: false,
    idFront: false,
    idBack: false,
  });
  const history = useHistory();

  const handleChange = (key, value, additionalField) => {
    if (additionalField) {
      setOptions({
        ...options,
        [key]: {
          ...options[key],
          [additionalField]: value,
        },
      });
    } else {
      setOptions({
        ...options,
        [key]: value,
      });
    }
  };

  const uploadImage = async (file, key, event) => {
    if (!file) {
      toast.error("New image required");
      return null;
    }
    setLoaders((prev) => ({
      ...prev,
      [key]: true,
    }));
    await uploadFile(file, "image", async (url, err) => {
      if (err) {
        alert(err);
        return null;
      }
      event.target.value = null;
      setOptions((prevState) => ({
        ...prevState,
        [key]: url,
      }));
      setLoaders((prev) => ({
        ...prev,
        [key]: false,
      }));
    });
  };

  const deleteImage = async (url, key, event) => {
    if (window.confirm("Are you sure to remove image?")) {
      setLoaders((prev) => ({
        ...prev,
        [key]: true,
      }));
      await removeFile(url);
      setLoaders((prev) => ({
        ...prev,
        [key]: false,
      }));
      setOptions((prevState) => ({
        ...prevState,
        [key]: "",
      }));
    }
  };

  const showErrors = () => {
    let error;
    if (!options.firstName) {
      error = {
        message: "First name is required",
        key: "firstName",
      };
    } else if (!options.lastName) {
      error = {
        message: "Last name is required",
        key: "lastName",
      };
    } else if (!options.dob) {
      error = {
        message: "Date of birth is required",
        key: "dob",
      };
    } else if (!options.age) {
      error = {
        message: "Age is required",
        key: "age",
      };
    } else if (!options.email) {
      error = {
        message: "Email is required",
        key: "email",
      };
    } else if (!options.mobile) {
      error = {
        message: "Mobile number is required",
        key: "mobile",
      };
    } else if (options.mobile && options.mobile.length !== 12) {
      error = {
        message: "Mobile number must contain 12-digits",
        key: "mobile",
      };
    } else if (options.address && !options.address.door) {
      error = {
        message: "Door number is required",
        key: "door",
      };
    } else if (options.address && !options.address.streetName) {
      error = {
        message: "Street name is required",
        key: "streetName",
      };
    } else if (options.address && !options.address.city) {
      error = {
        message: "City is required",
        key: "city",
      };
    } else if (options.address && !options.address.state) {
      error = {
        message: "State is required",
        key: "state",
      };
    } else if (options.address && !options.address.zipcode) {
      error = {
        message: "Postal / Zip code is required ",
        key: "zipcode",
      };
    } else if (!options.height) {
      error = {
        message: "Height is required",
        key: "height",
      };
    } else if (!options.weight) {
      error = {
        message: "Weight is required",
        key: "weight",
      };
    } else if (!options.reasonDescription) {
      error = {
        message: "Description is required",
        key: "reasonDescription",
      };
    } else if (!options.videoDoc) {
      error = {
        message: "Video is required",
        key: "videoDoc",
      };
    } else if (!options.idFront) {
      error = {
        message: "Proof of Identification (Front) is required",
        key: "idFront",
      };
    } else if (!options.idBack) {
      error = {
        message: "Proof of Identification (Back) is required",
        key: "idBack",
      };
    } else if (!options.orgName) {
      error = {
        message: "Workplace name is required",
        key: "orgName",
      };
    } else if (!options.orgAddress) {
      error = {
        message: "Workplace address is required",
        key: "orgAddress",
      };
    }
    setError(error);
    return error;
  };

  const saveCertificate = async () => {
    const err = await showErrors();
    if (err && err.message) {
      toast.error(err.message);
      return;
    }
    setSavingCertificate(true);
    options.assigned = !!options.doctorId;
    const res = await update(Tables.MEDICAL_REQUESTS, searchValue, options);

    if (res.status === 200) {
      toast.success(`Request updated. ${searchValue}`);
      history.goBack();
    } else {
      setError("Something went wrong. Refresh the page");
    }
    setSavingCertificate(false);
  };

  return (
    <>
      <div className="dashboard-content">
        <div className="container create-post-wrapper">
          <div className="card p-2 p-sm-4 my-sm-5">
            <div className="mt-2">
              <h6>
                Full name of the person who requires certificate * <br />
                <small className="text-secondary fs-10">
                  *As per Govt ID proof
                </small>
              </h6>
              <div className="row">
                <div className="col-12 col-sm-6">
                  <input
                    type="text"
                    className={`form-control font-small ${
                      error?.key === "firstName" && "error-border"
                    }`}
                    name="form-question"
                    placeholder="First name"
                    value={options.firstName}
                    onChange={(e) => handleChange("firstName", e.target.value)}
                  />
                  {error?.key === "firstName" ? (
                    <small className="text-danger fs-10">{error.message}</small>
                  ) : (
                    <small className="text-secondary fs-10">First name</small>
                  )}
                </div>
                <div className="col-12 col-sm-6 mt-4 mt-sm-0">
                  <input
                    type="text"
                    className={`form-control font-small ${
                      error?.key === "lastName" && "error-border"
                    }`}
                    name="form-question"
                    placeholder="Last name"
                    value={options.lastName}
                    onChange={(e) => handleChange("lastName", e.target.value)}
                  />
                  {error?.key === "lastName" ? (
                    <small className="text-danger fs-10">{error.message}</small>
                  ) : (
                    <small className="text-secondary fs-10">Last name</small>
                  )}
                </div>
              </div>
            </div>
            <div className="row mt-4">
              <div className="col-12 col-sm-6">
                <h6>Date of birth *</h6>
                <input
                  type="date"
                  className={`form-control ${
                    error?.key === "dob" && "error-border"
                  }`}
                  name="form-question"
                  placeholder="dob"
                  value={options.dob}
                  onChange={(e) => handleChange("dob", e.target.value)}
                />
                {error?.key === "dob" && (
                  <small className="text-danger fs-10">{error.message}</small>
                )}
              </div>
              <div className="col-12 col-sm-6 mt-3 mt-sm-0">
                <h6>Age *</h6>
                <input
                  type="number"
                  className={`form-control ${
                    error?.key === "age" && "error-border"
                  }`}
                  name="form-question"
                  placeholder="Age"
                  value={options.age}
                  onChange={(e) => handleChange("age", e.target.value)}
                />
                {error?.key === "age" && (
                  <small className="text-danger fs-10">{error.message}</small>
                )}
              </div>
            </div>
            <div className="mt-4">
              <h6>Gender *</h6>
              <select
                className="form-control font-small"
                aria-label=".form-select-sm"
                onChange={(e) => handleChange("gender", e.target.value)}
              >
                <option value="male">Male</option>
                <option value="female">Female</option>
                <option value="other">Other</option>
              </select>
            </div>
            <div className="row mt-4">
              <div className="col-12 col-sm-6">
                <h6 htmlFor="form-question">Email *</h6>
                <input
                  type="email"
                  className={`form-control ${
                    error?.key === "email" && "error-border"
                  }`}
                  name="form-question"
                  placeholder="Email"
                  value={options.email}
                  onChange={(e) => handleChange("email", e.target.value)}
                />
                {error?.key === "email" && (
                  <small className="text-danger fs-10">{error.message}</small>
                )}
              </div>
              <div className="col-12 col-sm-6 mt-4 mt-sm-0">
                <h6 htmlFor="form-question">Mobile *</h6>
                <input
                  type="number"
                  className={`form-control ${
                    error?.key === "mobile" && "error-border"
                  }`}
                  name="form-question"
                  placeholder="917890789090"
                  value={options.mobile}
                  onChange={(e) => handleChange("mobile", e.target.value)}
                />
                <small className="text-secondary fs-10">
                  e.g. 917890789078
                </small>
                {error?.key === "mobile" && (
                  <div className="text-danger fs-10">{error.message}</div>
                )}
              </div>
            </div>
            <div className="mt-4">
              <h6>
                Address *<br />
                <small className="text-secondary fs-10">
                  *As per Govt ID proof
                </small>
              </h6>
              <div className="row">
                <div className="col-12 col-sm-6 my-2">
                  <input
                    type="text"
                    className={`form-control ${
                      error?.key === "door" && "error-border"
                    }`}
                    name="form-question"
                    placeholder="Door no."
                    value={options.address && options.address.door}
                    onChange={(e) =>
                      handleChange("address", e.target.value, "door")
                    }
                  />
                  {error?.key === "door" ? (
                    <small className="text-danger fs-10">{error.message}</small>
                  ) : (
                    <small className="text-secondary fs-10">Door number</small>
                  )}
                </div>
                <div className="col-12 col-sm-6 my-2">
                  <input
                    type="text"
                    className={`form-control ${
                      error?.key === "streetName" && "error-border"
                    }`}
                    name="form-question"
                    placeholder="Street name"
                    value={options.address && options.address.streetName}
                    onChange={(e) =>
                      handleChange("address", e.target.value, "streetName")
                    }
                  />
                  {error?.key === "streetName" ? (
                    <small className="text-danger fs-10">{error.message}</small>
                  ) : (
                    <small className="text-secondary fs-10">Street name</small>
                  )}
                </div>
                <div className="col-12 col-sm-6 my-2">
                  <input
                    type="text"
                    className={`form-control ${
                      error?.key === "city" && "error-border"
                    }`}
                    name="form-question"
                    placeholder="City"
                    value={options.address && options.address.city}
                    onChange={(e) =>
                      handleChange("address", e.target.value, "city")
                    }
                  />
                  {error?.key === "city" ? (
                    <small className="text-danger fs-10">{error.message}</small>
                  ) : (
                    <small className="text-secondary fs-10">City</small>
                  )}
                </div>
                <div className="col-12 col-sm-6 my-2">
                  <input
                    type="text"
                    className={`form-control ${
                      error?.key === "state" && "error-border"
                    }`}
                    name="form-question"
                    placeholder="State / Region / Province"
                    value={options.address && options.address.state}
                    onChange={(e) =>
                      handleChange("address", e.target.value, "state")
                    }
                  />
                  {error?.key === "state" ? (
                    <small className="text-danger fs-10">{error.message}</small>
                  ) : (
                    <small className="text-secondary fs-10">
                      State / Region / Province
                    </small>
                  )}
                </div>
                <div className="col-12 col-sm-6 my-2">
                  <input
                    type="text"
                    className={`form-control ${
                      error?.key === "zipcode" && "error-border"
                    }`}
                    name="form-question"
                    placeholder="Postal/Zip Code"
                    value={options.address && options.address.zipcode}
                    onChange={(e) =>
                      handleChange("address", e.target.value, "zipcode")
                    }
                  />
                  {error?.key === "zipcode" ? (
                    <small className="text-danger fs-10">{error.message}</small>
                  ) : (
                    <small className="text-secondary fs-10">
                      Postal / Zip code
                    </small>
                  )}
                </div>
                <div className="col-12 col-sm-6 my-2">
                  <select
                    className="form-control font-small"
                    aria-label=".form-select-sm"
                    value={options.address && options.address.country}
                    onChange={(e) =>
                      handleChange("address", e.target.value, "country")
                    }
                  >
                    <option value="india" defaultValue>
                      India
                    </option>
                  </select>
                  <small className="text-secondary fs-10">Country</small>
                </div>
              </div>
            </div>

            {/* medical problem */}
            <div className="row mt-4 measurements">
              <div className="col-12 col-sm-6">
                <h6 htmlFor="form-question">Height *</h6>
                <div className="input-group">
                  <input
                    type="number"
                    className={`form-control ${
                      error?.key === "height" && "error-border"
                    }`}
                    name="form-question"
                    placeholder="Height (in cm)"
                    value={options.height}
                    onChange={(e) => handleChange("height", e.target.value)}
                  />
                  <div className="input-group-append">
                    <span className="input-group-text" id="basic-addon1">
                      cm
                    </span>
                  </div>
                </div>
                {error?.key === "height" && (
                  <div className="text-danger fs-10">{error.message}</div>
                )}
              </div>
              <div className="col-12 col-sm-6 mt-4 mt-sm-0">
                <h6 htmlFor="form-question">Weight *</h6>
                <div className="input-group">
                  <input
                    type="number"
                    className={`form-control ${
                      error?.key === "weight" && "error-border"
                    }`}
                    name="form-question"
                    placeholder="Weight (in Kg)"
                    value={options.weight}
                    onChange={(e) => handleChange("weight", e.target.value)}
                  />
                  <div className="input-group-append">
                    <span className="input-group-text" id="basic-addon1">
                      Kg
                    </span>
                  </div>
                </div>
                {error?.key === "weight" && (
                  <div className="text-danger fs-10">{error.message}</div>
                )}
              </div>
            </div>
            <div className="mt-4">
              <h6>
                Description *
                <div className="text-secondary fs-10 pt-2 fw-400">
                  Please explain why you need this certificate. Or any other
                  details that a doctor should be aware of.
                </div>
              </h6>
              <textarea
                className={`form-control fs-12 ${
                  error?.key === "reasonDescription" && "error-border"
                }`}
                name="form-question"
                rows="4"
                placeholder="Type here."
                value={options.reasonDescription}
                onChange={(e) =>
                  handleChange("reasonDescription", e.target.value)
                }
              />
              {error?.key === "reasonDescription" && (
                <small className="text-danger fs-10">{error.message}</small>
              )}
            </div>
            <div className="mt-4">
              <h6>
                Video evidence *
                <div className="text-secondary fs-10 pt-2 fw-400">
                  Only video formats supported.
                </div>
              </h6>
              <div className="text-center image-label">
                <div className={options.videoDoc && "d-none"}>
                  <label htmlFor="videoDoc" className="pointer">
                    {loaders.videoDoc ? (
                      <span className="fs-10">
                        Our system is working. Sometimes it takes a while...
                        <br />
                        <i className="fa fa-spinner fa-spin" />
                      </span>
                    ) : (
                      <span>
                        Upload <i className="fa fa-arrow-circle-o-up" /> <br />
                        <small className="fs-12">
                          15 secs full body video of you walking
                        </small>
                      </span>
                    )}
                  </label>
                  <input
                    type="file"
                    className="form-control-plaintext d-none"
                    id="videoDoc"
                    onChange={(e) =>
                      uploadImage(e.target.files[0], "videoDoc", e)
                    }
                    accept="video/*"
                  />
                </div>
                {options.videoDoc && (
                  <div className="">
                    <object
                      data={options.videoDoc}
                      className="camera-icon"
                      width="250px"
                      height="150px"
                    />
                    <button
                      className="btn btn-violet btn-sm"
                      onClick={(e) =>
                        deleteImage(options.videoDoc, "videoDoc", e)
                      }
                    >
                      Delete File
                    </button>{" "}
                  </div>
                )}
              </div>
              {error?.key === "videoDoc" && (
                <small className="text-danger fs-10">{error.message}</small>
              )}
            </div>
            <div className="mt-4">
              <h6>
                Photo proof of blood pressure & pulse
                <div className="text-secondary fs-10 pt-2 fw-400">
                  Only PNG/JPEG formats supported.{" "}
                  <a
                    href="https://storage.googleapis.com/medoc-live.appspot.com/images/photoproof_sample.jpeg"
                    target="_blank"
                    rel="noreferrer noopener"
                    className="text-violet"
                  >
                    Sample photo
                  </a>
                </div>
              </h6>
              <div className="text-center image-label">
                <div className={options.optionalDoc && "d-none"}>
                  <label htmlFor="optionalDoc" className="pointer">
                    {loaders.optionalDoc ? (
                      <span className="fs-10">
                        Our system is working. Sometimes it takes a while...
                        <br />
                        <i className="fa fa-spinner fa-spin" />
                      </span>
                    ) : (
                      <span>
                        Upload <i className="fa fa-arrow-circle-o-up" />
                      </span>
                    )}
                  </label>
                  <input
                    type="file"
                    className="form-control-plaintext d-none"
                    id="optionalDoc"
                    onChange={(e) =>
                      uploadImage(e.target.files[0], "optionalDoc", e)
                    }
                    accept="application/pdf,image/*"
                  />
                </div>
                {options.optionalDoc && (
                  <div className="">
                    <object
                      data={options.optionalDoc}
                      className="camera-icon"
                      width="250px"
                      height="150px"
                    />
                    <button
                      className="btn btn-violet btn-sm"
                      onClick={(e) =>
                        deleteImage(options.optionalDoc, "optionalDoc", e)
                      }
                    >
                      Delete File
                    </button>{" "}
                  </div>
                )}
              </div>
              {error?.key === "optionalDoc" && (
                <small className="text-danger fs-10">{error.message}</small>
              )}
            </div>
            {/* documents */}
            <div className="mt-4">
              <h6>
                Proof of Identification *
                <div className="text-secondary fs-10 pt-2 fw-400">
                  Only PNG/JPEG formats supported like Driving license, Aadhar,
                  PAN, Passport etc.
                </div>
              </h6>
              <div className="row container text-center pr-0">
                <div className="col-12 col-sm-6 image-label">
                  <div className={options.idFront && "d-none"}>
                    <label htmlFor="idFront" className="pointer">
                      {loaders.idFront ? (
                        <span className="fs-10">
                          Our system is working. Sometimes it takes a while...
                          <br />
                          <i className="fa fa-spinner fa-spin" />
                        </span>
                      ) : (
                        <span>Front</span>
                      )}
                    </label>
                    <input
                      type="file"
                      className="form-control-plaintext d-none"
                      id="idFront"
                      onChange={(e) =>
                        uploadImage(e.target.files[0], "idFront", e)
                      }
                      accept="image/*"
                    />
                  </div>
                  {options.idFront && (
                    <div>
                      <button
                        className="btn btn-violet btn-sm"
                        onClick={(e) =>
                          deleteImage(options.idFront, "idFront", e)
                        }
                      >
                        Delete Image
                      </button>
                      <br />
                      <img
                        src={options.idFront}
                        className="camera-icon"
                        alt="Image"
                        width="100px"
                        height="100px"
                      />
                    </div>
                  )}
                </div>
                <div className="col-12 col-sm-6 image-label">
                  <div className={options.idBack && "d-none"}>
                    <label htmlFor="idBack" className="pointer">
                      {loaders.idBack ? (
                        <span className="fs-10">
                          Our system is working. Sometimes it takes a while...
                          <br />
                          <i className="fa fa-spinner fa-spin" />
                        </span>
                      ) : (
                        <span>Back</span>
                      )}
                    </label>
                    <input
                      type="file"
                      className="form-control-plaintext d-none"
                      id="idBack"
                      onChange={(e) =>
                        uploadImage(e.target.files[0], "idBack", e)
                      }
                      accept="image/*"
                    />
                  </div>
                  {options.idBack && (
                    <div>
                      <button
                        className="btn btn-violet btn-sm"
                        onClick={(e) =>
                          deleteImage(options.idBack, "idBack", e)
                        }
                      >
                        Delete Image
                      </button>{" "}
                      <br />
                      <img
                        src={options.idBack}
                        className="camera-icon"
                        alt="Image"
                        width="100px"
                        height="100px"
                      />
                    </div>
                  )}
                </div>
              </div>
              {error?.key === "idFront" && (
                <small className="text-danger fs-10">{error.message}</small>
              )}
              {error?.key === "idBack" && (
                <small className="text-danger fs-10">{error.message}</small>
              )}
            </div>
            <hr />
            <div className="mt-3">
              <h6>Workplace Name *</h6>
              <input
                type="text"
                className={`form-control col-12 col-sm-6 ${
                  error?.key === "orgName" && "error-border"
                }`}
                placeholder="Workplace Name"
                value={options.orgName}
                onChange={(e) => handleChange("orgName", e.target.value)}
              />
              {error?.key === "orgName" && (
                <small className="text-danger fs-10">{error.message}</small>
              )}
            </div>
            <div className="mt-4">
              <h6>Workplace Address *</h6>
              <textarea
                className={`form-control fs-12 ${
                  error?.key === "orgAddress" && "error-border"
                }`}
                name="form-question"
                placeholder="Complete Workplace Address"
                value={options.orgAddress}
                onChange={(e) => handleChange("orgAddress", e.target.value)}
              />
              {error?.key === "orgAddress" && (
                <small className="text-danger fs-10">{error.message}</small>
              )}
            </div>
          </div>
          <div className={`${currentUser.isDoctor && "d-none"}`}>
            <AssignDoctorComponent
              handleChange={handleChange}
              options={options}
              setOptions={setOptions}
            />
            <PaymentStatusComponent
              handleChange={handleChange}
              options={options}
            />
            <DoctorRemarksComponent
              handleChange={handleChange}
              options={options}
              setOptions={setOptions}
            />
          </div>
          <div
            className="complete-payment btn btn-violet text-center"
            onClick={saveCertificate}
          >
            {savingCertificate ? (
              <i className="fa fa-spinner fa-spin" />
            ) : (
              <span>Save Record</span>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default UpdateFcComponent;
