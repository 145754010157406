import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import moment from "moment";

import { capitalizeFirstLetter } from "helpers";
import { getQuery, firestore } from "firebase_config";
import { Routes, Tables, ENV } from "constants/index";
import HeaderComponent from "./header";

function DoctorAnalyticsComponent({ currentUser }) {
  const [medRequests, setMedRequests] = useState();
  const [newPayment, setNewPayment] = useState({
    totalAmount: 0,
    deductions: 0,
    netAmount: 0,
    payLength: 0,
    cpayLength: 0,
    startDate: "",
    endDate: "",
  });

  useEffect(() => {
    async function getPaymentDetails() {
      const startDate = +ENV.PAYMENT_START_DATE;
      const endDate = +ENV.PAYMENT_END_DATE;
      const requests = await getQuery(
        firestore
          .collection(Tables.MEDICAL_REQUESTS)
          .where("doctorId", "==", currentUser.id)
          .where("updatedAt", ">", startDate)
          .where("updatedAt", "<=", endDate)
          .get()
      );

      const pay = requests.filter(
        (req) =>
          !req.isPrescriptionRequired &&
          req.status !== "pending" &&
          !req.reassigned
      ).length;
      const cpay = requests.filter(
        (req) =>
          req.isPrescriptionRequired &&
          req.status !== "pending" &&
          !req.reassigned
      ).length;

      const tp = currentUser.pay * pay + currentUser.payWithPrescription * cpay;
      setNewPayment({
        totalAmount: tp,
        deductions: tp * 0.1,
        netAmount: tp - tp * 0.1,
        payLength: pay,
        cpayLength: cpay,
        startDate,
        endDate,
      });
      setMedRequests(requests.filter((req) => req.status !== "pending"));
    }

    if (!newPayment.startDate) {
      getPaymentDetails();
    }
  });

  return (
    <div className="dashboard-content">
      <HeaderComponent />
      <div className="container settings-wrapper desktop-align-center pt-5">
        <div className="section">
          <div className="fs-12 fw-500">
            {moment(newPayment.startDate).format("MMM DD, YYYY")} -{" "}
            {moment(newPayment.endDate).format("MMM DD, YYYY")}
          </div>
          <ul className="section-list">
            <li className="d-flex flex-row align-items-center justify-content-between">
              <span className="option-name">Total Amount</span>
              <span className="option-name">
                {newPayment.totalAmount > 0
                  ? newPayment.totalAmount.toFixed(2)
                  : "0.00"}{" "}
                {ENV.CURRENCY}
              </span>
            </li>
            <li className="d-flex flex-row align-items-center justify-content-between">
              <span className="option-name">Deductions</span>
              <span className="option-name">
                {newPayment.deductions > 0
                  ? newPayment.deductions.toFixed(2)
                  : "0.00"}{" "}
                {ENV.CURRENCY}
              </span>
            </li>
            <li className="d-flex flex-row align-items-center justify-content-between">
              <span className="option-name">Net Amount (withdrawable)</span>
              <span className="option-name">
                {newPayment.netAmount > 0
                  ? newPayment.netAmount.toFixed(2)
                  : "0.00"}{" "}
                {ENV.CURRENCY}
              </span>
            </li>
            <li className="d-flex flex-row align-items-center justify-content-between">
              <span className="option-name">Normal Consultations</span>
              <span className="option-name">{newPayment.payLength}</span>
            </li>
            <li className="d-flex flex-row align-items-center justify-content-between">
              <span className="option-name">Prescription Consultations</span>
              <span className="option-name">{newPayment.cpayLength}</span>
            </li>
          </ul>
        </div>
        <div>
          <h6>Payment dates</h6>
          {currentUser.payments &&
            currentUser.payments.map((payment, i) => (
              <div key={i} className="card">
                Payment Date:{" "}
                <b>
                  {payment.paymentDate} - {payment.paymentMonth}{" "}
                  {payment.paymentYear}
                </b>{" "}
                <br />
                Total Amount: {payment.totalAmount} <br />
                Deductions: {payment.deductions} <br />
                Net Amount: {payment.netAmount}
              </div>
            ))}
        </div>
        <table className="table table-bordered table-responsive d-none">
          <thead>
            <tr>
              <th>Request Number</th>
              <th>Name</th>
              <th>Certificate Type</th>
              <th>Resolved At</th>
            </tr>
          </thead>
          <tbody>
            {medRequests?.map((req) => (
              <tr key={req.id}>
                <td>
                  <Link
                    to={`${Routes.APP_POSTS}/${req.id}`}
                    className="text-violet fw-500"
                  >
                    {req.id}
                  </Link>
                </td>
                <td>
                  {req.firstName} {req.lastName}
                </td>
                <td>
                  {" "}
                  {req.type
                    ? capitalizeFirstLetter(req.type.replace(/_/g, " "))
                    : "Sick leave certificate"}
                </td>
                <td>{moment(req.updatedAt).format("DD-MM-YYYY, HH:mm")}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default DoctorAnalyticsComponent;
