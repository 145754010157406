import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import "./style.css";

import SpecialHeaderComponent from "./children/special_header/component";
import {
  CallDoctorPopupComponent,
  ClinicsComponent,
  HeaderComponent,
  LoadingComponent,
} from "components";
import { SetPosts } from "store/actions";
import { getQuery, firestore } from "firebase_config";
import { Tables, Routes } from "constants/index";

class ShowPostsComponent extends Component {
  constructor(props) {
    super(props);
    this.bindPosts = props.bindPosts;
    this.propsState = props.history.location.state || {};
    this.state = {
      posts: [],
      isPublicDoctor: props.currentUser.roles.find((role) => role === "public"),
      isDoctor: props.currentUser.roles.find((role) => role === "doctor"),
      isAdmin: props.currentUser.roles.find((role) => role === "admin"),
      showPaymentPendingMessage: false,
      paymentPendingPost: {},
      virtualClinic: "",
    };
  }

  componentDidMount() {
    if (this.state.isDoctor) {
      this.props.history.push(Routes.APP_ASSIGN_TO_DOCTOR);
      return;
    }
    if (this.state.isAdmin) {
      this.props.history.push(Routes.APP_ADMIN_HOME);
      return;
    }
    this.loadPosts();
    if (this.state.isPublicDoctor) {
      this.loadPublicProfile();
    }
  }

  loadPosts = async () => {
    // this.setState({ loading: true });
    const posts = await getQuery(
      firestore
        .collection(Tables.MEDICAL_REQUESTS)
        .where("userId", "==", this.props.currentUser.id)
        .get()
    );
    const post = posts.sort((a, b) => b.updatedAt - a.updatedAt);
    if (
      post[0] &&
      (post[0].isEditRequired || post[0].paymentStatus === "pending")
    ) {
      this.setState({
        showPaymentPendingMessage: true,
        paymentPendingPost: post[0],
      });
    }
    await this.bindPosts(this.props.currentUser, "none", posts);
    // this.setState({ loading: false });
  };

  loadPublicProfile = async () => {
    const vc = await getQuery(
      firestore
        .collection(Tables.DC_PUBLIC_PROFILE)
        .where("userId", "==", this.props.currentUser.id)
        .get()
    );
    if (vc[0]) {
      this.setState({
        virtualClinic: vc[0],
      });
    }
  };

  render() {
    return (
      <>
        <HeaderComponent />
        <div className="pt-3 mt-5">
          {this.state.showPaymentPendingMessage && !this.state.isAdmin && (
            <SpecialHeaderComponent
              currentUser={this.currentUser}
              paymentPendingPost={this.state.paymentPendingPost}
            />
          )}
          <CallDoctorPopupComponent />
          <div className="container-fluid pt-4">
            {this.state.isPublicDoctor && (
              <>
                <div className="row">
                  <div className="col-12 col-sm-6 col-md-4 product-card">
                    <div className="card mx-2 mb-4 mx-sm-0">
                      <div className="h5 text-violet fw-600">
                        My Appointments
                      </div>
                      <p className="card-text mt-2 fs-12">
                        Single place to manage all your appointments. Create
                        prescriptions, send feedbacks & more.
                      </p>
                      <Link
                        to={Routes.APP_DC_PUBLIC_APPOINTMENTS}
                        className="btn btn-violet btn-sm py-2"
                      >
                        See all
                      </Link>{" "}
                    </div>
                  </div>
                  <div className="col-12 col-sm-6 col-md-4 product-card">
                    <div className="card mx-2 mb-4 mx-sm-0">
                      <div className="h5 text-violet fw-600 d-flex flex-row align-items-center">
                        Virtual Clinic
                        {!this.state.virtualClinic ? (
                          <span className="text-danger pl-2 fs-12 fw-500">
                            <i className="fa fa-dot-circle-o" /> Pending
                          </span>
                        ) : (
                          <a
                            href={`https://medoc.life/dr/${this.state.virtualClinic.username}`}
                            className="text-success pl-2 fs-12 fw-500"
                            rel="noopener noreferrer"
                            target="_blank"
                          >
                            <i className="fa fa-dot-circle-o" /> Live
                          </a>
                        )}
                      </div>
                      <p className="card-text mt-2 fs-12">
                        You are in charge of your clinic. It is important to
                        ensure that both your clinic and patients are well taken
                        care of.
                      </p>
                      <Link
                        to={Routes.APP_EDIT_VIRTUAL_CLINIC}
                        className="btn btn-violet btn-sm py-2"
                      >
                        {this.state.virtualClinic ? "Manage" : "Create"}
                      </Link>{" "}
                    </div>
                  </div>
                </div>
                <hr className="col-12" />
              </>
            )}
            <div className="row">
              <div
                className={
                  this.state.isAdmin
                    ? "d-none"
                    : "col-12 col-sm-6 col-md-4 product-card"
                }
              >
                <div className="card mx-2 mb-4 mx-sm-0">
                  <div className="h5 text-violet fw-600">
                    Medical certificate
                  </div>
                  <p className="card-text mt-3 fs-12">
                    Get all medical certificates within 60 minutes which is
                    certified by an Indian doctor.
                  </p>
                  <Link
                    to={Routes.APP_CHOOSE_CERTIFICATE_TYPE}
                    className="btn btn-violet btn-sm py-3 fw-500 mt-2"
                  >
                    <i className="fa fa-file-text-o pr-2" />
                    Get Certificate Now
                  </Link>{" "}
                </div>
              </div>
              <div className="col-12 col-sm-6 col-md-4 product-card d-none">
                <div className="card mx-2 mb-4 mx-sm-0">
                  <div className="h5">Online prescriptions</div>
                  <p className="card-text text-secondary fs-12">
                    Looking for a doctor to speak and get prescriptions. <br />{" "}
                    <br /> Fill out this form. Our doctor buddy will call you
                    and provide you digital prescriptions in minutes.
                  </p>
                  <Link
                    to={Routes.APP_CREATE_POST}
                    className="btn btn-violet btn-sm py-3 fw-500"
                  >
                    <i className="fa fa-user" />
                    Consult Now
                  </Link>{" "}
                </div>
              </div>
              <div
                className={
                  this.state.isAdmin
                    ? "d-none"
                    : "col-12 col-sm-6 col-md-4 product-card"
                }
              >
                <div className="card mx-2 mb-4 mx-sm-0">
                  <div className="h5 text-violet fw-600">Consult Doctor</div>
                  <p className="card-text mt-3 fs-12">
                    Share your health concerns or reports with a doctor and get
                    a feedback within 120 minutes*.
                  </p>
                  <Link
                    to={Routes.APP_CONSULT_DOCTOR}
                    className="btn btn-violet btn-sm py-3 fw-500 mt-2"
                  >
                    <i className="fa fa-user-o pr-1" /> Consult Now
                  </Link>{" "}
                </div>
              </div>
              <div className="col-12 col-sm-6 col-md-4 product-card d-none">
                <div className="card mx-2 mb-4 mx-sm-0">
                  <div className="h5">Medoc Global</div>
                  <p className="card-text text-secondary fs-12">
                    Contact Indian doctors while living overseas and receive a
                    response within 24 hours.
                  </p>
                  <Link
                    to={Routes.APP_CONSULT_DOCTOR_ABROAD}
                    className="btn btn-violet btn-sm py-2"
                  >
                    Seek Advice
                  </Link>{" "}
                </div>
              </div>
            </div>
            {this.state.loading && <LoadingComponent />}
          </div>
          <ClinicsComponent />
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { posts } = state.posts;
  return { posts };
};

const mapDispatchToProps = (dispatch) => {
  return {
    bindPosts: (currentUser, type, ops) =>
      dispatch(SetPosts(currentUser, type, ops)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ShowPostsComponent));
