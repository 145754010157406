import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import moment from "moment";

import HeaderComponent from "./header";
import { getQuery, firestore, update } from "firebase_config";
import { Tables } from "constants/db_tables";

function WithdrawRequestsComponent({ currentUser }) {
  const [transactions, setTransactions] = useState();

  useEffect(() => {
    async function getTransactions() {
      const txns = await getQuery(
        firestore
          .collection(Tables.TRANSACTIONS)
          .where("withdrawalRequest", "==", true)
          .where("status", "==", "pending")
          .orderBy("createdAt", "desc")
          .get()
      );
      setTransactions(txns);
    }
    if (!transactions) {
      getTransactions();
    }
  }, []);

  const confirmRequest = async (txnId, txnAmount) => {
    const res = await update(Tables.TRANSACTIONS, txnId, {
      status: "success",
      withdrawalRequest: false,
    });
    if (res.status === 200) {
      toast.success(`Withdrawal request of ${txnAmount} confirmed`);
      window.location.reload();
      return;
    }
    toast.error(
      `Something went wrong with this withdrawal request of ${txnAmount}`
    );
  };

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
  };

  return (
    <div>
      <HeaderComponent />
      <div className="dashboard-content pt-5 mt-5">
        {transactions?.length ? (
          transactions.map((txn, idx) => (
            <div className="card my-3" key={idx}>
              <div className="card-header">
                {txn.currency.toUpperCase()} &nbsp;
                {txn.withdrawalAmount}/- <small>({txn.amount})</small>
              </div>
              <div className="card-body font-small">
                Name:{" "}
                <span
                  className="text-violet"
                  onClick={(e) => copyToClipboard(txn.userDetails.accountName)}
                >
                  {" "}
                  {txn.userDetails.accountName}{" "}
                </span>{" "}
                <br />
                A/c:{" "}
                <span
                  className="text-violet"
                  onClick={(e) =>
                    copyToClipboard(txn.userDetails.accountNumber)
                  }
                >
                  {" "}
                  {txn.userDetails.accountNumber}{" "}
                </span>{" "}
                <br />
                Sort code:{" "}
                <span
                  className="text-violet"
                  onClick={(e) => copyToClipboard(txn.userDetails.ifscCode)}
                >
                  {" "}
                  {txn.userDetails.ifscCode}{" "}
                </span>{" "}
                <br />
                Created at:{" "}
                <span className="text-violet">
                  {moment(txn.createdAt).format("DD/MM/YYYY, HH:MM:SS")}
                </span>{" "}
                <br />
                <button
                  className="btn btn-violet btn-sm font-small mt-3"
                  onClick={(e) => confirmRequest(txn.id, txn.withdrawalAmount)}
                >
                  Confirm
                </button>
              </div>
            </div>
          ))
        ) : (
          <div className="mt-5 pt-5 text-center">
            No withdrawal requests found
          </div>
        )}
      </div>
    </div>
  );
}

export default WithdrawRequestsComponent;

// Ref: https://stackoverflow.com/questions/55156572/how-to-give-automatic-spaces-in-credit-card-validation-slash-in-a-date-format-v
