import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import {
  useStripe,
  useElements,
  PaymentElement,
} from "@stripe/react-stripe-js";

import { post } from "services";
import { add } from "firebase_config";
import { Routes } from "constants/index";

// Ref1: Testing cards - https://stripe.com/docs/testing
// Ref2: Functionality - https://stripe.com/docs/payments/accept-a-payment?platform=web&ui=elements

function CardElementComponent({
  currentUser,
  paymentIntent,
  goBack,
  requestId,
  rechargeAmount,
}) {
  const [loading, setLoading] = useState(false);
  const stripe = useStripe();
  const elements = useElements();
  const history = useHistory();

  const handleSubmit = async () => {
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }
    setLoading(true);
    const result = await stripe.confirmPayment({
      elements,
      redirect: "if_required",
      confirmParams: {
        payment_method_data: {
          billing_details: {
            name: currentUser.username,
            email: currentUser.email,
          },
        },
      },
    });

    if (result.error) {
      setLoading(false);
      toast.error(result.error.message);
      /* Cancel the payment intent */
      if (
        result.error.type !== "validation_error" &&
        result.error.type !== "card_error"
      ) {
        await post("/transaction/cancel", {
          paymentIntentId: paymentIntent.id,
        });
        history.push("/app/settings");
      }
    } else {
      let paymentIntentAmount = result.paymentIntent.amount.toString();
      paymentIntentAmount = paymentIntentAmount.replace("00", "");

      if (result.paymentIntent.status === "succeeded") {
        // Show a success message to your customer
        // There's a risk of the customer closing the window before callback
        // execution. Set up a webhook or plugin to listen for the
        // payment_intent.succeeded event that handles any business critical
        // post-payment actions.
        const res = await post("/transaction/recharge", {
          amount: paymentIntentAmount,
          paymentIntentId: result.paymentIntent.id,
          currency: result.paymentIntent.currency,
          requestId: requestId,
        });
        if (res.status === 201) {
          toast.success("Your payment is successful.");
          /* Log the activity */
          await add("logs", {
            text: `Your payment ${paymentIntentAmount}/- has been successful`,
            photoURL: currentUser.photoURL,
            receiverId: currentUser.id,
            actionLink: "/app/settings/",
            unread: true,
          });
          if (!currentUser.email.includes("example")) {
            await post("/send_email", {
              templateName: "patient_is_waiting.html",
              email: "clinton@medoc.life", // doctor email: Clinton
              displayName: "Clinton",
            });
          }
          setLoading(false);
        } else {
          toast.error(
            "Payment is unsuccessful. If your card has been debited, it will be refunded."
          );
          /* Log the activity */
          await add("logs", {
            text: `Your payment of ${paymentIntentAmount}/- has been failed`,
            photoURL: currentUser.photoURL,
            receiverId: currentUser.id,
            actionLink: "/app/settings/",
            unread: true,
          });
          setLoading(false);
        }
      }
      history.push(`${Routes.APP_POSTS}/${requestId}`);
    }
  };

  return (
    <div className="mt-4">
      <form>
        <PaymentElement />
      </form>
      <div className="text-center mt-4">
        <button
          className="btn btn-sm btn-violet-outline pointer col-12 col-sm-5 m-sm-3 mt-2 py-2"
          onClick={handleSubmit}
          disabled={!stripe || loading}
        >
          {loading ? (
            <div className="spinner-border spinner-border-sm" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          ) : (
            <span>Confirm</span>
          )}
        </button>
        <button
          className="btn btn-sm btn-outline-secondary pointer col-12 col-sm-5 mx-0 mx-sm-2 my-2 py-2"
          onClick={goBack}
        >
          <span>Cancel</span>
        </button>
      </div>
    </div>
  );
}

export default CardElementComponent;
