import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import "./style.css";

import HeaderComponent from "./header";
import CheckoutComponent from "./steps/checkout/component";
import CardDetailsComponent from "./steps/card_details/component";
import { LoadingComponent } from "components";
import { post } from "services";
import { Routes, Tables } from "constants/index";
import { getId } from "firebase_config";

function WalletRechargeComponent({ currentUser }) {
  const history = useHistory();
  const location = useLocation();
  const requestId = location.state?.requestId;
  const [rechargeAmount, setRechargeAmount] = useState(location.state?.amount);
  const [stepNumber, setStepNumber] = useState(1);
  const [paymentIntent, setPaymentIntent] = useState("");
  const [parentLoading, setParentLoading] = useState(false);
  const [request, setRequest] = useState();

  useEffect(async () => {
    const id = location.state?.requestId;
    const request = await getId(Tables.MEDICAL_REQUESTS, id);
    request.id = id;
    setRequest(request);
  }, []);

  const cancelPaymentIntent = async () => {
    if (paymentIntent.id) {
      setParentLoading(true);
      await post("/transaction/cancel", {
        paymentIntentId: paymentIntent.id,
      });
      setParentLoading(false);
    }
  };

  const goBack = async () => {
    if (stepNumber === 2 && paymentIntent.id) {
      if (window.confirm("Are you sure you want to cancel?")) {
        await cancelPaymentIntent();
        history.push(`${Routes.APP_POSTS}/${requestId}`);
      }
    } else {
      history.push(`${Routes.APP_POSTS}/${requestId}`);
    }
  };

  return (
    <div>
      <div className="dashboard-content -xs-bg-none pt-md-1">
        <div className="wallet-recharge-wrapper">
          {window.innerWidth < 576 ? (
            <HeaderComponent goBack={goBack} />
          ) : (
            <h5 className="text-center pt-4">
              Checkout
              <hr />
            </h5>
          )}
          {parentLoading || !request ? (
            <LoadingComponent />
          ) : (
            <div>
              {stepNumber === 1 && (
                <CheckoutComponent
                  currentUser={currentUser}
                  rechargeAmount={rechargeAmount}
                  setRechargeAmount={setRechargeAmount}
                  setStepNumber={setStepNumber}
                  paymentIntent={paymentIntent}
                  setPaymentIntent={setPaymentIntent}
                  cancelPaymentIntent={cancelPaymentIntent}
                  requestId={requestId}
                  request={request}
                  goBack={goBack}
                />
              )}
              {stepNumber === 2 && (
                <CardDetailsComponent
                  currentUser={currentUser}
                  rechargeAmount={rechargeAmount}
                  setStepNumber={setStepNumber}
                  paymentIntent={paymentIntent}
                  cancelPaymentIntent={cancelPaymentIntent}
                  requestId={requestId}
                  goBack={goBack}
                />
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default WalletRechargeComponent;

// Ref: https://stackoverflow.com/questions/55156572/how-to-give-automatic-spaces-in-credit-card-validation-slash-in-a-date-format-v
