import React from "react";
import { useHistory } from "react-router-dom";

import { Routes } from "constants/index";

const ParentComponent = ({ currentUser, paymentPendingPost: post }) => {
  const history = useHistory();

  const redirectToPostPage = () => {
    history.push(`${Routes.APP_POSTS}/${post.id}`);
  };

  return (
    <div className="text-center mx-3 mx-sm-0 mt-sm-2">
      <button
        className={`btn btn-sm btn-danger px-4 text-white`}
        onClick={redirectToPostPage}
      >
        {post.paymentStatus === "pending" && "Your payment is pending!"}
        {post.isEditRequired && "Complete the form to submit your request!"}
      </button>
    </div>
  );
};

export default ParentComponent;
