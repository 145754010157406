import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

import { post } from "services";
import { LoadingComponent } from "components";
import { Routes, Tables, ENV } from "constants/index";
import { update } from "firebase_config";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

function CheckoutComponent({
  currentUser,
  rechargeAmount,
  setRechargeAmount,
  setPaymentIntent,
  setStepNumber,
  requestId,
  request,
  goBack,
}) {
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const saveCheckout = async (gateway) => {
    setLoading(true);
    let res;
    if (gateway === "razorpay") {
      res = await post("/razorpay/create_order", {
        amount: rechargeAmount,
        currency: "INR",
        requestId,
      });

      await razorpayCheckout({
        currentUser,
        request,
        order: res.data?.order,
        setLoading,
      });
    } else {
      res = await post("/transaction/checkout", {
        amount: rechargeAmount,
        currency: "INR",
      });
      if (res.status === 201) {
        setPaymentIntent(res.data.data);
        setStepNumber(2);
      } else {
        toast.error("Something went wrong. Try again");
      }
    }
    setLoading(false);
  };

  const razorpayCheckout = ({ currentUser, request, order, setLoading }) => {
    const options = {
      key: ENV.RAZORPAY_KEY_ID, // Enter the Key ID generated from the Dashboard
      amount: order.amount,
      currency: order.currency,
      name: "Medoc",
      description: request.product.name,
      image:
        "https://storage.googleapis.com/medoc-live.appspot.com/images/razorpay_logo.jpeg",
      order_id: order.id,
      handler: async (response) => {
        const data = {
          paymentStatus: "completed",
          isEditRequired: false,
        };

        if (
          request.type === "caretaker_certificate" ||
          request.type === "form_1a"
        ) {
          data.isEditRequired = true;
        }
        await update(Tables.MEDICAL_REQUESTS, requestId, data);

        toast.success("Your payment is successful.");
        history.push({
          pathname: Routes.APP_RECHARGE_SUCCESS,
          state: {
            requestId,
            order,
            response,
          },
        });
      },
      prefill: {
        name: request.firstName,
        email: request.email,
        contact: request.mobile,
      },
      notes: {
        displayName: request.firstName,
        userId: currentUser.id,
        requestId: request.id,
        requestType: request.type,
      },
      theme: {
        color: "#684490",
      },
    };
    const rzPopup = new window.Razorpay(options);
    rzPopup.on("payment.failed", function (response) {
      console.log("resp", response);
    });
    rzPopup.open();
  };

  return loading ? (
    <div>
      <LoadingComponent />
      <div className="text-secondary text-center mt-5 pt-5">
        Please donot refresh this page while we process your request!
      </div>
    </div>
  ) : (
    <div className="container mt-3">
      <div className="form-group">
        <label className="form-label pb-1">Amount</label>
        <input
          type="number"
          className="form-control"
          placeholder={rechargeAmount}
          value={rechargeAmount}
          disabled
        />
      </div>
      <div className="form-group">
        <label className="form-label pb-1">Currency</label>
        <select
          className="form-control font-small"
          aria-label=".form-select-sm"
          disabled
        >
          <option defaultValue>Indian Rupee (INR)</option>
        </select>
      </div>
      <div className="form-group">
        <label className="form-label pb-1">Request Id</label>
        <input
          type="number"
          className="form-control"
          placeholder={requestId}
          value={requestId}
          disabled
        />
      </div>
      {request.type !== "consult_doctor" && (
        <div className="text-center fs-12 py-3">
          If our doctor finds that your certificate request is fake or goes
          against{" "}
          <Link to={Routes.APP_GUIDELINES_SLC} className="text-violet">
            our company rules
          </Link>
          , we don't refund your money.{" "}
          <a
            className="text-violet"
            href="https://medoc.life/rp"
            target="_blank"
            rel="noopener noreferrer"
          >
            Checkout our refund policy
          </a>
        </div>
      )}
      <div className="text-center">
        <button
          className="btn btn-sm btn-violet pointer col-12 col-sm-4 my-2 py-2"
          onClick={(e) => saveCheckout("razorpay")}
          disabled={loading}
        >
          <span>Pay Now</span>
        </button>
      </div>
      <div className="text-center d-none">
        <button
          className="btn btn-sm btn-violet pointer col-12 col-sm-4 my-2 py-2"
          onClick={(e) => saveCheckout("stripe")}
          disabled={loading}
        >
          <span>Pay via Stripe</span>
        </button>
        <div className="text-secondary fs-10">
          *Stripe accepts Visa, Master, and American Express only.
        </div>
      </div>
      <div className="text-center">
        <button
          className="btn btn-sm btn-outline-danger pointer col-12 col-sm-4 mx-0 mx-sm-2 my-2 py-2"
          onClick={goBack}
        >
          <span>Cancel</span>
        </button>
      </div>
    </div>
  );
}

export default CheckoutComponent;
