import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { MultiSelect } from "react-multi-select-component";
import { toast } from "react-toastify";
import "./style.css";

import HeaderComponent from "./header";
import { add, update, getQuery, firestore, uploadFile } from "firebase_config";
import { Tables, Routes } from "constants/index";

const weekdays = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];

const dialects = [
  { label: "Bengali", value: "Bengali" },
  { label: "English", value: "English" },
  { label: "Hindi", value: "Hindi" },
  { label: "Kannada", value: "Kannada" },
  { label: "Tamil", value: "Tamil" },
  { label: "Telugu", value: "Telugu" },
];

function EditAvailabilityComponent({ currentUser }) {
  const [loading, setLoading] = useState(false);
  const [startTimes, setStartTimes] = useState({
    monday: "09:00",
    tuesday: "09:00",
    wednesday: "09:00",
    thursday: "09:00",
    friday: "09:00",
    saturday: "09:00",
    sunday: "09:00",
  });
  const [endTimes, setEndTimes] = useState({
    monday: "21:00",
    tuesday: "21:00",
    wednesday: "21:00",
    thursday: "21:00",
    friday: "21:00",
    saturday: "21:00",
    sunday: "21:00",
  });
  const [errorMessage, setErrorMessage] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [isVirtualClinicExists, setIsVirtualClinicExists] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [options, setOptions] = useState({
    username: currentUser.username || "",
    name: currentUser.displayName || "",
    email: currentUser.email || "",
    bio: currentUser.bio || "",
    photoURL: currentUser.photoURL || "",
    qualification: currentUser.qualification || "",
    types: [
      {
        key: "audio",
        title: "Audio Call",
        description:
          "Book an appointment with me today for a hassle-free and effective audio call consultation.",
        cost: 0,
        enabled: false,
      },
      {
        key: "video",
        title: "Video Call",
        description:
          "Get dedicated high-quality care and ensuring your well-being. Book an appointment with me today ",
        cost: 0,
        enabled: false,
        link: "",
      },
      {
        key: "text",
        title: "Text Query (Q&A)",
        description:
          "Contact me today for reliable and trustworthy medical advice",
        cost: 0,
        enabled: false,
        respondsInMinutes: 30,
      },
    ],
    specialities: "",
    availabilities: [
      {
        day: "monday",
        startTime: "09:00",
        endTime: "21:00",
      },
      {
        day: "tuesday",
        startTime: "09:00",
        endTime: "21:00",
      },
      {
        day: "wednesday",
        startTime: "09:00",
        endTime: "21:00",
      },
      {
        day: "thursday",
        startTime: "09:00",
        endTime: "21:00",
      },
      {
        day: "friday",
        startTime: "09:00",
        endTime: "21:00",
      },
      {
        day: "saturday",
        startTime: "09:00",
        endTime: "21:00",
      },
      {
        day: "sunday",
        startTime: "09:00",
        endTime: "21:00",
      },
    ],
    socialMediaLinks: [
      {
        key: "linkedin",
        title: "Linkedin",
        link: "",
      },
      {
        key: "twitter",
        title: "Twitter",
        link: "",
      },
      {
        key: "facebook",
        title: "Facebook",
        link: "",
      },
    ],
    dialects: [],
  });
  const history = useHistory();

  // create an array of 30-minute time slots from 00:00 to 23:30
  const timeSlots = [];
  for (let hour = 0; hour <= 23; hour++) {
    for (let minute = 0; minute < 60; minute += 30) {
      const time = `${hour.toString().padStart(2, "0")}:${minute
        .toString()
        .padStart(2, "0")}`;
      timeSlots.push(time);
    }
  }

  useEffect(() => {
    async function getPublicProfile() {
      const opt = await getQuery(
        firestore
          .collection(Tables.DC_PUBLIC_PROFILE)
          .where("userId", "==", currentUser.id)
          .get()
      );
      if (opt[0]) {
        setOptions(opt[0]);
        setIsVirtualClinicExists(true);
      }
    }

    if (!options.userId) {
      getPublicProfile();
    }
  }, []);

  const handleStartTimeChange = (event, day) => {
    const { value } = event.target;
    const newStartTimes = { ...startTimes, [day]: value };
    setStartTimes(newStartTimes);

    // check if the start time is greater than the end time
    if (
      newStartTimes[day] &&
      endTimes[day] &&
      newStartTimes[day] > endTimes[day]
    ) {
      setErrorMessage({
        message: "Start time cannot be greater than end time",
        day,
      });
    } else {
      setErrorMessage("");
    }
  };

  const handleEndTimeChange = (event, day) => {
    const { value } = event.target;
    const newEndTimes = { ...endTimes, [day]: value };
    setEndTimes(newEndTimes);

    // check if the start time is greater than the end time
    if (
      startTimes[day] &&
      newEndTimes[day] &&
      startTimes[day] > newEndTimes[day]
    ) {
      setErrorMessage({
        message: "End time cannot be less than start time",
        day,
      });
    } else {
      setErrorMessage("");
    }
  };

  const handleNotAvailability = (day) => {
    const newStartTimes = { ...startTimes, [day]: "0:00" };
    setStartTimes(newStartTimes);

    const newEndTimes = { ...endTimes, [day]: "0:00" };
    setEndTimes(newEndTimes);
  };

  const handleTypesChange = (id, key, value) => {
    const newTypes = options.types.map((item, i) => {
      if (i === id) {
        return { ...item, [key]: value };
      }
      return item;
    });
    setOptions((prevState) => ({
      ...prevState,
      types: newTypes,
    }));
  };

  const handleSmlChange = (id, key, value) => {
    const newSml = options.socialMediaLinks.map((item, i) => {
      if (i === id) {
        return { ...item, link: value };
      }
      return item;
    });
    setOptions((prevState) => ({
      ...prevState,
      socialMediaLinks: newSml,
    }));
  };

  const handleChange = (key, value) => {
    setOptions((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const copyToClipboard = (username) => {
    navigator.clipboard.writeText(`https://medoc.life/dr/${username}`);
    toast.success("Text copied.");
  };

  const showErrors = () => {
    let err = {
      key: "",
      message: "",
    };
    if (!options.photoURL) {
      err = {
        key: "photoURL",
        message: "Virtual Clinic Image is required",
      };
    }
    return err;
  };

  const saveDetails = async () => {
    const err = await showErrors();
    if (err.message) {
      toast.error(err.message);
      return;
    }
    setLoading(true);
    let res;
    if (options.id) {
      const avails = weekdays.map((day) => ({
        day: day.toLowerCase(),
        startTime: startTimes[day],
        endTime: endTimes[day],
      }));

      res = await update(Tables.DC_PUBLIC_PROFILE, options.id, {
        ...options,
        availabilities: isEdit ? avails : options.availabilities,
      });
    } else {
      res = await add(Tables.DC_PUBLIC_PROFILE, {
        ...options,
        availabilities: weekdays.map((day) => ({
          day: day.toLowerCase(),
          startTime: startTimes[day.toLowerCase()],
          endTime: endTimes[day.toLowerCase()],
        })),
        userId: currentUser.id,
      });
    }
    if (res.status === 200) {
      toast.success("Your virtual clinic details updated!");
      history.push(Routes.APP_SETTINGS);
    }
    setLoading(false);
  };

  const uploadImage = async (file) => {
    if (!file) {
      toast.error("New image required");
      return null;
    }
    setUploading(true);
    await uploadFile(file, "image", async (url, err) => {
      if (err) {
        alert(err);
        return null;
      }
      setOptions({ ...options, photoURL: url });
      setUploading(false);
    });
  };

  return (
    <>
      <HeaderComponent
        save={saveDetails}
        loading={loading}
        headerText={isVirtualClinicExists ? "Save" : "Create"}
      />
      <div className="dashboard-content">
        <div className="edit-profile-wrapper edit-vc mt-sm-5">
          <div
            className={`mt-sm-5 fw-500 alert alert-primary text-violet ${
              options.username ? "text-center pointer" : "d-none"
            }`}
            onClick={(e) => copyToClipboard(options.username)}
          >
            medoc.life/dr/{options.username}
            <i className="fa fa-copy ml-2" />
          </div>
          <div className="">
            <label htmlFor="staticImage" className="h6">
              Upload Virtual Clinic Image
            </label>
            <input
              type="file"
              className="form-control-plaintext d-none"
              id="staticImage"
              onChange={(e) => uploadImage(e.target.files[0])}
              accept="image/*"
            />{" "}
            <br />
            <label
              className="btn btn-violet-outline btn-sm"
              htmlFor="staticImage"
            >
              {!options.photoURL ? (
                <i
                  className={`fa fa-${uploading ? "spinner fa-spin" : "plus"}`}
                />
              ) : (
                <img src={options.photoURL} className="vc-icon" alt="Image" />
              )}
            </label>
          </div>
          <div className="dashboard-content">
            <div className="row">
              <div className="form-group mt-3 col-12">
                <label htmlFor="userName" className="h6">
                  Bio{" "}
                  <span className="text-violet">
                    (Required at least 50 words)
                  </span>
                </label>
                <div className="">
                  <textarea
                    className="form-control"
                    placeholder="Make a memorable first impression by introducing yourself in your bio. Crafting a compelling bio increases the likelihood of receiving consultation requests."
                    value={options.bio}
                    onChange={(e) => handleChange("bio", e.target.value)}
                    rows="5"
                  ></textarea>
                </div>
              </div>
              <div className="form-group mt-3 col-12">
                <label htmlFor="specialities" className="h6">
                  Specialities
                </label>
                <div className="">
                  <input
                    type="text"
                    className="form-control"
                    id="specialities"
                    value={options.specialities}
                    onChange={(e) =>
                      handleChange("specialities", e.target.value)
                    }
                  />
                </div>
              </div>
              <div className="form-group col-12">
                <label htmlFor="specialities" className="h6">
                  Services Offer (in INR)
                </label>
                <div className="">
                  {options.types.map((type, idx) => (
                    <>
                      <div className="card p-3 my-2" key={type.title}>
                        <div
                          className="form-check d-flex flex-row align-items-center"
                          key={idx}
                        >
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id={type.title}
                            checked={type.enabled}
                            onChange={(e) =>
                              handleTypesChange(idx, "enabled", !type.enabled)
                            }
                          />
                          <label
                            className="form-check-label"
                            htmlFor={type.title}
                          >
                            {type.title}
                          </label>
                        </div>
                        {type.enabled && (
                          <div className="mb-3">
                            <div className="row align-items-center px-3">
                              <div
                                className={`${
                                  type.enabled ? "pl-2 mt-2 col-12" : "d-none"
                                }`}
                              >
                                <label
                                  htmlFor="specialities"
                                  className="fs-12 fw-500"
                                >
                                  Cost (in INR)
                                </label>
                                <input
                                  type="number"
                                  className="form-control"
                                  placeholder="Consultation cost (in INR)"
                                  value={type.cost}
                                  onChange={(e) =>
                                    handleTypesChange(
                                      idx,
                                      "cost",
                                      e.target.value
                                    )
                                  }
                                />
                              </div>
                              <div
                                className={`${
                                  type.enabled ? "pl-2 mt-3 col-12" : "d-none"
                                }`}
                              >
                                <label
                                  htmlFor="specialities"
                                  className="fs-12 fw-500"
                                >
                                  Description
                                </label>
                                <textarea
                                  className="form-control"
                                  placeholder="Description"
                                  value={type.description}
                                  onChange={(e) =>
                                    handleTypesChange(
                                      idx,
                                      "description",
                                      e.target.value
                                    )
                                  }
                                />
                              </div>
                              <div
                                className={`${
                                  type.key === "video"
                                    ? "pl-2 mt-3 col-12"
                                    : "d-none"
                                }`}
                              >
                                <label htmlFor="link" className="fs-12 fw-500">
                                  Video Meet Link
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Google Meet / Zoom Link"
                                  value={type.link}
                                  onChange={(e) =>
                                    handleTypesChange(
                                      idx,
                                      "link",
                                      e.target.value
                                    )
                                  }
                                />
                              </div>
                              <div
                                className={`${
                                  type.key === "text"
                                    ? "pl-2 mt-3 col-12"
                                    : "d-none"
                                }`}
                              >
                                <label htmlFor="link" className="fs-12 fw-500">
                                  Responds In (minutes)
                                </label>
                                <input
                                  type="number"
                                  className="form-control"
                                  placeholder="Responds In"
                                  value={type.respondsIn}
                                  onChange={(e) =>
                                    handleTypesChange(
                                      idx,
                                      "respondsIn",
                                      e.target.value
                                    )
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </>
                  ))}
                </div>
              </div>
            </div>
            <div>
              <h6 className="">Social Media Links</h6>
              <div className="mt-2">
                {options.socialMediaLinks.map((sml, idx) => (
                  <div className="px-3 py-1" key={sml.key}>
                    <label className="fs-12 fw-500">{sml.title}</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder={`Enter ${sml.title} Url`}
                      value={sml.link}
                      onChange={(e) =>
                        handleSmlChange(idx, sml.title, e.target.value)
                      }
                    />
                  </div>
                ))}
              </div>
            </div>
            <div>
              <h6 className="mt-4">Languages speak</h6>
              <div className="">
                <MultiSelect
                  options={dialects}
                  value={options.dialects}
                  onChange={(e) => handleChange("dialects", e)}
                  labelledBy="Select"
                />
              </div>
            </div>
            <h6 className="mt-5">
              Your Availability
              {!isEdit ? (
                <div
                  className="btn btn-violet btn-sm pull-right"
                  onClick={(e) => setIsEdit(true)}
                >
                  Edit
                </div>
              ) : (
                <div
                  className="btn btn-danger-outline btn-sm pull-right"
                  onClick={(e) => setIsEdit(false)}
                >
                  Cancel
                </div>
              )}
            </h6>
            <div className="clearfix mb-2"></div>
            <div>
              {weekdays.map((day, idx) => (
                <div key={`${day}-${idx}`}>
                  <div className="card pt-2 px-3 mb-2">
                    <h6 className="d-flex flex-row">{day}</h6>
                    {!isEdit && (
                      <div className="pb-2">
                        <i className="fa fa-clock-o" /> &nbsp;
                        {
                          options.availabilities.find(
                            (a) => a.day === day.toLowerCase()
                          )?.startTime
                        }{" "}
                        -{" "}
                        {
                          options.availabilities.find(
                            (a) => a.day === day.toLowerCase()
                          )?.endTime
                        }
                      </div>
                    )}
                    {isEdit && (
                      <div className="form-check d-flex flex-row align-items-center ml-1 mt-3 mb-4">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="notAvailable"
                          checked={day.enabled}
                          onChange={(e) => handleNotAvailability(day)}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="notAvailable"
                        >
                          Not available
                        </label>
                      </div>
                    )}
                    {isEdit && !day.disabled && (
                      <div className="d-flex flex-row">
                        <div className="form-group d-flex flex-row">
                          <label htmlFor={`${day}-start-time`} className="mr-2">
                            Start Time:
                          </label>
                          <select
                            id={`${day}-start-time`}
                            className="form-select form-select-sm"
                            value={startTimes[day] || ""}
                            onChange={(e) => handleStartTimeChange(e, day)}
                          >
                            <option value=""></option>
                            {timeSlots.map((time) => (
                              <option key={time} value={time}>
                                {time}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className="form-group d-flex flex-row ml-3">
                          <label htmlFor={`${day}-end-time`} className="mr-2">
                            End Time:
                          </label>
                          <select
                            id={`${day}-end-time`}
                            value={endTimes[day] || ""}
                            onChange={(e) => handleEndTimeChange(e, day)}
                          >
                            <option value=""></option>
                            {timeSlots.map((time) => (
                              <option key={time} value={time}>
                                {time}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    )}
                  </div>
                  {errorMessage && errorMessage.day === day && (
                    <p className="text-danger">{errorMessage.message}</p>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default EditAvailabilityComponent;
