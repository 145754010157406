import React from "react";
import "./style.css";

import HeaderComponent from "./header";

function SlcComponent(props) {
  return (
    <div>
      <HeaderComponent />
      <div className="dashboard-content mt-5 pt-2 pt-sm-5">
        <div className="fs-12 mt-sm-5">
          <ul className="mt-2 pr-4 text-justify">
            <li>
              Our working hours are from 09:00am – 9:00pm IST on weekdays &
              09:00am - 06:00pm IST on weekends. Any orders placed after/before
              working hours will be serviced in the next working slot.
            </li>
            <li>
              We may ask mandatory ID documents and some additional information
              about your health during doctor call or in later steps.
            </li>
            <li>
              If the doctor needs more information, our consult doctor will call
              you to conduct the diagnosis and release the certificate.
            </li>
            <li>
              Since we are an online platform, we do not mention the location of
              issuance on the certificate whereas we do mention the NMC
              (National Medical Commission of India) registered number and
              doctor's qualification on the certificate.
            </li>
            <li>
              The date when the doctor signs the certificate or prescription
              should be the same as the issue date. It's completely prohibited
              to put an earlier date than the actual signing date.
            </li>
            <li>
              Every certificate adheres to clause 34 of The National Medical
              Commission Act, 2019 | Details -{" "}
              <a
                href="https://www.nmc.org.in/nmc-act/"
                target="_blank"
                rel="noreferrer noopener"
              >
                {" "}
                https://www.nmc.org.in/nmc-act/
              </a>
            </li>
            <li>
              The certificate format cannot be changed and is fixed under
              National Medical Commission Guidelines. The portal's email id and
              phone number are on the note so that your office or employer can
              verify your letter.
            </li>
            <li>
              <a
                href="https://storage.cloud.google.com/medoc-live.appspot.com/images/New%20Sample%20Sick%20Leave%20Certificate.jpeg"
                target="_blank"
                rel="noreferrer noopener"
              >
                {" "}
                Click here
              </a>{" "}
              for a sample certificate. The information may change in the actual
              certificate.
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default SlcComponent;
