export default [
  {
    name: "SUPERB: Digital Certificate in 60 minutes (recommends)",
    description:
      "This will be reviewed and signed by an Indian doctor and sent via email, usually delivered within 60 mins. No wait time.",
    price: 520,
    totalPrice: 650,
    abroadPrice: 1600,
    abroadTotalPrice: 2500,
    discountPercent: 25,
    priority: "high",
    display: true,
    assignsAfter: 0,
  },
  {
    name: "GOOD: Digital Certificate in 24 hours",
    description:
      "This will be reviewed and signed by an Indian doctor and sent via email, usually delivered in 12-24 hours. The wait time can vary depending on the queue, but your request will be processed within 24 hours.",
    price: 420,
    abroadPrice: 1400,
    abroadTotalPrice: 2500,
    totalPrice: 500,
    discountPercent: 25,
    priority: "low",
    display: true,
    assignsAfter: 12,
  },
];
