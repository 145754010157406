export default [
  {
    name: "HIGH PRIORITY: Get prescription, and feedback within 60 minutes",
    description:
      "An Indian M.B.B.S doctor will review your details and call you within 60 minutes. Following the call, you will receive online prescription, feedback via email and will be able to chat with the doctor for up to 24 hours.",
    price: 600,
    totalPrice: 1500,
    discountPercent: 60,
    priority: "high",
    display: true,
  },
  {
    name: "LOW PRIORITY: Talk to Doctor Buddy upto 10 minutes",
    description:
      "An Indian M.B.B.S doctor will review this and call you within 72 hours. There is no prescription or chat facility available.",
    price: 500,
    totalPrice: 1500,
    discountPercent: 60,
    priority: "low",
    display: true,
  },
];
