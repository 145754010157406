import React from "react";
import "./style.css";

const ProgressBar = ({ progress }) => {
  return (
    <div className="col-12 text-center">
      <label
        htmlFor="progressBar"
        className={`fs-12 fw-500 text-${progress.color}`}
      >
        {progress.label}
      </label>{" "}
      <br />
      <input
        type="range"
        value={progress.value}
        className="range col-12 px-0"
        readOnly
      />
    </div>
  );
};

export default ProgressBar;

// Ref: https://codepen.io/vsync/pen/mdEJMLv?editors=1100
