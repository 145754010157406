import React, { useState, useEffect } from "react";
import shortid from "shortid";
import { toast } from "react-toastify";
import "./style.css";

import HeaderComponent from "./header";
import { Tables } from "constants/index";
import { add, getQuery, firestore } from "firebase_config";

function InviteFriendsComponent({ currentUser }) {
  const [copyText, setCopyText] = useState(false);
  const [invites, setInvites] = useState(0);

  useEffect(async () => {
    const i = await getQuery(
      firestore
        .collection(Tables.PROMOCODES)
        .where("userId", "==", currentUser.id)
        .get()
    );
    if (i.length) {
      setInvites(i[0].count);
    }
  }, []);

  const createCode = async () => {
    const code = await getQuery(
      firestore
        .collection(Tables.PROMOCODES)
        .where("userId", "==", currentUser.id)
        .get()
    );
    if (code.length) {
      setCopyText(code[0].code);
      showAlert(code[0].code);
    } else {
      const sid = shortid.generate();
      await add(Tables.PROMOCODES, {
        code: sid,
        type: "influencer",
        userId: currentUser.id,
      });
      setCopyText(shortid);
    }
  };

  const showAlert = (c) => {
    navigator.clipboard.writeText(c);
    toast.success("Code Copied!");
  };

  return (
    <div>
      <HeaderComponent />
      <div className="dashboard-content -xs-bg-none">
        <div className="invite-friends-wrapper text-center">
          <div className="my-3">
            {copyText ? (
              <div>
                Your referral code
                <h4
                  className="text-violet pt-2 pointer"
                  onClick={(e) => showAlert(copyText)}
                >
                  {copyText}
                </h4>
              </div>
            ) : (
              <div className="btn btn-violet" onClick={createCode}>
                Show Referral Code
              </div>
            )}
          </div>
          <div>
            People used your referral code <br />
            <h2 className="text-violet">{invites || 0}</h2>
          </div>
          <div>
            You earned <br />
            <h2 className="text-violet">{currentUser.withdrawAmount} INR</h2>
          </div>
        </div>
      </div>
    </div>
  );
}

export default InviteFriendsComponent;
