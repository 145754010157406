import React, { useState } from "react";
import { toast } from "react-toastify";
import moment from "moment";

import { update, getQuery, firestore } from "firebase_config";
import { Tables } from "constants/index";
import { post } from "services";

const AddPaymentDetails = ({ userDetails, error }) => {
  const [newPayment, setNewPayment] = useState({
    totalAmount: 0,
    deductions: 0,
    tdsPercent: 0,
    netAmount: 0,
    payLength: 0,
    cpayLength: 0,
    startDate: "",
    endDate: "",
  });

  const calculateTotalAmount = async () => {
    const startDate = new Date(newPayment?.startDate).getTime();
    const endDate = new Date(newPayment?.endDate).getTime();
    const requests = await getQuery(
      firestore
        .collection(Tables.MEDICAL_REQUESTS)
        .where("updatedAt", ">", startDate)
        .where("updatedAt", "<=", endDate)
        .where("doctorId", "==", userDetails.id)
        .get()
    );

    const pay = requests.filter(
      (req) => !req.isPrescriptionRequired && req.status !== "pending"
    ).length;
    const cpay = requests.filter(
      (req) => req.isPrescriptionRequired && req.status !== "pending"
    ).length;

    const tp = userDetails.pay * pay + userDetails.payWithPrescription * cpay;
    setNewPayment((prev) => ({
      ...prev,
      totalAmount: tp,
      deductions: tp * (newPayment.tdsPercent / 100),
      netAmount: tp - tp * (newPayment.tdsPercent / 100),
      payLength: pay,
      cpayLength: cpay,
    }));
  };

  const handleChange = (key, value) => {
    setNewPayment((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const completePayment = async () => {
    if (!newPayment.startDate || !newPayment.endDate) {
      toast.error("Start Date, End Date are required");
      return;
    }
    let payments;
    const np = {
      startDate: newPayment.startDate,
      endDate: newPayment.endDate,
      normalConsultations: newPayment.payLength,
      prescriptionConsultations: newPayment.cpayLength,
      totalConsultations: newPayment.payLength + newPayment.cpayLength,
      totalAmount: newPayment.totalAmount,
      deductions: newPayment.deductions,
      netAmount: newPayment.netAmount,
      paymentTransferDate: moment().format("YYYY-MM-DD"),
    };
    if (userDetails.payments) {
      payments = [np, ...userDetails.payments];
    } else {
      payments = [np];
    }
    if (window.confirm("Are you sure to complete payment?")) {
      const res = await update(Tables.USERS, userDetails.id, { payments });
      await post("/send_email", {
        templateName: "level_two_payment_receipt.html",
        email: userDetails.email,
        subject: "Your Level 2 Monthly Payment Processed!",
        displayName: userDetails.displayName,
        paymentMonth: moment(np.startDate).format("MMM YYYY"),
        newPayment: np,
      });
      if (res.status === 200) {
        toast.success("Payment Added");
      }
    }
  };

  return (
    <div>
      <h6>Pay monthly salary</h6>
      <div>
        <div className="row mt-4">
          <div className="col-12 col-sm-6">
            <h6>Start Date *</h6>
            <input
              type="date"
              className={`form-control ${
                error?.key === "startDate" && "error-border"
              }`}
              name="form-question"
              onChange={(e) => handleChange("startDate", e.target.value)}
            />
            {error?.key === "startDate" && (
              <small className="text-danger fs-10">{error.message}</small>
            )}
          </div>
          <div className="col-12 col-sm-6">
            <h6>End Date *</h6>
            <input
              type="date"
              className={`form-control ${
                error?.key === "dob" && "error-border"
              }`}
              name="form-question"
              onChange={(e) => handleChange("endDate", e.target.value)}
            />
            {error?.key === "endDate" && (
              <small className="text-danger fs-10">{error.message}</small>
            )}
          </div>
          <div className="col-12 col-sm-6 mt-2">
            <h6 htmlFor="form-question">TDS Dedcution *</h6>
            <select
              className="form-control font-small"
              aria-label=".form-select-sm"
              onChange={(e) => handleChange("tdsPercent", e.target.value)}
            >
              <option value={0} defaultValue>
                0%
              </option>
              <option value={10}>10%</option>
            </select>
            {error?.key === "totalAmount" && (
              <div className="text-danger fs-10">{error.message}</div>
            )}
          </div>
        </div>
        <button
          className="btn btn-violet btn-sm my-3"
          onClick={calculateTotalAmount}
        >
          Calculate
        </button>
        <button className="btn btn-violet btn-sm m-3" onClick={completePayment}>
          Complete Payment
        </button>
        <div>
          Total Amount: {newPayment.totalAmount} <br />
          Deductions: {newPayment.deductions} <br />
          Net Amount: {newPayment.netAmount} <br />
          Normal consultations: {newPayment.payLength} <br />
          Prescription consultations: {newPayment.cpayLength}
        </div>
      </div>
    </div>
  );
};

export default AddPaymentDetails;
