import React, { useState } from "react";
import moment from "moment";
import { toast } from "react-toastify";

import { uploadFile, removeFile } from "firebase_config";

const DoctorRemarksComponent = ({
  currentUser,
  options,
  setOptions,
  handleChange,
}) => {
  const [loading, setLoading] = useState(false);
  const [text, setText] = useState();
  const [url, setUrl] = useState();

  const uploadImage = async (file, key) => {
    if (!file) {
      toast.error("New image required");
      return null;
    }
    setLoading(true);
    await uploadFile(file, "image", async (url, err) => {
      if (err) {
        window.alert(err);
        return null;
      }
      setUrl(url);
      setLoading(false);
    });
  };

  const deleteImage = async (url, key) => {
    if (window.confirm("Are you sure to remove file?")) {
      setLoading(true);
      await removeFile(url);
      setLoading(false);
      setUrl("");
    }
  };

  const saveDoctorRemarks = async () => {
    setLoading(true);
    setOptions((prev) => ({
      ...prev,
      additionalInfoRequired: false,
      doctorRemarks: [
        ...options.doctorRemarks,
        {
          p2d: text || "",
          createdAt: new Date().getTime(),
          doc: url || "",
        },
      ],
    }));
    setUrl("");
    setText("");
    setLoading(false);
  };

  return (
    <>
      <div>
        <div className="mt-4">
          <h6>Doctor remarks</h6>
          {options.doctorRemarks &&
            options.doctorRemarks.map((remark, idx) => (
              <div className="my-1" key={idx}>
                {remark.d2p && (
                  <div>
                    <b>doctor to patient</b>: {remark.d2p}{" "}
                    <a
                      href={remark.doc}
                      target="_blank"
                      rel="noopener noreferrer"
                      className={`${!remark.doc && "d-none"}`}
                    >
                      <i className="fa fa-paperclip text-violet fs-20 mr-2"></i>
                    </a>
                    <span className="text-secondary fs-10">
                      {moment(remark.createdAt).format("hh:mm DD/MM/YY")}
                    </span>
                  </div>
                )}
                {remark.p2d && (
                  <div>
                    <b>patient to doctor</b>: {remark.p2d}{" "}
                    <a
                      href={remark.doc}
                      target="_blank"
                      rel="noopener noreferrer"
                      className={`${!remark.doc && "d-none"}`}
                    >
                      <i className="fa fa-paperclip text-violet fs-20 mr-2"></i>
                    </a>
                    <span className="text-secondary fs-10">
                      {moment(remark.createdAt).format("hh:mm DD/MM/YY")}
                    </span>
                  </div>
                )}
              </div>
            ))}
          <div className="mt-3 col-12">
            <div className="font-small fw-500 text-secondary">Response:</div>
            <div>
              <textarea
                className="form-control font-small"
                placeholder="Response"
                value={text}
                onChange={(e) => setText(e.target.value)}
              />
            </div>
            <div>
              {url ? (
                <div className="alert alert-secondary py-1 mt-2">
                  Document
                  <div
                    className="pointer pull-right"
                    onClick={(e) => deleteImage(url, "url")}
                  >
                    {loading ? (
                      <i className="fa fa-spinner fa-spin" />
                    ) : (
                      <i className="fa fa-trash text-danger" />
                    )}
                  </div>
                </div>
              ) : (
                <div className="">
                  <label htmlFor="doctorRemark" className="pointer">
                    <div className="btn btn-violet-outline btn-sm mt-2 pointer">
                      {loading ? (
                        <i className="fa fa-spinner fa-spin" />
                      ) : (
                        <i className="fa fa-paperclip" />
                      )}
                    </div>
                  </label>
                  <input
                    type="file"
                    className="form-control-plaintext d-none"
                    id="doctorRemark"
                    onChange={(e) => uploadImage(e.target.files[0], "url")}
                    accept="application/pdf,image/*,video/*"
                  />
                </div>
              )}
            </div>
            <div className="text-center">
              <button
                className="btn btn-violet-outline btn-sm px-3 my-2"
                onClick={saveDoctorRemarks}
                disabled={loading}
              >
                Save Remarks
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DoctorRemarksComponent;
