import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { signout } from "firebase_config";

import { StaticHeaderComponent, StaticFooterComponent } from "components";
import { Routes } from "constants/index";

const NotFoundComponent = (props) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(async () => {
    const token = window.sessionStorage.getItem("token");
    if (token) {
      setIsLoggedIn(true);
      await signout();
    }
  }, []);

  return (
    <>
      <StaticHeaderComponent />
      <div className="dashboard-content pt-5 mt-5 text-center">
        <h2 className="my-5 pt-5">Oops! This page is not available.</h2>
        <p>
          {isLoggedIn && <span>You're logged out. </span>}Use the following
          links to securely go back
        </p>
        <Link to={Routes.LOGIN} className="btn btn-violet btn-sm px-3 my-2">
          {" "}
          Login to your account
        </Link>{" "}
      </div>
      <StaticFooterComponent />
    </>
  );
};

export default NotFoundComponent;
