import caretaker from "./caretaker";
import sickLeaveForWork from "./sickLeaveForWork";
import sickLeaveForSchool from "./sickLeaveForSchool";
import fitToAdopt from "./fitToAdopt";
import fitToDrive from "./fitToDrive";
import fitToHike from "./fitToHike";
import fitToJoinSchool from "./fitToJoinSchool";
import fitToWork from "./fitToWork";
import fitToFly from "./fitToFly";
import recovery from "./recovery";
import custom from "./custom";
import consultDoctor from "./consultDoctor";
import callDoctor from "./callDoctor";

export const Products = {
  caretaker,
  sickLeaveForWork,
  sickLeaveForSchool,
  fitToAdopt,
  fitToDrive,
  fitToHike,
  fitToJoinSchool,
  fitToWork,
  fitToFly,
  recovery,
  custom,
  consultDoctor,
  callDoctor,
};
