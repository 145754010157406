import axios from "axios";
import { ENV } from "constants/env_keys";
import { signout } from "firebase_config";

const error = async (err) => {
  err = err.toJSON();
  if (err.message.contains("401")) {
    await signout();
  }
  return err.response;
};

export const post = async (url, data = {}) => {
  const res = await axios
    .post(ENV.BASE_URL + url, data, {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${window.sessionStorage.getItem("token")}`,
      },
    })
    .catch((err) => error(err));
  return res;
};

export const get = async (url, data = {}) => {
  const res = await axios
    .get(ENV.BASE_URL + url, data, {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${window.sessionStorage.getItem("token")}`,
      },
    })
    .catch((err) => error(err));
  return res;
};
