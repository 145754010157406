import React, { useState } from "react";
import { toast } from "react-toastify";

import { uploadFile, removeFile } from "firebase_config";

const UploadDocumentsComponent = ({
  setStepNumber,
  options,
  requiredDocs,
  setError,
  handleChange,
}) => {
  const [files, setFiles] = useState(options.files || []);
  const [optionalDocLoading, setOptionalDocLoading] = useState();

  const uploadImage = async (file, key, event) => {
    if (!file) {
      toast.error("New image required");
      return null;
    }
    setOptionalDocLoading(true);
    await uploadFile(file, "image", async (url, err) => {
      if (err) {
        alert(err);
        return null;
      }
      event.target.value = null;
      setFiles([
        ...files,
        {
          name: file.name,
          type: file.type,
          url,
        },
      ]);
      setOptionalDocLoading(false);
    });
  };

  const deleteImage = async (url, key, event) => {
    if (window.confirm("Are you sure to remove file?")) {
      setOptionalDocLoading(true);
      await removeFile(url);

      const f = files.filter((f) => f.url !== url);
      setFiles(f);

      setOptionalDocLoading(false);
    }
  };

  const showErrors = () => {
    let error;
    if (files && files.length < 2) {
      error = {
        message: "Upload Govt. issued ID (Both Front & Back)",
        key: "files",
      };
    }

    setError(error);
    return error;
  };

  const saveRequest = async () => {
    const err = await showErrors();
    if (err && err.message) {
      toast.error(err.message);
      return;
    }
    if (
      window.confirm(
        "Once you proceed further, it is not possible to change these details. Are you sure to proceed?"
      )
    ) {
      await handleChange("files", files);
      setStepNumber(4);
    }
  };

  return (
    <>
      <div className="dashboard-content">
        <div className="container pt-5 mt-sm-5">
          <div className="mt-sm-5"></div>
          <div className="card p-2 p-sm-4 my-sm-5">
            {requiredDocs()}
            {/* documents */}
            <div className="mt-4">
              <h6 className="d-none">
                Add documents
                <div className="text-secondary fs-10 pt-2 fw-400">
                  Prescriptions, discharge papers, doctor's letter, test
                  reports, etc
                </div>
              </h6>
              {files &&
                files.map((file) => (
                  <div
                    className="alert alert-secondary px-2 py-2 align-items-center"
                    key={file.name}
                  >
                    <span className="font-small">{file.name}</span>
                    <i
                      className="fa fa-trash pull-right text-danger pt-1"
                      onClick={(e) => deleteImage(file.url, "files", e)}
                    />
                  </div>
                ))}
              {optionalDocLoading ? (
                <i className="fa fa-spinner fa-spin" />
              ) : (
                <div>
                  <label
                    htmlFor="optionalDoc"
                    className="pointer btn btn-violet btn-sm"
                  >
                    Add File <i className="fa fa-plus ml-2" />
                  </label>
                  <input
                    type="file"
                    className="form-control-plaintext d-none"
                    id="optionalDoc"
                    onChange={(e) =>
                      uploadImage(e.target.files[0], "optionalDoc", e)
                    }
                    accept="application/*,image/*,video/*"
                  />
                </div>
              )}
            </div>
            <div className="d-flex flex-row justify-content-center text-center mt-3">
              <button
                className="btn btn-danger-rounded text-white px-4 mx-2"
                onClick={(e) => setStepNumber(2)}
              >
                Go Back
              </button>
              <button
                className="btn btn-violet-rounded px-4 mx-2"
                onClick={saveRequest}
              >
                Next
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UploadDocumentsComponent;
