import React, { useState } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import "./style.css";

import HeaderComponent from "./header";
import { SetDbUser } from "store/actions";
import { add, update, timestamp, uploadFile } from "firebase_config";
import { CustomImageComponent } from "components";

function EditProfileComponent(props) {
  const { user, currentUser, bindDbUser } = props;
  const [profile, setProfile] = useState(currentUser);
  const [uploading, setUploading] = useState(false);
  const [loading, setLoading] = useState(false);
  const isDoctor = props.currentUser.roles.find(
    (role) => role === "doctor" || role === "public"
  );

  const saveDetails = async (e) => {
    e.preventDefault();
    if (!profile.displayName || !profile.displayName.trim()) {
      toast.error("Name is mandatory");
      return null;
    }
    if (isDoctor && !profile.nmcNumber) {
      toast.error("NMC Registration Number is mandatory");
      return null;
    }
    if (isDoctor && !profile.qualification) {
      toast.error("Qualification is mandatory");
      return null;
    }
    if (isDoctor && !profile.dob) {
      toast.error("Date of Birth is mandatory");
      return null;
    }
    if (isDoctor && !profile.mobile) {
      toast.error("Mobile number is mandatory");
      return null;
    }
    if (isDoctor && profile.mobile && profile.mobile.length !== 12) {
      toast.error("Mobile number must contain country code");
      return null;
    }
    const data = { ...profile };
    // if (profile.displayName) {
    //   data = Object.assign(data, { displayName: profile.displayName.trim() });
    // }
    // if (profile.nmcNumber) {
    //   data = Object.assign(data, { nmcNumber: profile.nmcNumber.trim() });
    // }
    // if (profile.dob) {
    //   data = Object.assign(data, { dob: profile.dob });
    // }
    // data = Object.assign(data, { bio: profile.bio });

    setLoading(true);
    await updateDbUser(data);
    /* Log the activity */
    await add("logs", {
      text: "Profile edited",
      userId: currentUser.id,
      collection: "users",
      timestamp,
    });
    setLoading(false);
  };

  const updateDbUser = async (data) => {
    /* Update in firestore, instead of firebase auth */
    /* let res = await updateProfile(data); */
    const res = await update("users", currentUser.id, data);
    await bindDbUser({ ...currentUser, ...data });
    if (res.status === 200) {
      toast.success("Successfully updated");
    }
  };

  const uploadImage = async (file) => {
    if (!file) {
      toast.error("New image required");
      return null;
    }
    setUploading(true);
    await uploadFile(file, "image", async (url, err) => {
      if (err) {
        alert(err);
        return null;
      }
      await update("users", currentUser.id, { photoURL: url });
      setProfile({ ...profile, photoURL: url });
      bindDbUser({ ...currentUser, photoURL: url });
      setUploading(false);
    });
  };

  // const deleteImage = async () => {
  //   if (window.confirm('Are you sure you want to remove profile image?')) {
  //     /* Don't remove source image. Affects in chats & alerts */
  //     // await removeImage(fileUrl);
  //     /* Log the activity */
  //     await add('logs', {
  //       text: `${user.displayName} removed profile image`,
  //       photoURL: '',
  //       receiverId: '',
  //       userId: user.id,
  //       actionType: 'update',
  //       collection: 'users',
  //       actionId: user.id,
  //       actionKey: 'photoURL',
  //       timestamp
  //     })
  //     await update('users', user.id, { photoURL: '' })
  //   }
  // }

  const handleChange = (key, value) => {
    setProfile((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  return (
    <div>
      <HeaderComponent save={saveDetails} loading={loading} />
      <div>
        <div className="dashboard-content">
          <div className="edit-profile-wrapper mt-sm-5">
            <div className="upload-image">
              <label htmlFor="staticImage">
                {!uploading ? (
                  <CustomImageComponent
                    user={currentUser}
                    size="lg"
                    style={{ textAlign: "center" }}
                  />
                ) : (
                  <i className="fa fa-spinner fa-spin"></i>
                )}
              </label>
              <input
                type="file"
                className="form-control-plaintext d-none"
                id="staticImage"
                onChange={(e) => uploadImage(e.target.files[0])}
                accept="image/*"
              />
              <label className="camera-icon-wrapper" htmlFor="staticImage">
                <img
                  src={require("assets/svgs/Camera.svg").default}
                  className="camera-icon"
                  alt="Save"
                />
              </label>
            </div>
            <div className="form-group">
              <label htmlFor="displayName" className="form-label">
                Full name
              </label>
              <div className="">
                <input
                  type="text"
                  className="form-control"
                  id="displayName"
                  value={profile.displayName}
                  placeholder="Name"
                  onChange={(e) => handleChange("displayName", e.target.value)}
                  readOnly={isDoctor}
                />
              </div>
              {isDoctor && (
                <div className="fs-10 text-secondary">
                  * As per NMC registration
                </div>
              )}
            </div>
            <div className={isDoctor ? "form-group" : "d-none"}>
              <label htmlFor="nmcNumber" className="form-label">
                NMC Registration Number *
              </label>
              <div className="">
                <input
                  type="text"
                  className="form-control"
                  id="nmcNumber"
                  value={profile.nmcNumber}
                  placeholder="NMC Registration Number"
                  onChange={(e) => handleChange("nmcNumber", e.target.value)}
                  readOnly={isDoctor}
                />
              </div>
              {isDoctor && (
                <div className="fs-10 text-secondary">
                  * This will be printed on certificate
                </div>
              )}
            </div>
            <div className={isDoctor ? "form-group" : "d-none"}>
              <label htmlFor="nmcNumber" className="form-label">
                Qualification *
              </label>
              <div className="">
                <input
                  type="text"
                  className="form-control"
                  id="nmcNumber"
                  value={profile.qualification}
                  placeholder="M.B.B.S., M.D"
                  onChange={(e) =>
                    handleChange("qualification", e.target.value)
                  }
                  readOnly={isDoctor}
                />
              </div>
              {isDoctor && (
                <div className="fs-10 text-secondary">
                  * This will be printed on certificate
                </div>
              )}
            </div>
            <div className="form-group">
              <label htmlFor="dob" className="form-label">
                Date of birth *
              </label>
              <div className="">
                <input
                  type="date"
                  className="form-control"
                  id="dob"
                  value={profile.dob}
                  placeholder="DD/MM/YYYY"
                  onChange={(e) => handleChange("dob", e.target.value)}
                  readOnly={isDoctor}
                />
              </div>
            </div>
            <div className="form-group">
              <label htmlFor="staticEmail" className="form-label">
                Email *
                <i
                  className={`ml-2 fa fa-${
                    (user && user.emailVerified) || currentUser.emailVerified
                      ? "check text-success"
                      : "warning text-warning"
                  }`}
                  data-container="body"
                  data-toggle="tooltip"
                  data-placement="top"
                  title={`${
                    user.emailVerified || currentUser.emailVerified
                      ? "Verified"
                      : "Not verified"
                  }`}
                ></i>
              </label>
              <div className="">
                <input
                  type="text"
                  className="form-control"
                  id="email"
                  value={currentUser.email}
                  readOnly
                />
              </div>
            </div>
            <div className="form-group">
              <label htmlFor="mobile" className="form-label">
                Mobile *
              </label>
              <div className="">
                <input
                  type="number"
                  className="form-control"
                  id="mobile"
                  value={profile.mobile}
                  placeholder="918074295197"
                  onChange={(e) => handleChange("mobile", e.target.value)}
                  readOnly={isDoctor}
                />
              </div>
              {isDoctor && (
                <div>
                  <div className="row">
                    <div className="form-group mt-3 col-6">
                      <label htmlFor="mobile" className="form-label">
                        Normal consultation (in INR)
                      </label>
                      <div className="">
                        <input
                          type="number"
                          className="form-control"
                          id="pay"
                          value={profile.pay}
                          readOnly
                        />
                      </div>
                    </div>
                    <div className="form-group mt-3 col-6">
                      <label
                        htmlFor="payWithPrescription"
                        className="form-label"
                      >
                        Prescription consultation (in INR)
                      </label>
                      <div className="">
                        <input
                          type="number"
                          className="form-control"
                          id="payWithPrescription"
                          value={profile.payWithPrescription}
                          readOnly
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="text-center d-none">
              <input
                type="checkbox"
                className="form-check-input"
                id="tnc"
                name="tnc"
                checked={profile.tnc}
              />
              <label className="form-check-label" htmlFor="tnc1">
                You must accept our{" "}
                <Link to="/tnc" className="text-violet fw-600">
                  Terms and conditions
                </Link>
                , and{" "}
                <Link to="/tnc" className="text-violet fw-600">
                  sign the contract here.
                </Link>
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  const { user } = state.authUsers;
  return { user };
};

const mapDispatchToProps = (dispatch) => {
  return {
    bindDbUser: (content) => dispatch(SetDbUser(content)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditProfileComponent);
