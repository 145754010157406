import React from "react";

// Stripe pkgs
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

import CardElementComponent from "../card_element/component";
// import { getQuery, firestore } from 'firebase_config'
// import { post } from 'services'

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
// If its dev - use process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY_TEST
// If its prod - use process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

function CardDetailsComponent({
  currentUser,
  rechargeAmount,
  paymentIntent,
  requestId,
  goBack,
}) {
  const options = {
    clientSecret: paymentIntent.client_secret,
  };
  return (
    <Elements stripe={stripePromise} options={options}>
      <CardElementComponent
        paymentIntent={paymentIntent}
        currentUser={currentUser}
        requestId={requestId}
        goBack={goBack}
        rechargeAmount={rechargeAmount}
      />
    </Elements>
  );
}

export default CardDetailsComponent;
