import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import moment from "moment";
import "./style.css";

import HeaderComponent from "../header";
import {
  add,
  update,
  uploadFile,
  removeFile,
  getQuery,
  firestore,
} from "firebase_config";
import { Tables, Routes, Products } from "constants/index";

const currency = "INR";
const products = Products.fitToDrive;

const Form1aComponent = ({ currentUser, location }) => {
  const [options, setOptions] = useState({
    status: "pending",
    confirmTnc: false,
    firstName: "",
    lastName: "",
    dob: "",
    age: "",
    mobile: "",
    email: "",
    address: {
      country: "India",
    },
    reasonDescription: "",
    optionalDoc: "",
    files: [],
    gender: "male",
    userId: currentUser.id,
    assigned: false,
    product: products[0],
    paymentStatus: "pending",
    doctorRemarks: [],
    isEditRequired: false,
    additionalInfoRequired: false,
    promocode: "",
    type: "form_1a",
    defaultMessage: "Form 1A",
  });
  const history = useHistory();
  const [smallDevice, setSmallDevice] = useState(false);
  const [savingCertificate, setSavingCertificate] = useState(false);
  const [error, setError] = useState({
    message: "",
    key: "",
  });
  const [optionalDocLoading, setOptionalDocLoading] = useState(false);
  const [applyDiscount, setApplyDiscount] = useState();
  const [discountPrice, setDiscountPrice] = useState();
  const [files, setFiles] = useState([]);

  useEffect(() => {
    if (window.innerWidth <= 576) {
      setSmallDevice(true);
    } else {
      setSmallDevice(false);
    }
  });

  const handleChange = (key, value, additionalField) => {
    if (additionalField) {
      setOptions({
        ...options,
        [key]: {
          ...options[key],
          [additionalField]: value,
        },
      });
    } else {
      setOptions({
        ...options,
        [key]: value,
      });
    }
    if (key === "product") {
      verifyPromoCode(value);
    }
  };

  const uploadImage = async (file, key, event) => {
    if (!file) {
      toast.error("New image required");
      return null;
    }
    setOptionalDocLoading(true);
    await uploadFile(file, "image", async (url, err) => {
      if (err) {
        alert(err);
        return null;
      }
      event.target.value = null;
      setFiles([
        ...files,
        {
          name: file.name,
          type: file.type,
          url,
        },
      ]);
      setOptionalDocLoading(false);
    });
  };

  const deleteImage = async (url, key, event) => {
    if (window.confirm("Are you sure to remove file?")) {
      setOptionalDocLoading(true);

      await removeFile(url);

      const f = files.filter((f) => f.url !== url);
      setFiles(f);

      setOptionalDocLoading(false);
    }
  };

  const redirectToGuildelines = () => {
    if (
      window.confirm("Your form data will be lost. Are you sure to redirect?")
    ) {
      history.push({
        pathname: Routes.APP_GUIDELINES_SLC,
        state: {
          options,
        },
      });
    }
  };

  const showErrors = () => {
    let error;
    if (!options.firstName) {
      error = {
        message: "First name is required",
        key: "firstName",
      };
    } else if (!options.lastName) {
      error = {
        message: "Last name is required",
        key: "lastName",
      };
    } else if (!options.dob) {
      error = {
        message: "Date of birth is required",
        key: "dob",
      };
    } else if (!options.age) {
      error = {
        message: "Age is required",
        key: "age",
      };
    } else if (!options.email) {
      error = {
        message: "Email is required",
        key: "email",
      };
    } else if (!options.mobile) {
      error = {
        message: "Mobile number is required",
        key: "mobile",
      };
    } else if (options.address && !options.address.door) {
      error = {
        message: "Door number is required",
        key: "door",
      };
    } else if (options.address && !options.address.streetName) {
      error = {
        message: "Street name is required",
        key: "streetName",
      };
    } else if (options.address && !options.address.city) {
      error = {
        message: "City is required",
        key: "city",
      };
    } else if (options.address && !options.address.state) {
      error = {
        message: "State is required",
        key: "state",
      };
    } else if (options.address && !options.address.zipcode) {
      error = {
        message: "Postal / Zip code is required ",
        key: "zipcode",
      };
    } else if (files && files.length < 3) {
      error = {
        message:
          "Upload Govt. ID (both front and back) and a Passport size photo",
        key: "files",
      };
    } else if (!options.reasonDescription) {
      error = {
        message: "Birth marks required",
        key: "reasonDescription",
      };
    } else if (!options.product) {
      error = {
        message: "Choose a product",
        key: "product",
      };
    } else if (!options.confirmTnc) {
      error = {
        message: "Please confirm our Terms and Conditions",
        key: "confirmTnc",
      };
    } else if (!options.confirmGuidelines) {
      error = {
        message: "Please confirm our Guidelines",
        key: "confirmGuidelines",
      };
    }
    setError(error);
    return error;
  };

  const saveRequest = async () => {
    const err = await showErrors();
    if (err && err.message) {
      toast.error(err.message);
      return;
    }
    const state = options.address.state && options.address.state.trim();
    if (state && state.toLowerCase() === "maharashtra") {
      alert(
        "Online Certificates are not valid in the state of Maharashtra for Form 1A. Please check the list of outlined Doctors by RTO for your zipcode."
      );
      return;
    }
    setSavingCertificate(true);
    options.priority = options.product.priority;
    options.closeRequestTime = moment().valueOf() + 24 * 60 * 60 * 1000;
    if (discountPrice) {
      options.product = {
        ...options.product,
        price: discountPrice,
      };
    }

    if (options.product.priority === "low") {
      options.noChat = true;
    }

    if (files.length > 0) {
      options.files = files;
    }

    const res = await add(Tables.MEDICAL_REQUESTS, options);
    setSavingCertificate(false);
    if (res.status === 200) {
      toast.success(
        "Your request has been saved. Complete the payment to proceed further."
      );
      history.push({
        pathname: Routes.APP_RECHARGE,
        state: {
          amount: options.product.price,
          requestId: res.data.id,
        },
      });
    }
  };

  const verifyPromoCode = async (val) => {
    const cc = await getQuery(
      firestore
        .collection(Tables.PROMOCODES)
        .where("code", "==", options.promocode)
        .get()
    );
    const pcode = cc[0];
    const unixDate = moment().unix() * 1000;
    if (
      pcode &&
      pcode.code === options.promocode &&
      unixDate < pcode.expiresOn
    ) {
      if (pcode.type === "limited" && pcode.count > 0) {
        // limited promocodes
        update(Tables.PROMOCODES, pcode.id, {
          count: pcode.count - 1,
        });
        let dp = options.product.price * (1 - pcode.discountPercent);
        if (val) {
          dp = val.price * (1 - pcode.discountPercent);
        }
        setApplyDiscount(pcode);
        setDiscountPrice(dp);
        setError({
          key: "",
          message: "",
        });
      } else if (pcode.type !== "limited") {
        // if type is influencer
        let dp = options.product.price * (1 - pcode.discountPercent);
        if (val) {
          dp = val.price * (1 - pcode.discountPercent);
        }
        setApplyDiscount(pcode);
        setDiscountPrice(dp);
        setError({
          key: "",
          message: "",
        });
      } else {
        setError({
          key: "promocode",
          message: "Code is invalid",
        });
      }
    } else {
      setError({
        key: "promocode",
        message: "Code is invalid",
      });
    }
  };

  return (
    <>
      <HeaderComponent
        headerText={location.state?.headerText || "Medical Certificate"}
      />
      <div className="dashboard-content">
        <div className="container create-post-wrapper pt-5 mt-sm-5">
          <div className="card p-2 p-sm-4 my-sm-5">
            <div className="font-small text-violet fw-500">
              Note: Online Certificates are not valid in the state of
              Maharashtra for Form 1A. Please check the list of outlined Doctors
              by RTO for your zipcode.
            </div>
            <hr />
            <div className="mt-2">
              <h6>
                Full name of the person * <br />
                <small className="text-secondary fs-10">
                  *As per Govt ID proof
                </small>
              </h6>
              <div className="row">
                <div className="col-12 col-sm-6">
                  <input
                    type="text"
                    className={`form-control font-small ${
                      error?.key === "firstName" && "error-border"
                    }`}
                    name="form-question"
                    placeholder="First name"
                    onChange={(e) => handleChange("firstName", e.target.value)}
                  />
                  {error?.key === "firstName" ? (
                    <small className="text-danger fs-10">{error.message}</small>
                  ) : (
                    <small className="text-secondary fs-10">First name</small>
                  )}
                </div>
                <div className="col-12 col-sm-6 mt-4 mt-sm-0">
                  <input
                    type="text"
                    className={`form-control font-small ${
                      error?.key === "lastName" && "error-border"
                    }`}
                    name="form-question"
                    placeholder="Last name"
                    onChange={(e) => handleChange("lastName", e.target.value)}
                  />
                  {error?.key === "lastName" ? (
                    <small className="text-danger fs-10">{error.message}</small>
                  ) : (
                    <small className="text-secondary fs-10">Last name</small>
                  )}
                </div>
              </div>
            </div>
            <div className="row mt-4">
              <div className="col-12 col-sm-6">
                <h6>Date of birth *</h6>
                <input
                  type="date"
                  className={`form-control ${
                    error?.key === "dob" && "error-border"
                  }`}
                  name="form-question"
                  placeholder="dob"
                  onChange={(e) => handleChange("dob", e.target.value)}
                />
                {error?.key === "dob" && (
                  <small className="text-danger fs-10">{error.message}</small>
                )}
              </div>
              <div className="col-12 col-sm-6 mt-3 mt-sm-0">
                <h6>Age *</h6>
                <input
                  type="number"
                  className={`form-control ${
                    error?.key === "age" && "error-border"
                  }`}
                  name="form-question"
                  placeholder="Age"
                  onChange={(e) => handleChange("age", e.target.value)}
                />
                {error?.key === "age" && (
                  <small className="text-danger fs-10">{error.message}</small>
                )}
              </div>
            </div>
            <div className="mt-4">
              <h6>Gender *</h6>
              <select
                className="form-control font-small"
                aria-label=".form-select-sm"
                onChange={(e) => handleChange("gender", e.target.value)}
              >
                <option value="male">Male</option>
                <option value="female">Female</option>
                <option value="other">Other</option>
              </select>
            </div>
            <div className="row mt-4">
              <div className="col-12 col-sm-6">
                <h6 htmlFor="form-question">Email *</h6>
                <input
                  type="email"
                  className={`form-control ${
                    error?.key === "email" && "error-border"
                  }`}
                  name="form-question"
                  placeholder="Email"
                  onChange={(e) => handleChange("email", e.target.value)}
                />
                {error?.key === "email" && (
                  <small className="text-danger fs-10">{error.message}</small>
                )}
              </div>
              <div className="col-12 col-sm-6 mt-4 mt-sm-0">
                <h6 htmlFor="form-question">Mobile *</h6>
                <input
                  type="number"
                  className={`form-control ${
                    error?.key === "mobile" && "error-border"
                  }`}
                  name="form-question"
                  placeholder="917890789090"
                  onChange={(e) => handleChange("mobile", e.target.value)}
                />
                <small className="text-secondary fs-10">
                  Country code is mandatory. e.g. 917890789078.
                </small>
                {error?.key === "mobile" && (
                  <div className="text-danger fs-10">{error.message}</div>
                )}
              </div>
              <div className="col-12 mt-4">
                <h6>
                  Address *<br />
                  <small className="text-secondary fs-10">
                    *As per Govt ID proof.
                  </small>
                </h6>
                <div className="row">
                  <div className="col-12 col-sm-6 my-2">
                    <input
                      type="text"
                      className={`form-control ${
                        error?.key === "door" && "error-border"
                      }`}
                      name="form-question"
                      placeholder="Door no."
                      onChange={(e) =>
                        handleChange("address", e.target.value, "door")
                      }
                    />
                    {error?.key === "door" ? (
                      <small className="text-danger fs-10">
                        {error.message}
                      </small>
                    ) : (
                      <small className="text-secondary fs-10">
                        Door number
                      </small>
                    )}
                  </div>
                  <div className="col-12 col-sm-6 my-2">
                    <input
                      type="text"
                      className={`form-control ${
                        error?.key === "streetName" && "error-border"
                      }`}
                      name="form-question"
                      placeholder="Street name"
                      onChange={(e) =>
                        handleChange("address", e.target.value, "streetName")
                      }
                    />
                    {error?.key === "streetName" ? (
                      <small className="text-danger fs-10">
                        {error.message}
                      </small>
                    ) : (
                      <small className="text-secondary fs-10">
                        Street name
                      </small>
                    )}
                  </div>
                  <div className="col-12 col-sm-6 my-2">
                    <input
                      type="text"
                      className={`form-control ${
                        error?.key === "city" && "error-border"
                      }`}
                      name="form-question"
                      placeholder="City"
                      onChange={(e) =>
                        handleChange("address", e.target.value, "city")
                      }
                    />
                    {error?.key === "city" ? (
                      <small className="text-danger fs-10">
                        {error.message}
                      </small>
                    ) : (
                      <small className="text-secondary fs-10">City</small>
                    )}
                  </div>
                  <div className="col-12 col-sm-6 my-2">
                    <input
                      type="text"
                      className={`form-control ${
                        error?.key === "state" && "error-border"
                      }`}
                      name="form-question"
                      placeholder="State / Region / Province"
                      onChange={(e) =>
                        handleChange("address", e.target.value, "state")
                      }
                    />
                    {error?.key === "state" ? (
                      <small className="text-danger fs-10">
                        {error.message}
                      </small>
                    ) : (
                      <small className="text-secondary fs-10">
                        State / Region / Province
                      </small>
                    )}
                  </div>
                  <div className="col-12 col-sm-6 my-2">
                    <input
                      type="text"
                      className={`form-control ${
                        error?.key === "zipcode" && "error-border"
                      }`}
                      name="form-question"
                      placeholder="Postal/Zip Code"
                      onChange={(e) =>
                        handleChange("address", e.target.value, "zipcode")
                      }
                    />
                    {error?.key === "zipcode" ? (
                      <small className="text-danger fs-10">
                        {error.message}
                      </small>
                    ) : (
                      <small className="text-secondary fs-10">
                        Postal / Zip code
                      </small>
                    )}
                  </div>
                  <div className="col-12 col-sm-6 my-2">
                    <select
                      className="form-control font-small"
                      aria-label=".form-select-sm"
                      onChange={(e) =>
                        handleChange("address", e.target.value, "country")
                      }
                    >
                      <option value="india" defaultValue>
                        India
                      </option>
                    </select>
                    <small className="text-secondary fs-10">Country</small>
                  </div>
                </div>
              </div>
            </div>
            {/* documents */}
            <div className="mt-4">
              <h6>
                Add documents *
                <div className="text-secondary fs-10 pt-2 fw-400">
                  Upload Govt. issued ID proof (both front and back), Passport
                  size photo, Form 1A from RTO website.
                </div>
              </h6>
              {files &&
                files.map((file) => (
                  <div
                    className="alert alert-secondary px-2 py-2 align-items-center"
                    key={file.name}
                  >
                    <span className="font-small">{file.name}</span>
                    <i
                      className="fa fa-trash pull-right text-danger pt-1"
                      onClick={(e) => deleteImage(file.url, "files", e)}
                    />
                  </div>
                ))}
              {optionalDocLoading ? (
                <i className="fa fa-spinner fa-spin" />
              ) : (
                <div>
                  <label
                    htmlFor="optionalDoc"
                    className="pointer btn btn-violet-outline btn-sm"
                  >
                    <i className="fa fa-plus" />
                  </label>
                  <input
                    type="file"
                    className="form-control-plaintext d-none"
                    id="optionalDoc"
                    onChange={(e) =>
                      uploadImage(e.target.files[0], "optionalDoc", e)
                    }
                    accept="application/*,image/*,video/*"
                  />
                </div>
              )}
              {error?.key === "files" && (
                <small className="text-danger fs-10 mb-2">
                  {error.message}
                </small>
              )}
            </div>
            <div className="mt-4">
              <h6>
                Birth marks (moles) *
                <div className="text-secondary fs-10 pt-2 fw-400">
                  Two birth marks (moles) are supposed to be outlined in the
                  Form 1A.
                </div>
              </h6>
              <textarea
                className={`form-control fs-12 ${
                  error?.key === "reasonDescription" && "error-border"
                }`}
                name="form-question"
                rows="2"
                placeholder="Type here."
                onChange={(e) =>
                  handleChange("reasonDescription", e.target.value)
                }
              />
              {error?.key === "reasonDescription" && (
                <small className="text-danger fs-10">{error.message}</small>
              )}
            </div>
            {/* products */}
            <div className="mt-4">
              <h6>My products *</h6>
              <div className="row">
                {products.map(
                  (product, idx) =>
                    product.display && (
                      <div key={idx} className="col-12 my-2">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="product"
                          id={`product-${idx}`}
                          onChange={(e) => handleChange("product", product)}
                          checked={options.product.name === product.name}
                        />
                        <label
                          className="form-check-label"
                          htmlFor={`product-${idx}`}
                        >
                          <div className="media">
                            <div className="media-body">
                              <div className="fw-500">
                                {product.name} {smallDevice && <div></div>}
                                <div
                                  className={
                                    smallDevice ? "my-2" : "pull-right"
                                  }
                                >
                                  {product.price.toFixed(2)} {currency} &nbsp;
                                  <strike className="text-orange-600 fw-500 mt-2 fs-12">
                                    {product.totalPrice.toFixed(2)} {currency}
                                  </strike>
                                </div>
                              </div>
                              <div className="mt-1 fs-12">
                                {product.description}
                              </div>
                            </div>
                          </div>
                        </label>
                      </div>
                    )
                )}
              </div>
              <div className="mt-4 ml-4 d-none">
                <div className="form-question fs-12">Have a Promo Code?</div>
                <div className="input-group col-12 col-sm-6 pl-0">
                  <input
                    type="text"
                    className={`form-control fs-12 ${
                      error?.key === "promocode" && "error-border"
                    }`}
                    placeholder="Promocode"
                    aria-label="Promocode"
                    aria-describedby="promo-code"
                    onChange={(e) => handleChange("promocode", e.target.value)}
                    disabled={discountPrice}
                  />
                  <div
                    className="input-group-append"
                    onClick={(e) => verifyPromoCode()}
                  >
                    <span
                      className="btn btn-violet btn-sm px-3"
                      id="promo-code"
                      disabled={discountPrice}
                    >
                      {applyDiscount ? "Applied!" : "Apply"}
                    </span>
                  </div>
                </div>
                <small className="text-secondary fs-10">e.g. MEDOC_20D</small>
                {error?.key === "promocode" && (
                  <div className="text-danger fs-10">{error.message}</div>
                )}
              </div>
              <div className="text-secondary fs-12 text-center mt-3 d-none">
                Note: This service solely provides healthcare advice and does
                not give certificates, referral letters, prescriptions, or
                anything else.
              </div>
              <div className="mt-4 text-center fs-16 fw-500">
                Total cost:{" "}
                <span className="text-violet">
                  {discountPrice
                    ? discountPrice.toFixed(2)
                    : options.product.price.toFixed(2)}{" "}
                  {currency}
                  {discountPrice && options.product.price && (
                    <strike className="text-orange-600 pl-2">
                      {options.product.price.toFixed(2)} {currency}
                    </strike>
                  )}
                </span>
              </div>
              {discountPrice && (
                <div className="text-orange-600 text-center pt-3 fs-12">
                  Hurray! You saved extra {applyDiscount.discountPercent * 100}%
                </div>
              )}
              <hr />
              <div className="tnc">
                <div className="pt-2">
                  <input
                    className="form-check-input ml-1 mt-2"
                    type="checkbox"
                    name="confirmGuidelines"
                    id="confirmGuidelines"
                    onChange={(e) => handleChange("confirmGuidelines", true)}
                    checked={options.confirmGuidelines}
                  />
                  <label
                    className={`form-check-label ml-4 text-justify ${
                      error?.key === "confirmGuidelines" && "error-color"
                    }`}
                    htmlFor="confirmGuidelines"
                  >
                    I adhere to all our{" "}
                    <span
                      className="text-violet px-0 mx-0"
                      onClick={redirectToGuildelines}
                    >
                      medoc guidelines.
                    </span>{" "}
                    Our doctors are available from 7am to 9pm IST.
                  </label>
                </div>
              </div>
              <div className="tnc">
                <div className="pt-2">
                  <input
                    className="form-check-input ml-1"
                    type="checkbox"
                    name="confirmTnc"
                    id="confirmTnc"
                    onChange={(e) => handleChange("confirmTnc", true)}
                    checked={options.confirmTnc}
                  />
                  <label
                    className={`form-check-label ml-4 text-justify ${
                      error?.key === "confirmGuidelines" && "error-color"
                    }`}
                    htmlFor="confirmTnc"
                  >
                    I confirm that I am not seriously unwell and I do NOT have
                    any of the following symptoms: chest pain, shortness of
                    breath, unable to swallow fluids or saliva, weakness or
                    numbness down one side, and slurred speech. I confirm I do
                    not think I need to see a General Practitioner. If symptoms
                    are related to a COVID vaccine, I will consult my General
                    Practitioner or the medical professional who provided the
                    immunization. I agree that the issuance of a medical
                    certificate and its duration is based on the discretion of
                    the consulting doctor. I confirm that I am not using the
                    certificate for any medico-legal purposes. In case of
                    non-issuance of the certificate due to any reason, a service
                    charge of INR 500/- will be deducted while refunding the
                    amount. The refund will be done online and usually takes
                    7-10 working days. I also agree to the{" "}
                    <Link to="/rp" target="_blank" className="text-violet">
                      Refund and cancellation policy
                    </Link>
                    . I confirm that this online consultation is not a
                    replacement for a doctor's visit. I have read, understood,
                    and agree with the information in the terms of service and
                    consent to the use of information in accordance with the
                    privacy policy.
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div
            className="complete-payment btn btn-violet text-center"
            onClick={saveRequest}
          >
            {savingCertificate ? (
              <i className="fa fa-spinner fa-spin" />
            ) : (
              <span>Complete Payment</span>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Form1aComponent;
