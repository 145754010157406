import React from "react";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import { gtokFavicon } from "images";

export default function HelmetMetaDataComponent(props) {
  const location = useLocation();
  const currentUrl =
    props.currentUrl || "https://medoc.life" + location.pathname;
  const quote = props.quote || "";
  const title =
    props.title || "Medoc | Medical Certificates Online | Only 149 INR";
  const image = props.image || gtokFavicon;
  const description =
    props.description ||
    "Medoc is an easiest way to get medical certificates online. A telehealth platform that helps you enjoy confidential consultations with registered Indian doctors.";
  const hashtag = props.hashtag || "#medoc";
  const keywords =
    props.keywords || "medical certificate,indian doctors,sick leave";
  return (
    <Helmet>
      <title>{title}</title>
      <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
      <meta name="csrf_token" content="" />
      <meta property="type" content="webapp" />
      <meta property="url" content={currentUrl} />
      <meta name="_token" content="" />
      <meta name="robots" content="noodp" />
      <meta property="title" content={title} />
      <meta property="quote" content={quote} />
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords} />
      <meta property="image" content={image} />
      <meta property="og:locale" content="en_US" />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:quote" content={quote} />
      <meta property="og:hashtag" content={hashtag} />
      <meta property="og:image" content={image} />
      <meta content="image/*" property="og:image:type" />
      <meta property="og:url" content={currentUrl} />
      <meta property="og:site_name" content="Medoc" />
      <meta property="og:description" content={description} />
      <link rel="icon" type="image/png" href={gtokFavicon} sizes="16x16" />
    </Helmet>
  );
}
