export const SET_SUBSCRIPTION_PLANS = "SET_SUBSCRIPTION_PLANS";

export const SET_SEARCH_USERS = "SET_SEARCH_USERS";
export const SET_ALL_USERS = "ALL_USERS";
export const SET_ADMIN_USERS = "ADMIN_USERS";
export const SET_SINGLE_USER = "SINGLE_USER";

export const SET_USER = "SET_USER";
export const SET_DB_USER = "SET_DB_USER";
export const SET_LOGGED_IN = "SET_LOGGED_IN";
export const SET_RELOAD = "SET_RELOAD";

export const SET_RELATIONSHIPS = "SET_RELATIONSHIPS";
export const SET_USER_RELATIONS = "SET_USER_RELATIONS";

export const SET_CHAT_MESSAGES = "SET_CHAT_MESSAGES";
export const SET_CONVOS = "SET_CONVOS";
export const SET_CHATBOT_MESSAGES = "SET_CHATBOT_MESSAGES";

export const SET_SURVEYS_LIST = "SET_SURVEYS_LIST";
export const SET_SURVEYS_AFTER_RESPONSES = "SET_SURVEYS_AFTER_RESPONSES";

export const GET_ALERTS = "GET_ALERTS";
export const SET_ALERTS = "SET_ALERTS";
export const SET_NEW_ALERTS_COUNT = "SET_NEW_ALERTS_COUNT";

export const SET_NEW_MESSAGES_COUNT = "SET_NEW_MESSAGES_COUNT";

export const SET_TRENDING_POSTS = "SET_TRENDING_POSTS";
export const SET_POSTS = "SET_POSTS";
export const SET_SELECTED_USER_POSTS = "SET_SELECTED_USER_POSTS";
export const SET_SHARE_POST = "SET_SHARE_POST";
export const SET_NEW_POST = "SET_NEW_POST";
export const SET_DELETED_POST = "SET_DELETED_POST";
export const SET_UPDATED_POST = "SET_UPDATED_POST";

export const SET_PERMISSIONS = "SET_PERMISSIONS";

export const SET_PURCHASE_ORDERS = "SET_PURCHASE_ORDERS";
export const SET_TRANSACTIONS = "SET_TRANSACTIONS";
export const SET_WALLET = "SET_WALLET";
export const SET_PRICES = "SET_PRICES";

export const SET_CURRENT_AUDIO = "SET_CURRENT_AUDIO";
