import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import moment from "moment";
import "./style.css";

import HeaderComponent from "../header";
import {
  add,
  update,
  uploadFile,
  removeFile,
  getQuery,
  firestore,
} from "firebase_config";
import { Tables, Routes, Products } from "constants/index";
import AcceptTermsComponent from "../accept_terms/component";
import PersonalDetailsComponent from "../personal_details/component";
import UploadDocumentsComponent from "../upload_documents/component";

const currency = "INR";
const products = Products.sickLeaveForWork;
const reasons = [
  "Migraine",
  "Period pain",
  "Common cold and flu",
  "Back pain",
  "Headache",
  "Anxiety, depression or stress",
  "Other",
];

const CreatePostComponent = ({ currentUser, location }) => {
  const [options, setOptions] = useState({
    status: "pending",
    confirmGuidelines: false,
    confirmTnc: false,
    leaveReason: "Sick Leave",
    leaveFrom: location.state?.type === "sick_to_school" ? "studies" : "work",
    firstName: "",
    lastName: "",
    dob: "",
    age: "",
    address: {
      country: "India",
    },
    mobile: "",
    email: "",
    reason: "",
    otherReason: "",
    reasonDescription: "",
    leaveDateFrom: "",
    leaveDateTo: "",
    orgAddress: "",
    orgName: "",
    idFront: "",
    idBack: "",
    optionalDoc: "",
    gender: "male",
    userId: currentUser.id,
    assigned: false,
    product: products[0],
    paymentStatus: "pending",
    doctorRemarks: [],
    additionalInfoRequired: false,
    promocode: "",
    isPrescriptionRequired: true,
    isEditRequired: false,
    isStudent: false,
    subType: location.state?.type || "",
    orgLocation: "india",
  });
  const history = useHistory();
  const [smallDevice, setSmallDevice] = useState(false);
  const [countChars, setCountChars] = useState(25);
  const [savingCertificate, setSavingCertificate] = useState(false);
  const [error, setError] = useState({
    message: "",
    key: "",
  });
  const [idFrontLoading, setIdFrontLoading] = useState(false);
  const [idBackLoading, setIdBackLoading] = useState(false);
  const [optionalDocLoading, setOptionalDocLoading] = useState(false);
  const [applyDiscount, setApplyDiscount] = useState();
  const [discountPrice, setDiscountPrice] = useState();
  const [hidePrescriptionAlert, setHidePrescriptionAlert] = useState(true);
  const [stepNumber, setStepNumber] = useState(1);

  useEffect(() => {
    if (window.innerWidth <= 576) {
      setSmallDevice(true);
    } else {
      setSmallDevice(false);
    }
  });

  const handleChange = (key, value, additionalField) => {
    if (key === "otherReason") {
      const max = 20;
      setCountChars(max - value.length);
    }
    if (additionalField) {
      setOptions({
        ...options,
        [key]: {
          ...options[key],
          [additionalField]: value,
        },
      });
    } else {
      setOptions({
        ...options,
        [key]: value,
      });
    }
    if (key === "product" && options.promocode) {
      verifyPromoCode(value);
    }
  };

  const uploadImage = async (file, key, event) => {
    if (!file) {
      toast.error("New image required");
      return null;
    }
    if (key === "idFront") {
      setIdFrontLoading(true);
    } else if (key === "idBack") {
      setIdBackLoading(true);
    } else {
      setOptionalDocLoading(true);
    }
    await uploadFile(file, "image", async (url, err) => {
      if (err) {
        alert(err);
        return null;
      }
      event.target.value = null;
      setOptions((prevState) => ({
        ...prevState,
        [key]: url,
      }));

      if (key === "idFront") {
        setIdFrontLoading(false);
      } else if (key === "idBack") {
        setIdBackLoading(false);
      } else {
        setOptionalDocLoading(false);
      }
    });
  };

  const deleteImage = async (url, key, event) => {
    if (window.confirm("Are you sure to remove image?")) {
      if (key === "idFront") {
        setIdFrontLoading(true);
      } else if (key === "idBack") {
        setIdBackLoading(true);
      } else {
        setOptionalDocLoading(true);
      }

      await removeFile(url);

      if (key === "idFront") {
        setIdFrontLoading(false);
      } else if (key === "idBack") {
        setIdBackLoading(false);
      } else {
        setOptionalDocLoading(false);
      }
      setOptions((prevState) => ({
        ...prevState,
        [key]: "",
      }));
    }
  };

  const showErrors = () => {
    let error;
    if (!options.orgName) {
      error = {
        message: "Workplace name is required",
        key: "orgName",
      };
    } else if (!options.orgAddress) {
      error = {
        message: "Workplace address is required",
        key: "orgAddress",
      };
    } else if (!options.leaveDateFrom) {
      error = {
        message: "From date is required",
        key: "leaveDateFrom",
      };
    } else if (!options.leaveDateTo) {
      error = {
        message: "To date is required",
        key: "leaveDateTo",
      };
    } else if (!options.reason) {
      error = {
        message: "Reason is required",
        key: "reason",
      };
    } else if (options.reason === "Other" && !options.otherReason) {
      error = {
        message: "Other reason is required",
        key: "otherReason",
      };
    } else if (options.isStudent && !options.studentIdDoc) {
      error = {
        message: "Student ID is required",
        key: "studentIdDoc",
      };
    } else if (!options.product) {
      error = {
        message: "Choose a product",
        key: "product",
      };
    } else if (!options.confirmTnc) {
      error = {
        message: "Please confirm our Terms and Conditions",
        key: "confirmTnc",
      };
    } else if (!options.confirmGuidelines) {
      error = {
        message: "Please confirm our Guidelines",
        key: "confirmGuidelines",
      };
    }
    setError(error);
    return error;
  };

  const saveCertificate = async () => {
    const err = await showErrors();
    if (err && err.message) {
      toast.error(err.message);
      return;
    }
    setSavingCertificate(true);
    options.priority = options.product.priority;

    options.product = {
      ...options.product,
      price: parseInt(totalCost()),
    };

    const res = await add(Tables.MEDICAL_REQUESTS, options);
    setSavingCertificate(false);
    if (res.status === 200) {
      toast.success(
        "Your request has been saved. Complete the payment to proceed further."
      );
      history.push({
        pathname: Routes.APP_RECHARGE,
        state: {
          amount: options.product.price,
          requestId: res.data.id,
        },
      });
    }
  };

  const verifyPromoCode = async (val) => {
    const cc = await getQuery(
      firestore
        .collection(Tables.PROMOCODES)
        .where("code", "==", options.promocode)
        .get()
    );
    const pcode = cc[0];
    const unixDate = moment().unix() * 1000;
    if (
      pcode &&
      pcode.code === options.promocode &&
      unixDate < pcode.expiresOn
    ) {
      if (pcode.type === "limited" && pcode.count > 0) {
        // limited promocodes
        update(Tables.PROMOCODES, pcode.id, {
          count: pcode.count - 1,
        });
        let dp = options.product.price * (1 - pcode.discountPercent);
        if (val) {
          dp = val.price * (1 - pcode.discountPercent);
        }
        setApplyDiscount(pcode);
        setDiscountPrice(dp);
        setError({
          key: "",
          message: "",
        });
      } else if (pcode.type !== "limited") {
        // if type is influencer
        let dp = options.product.price * (1 - pcode.discountPercent);
        if (val) {
          dp = val.price * (1 - pcode.discountPercent);
        }
        setApplyDiscount(pcode);
        setDiscountPrice(dp);
        setError({
          key: "",
          message: "",
        });
      } else {
        setError({
          key: "promocode",
          message: "Code is invalid",
        });
      }
    } else {
      setError({
        key: "promocode",
        message: "Code is invalid",
      });
    }
  };

  const totalCost = () => {
    if (discountPrice) {
      let p = discountPrice;
      if (options.isPrescriptionRequired) {
        p += options.product.prescriptionPrice;
      } else {
        p = discountPrice;
      }
      return p.toFixed(2);
    } else if (options.isStudent) {
      let p = options.product.studentPrice;
      if (options.isPrescriptionRequired) {
        p += options.product.prescriptionPrice;
      } else {
        p = options.product.studentPrice;
      }
      return p.toFixed(2);
    } else if (options.orgLocation === "abroad") {
      let p = options.product.abroadPrice;
      if (options.isPrescriptionRequired) {
        p += options.product.prescriptionPrice;
      } else {
        p = options.product.abroadPrice;
      }
      return p.toFixed(2);
    } else {
      let p = options.product.price;
      if (options.isPrescriptionRequired) {
        p += options.product.prescriptionPrice;
      } else {
        p = options.product.price;
      }
      return p.toFixed(2);
    }
  };

  const requiredDocs = () => (
    <div>
      <h5 className="text-center">Upload documents</h5>
      <ul className="fs-12 mt-3">
        <li>
          Proof of Identification (Govt. issued ID){" "}
          <span className="text-danger fs-14 fw-500">*</span>
        </li>
        <li>
          Address Proof (Govt. issued ID){" "}
          <span className="text-danger fw-500">*</span>
        </li>
        <li>
          Any documents like prescriptions, discharge papers, doctor's letter,
          test reports, etc (if you're requesting more than 7 days of leave)
        </li>
      </ul>
      <div className="text-secondary fs-10 text-center">
        Note: Only PNG, JPEG, JPG formats supported.
      </div>
    </div>
  );

  return (
    <>
      <HeaderComponent
        headerText={location.state?.headerText || "Medical Certificate"}
      />
      {stepNumber === 1 && (
        <AcceptTermsComponent
          options={options}
          setStepNumber={setStepNumber}
          refundAmount={250}
          handleChange={handleChange}
        />
      )}
      {stepNumber === 2 && (
        <PersonalDetailsComponent
          options={options}
          error={error}
          setError={setError}
          setStepNumber={setStepNumber}
          handleChange={handleChange}
        />
      )}
      {stepNumber === 3 && (
        <UploadDocumentsComponent
          options={options}
          error={error}
          setError={setError}
          setStepNumber={setStepNumber}
          handleChange={handleChange}
          requiredDocs={requiredDocs}
        />
      )}
      {stepNumber === 4 && (
        <>
          <div className="dashboard-content">
            <div className="container create-post-wrapper pt-5 mt-sm-5">
              <div className="card p-2 p-sm-4 my-sm-5">
                <h5 className="text-black text-center">Other details</h5>
                <div className="row mt-4">
                  <div className="col-12 col-sm-6">
                    <h6 htmlFor="form-question">Leave From *</h6>
                    <input
                      type="date"
                      className={`form-control ${
                        error?.key === "leaveDateFrom" && "error-border"
                      }`}
                      onChange={(e) =>
                        handleChange("leaveDateFrom", e.target.value)
                      }
                      max={moment().format("DD/MM/YYYY")}
                    />
                    {error?.key === "leaveDateFrom" && (
                      <small className="text-danger fs-10">
                        {error.message}
                      </small>
                    )}
                  </div>
                  <div className="col-12 col-sm-6 mt-4 mt-sm-0">
                    <h6 htmlFor="form-question">Leave To *</h6>
                    <input
                      type="date"
                      className={`form-control ${
                        error?.key === "leaveDateTo" && "error-border"
                      }`}
                      onChange={(e) =>
                        handleChange("leaveDateTo", e.target.value)
                      }
                      max={moment().format("DD/MM/YYYY")}
                    />
                    {error?.key === "leaveDateTo" && (
                      <small className="text-danger fs-10">
                        {error.message}
                      </small>
                    )}
                  </div>
                </div>
                {/* medical problem */}
                <div className="mt-4 pt-1">
                  <h6>
                    The main reason for the certificate *
                    <div className="text-secondary fs-10 pt-2 fw-400">
                      This will be written on your certificate.
                    </div>
                  </h6>
                  <div className="row">
                    {reasons.map((reason, idx) => (
                      <div key={idx} className="col-12 col-sm-6 py-1">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="reason"
                          id={`reason-${idx}`}
                          onChange={(e) => handleChange("reason", reason)}
                          checked={options.reason === reason}
                        />
                        <label
                          className="form-check-label"
                          htmlFor={`reason-${idx}`}
                        >
                          {reason}
                        </label>
                      </div>
                    ))}
                  </div>
                </div>
                {options.reason === "Other" && (
                  <div className="col-12 col-sm-6 mt-4 mt-sm-0">
                    <input
                      type="text"
                      className={`form-control font-small ${
                        error?.key === "otherReason" && "error-border"
                      }`}
                      name="form-question"
                      placeholder="eg. body pain, mild fever etc."
                      onChange={(e) =>
                        handleChange("otherReason", e.target.value)
                      }
                      maxLength="25"
                    />
                    <small className="text-secondary fs-10">
                      {countChars} chars left
                    </small>
                  </div>
                )}
                {error?.key === "reason" && (
                  <small className="text-danger fs-10">{error.message}</small>
                )}
                {error?.key === "otherReason" && (
                  <small className="text-danger fs-10">{error.message}</small>
                )}
                <hr />
                <h6 className="text-center mt-1">
                  To Whomsoever It May Concern
                </h6>
                <div className="mt-4">
                  <h6>Organization Name *</h6>
                  <input
                    type="text"
                    className={`form-control col-12 ${
                      error?.key === "orgName" && "error-border"
                    }`}
                    name="form-question"
                    placeholder="Organization Name"
                    onChange={(e) => handleChange("orgName", e.target.value)}
                  />
                  {error?.key === "orgName" && (
                    <small className="text-danger fs-10">{error.message}</small>
                  )}
                </div>
                <div className="mt-4">
                  <h6>
                    Organization Located in *
                    <div className="text-secondary fs-10 fw-400 mt-1">
                      Organization address should match with its location{" "}
                    </div>
                  </h6>
                  <select
                    className="form-control font-small"
                    aria-label=".form-select-sm"
                    onChange={(e) =>
                      handleChange("orgLocation", e.target.value)
                    }
                  >
                    <option value="india" defaultValue>
                      India
                    </option>
                    <option value="abroad">Outside India</option>
                  </select>
                </div>
                <div className="mt-4">
                  <h6>Organization Address *</h6>
                  <textarea
                    className={`form-control fs-12 ${
                      error?.key === "orgAddress" && "error-border"
                    }`}
                    name="form-question"
                    placeholder="Complete Organization Address"
                    onChange={(e) => handleChange("orgAddress", e.target.value)}
                  />
                  {error?.key === "orgAddress" && (
                    <small className="text-danger fs-10">{error.message}</small>
                  )}
                </div>

                <div className="mt-4 d-none">
                  <h6>
                    Please describe the timeline of your symptoms, and the
                    details of your symptoms
                  </h6>
                  <textarea
                    className={`form-control fs-12 ${
                      error?.key === "reasonDescription" && "error-border"
                    }`}
                    name="form-question"
                    rows="4"
                    placeholder="Type here."
                    onChange={(e) =>
                      handleChange("reasonDescription", e.target.value)
                    }
                  />
                  {error?.key === "reasonDescription" && (
                    <small className="text-danger fs-10">{error.message}</small>
                  )}
                </div>
                {/* documents */}
                <div className="mt-4 d-none">
                  <h6>
                    Proof of Identification *
                    <div className="text-secondary fs-10 pt-2 fw-400">
                      Only PNG/JPEG formats supported like Driving license,
                      Aadhar, PAN, Passport etc.
                    </div>
                  </h6>
                  <div className="row container text-center pr-0">
                    <div className="col-12 col-sm-6 image-label">
                      {idFrontLoading && (
                        <i className="fa fa-spinner fa-spin" />
                      )}
                      <div
                        className={`${
                          (options.idFront || idFrontLoading) && "d-none"
                        }`}
                      >
                        <label htmlFor="idFront" className="pointer">
                          Front{" "}
                        </label>
                        <input
                          type="file"
                          className={`form-control-plaintext d-none ${
                            error?.key === "idFront" && "error-border"
                          }`}
                          id="idFront"
                          onChange={(e) =>
                            uploadImage(e.target.files[0], "idFront", e)
                          }
                          accept="image/*"
                        />
                      </div>
                      {options.idFront && !idFrontLoading && (
                        <div>
                          <button
                            className="btn btn-violet btn-sm"
                            onClick={(e) =>
                              deleteImage(options.idFront, "idFront", e)
                            }
                          >
                            Delete Image
                          </button>
                          <br />
                          <img
                            src={options.idFront}
                            className="camera-icon"
                            alt="Image"
                            width="100px"
                            height="100px"
                          />
                        </div>
                      )}
                    </div>
                    <div className="col-12 col-sm-6 image-label">
                      {idBackLoading && <i className="fa fa-spinner fa-spin" />}
                      <div
                        className={`${
                          (options.idBack || idBackLoading) && "d-none"
                        }`}
                      >
                        <label htmlFor="idBack" className="pointer">
                          Back{" "}
                        </label>
                        <input
                          type="file"
                          className={`form-control-plaintext d-none ${
                            error?.key === "idFront" && "error-border"
                          }`}
                          id="idBack"
                          onChange={(e) =>
                            uploadImage(e.target.files[0], "idBack", e)
                          }
                          accept="image/*"
                        />
                      </div>
                      {options.idBack && !idBackLoading && (
                        <div>
                          <button
                            className="btn btn-violet btn-sm"
                            onClick={(e) =>
                              deleteImage(options.idBack, "idBack", e)
                            }
                          >
                            Delete Image
                          </button>{" "}
                          <br />
                          <img
                            src={options.idBack}
                            className="camera-icon"
                            alt="Image"
                            width="100px"
                            height="100px"
                          />
                        </div>
                      )}
                    </div>
                  </div>
                  {error?.key === "idFront" && (
                    <small className="text-danger fs-10">{error.message}</small>
                  )}
                  {error?.key === "idBack" && (
                    <small className="text-danger fs-10">{error.message}</small>
                  )}
                </div>
                <div
                  className={`mt-4 pt-1 d-none ${
                    (!options.age || options.age > 18) && "d-none"
                  }`}
                >
                  <h6>
                    Are you a student?
                    <div className="text-secondary fs-10 pt-2 fw-400 d-none">
                      Get student discount with student ID proof.
                    </div>
                  </h6>
                  <div className="col-12 py-1 ml-1">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      name="isStudent"
                      id="isStudent"
                      onChange={(e) =>
                        handleChange("isStudent", !options.isStudent)
                      }
                    />
                    <label className="form-check-label" htmlFor="isStudent">
                      Yes, I'm student. Give me student discount.
                    </label>
                  </div>
                </div>
                {options.isStudent && (
                  <div className="mt-4">
                    <h6>
                      Student ID upload *
                      <div className="text-secondary fs-10 pt-2 fw-400">
                        Our admin team will verify all student requests
                        manually.
                      </div>
                    </h6>
                    <div className="text-center image-label">
                      {optionalDocLoading && (
                        <i className="fa fa-spinner fa-spin" />
                      )}
                      <div
                        className={`${
                          (options.studentIdDoc || optionalDocLoading) &&
                          "d-none"
                        }`}
                      >
                        <label htmlFor="studentIdDoc" className="pointer">
                          Browse File{" "}
                        </label>
                        <input
                          type="file"
                          className="form-control-plaintext d-none"
                          id="studentIdDoc"
                          onChange={(e) =>
                            uploadImage(e.target.files[0], "studentIdDoc", e)
                          }
                          accept="application/pdf,image/*"
                        />
                      </div>
                      {options.studentIdDoc && !optionalDocLoading && (
                        <div>
                          <button
                            className="btn btn-violet btn-sm"
                            onClick={(e) =>
                              deleteImage(
                                options.studentIdDoc,
                                "studentIdDoc",
                                e
                              )
                            }
                          >
                            Delete File
                          </button>{" "}
                          <br />
                          <object
                            data={options.studentIdDoc}
                            className="camera-icon"
                            width="100%"
                            height="150px"
                          />
                        </div>
                      )}
                    </div>
                  </div>
                )}
                <div className="text-center mt-5 d-none">
                  <h5>Workplace details</h5>
                </div>
                <small className="text-center text-secondary fs-10 d-none">
                  No medical certificate is issued as "Whomsoever It May
                  Concern" as the National Medical Council guidelines state that
                  a medical certificate has to be addressed to an organisation
                  (School/College/Company){" "}
                </small>
                <hr className="d-none" />
                <div className="mt-3 d-none">
                  <h6>Workplace name *</h6>
                  <input
                    type="text"
                    className={`form-control col-12 ${
                      error?.key === "orgName" && "error-border"
                    }`}
                    name="form-question"
                    placeholder="Workplace Name"
                    onChange={(e) => handleChange("orgName", e.target.value)}
                  />
                  {error?.key === "orgName" && (
                    <small className="text-danger fs-10">{error.message}</small>
                  )}
                </div>
                <div className="mt-4 d-none">
                  <h6>Workplace Address *</h6>
                  <textarea
                    className={`form-control fs-12 ${
                      error?.key === "orgAddress" && "error-border"
                    }`}
                    name="form-question"
                    placeholder="Complete Workplace Address"
                    onChange={(e) => handleChange("orgAddress", e.target.value)}
                  />
                  {error?.key === "orgAddress" && (
                    <small className="text-danger fs-10">{error.message}</small>
                  )}
                </div>
                <div className="text-center mt-5 d-none">
                  <h5>
                    Dates of the certificate <br />
                    <small className="text-secondary fs-10">
                      Future date certificates will not be issued.{" "}
                    </small>
                  </h5>
                </div>
                <hr className="d-none" />
                <div className="row mt-4 d-none">
                  <div className="col-12 col-sm-6">
                    <h6 htmlFor="form-question">Leave Date From *</h6>
                    <input
                      type="date"
                      className={`form-control ${
                        error?.key === "leaveDateFrom" && "error-border"
                      }`}
                      onChange={(e) =>
                        handleChange("leaveDateFrom", e.target.value)
                      }
                      max={moment().format("DD/MM/YYYY")}
                    />
                    {error?.key === "leaveDateFrom" && (
                      <small className="text-danger fs-10">
                        {error.message}
                      </small>
                    )}
                  </div>
                  <div className="col-12 col-sm-6 mt-4 mt-sm-0">
                    <h6 htmlFor="form-question">Leave Date To *</h6>
                    <input
                      type="date"
                      className={`form-control ${
                        error?.key === "leaveDateTo" && "error-border"
                      }`}
                      onChange={(e) =>
                        handleChange("leaveDateTo", e.target.value)
                      }
                      max={moment().format("DD/MM/YYYY")}
                    />
                    {error?.key === "leaveDateTo" && (
                      <small className="text-danger fs-10">
                        {error.message}
                      </small>
                    )}
                  </div>
                  <small className="text-secondary fs-10 p-3 d-none">
                    *Above 7 days of leave request, a prescription or reports is
                    mandatory for us to issue a medical certificate. Issuance of
                    the medical certificate and duration is based on the
                    discretion of the consulting doctor.
                  </small>
                </div>
                <hr className="mt-4" />
                {/* products */}
                <div className="text-orange-600 fw-500 mt-2 text-center">
                  Summer Offer: 25% discount on all products
                </div>
                <div className="mt-3">
                  <h6>My products *</h6>
                  <div className="row">
                    {products.map(
                      (product, idx) =>
                        product.display && (
                          <div key={idx} className="col-12 my-2">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="product"
                              id={`product-${idx}`}
                              onChange={(e) => handleChange("product", product)}
                              checked={options.product.name === product.name}
                            />
                            <label
                              className="form-check-label"
                              htmlFor={`product-${idx}`}
                            >
                              <div className="media">
                                <div className="media-body">
                                  <div className="fw-500">
                                    {product.name} {smallDevice && <div></div>}
                                    <div
                                      className={
                                        smallDevice ? "my-2" : "pull-right"
                                      }
                                    >
                                      {options.isStudent &&
                                        product.studentPrice.toFixed(2)}
                                      {options.orgLocation === "abroad" &&
                                        product.abroadPrice.toFixed(2)}
                                      {options.orgLocation !== "abroad" &&
                                        !options.isStudent &&
                                        product.price.toFixed(2)}{" "}
                                      {currency} &nbsp;
                                      <strike className="text-orange-600 fw-500 mt-2 fs-12">
                                        {options.orgLocation === "abroad"
                                          ? product.abroadTotalPrice.toFixed(2)
                                          : product.totalPrice.toFixed(2)}{" "}
                                        {currency}
                                      </strike>
                                    </div>
                                  </div>
                                  <div className="mt-1 fs-12">
                                    {product.description}
                                  </div>
                                </div>
                              </div>
                            </label>
                          </div>
                        )
                    )}
                  </div>
                  <div className="mt-4 ml-4">
                    <div className="form-question fs-12">
                      Have a Referral Code?
                    </div>
                    <div className="input-group col-12 col-sm-6 pl-0">
                      <input
                        type="text"
                        className={`form-control fs-12 ${
                          error?.key === "promocode" && "error-border"
                        }`}
                        placeholder="Referral code"
                        aria-label="Referral code"
                        aria-describedby="promo-code"
                        onChange={(e) =>
                          handleChange("promocode", e.target.value)
                        }
                        disabled={discountPrice}
                      />
                      <div
                        className="input-group-append"
                        onClick={(e) => verifyPromoCode()}
                      >
                        <span
                          className="btn btn-violet btn-sm px-3"
                          id="promo-code"
                          disabled={discountPrice}
                        >
                          {applyDiscount ? "Applied!" : "Apply"}
                        </span>
                      </div>
                    </div>
                    <small className="text-secondary fs-10">
                      e.g. MEDOC_20D
                    </small>
                    {error?.key === "promocode" && (
                      <div className="text-danger fs-10">{error.message}</div>
                    )}
                  </div>
                  <div>
                    <div className="pt-4 text-center">
                      <div
                        className={`alert alert-info fs-12 fade show ${
                          hidePrescriptionAlert && "d-none"
                        }`}
                        role="alert"
                      >
                        Some workplaces require prescription in addition to a
                        sick leave certificate. We recommend that you include
                        prescription if you are unsure about your workplace
                        policies.
                      </div>
                      <input
                        className="form-check-input ml-1 mt-2"
                        type="checkbox"
                        name="isPrescriptionRequired"
                        id="isPrescriptionRequired"
                        onChange={(e) =>
                          handleChange(
                            "isPrescriptionRequired",
                            !options.isPrescriptionRequired
                          )
                        }
                        checked={options.isPrescriptionRequired}
                      />
                      <label
                        className={`form-check-label ml-4 mt-1 text-justify ${
                          error?.key === "isPrescriptionRequired" &&
                          "error-color"
                        }`}
                        htmlFor="isPrescriptionRequired"
                      >
                        <small className="fw-500">
                          Get prescription for{" "}
                          {options.product.prescriptionPrice}
                          .00 INR/-
                        </small>
                      </label>{" "}
                      <i
                        className="fa fa-info-circle pointer text-violet"
                        onClick={(e) =>
                          setHidePrescriptionAlert(!hidePrescriptionAlert)
                        }
                      />
                    </div>
                  </div>
                  <div className="mt-2 text-center fs-16 fw-500">
                    Total cost:{" "}
                    <span className="text-violet">
                      {totalCost()}
                      {currency}
                      {discountPrice && options.product.price && (
                        <strike className="text-orange-600 pl-2">
                          {options.product.price.toFixed(2)} {currency}
                        </strike>
                      )}
                    </span>
                  </div>
                  {discountPrice && (
                    <div className="text-orange-600 text-center pt-3 fs-12">
                      Hurray! You saved extra{" "}
                      {applyDiscount.discountPercent * 100}%
                    </div>
                  )}
                </div>
              </div>
              <div
                className="complete-payment btn-violet-rounded px-3 py-2 text-center"
                onClick={saveCertificate}
              >
                {savingCertificate ? (
                  <i className="fa fa-spinner fa-spin" />
                ) : (
                  <span>Complete Payment</span>
                )}
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default CreatePostComponent;
