import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

import { update, uploadFile, removeFile } from "firebase_config";
import { Tables } from "constants/index";
import AddPaymentDetailsComponent from "../add_payment_details/component";

const UpdateUserComponent = ({ currentUser, userDetails, setUserDetails }) => {
  const [savingCertificate, setSavingCertificate] = useState(false);
  const [error, setError] = useState({
    message: "",
    key: "",
  });
  const [optionalDocLoading, setOptionalDocLoading] = useState(false);
  const [addPaymentDetails, setAddPaymentDetails] = useState(false);
  const history = useHistory();

  const handleChange = (key, value, additionalField) => {
    if (additionalField) {
      setUserDetails({
        ...userDetails,
        [key]: {
          ...userDetails[key],
          [additionalField]: value,
        },
      });
    } else {
      setUserDetails({
        ...userDetails,
        [key]: value,
      });
    }
  };

  const uploadImage = async (file, key, event) => {
    if (!file) {
      toast.error("New image required");
      return null;
    }
    setOptionalDocLoading(true);
    await uploadFile(file, "image", async (url, err) => {
      if (err) {
        alert(err);
        return null;
      }
      event.target.value = null;
      setUserDetails((prevState) => ({
        ...prevState,
        [key]: url,
      }));
      setOptionalDocLoading(false);
    });
  };

  const deleteImage = async (url, key, event) => {
    if (window.confirm("Are you sure to remove image?")) {
      setOptionalDocLoading(true);
      await removeFile(url);
      setOptionalDocLoading(false);
      setUserDetails((prevState) => ({
        ...prevState,
        [key]: "",
      }));
    }
  };

  const showErrors = () => {
    let error;
    if (!userDetails.displayName) {
      error = {
        message: "Full name is required",
        key: "displayName",
      };
    } else if (!userDetails.dob) {
      error = {
        message: "Date of birth is required",
        key: "dob",
      };
    } else if (!userDetails.age) {
      error = {
        message: "Age is required",
        key: "age",
      };
    } else if (!userDetails.email) {
      error = {
        message: "Email is required",
        key: "email",
      };
    } else if (!userDetails.mobile) {
      error = {
        message: "Mobile number is required",
        key: "mobile",
      };
    } else if (userDetails.mobile && userDetails.mobile.length !== 12) {
      error = {
        message: "Mobile number must contain 12-digits",
        key: "mobile",
      };
    } else if (userDetails.address && !userDetails.address.door) {
      error = {
        message: "Door number is required",
        key: "door",
      };
    } else if (userDetails.address && !userDetails.address.streetName) {
      error = {
        message: "Street name is required",
        key: "streetName",
      };
    } else if (userDetails.address && !userDetails.address.city) {
      error = {
        message: "City is required",
        key: "city",
      };
    } else if (userDetails.address && !userDetails.address.state) {
      error = {
        message: "State is required",
        key: "state",
      };
    } else if (userDetails.address && !userDetails.address.zipcode) {
      error = {
        message: "Postal / Zip code is required ",
        key: "zipcode",
      };
    } else if (!userDetails.qualification) {
      error = {
        message: "Qualification is required",
        key: "qualification",
      };
    } else if (!userDetails.nmcNumber) {
      error = {
        message: "NMC Number is required",
        key: "nmcNumber",
      };
    } else if (!userDetails.signature) {
      error = {
        message: "Signature is required",
        key: "signature",
      };
    } else if (!userDetails.photoURL) {
      error = {
        message: "Profile Picture is required",
        key: "photoURL",
      };
    } else if (!userDetails.stamp) {
      error = {
        message: "Stamp is required",
        key: "stamp",
      };
    } else if (!userDetails.pay) {
      error = {
        message: "Pay is required",
        key: "pay",
      };
    } else if (!userDetails.payWithPrescription) {
      error = {
        message: "Pay with prescription is required",
        key: "payWithPrescription",
      };
    }
    setError(error);
    return error;
  };

  const saveUser = async () => {
    const err = await showErrors();
    if (err && err.message) {
      toast.error(err.message);
      return;
    }
    setSavingCertificate(true);

    if (userDetails.newPayment && !userDetails.payments) {
      userDetails.payments = [userDetails.newPayment];
    } else if (userDetails.newPayment && userDetails.payments) {
      userDetails.payments.push(userDetails.newPayment);
    }
    delete userDetails.newPayment;

    const res = await update(Tables.USERS, userDetails.id, userDetails);

    if (res.status === 200) {
      toast.success(`Request updated. ${userDetails.id}`);
      history.goBack();
    } else {
      setError("Something went wrong. Refresh the page");
    }
    setSavingCertificate(false);
  };

  return (
    <>
      <div className="dashboard-content">
        <div className="container create-post-wrapper mt-0 pt-0">
          {userDetails && (
            <>
              <div className="card p-2 p-sm-4 my-sm-5">
                <div className="col-12 col-sm-6 mt-4">
                  <h6>
                    Full name * <br />
                    <small className="text-secondary fs-10">
                      *As per Govt ID proof
                    </small>
                  </h6>
                  <input
                    type="text"
                    className={`form-control font-small ${
                      error?.key === "displayName" && "error-border"
                    }`}
                    name="form-question"
                    placeholder="Full name"
                    value={userDetails.displayName}
                    onChange={(e) =>
                      handleChange("displayName", e.target.value)
                    }
                  />
                  {error?.key === "displayName" ? (
                    <small className="text-danger fs-10">{error.message}</small>
                  ) : (
                    <small className="text-secondary fs-10">Full name</small>
                  )}
                </div>
                <div className="col-12 col-sm-6 mt-4">
                  <h6>Qualification *</h6>
                  <input
                    type="text"
                    className={`form-control font-small ${
                      error?.key === "qualification" && "error-border"
                    }`}
                    name="form-question"
                    placeholder="Qualification"
                    value={userDetails.qualification}
                    onChange={(e) =>
                      handleChange("qualification", e.target.value)
                    }
                  />
                  {error?.key === "qualification" && (
                    <small className="text-danger fs-10">{error.message}</small>
                  )}
                </div>
                <div className="col-12 col-sm-6 mt-4">
                  <h6 htmlFor="form-question">NMC Number *</h6>
                  <input
                    type="number"
                    className={`form-control ${
                      error?.key === "nmcNumber" && "error-border"
                    }`}
                    name="form-question"
                    placeholder="1234566"
                    value={userDetails.nmcNumber}
                    onChange={(e) => handleChange("nmcNumber", e.target.value)}
                  />
                  {error?.key === "mobile" && (
                    <div className="text-danger fs-10">{error.message}</div>
                  )}
                </div>
                <div className="row mt-4">
                  <div className="col-12 col-sm-6">
                    <h6>Date of birth *</h6>
                    <input
                      type="date"
                      className={`form-control ${
                        error?.key === "dob" && "error-border"
                      }`}
                      name="form-question"
                      placeholder="dob"
                      value={userDetails.dob}
                      onChange={(e) => handleChange("dob", e.target.value)}
                    />
                    {error?.key === "dob" && (
                      <small className="text-danger fs-10">
                        {error.message}
                      </small>
                    )}
                  </div>
                  <div className="col-12 col-sm-6 mt-3 mt-sm-0">
                    <h6>Age *</h6>
                    <input
                      type="number"
                      className={`form-control ${
                        error?.key === "age" && "error-border"
                      }`}
                      name="form-question"
                      placeholder="Age"
                      value={userDetails.age}
                      onChange={(e) => handleChange("age", e.target.value)}
                    />
                    {error?.key === "age" && (
                      <small className="text-danger fs-10">
                        {error.message}
                      </small>
                    )}
                  </div>
                </div>
                <div className="mt-4">
                  <h6>Gender *</h6>
                  <select
                    className="form-control font-small"
                    aria-label=".form-select-sm"
                    onChange={(e) => handleChange("gender", e.target.value)}
                  >
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                  </select>
                </div>
                <div className="row mt-4">
                  <div className="col-12 col-sm-6">
                    <h6 htmlFor="form-question">Email *</h6>
                    <input
                      type="email"
                      className={`form-control ${
                        error?.key === "email" && "error-border"
                      }`}
                      name="form-question"
                      placeholder="Email"
                      value={userDetails.email}
                      onChange={(e) => handleChange("email", e.target.value)}
                    />
                    {error?.key === "email" && (
                      <small className="text-danger fs-10">
                        {error.message}
                      </small>
                    )}
                  </div>
                  <div className="col-12 col-sm-6 mt-4 mt-sm-0">
                    <h6 htmlFor="form-question">Mobile *</h6>
                    <input
                      type="number"
                      className={`form-control ${
                        error?.key === "mobile" && "error-border"
                      }`}
                      name="form-question"
                      placeholder="917890789090"
                      value={userDetails.mobile}
                      onChange={(e) => handleChange("mobile", e.target.value)}
                    />
                    <small className="text-secondary fs-10">
                      e.g. 917890789078
                    </small>
                    {error?.key === "mobile" && (
                      <div className="text-danger fs-10">{error.message}</div>
                    )}
                  </div>
                </div>
                <div className="mt-4">
                  <h6>
                    Address *<br />
                    <small className="text-secondary fs-10 d-none">
                      *As per Govt ID proof
                    </small>
                  </h6>
                  <div className="row">
                    <div className="col-12 col-sm-6 my-2">
                      <input
                        type="text"
                        className={`form-control ${
                          error?.key === "door" && "error-border"
                        }`}
                        name="form-question"
                        placeholder="Door no."
                        value={userDetails.address && userDetails.address.door}
                        onChange={(e) =>
                          handleChange("address", e.target.value, "door")
                        }
                      />
                      {error?.key === "door" ? (
                        <small className="text-danger fs-10">
                          {error.message}
                        </small>
                      ) : (
                        <small className="text-secondary fs-10">
                          Door number
                        </small>
                      )}
                    </div>
                    <div className="col-12 col-sm-6 my-2">
                      <input
                        type="text"
                        className={`form-control ${
                          error?.key === "streetName" && "error-border"
                        }`}
                        name="form-question"
                        placeholder="Street name"
                        value={
                          userDetails.address && userDetails.address.streetName
                        }
                        onChange={(e) =>
                          handleChange("address", e.target.value, "streetName")
                        }
                      />
                      {error?.key === "streetName" ? (
                        <small className="text-danger fs-10">
                          {error.message}
                        </small>
                      ) : (
                        <small className="text-secondary fs-10">
                          Street name
                        </small>
                      )}
                    </div>
                    <div className="col-12 col-sm-6 my-2">
                      <input
                        type="text"
                        className={`form-control ${
                          error?.key === "city" && "error-border"
                        }`}
                        name="form-question"
                        placeholder="City"
                        value={userDetails.address && userDetails.address.city}
                        onChange={(e) =>
                          handleChange("address", e.target.value, "city")
                        }
                      />
                      {error?.key === "city" ? (
                        <small className="text-danger fs-10">
                          {error.message}
                        </small>
                      ) : (
                        <small className="text-secondary fs-10">City</small>
                      )}
                    </div>
                    <div className="col-12 col-sm-6 my-2">
                      <input
                        type="text"
                        className={`form-control ${
                          error?.key === "state" && "error-border"
                        }`}
                        name="form-question"
                        placeholder="State / Region / Province"
                        value={userDetails.address && userDetails.address.state}
                        onChange={(e) =>
                          handleChange("address", e.target.value, "state")
                        }
                      />
                      {error?.key === "state" ? (
                        <small className="text-danger fs-10">
                          {error.message}
                        </small>
                      ) : (
                        <small className="text-secondary fs-10">
                          State / Region / Province
                        </small>
                      )}
                    </div>
                    <div className="col-12 col-sm-6 my-2">
                      <input
                        type="text"
                        className={`form-control ${
                          error?.key === "zipcode" && "error-border"
                        }`}
                        name="form-question"
                        placeholder="Postal/Zip Code"
                        value={
                          userDetails.address && userDetails.address.zipcode
                        }
                        onChange={(e) =>
                          handleChange("address", e.target.value, "zipcode")
                        }
                      />
                      {error?.key === "zipcode" ? (
                        <small className="text-danger fs-10">
                          {error.message}
                        </small>
                      ) : (
                        <small className="text-secondary fs-10">
                          Postal / Zip code
                        </small>
                      )}
                    </div>
                    <div className="col-12 col-sm-6 my-2">
                      <select
                        className="form-control font-small"
                        aria-label=".form-select-sm"
                        value={
                          userDetails.address && userDetails.address.country
                        }
                        onChange={(e) =>
                          handleChange("address", e.target.value, "country")
                        }
                      >
                        <option value="india" defaultValue>
                          India
                        </option>
                      </select>
                      <small className="text-secondary fs-10">Country</small>
                    </div>
                  </div>
                </div>

                {/* medical problem */}
                <div className="mt-4">
                  <h6>Bio</h6>
                  <textarea
                    className={`form-control fs-12 ${
                      error?.key === "bio" && "error-border"
                    }`}
                    name="form-question"
                    rows="4"
                    placeholder="Introduce Yourself."
                    value={userDetails.bio}
                    onChange={(e) => handleChange("bio", e.target.value)}
                  />
                  {error?.key === "bio" && (
                    <small className="text-danger fs-10">{error.message}</small>
                  )}
                </div>
                {/* documents */}
                <div className="mt-4">
                  <h6>
                    Profile Pic
                    <div className="text-secondary fs-10 pt-2 fw-400">
                      Only Images Supported
                    </div>
                  </h6>
                  <div className="text-center image-label">
                    {optionalDocLoading && (
                      <i className="fa fa-spinner fa-spin" />
                    )}
                    <div
                      className={`${
                        (userDetails.photoURL || optionalDocLoading) && "d-none"
                      }`}
                    >
                      <label htmlFor="photoURL" className="pointer">
                        Browse File{" "}
                      </label>
                      <input
                        type="file"
                        className="form-control-plaintext d-none"
                        id="photoURL"
                        onChange={(e) =>
                          uploadImage(e.target.files[0], "photoURL", e)
                        }
                        accept="image/*"
                      />
                    </div>
                    {userDetails.photoURL && !optionalDocLoading && (
                      <div>
                        <button
                          className="btn btn-violet btn-sm"
                          onClick={(e) =>
                            deleteImage(userDetails.photoURL, "photoURL", e)
                          }
                        >
                          Delete File
                        </button>{" "}
                        <br />
                        <object
                          data={userDetails.photoURL}
                          className="camera-icon"
                          width="100%"
                          height="150px"
                        />
                      </div>
                    )}
                  </div>
                </div>
                {currentUser.isAdmin && (
                  <>
                    <div className="mt-4">
                      <h6>
                        Signature
                        <div className="text-secondary fs-10 pt-2 fw-400">
                          Only Images Supported
                        </div>
                      </h6>
                      <div className="text-center image-label">
                        {optionalDocLoading && (
                          <i className="fa fa-spinner fa-spin" />
                        )}
                        <div
                          className={`${
                            (userDetails.signature || optionalDocLoading) &&
                            "d-none"
                          }`}
                        >
                          <label htmlFor="signature" className="pointer">
                            Browse File{" "}
                          </label>
                          <input
                            type="file"
                            className="form-control-plaintext d-none"
                            id="signature"
                            onChange={(e) =>
                              uploadImage(e.target.files[0], "signature", e)
                            }
                            accept="image/*"
                          />
                        </div>
                        {userDetails.signature && !optionalDocLoading && (
                          <div>
                            <button
                              className="btn btn-violet btn-sm"
                              onClick={(e) =>
                                deleteImage(
                                  userDetails.signature,
                                  "signature",
                                  e
                                )
                              }
                            >
                              Delete File
                            </button>{" "}
                            <br />
                            <object
                              data={userDetails.signature}
                              className="camera-icon"
                              width="100%"
                              height="150px"
                            />
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="mt-4">
                      <h6>
                        Stamp
                        <div className="text-secondary fs-10 pt-2 fw-400">
                          Only Images Supported
                        </div>
                      </h6>
                      <div className="text-center image-label">
                        {optionalDocLoading && (
                          <i className="fa fa-spinner fa-spin" />
                        )}
                        <div
                          className={`${
                            (userDetails.stamp || optionalDocLoading) &&
                            "d-none"
                          }`}
                        >
                          <label htmlFor="stamp" className="pointer">
                            Browse File{" "}
                          </label>
                          <input
                            type="file"
                            className="form-control-plaintext d-none"
                            id="stamp"
                            onChange={(e) =>
                              uploadImage(e.target.files[0], "stamp", e)
                            }
                            accept="image/*"
                          />
                        </div>
                        {userDetails.stamp && !optionalDocLoading && (
                          <div>
                            <button
                              className="btn btn-violet btn-sm"
                              onClick={(e) =>
                                deleteImage(userDetails.stamp, "stamp", e)
                              }
                            >
                              Delete File
                            </button>{" "}
                            <br />
                            <object
                              data={userDetails.stamp}
                              className="camera-icon"
                              width="100%"
                              height="150px"
                            />
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="col-12 mt-4">
                      <h6 htmlFor="form-question">Withdraw Amount *</h6>
                      <input
                        type="number"
                        className={`form-control ${
                          error?.key === "withdrawAmount" && "error-border"
                        }`}
                        name="form-question"
                        value={userDetails.withdrawAmount}
                        onChange={(e) =>
                          handleChange("withdrawAmount", +e.target.value)
                        }
                      />
                    </div>
                    <div className="col-12 mt-4">
                      <h6 htmlFor="form-question">Consultation Pay *</h6>
                      <input
                        type="number"
                        className={`form-control ${
                          error?.key === "pay" && "error-border"
                        }`}
                        name="form-question"
                        placeholder="70"
                        value={userDetails.pay}
                        onChange={(e) => handleChange("pay", +e.target.value)}
                      />
                      {error?.key === "pay" && (
                        <div className="text-danger fs-10">{error.message}</div>
                      )}
                    </div>
                    <div className="col-12 mt-4">
                      <h6 htmlFor="form-question">
                        Consultation Pay + Prescription *
                      </h6>
                      <input
                        type="number"
                        className={`form-control ${
                          error?.key === "payWithPrescription" && "error-border"
                        }`}
                        name="form-question"
                        placeholder="100"
                        value={userDetails.payWithPrescription}
                        onChange={(e) =>
                          handleChange("payWithPrescription", +e.target.value)
                        }
                      />
                      {error?.key === "pay" && (
                        <div className="text-danger fs-10">{error.message}</div>
                      )}
                    </div>
                    <hr />

                    {addPaymentDetails ? (
                      <AddPaymentDetailsComponent
                        currentUser={currentUser}
                        userDetails={userDetails}
                        handleChange={handleChange}
                        error={error}
                      />
                    ) : (
                      <button
                        className="btn btn-violet"
                        onClick={(e) =>
                          setAddPaymentDetails(!addPaymentDetails)
                        }
                      >
                        Add Payment
                      </button>
                    )}
                  </>
                )}
              </div>
              <div
                className="complete-payment btn btn-violet text-center"
                onClick={saveUser}
              >
                {savingCertificate ? (
                  <i className="fa fa-spinner fa-spin" />
                ) : (
                  <span>Save Record</span>
                )}
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default UpdateUserComponent;
