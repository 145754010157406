import React, { useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import "./style.css";

import HeaderComponent from "./header";
import AdminZoneComponent from "./admin_zone/component";
import { signout, update, getId } from "firebase_config";
import { SetUser, SetLoggedIn, SetDbUser } from "store/actions";
import { Tables, Routes, ENV } from "constants/index";
import CallDoctorPopupComponent from "components/call_doctor_popup/component";

function SettingsComponent({
  currentUser,
  bindLoggedIn,
  bindUser,
  bindDbUser,
}) {
  const history = useHistory();
  const isPublicDoctor = currentUser.roles.find((role) => role === "public");

  useEffect(async () => {
    const dbUser = await getId(Tables.USERS, currentUser.id);
    await bindDbUser({ ...dbUser, id: currentUser.id });
  }, []);

  const signoutUser = async () => {
    await signout();
    await bindLoggedIn(false);
    await bindDbUser(null);
    await bindUser(null);
    sessionStorage.clear();
    history.push("/");
  };

  const deactivateUser = async () => {
    if (window.confirm("Are you sure to deactivate?")) {
      const res = await update("users", currentUser.id, { deactivate: true });
      await bindDbUser({ ...currentUser, deactivate: true });
      if (res.status === 200) {
        alert("Successfully deactivated");
        await signoutUser();
      } else {
        alert("Successfully went wrong. Try later.");
      }
    }
  };

  const deleteUser = async () => {
    alert(
      "To delete your account, please give us an email at team@perlainfotech.com"
    );
    // await remove('users', dbUser.id)
    // await removeProfile()
    // history.push('/profile_deleted')
  };

  const comeBackAlert = async () => {
    alert("We work hard for you here. Come back later please.");
    // await remove('users', dbUser.id)
    // await removeProfile()
    // history.push('/profile_deleted')
  };

  const contactUs = async () => {
    alert("Contact us at team@perlainfotech.com");
  };

  const inviteFriend = async () => {
    alert("Share this app link - https://paygames.uk");
  };

  const handlePrivateAcc = async () => {
    const res = await update("users", currentUser.id, {
      private: !currentUser.private,
    });
    await bindDbUser({ ...currentUser, private: !currentUser.private });
    if (res.status !== 200) {
      alert("Successfully went wrong. Try later.");
    }
  };

  // const installPrompt = () => {
  //   deferredPrompt.prompt()
  //   deferredPrompt.userChoice().then((choiceResult) => {
  //     if (choiceResult.outcome === 'accepted') {
  //       console.log('User accepted A2HS prompt')
  //     } else {
  //       console.log('User denied A2HS prompt')
  //     }
  //     deferredPrompt = null
  //   })
  // }

  const reload = () => window.location.reload();

  return (
    <div className="dashboard-content">
      <HeaderComponent />
      <div className="container settings-wrapper desktop-align-center pt-5">
        <CallDoctorPopupComponent />
        <div className="section">
          <div className="section-header">Personal zone</div>
          <ul className="section-list">
            <li className="d-none">
              <div className="d-flex flex-row align-items-center justify-content-between">
                <span className="option-name" htmlFor="customSwitch1">
                  Private account
                </span>
                <div className="custom-control custom-switch">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id="private"
                    onChange={handlePrivateAcc}
                    checked={currentUser.private || false}
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="private"
                  ></label>
                </div>
              </div>
            </li>
            <li>
              <Link
                to="/app/requests"
                className="d-flex flex-row align-items-center justify-content-between"
              >
                <span className="option-name">My Certificates</span>
                <i className="option-name fa fa-arrow-right pull-right pl-2" />
              </Link>
            </li>
            <li className={currentUser.isAdmin ? "section" : "d-none"}>
              <Link
                to={Routes.APP_DC_PUBLIC_APPOINTMENTS}
                className="d-flex flex-row align-items-center justify-content-between"
              >
                <span className="option-name">All Appointments</span>
                <i className="option-name fa fa-arrow-right pull-right pl-2" />
              </Link>
            </li>
            <li>
              <Link
                to="/app/settings/edit_profile"
                className="d-flex flex-row align-items-center justify-content-between"
              >
                <span className="option-name">Edit profile</span>
              </Link>
            </li>
            <li className={isPublicDoctor ? "section" : "d-none"}>
              <Link
                to={Routes.APP_EDIT_VIRTUAL_CLINIC}
                className="d-flex flex-row align-items-center justify-content-between"
              >
                <span className="option-name">
                  Virtual Clinic{" "}
                  <span className="badge badge-success">NEW</span>
                </span>
              </Link>
            </li>
            <li className={currentUser.isDoctor ? "d-none" : "section"}>
              <Link
                to={Routes.APP_GUIDELINES_SLC}
                className="d-flex flex-row align-items-center justify-content-between"
              >
                <span className="option-name">Certificate Guidelines</span>
              </Link>
            </li>
            <li>
              <a
                href="https://www.trustpilot.com/evaluate/medoc.life"
                target="_blank"
                rel="noopener noreferrer"
                className="text-black"
              >
                <span className="option-name">Leave a review</span>
              </a>
            </li>
            <li className="d-none">
              <Link
                to="/app/settings/change_password"
                className="d-flex flex-row align-items-center justify-content-between"
              >
                <span className="option-name">Change password</span>
              </Link>
            </li>
            <li className="d-none">
              <Link
                to="/app/wallet_settings"
                className="d-flex flex-row align-items-center justify-content-between"
              >
                <span className="option-name">Wallet settings</span>
              </Link>
            </li>
            <li className={!currentUser.isDoctor && "d-none"}>
              <Link
                to={Routes.APP_SETTINGS_ANALYTICS}
                className="d-flex flex-row align-items-center justify-content-between"
              >
                <span className="option-name">Monthly Stats</span>
                <span className="option-name">
                  {currentUser.withdrawAmount > 0
                    ? currentUser.withdrawAmount.toFixed(2)
                    : "0.00"}{" "}
                  {ENV.CURRENCY}
                </span>
              </Link>
            </li>
          </ul>
        </div>
        <div className="d-none">
          <div className="section-header">
            Money zone
            <span className="pl-2 lead pointer pull-right" onClick={reload}>
              &#x21bb;
            </span>
          </div>
          <ul className="section-list">
            <li className="d-none">
              <Link
                to="/app/recharge"
                className="d-flex flex-row align-items-center justify-content-between"
              >
                <span className="option-name">Deposit</span>
                <span className="option-name text-violet">
                  {ENV.CURRENCY}
                  {currentUser.depositAmount > 0
                    ? currentUser.depositAmount.toFixed(2)
                    : "0.00"}
                </span>
              </Link>
            </li>
            <li>
              <Link
                to="/app/settings"
                className="d-flex flex-row align-items-center justify-content-between"
              >
                <span className="option-name">Withdrawable</span>
                <span className="option-name">
                  {currentUser.withdrawAmount > 0
                    ? currentUser.withdrawAmount.toFixed(2)
                    : "0.00"}{" "}
                  {ENV.CURRENCY}
                </span>
              </Link>
            </li>
            <li className="d-none">
              <Link
                to="/app/change_passcode"
                className="d-flex flex-row align-items-center justify-content-between"
              >
                <span className="option-name">Change passcode</span>
              </Link>
            </li>
            <li className="d-none">
              <div className="d-flex flex-row align-items-center justify-content-between">
                <span className="option-name" htmlFor="customSwitch1">
                  Freeze wallet
                </span>
                <div className="custom-control custom-switch">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id="private"
                    onChange={handlePrivateAcc}
                    checked={currentUser.private || false}
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="private"
                  ></label>
                </div>
              </div>
            </li>
          </ul>
        </div>
        <div className="section d-none">
          <div className="section-header">Support zone</div>
          <ul className="section-list">
            <li>
              <Link
                to={Routes.APP_REFER_FRIENDS}
                className="d-flex flex-row align-items-center justify-content-between"
              >
                <div className="option-name">
                  Refer friends <br />
                  <span className="fs-12">
                    Earn <span className="fw-600 text-violet">50 INR/-</span>{" "}
                    for every successful referral &#127881;
                  </span>
                </div>
                <div className="btn btn-violet-rounded btn-sm fs-12">
                  Get Code
                </div>
              </Link>
            </li>
          </ul>
        </div>
        <div className="section d-none">
          <div className="section-header">Legal zone</div>
          <ul className="section-list">
            <li>
              <Link to="/app/settings" className="row" onClick={comeBackAlert}>
                <img
                  src={require("assets/svgs/Terms.svg").default}
                  className="col-2"
                  alt="Terms"
                />
                <span className="option-name col-8">Terms of service</span>
                <img
                  src={require("assets/svgs/AngleRight.svg").default}
                  className="option-open col-2"
                  alt="Open"
                />
              </Link>
            </li>
            <li>
              <Link to="/app/settings" className="row" onClick={comeBackAlert}>
                <img
                  src={require("assets/svgs/PrivacyPolicy.svg").default}
                  className="scale-1-4 col-2"
                  alt="PrivacyPolicy"
                />
                <span className="option-name col-8">Privacy policy</span>
                <img
                  src={require("assets/svgs/AngleRight.svg").default}
                  className="option-open col-2"
                  alt="Open"
                />
              </Link>
            </li>
            <li>
              <Link to="/app/settings" className="row" onClick={comeBackAlert}>
                <img
                  src={require("assets/svgs/UserAgreement.svg").default}
                  className="scale-1-4 col-2"
                  alt="UserAgreement"
                />
                <span className="option-name col-8">User agreement</span>
                <img
                  src={require("assets/svgs/AngleRight.svg").default}
                  className="option-open col-2"
                  alt="Open"
                />
              </Link>
            </li>
            <li>
              <Link to="/app/settings" className="row" onClick={comeBackAlert}>
                <img
                  src={require("assets/svgs/DataPrivacy.svg").default}
                  className="col-2"
                  alt="DataPrivacy"
                />
                <span className="option-name col-8">Data policy</span>
                <img
                  src={require("assets/svgs/AngleRight.svg").default}
                  className="option-open col-2"
                  alt="Open"
                />
              </Link>
            </li>
          </ul>
        </div>
        <div className="section d-none">
          <div className="section-header">Support Zone</div>
          <ul className="section-list">
            <li>
              <Link to="/app/settings" className="row">
                <img
                  src={require("assets/svgs/Workflow.svg").default}
                  className="scale-0-9 col-2"
                  alt="Workflow"
                />
                <span className="option-name col-8 bn">Install app</span>
                <img
                  src={require("assets/svgs/AngleRight.svg").default}
                  className="option-open col-2 d-none"
                  alt="Open"
                />
              </Link>
            </li>
            <li>
              <Link to="/app/settings" className="row" onClick={inviteFriend}>
                <img
                  src={require("assets/svgs/Workflow.svg").default}
                  className="scale-0-9 col-2"
                  alt="Workflow"
                />
                <span className="option-name col-8">Invite my friends</span>
                <img
                  src={require("assets/svgs/AngleRight.svg").default}
                  className="option-open col-2 d-none"
                  alt="Open"
                />
              </Link>
            </li>
            <li className="">
              <Link to="/app/settings" className="row" onClick={comeBackAlert}>
                <img
                  src={require("assets/svgs/Workflow.svg").default}
                  className="scale-0-9 col-2"
                  alt="Workflow"
                />
                <span className="option-name col-8">App workflow</span>
                <img
                  src={require("assets/svgs/AngleRight.svg").default}
                  className="option-open col-2"
                  alt="Open"
                />
              </Link>
            </li>
            <li className="">
              <Link to="/app/settings" className="row" onClick={comeBackAlert}>
                <img
                  src={require("assets/svgs/Faq.svg").default}
                  className="scale-1-1 col-2"
                  alt="Faq"
                />
                <span className="option-name col-8">Faq's</span>
                <img
                  src={require("assets/svgs/AngleRight.svg").default}
                  className="option-open col-2"
                  alt="Open"
                />
              </Link>
            </li>
            <li>
              <Link to="/app/settings" className="row" onClick={contactUs}>
                <img
                  src={require("assets/svgs/Support.svg").default}
                  className="scale-1-3 col-2"
                  alt="Support"
                />
                <span className="option-name col-8">Contact us</span>
                <img
                  src={require("assets/svgs/AngleRight.svg").default}
                  className="option-open col-2 d-none"
                  alt="Open"
                />
              </Link>
            </li>
          </ul>
        </div>
        <div className="section">
          <div className="section-header">Cautious zone</div>
          <ul className="section-list">
            <li>
              <div className="d-flex flex-row align-items-center justify-content-between">
                <span
                  className="option-name"
                  htmlFor="customSwitch1"
                  onClick={signoutUser}
                >
                  Logout
                </span>
              </div>
            </li>
            <li className="d-none">
              <div className="d-flex flex-row align-items-center justify-content-between">
                <span
                  className="option-name"
                  htmlFor="customSwitch1"
                  onClick={deactivateUser}
                >
                  Deactivate account
                </span>
              </div>
            </li>
            <li onClick={deleteUser} className="d-none">
              <div className="row pointer">
                <img
                  src={require("assets/svgs/DeleteAccount.svg").default}
                  className="scale-0-9 col-2"
                  alt="DeleteAccount"
                />
                <span className="option-name col-8">Delete account</span>
                <img
                  src={require("assets/svgs/AngleRight.svg").default}
                  className="option-open col-2 d-none"
                  alt="Open"
                />
              </div>
            </li>
          </ul>
        </div>
        {currentUser.admin && <AdminZoneComponent currentUser={currentUser} />}
      </div>
    </div>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    bindLoggedIn: (content) => dispatch(SetLoggedIn(content)),
    bindUser: (content) => dispatch(SetUser(content)),
    bindDbUser: (content) => dispatch(SetDbUser(content)),
  };
};

export default connect(null, mapDispatchToProps)(SettingsComponent);
