import React from "react";

import { capitalizeFirstLetter } from "helpers";

const CompleteDetailsComponent = ({ currentUser, options, doctorDetails }) => {
  const favicon =
    "https://storage.googleapis.com/medoc-live.appspot.com/images/favicon.png";
  return (
    <>
      <div>
        <h6 className="mt-5 text-center">Assigned to:</h6>
        {doctorDetails ? (
          <div className="d-flex flex-row align-items-center mt-2 justify-content-center">
            <img
              src={doctorDetails.photoURL || favicon}
              className="doctor-img"
              alt="Doctor photo"
            />
            <div>
              <div className="fw-500">Dr. {doctorDetails.displayName}</div>
              <div className="fs-12">
                {doctorDetails.qualification} | Reg. No.{" "}
                {doctorDetails.nmcNumber}
              </div>
              <div className="fs-12">{doctorDetails.email}</div>
              <div className="fs-12">ID: {options.doctorId}</div>
            </div>
          </div>
        ) : (
          <h6 className="text-center">Not Yet Assigned</h6>
        )}
      </div>
      <div>
        <div className="mt-3 text-center">
          <div className="font-small fw-500 text-secondary">
            Certificate Type
          </div>
          <div>
            {options.type
              ? capitalizeFirstLetter(options.type.replace(/_/g, " "))
              : "Sick leave certificate"}
          </div>
        </div>
        <div className="mt-3 text-center">
          <div className="font-small fw-500 text-secondary">
            Purchased product
          </div>
          {options.product.name}
        </div>
      </div>
      {options.certLink && (
        <div className="mt-3 text-center">
          <div className="font-small fw-500 text-secondary">
            Certificate Link
          </div>
          <a
            href={options.certLink}
            target="_blank"
            rel="noopener noreferrer"
            className="text-violet fw-500"
          >
            Download here <i className="fa fa-arrow-circle-o-down" />
          </a>
        </div>
      )}
      {options.prescriptionLink && (
        <div className="mt-3 text-center">
          <div className="font-small fw-500 text-secondary">
            Prescription Link
          </div>
          <a
            href={options.prescriptionLink}
            target="_blank"
            rel="noopener noreferrer"
            className="text-violet fw-500"
          >
            Download here <i className="fa fa-arrow-circle-o-down" />
          </a>
        </div>
      )}
    </>
  );
};

export default CompleteDetailsComponent;
