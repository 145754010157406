import React, { useState } from "react";
import { toast } from "react-toastify";

import { LoadingComponent } from "components";
import { post } from "services";

const CheckPaymentStatusComponent = ({ currentUser, request }) => {
  const [loading, setLoading] = useState(false);
  const [paymentId, setPaymentId] = useState("");

  const checkStatus = async () => {
    if (
      !paymentId ||
      paymentId.length !== 18 ||
      !paymentId.startsWith("pay_L")
    ) {
      toast.error("Invalid Payment Id");
      return;
    }
    setLoading(true);
    const res = await post("/razorpay/payment_status", {
      requestId: request.id,
      paymentId,
    });

    console.log("res", res);
    if (res.status === 201) {
      toast.success(res.data.message);
    }
    setLoading(false);
  };

  return (
    <>
      <div className="input-group input-group-sm">
        <input
          type="text"
          className="form-control"
          placeholder="Payment Id"
          onChange={(e) => setPaymentId(e.target.value)}
        />
        {loading ? (
          <LoadingComponent />
        ) : (
          <div className="input-group-append pointer" onClick={checkStatus}>
            <span className="input-group-text">Check Payment Status</span>
          </div>
        )}
      </div>
    </>
  );
};

export default CheckPaymentStatusComponent;
