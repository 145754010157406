import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";

import { getQuery, firestore } from "firebase_config";
import {
  StaticHeaderComponent,
  StaticFooterComponent,
  LoadingComponent,
} from "components";
import { Tables } from "constants/index";

const ShowDocumentComponent = (props) => {
  const [smallDevice, setSmallDevice] = useState(false);
  const [loading, setLoading] = useState(false);
  const [shortLinkObject, setShortLinkObject] = useState("");

  useEffect(() => {
    if (window.innerWidth <= 576) {
      setSmallDevice(true);
    } else {
      setSmallDevice(false);
    }
  });

  useEffect(async () => {
    const { params } = props.match;
    if (params.shortCode) {
      setLoading(true);
      const obj = await getQuery(
        firestore
          .collection(Tables.SHORT_LINKS)
          .where("code", "==", params.shortCode)
          .get()
      );
      if (obj[0]) {
        setShortLinkObject(obj[0]);
      }
      setLoading(false);
    }
  }, []);

  return (
    <div className="home-wrapper">
      <StaticHeaderComponent smallDevice={smallDevice} />
      {loading ? (
        <LoadingComponent />
      ) : (
        <div className="mt-5 pt-5 text-center">
          {shortLinkObject ? (
            <object
              data={shortLinkObject.link}
              width="80%"
              height="500px"
              type="application/pdf"
            >
              <p className="my-5 py-5">
                Oops! Your browser does not support to view PDFs <br />
                <a
                  href={shortLinkObject.link}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="btn btn-violet mt-3"
                >
                  Download PDF
                </a>
              </p>
            </object>
          ) : (
            <h3>Oops! Not found</h3>
          )}
        </div>
      )}
      <StaticFooterComponent />
    </div>
  );
};

export default withRouter(ShowDocumentComponent);
