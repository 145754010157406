import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

import { update } from "firebase_config";
import { post } from "services";
import { capitalizeFirstLetter } from "helpers";
import { Tables, Routes } from "constants/index";

const AmiComponent = ({ request, setAmiFlag }) => {
  const [loading, setLoading] = useState(false);
  const [doctorRemarks, setDoctorRemarks] = useState();
  const consultRequest = request.type === "consult_doctor";
  const history = useHistory();

  const additionalInfoRequired = async () => {
    const msg = consultRequest ? "share feedback" : "ask more information";
    if (!doctorRemarks) {
      toast.error(`${msg} is required.`);
      return;
    }
    if (window.confirm(`Are you sure ${msg}?`)) {
      const params = {
        status: "pending",
        additionalInfoRequired: true,
        doctorRemarks: [
          ...request.doctorRemarks,
          { d2p: doctorRemarks, createdAt: new Date().getTime() },
        ],
        assigned: false,
      };
      if (
        request.type === "consult_doctor" &&
        request.product.priority === "low"
      ) {
        params.status = "accepted";
        params.additionalInfoRequired = false;
      }
      if (request.type !== "consult_doctor") {
        params.doctorId = "";
      }
      setLoading(true);
      await update(Tables.MEDICAL_REQUESTS, request.id, params);
      if (request.type === "consult_doctor") {
        await post("/send_email", {
          templateName: "consult_doctor_feedback_shared.html",
          email: request.email,
          displayName: capitalizeFirstLetter(request.firstName),
          doctorRemarks,
          requestId: request.id,
        });
      } else {
        await post("/send_email", {
          templateName: "additional_info_request.html",
          email: request.email,
          displayName: capitalizeFirstLetter(request.firstName),
          doctorRemarks,
          requestId: request.id,
        });
      }
      post("/send_sms", {
        to: request.mobile,
        message:
          "You received a new message from Doctor Buddy. Login now at https://medoc.life",
      });
      setLoading(false);
      toast.success("Your message sent to patient");
      history.push(Routes.APP_SETTINGS);
    }
  };

  return (
    <>
      <div className="mt-3 col-12">
        <div className="font-small fw-500 text-secondary">
          {consultRequest ? "Share Feedback" : "Ask More Information"}
        </div>
        <div>
          <textarea
            className="form-control font-small mt-1"
            placeholder="Type Message Here"
            onChange={(e) => setDoctorRemarks(e.target.value)}
          />
        </div>
      </div>
      <div className="mt-3 col-12 text-center">
        <button
          className="btn btn-violet btn-sm px-3 my-2 col-6"
          onClick={additionalInfoRequired}
          disabled={loading}
        >
          Send Message
        </button>{" "}
        <button
          className="btn btn-danger-outline btn-sm px-3 my-2 col-6"
          onClick={(e) => setAmiFlag(false)}
          disabled={loading}
        >
          Cancel
        </button>{" "}
      </div>
    </>
  );
};

export default AmiComponent;
