import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import moment from "moment";
// import $ from 'jquery'
import "./style.css";

import HeaderComponent from "./header";
import PayoutDetailsComponent from "./steps/payout_details/component";
import ConfirmComponent from "./steps/confirm/component";
import { getQuery, firestore } from "firebase_config";
import { post } from "services";
import { ENV } from "constants/env_keys";

function WalletWithdrawComponent({ currentUser }) {
  const [withdrawAmount, setWithdrawAmount] = useState(ENV.MIN_DEPOSIT_AMOUNT);
  const [accountName, setAccountName] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [ifscCode, setIfscCode] = useState("");
  const [stepNumber, setStepNumber] = useState(1);
  const [result, setResult] = useState({});
  const [selectedWallet, setSelectedWallet] = useState("");
  const [withdrawTransaction, setWithdrawTransaction] = useState("");
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const withdrawalDate = moment().add(10, "days").format("DD/MM/YYYY"); // 10 days

  useEffect(() => {
    async function getWalletDetails() {
      const wallet = await getQuery(
        firestore
          .collection("wallets")
          .where("userId", "==", currentUser.id)
          .get()
      );
      if (wallet[0]) {
        setSelectedWallet(wallet[0]);
        getWithdrawTransaction();
      }
    }

    async function getWithdrawTransaction() {
      const trn = await getQuery(
        firestore
          .collection("transactions")
          .where("userId", "==", currentUser.id)
          .where("withdrawalRequest", "==", true)
          .where("status", "==", "pending")
          .orderBy("createdAt", "desc")
          .get()
      );
      if (trn[0]) {
        setWithdrawTransaction(trn[0]);
      }
    }

    if (!selectedWallet) {
      getWalletDetails();
    }
  }, []);

  const save = async (withdrawAmountAfterTaxes) => {
    if (withdrawAmount < +ENV.MIN_DEPOSIT_AMOUNT) {
      toast.error(
        `Minimum withdrawal amount is ${ENV.CURRENCY}${ENV.MIN_DEPOSIT_AMOUNT}`
      );
      return null;
    }
    if (!accountName) {
      toast.error("Account holder name is required");
      return null;
    }
    if (!accountNumber) {
      toast.error("Account number must be valid");
      return null;
    }

    if (!ifscCode) {
      toast.error("IFSC code must be valid");
      return null;
    }

    setLoading(true);
    const data = {
      userId: currentUser.id,
      walletId: selectedWallet.id,
      amount: +withdrawAmount,
      withdrawalAmount: withdrawAmountAfterTaxes,
      currency: "gbp",
      currencySymbol: ENV.CURRENCY,
      type: "debit",
      status: "pending",
      withdrawalRequest: true,
      withdrawalDate,
      userDetails: {
        accountName,
        accountNumber,
        ifscCode,
      },
    };

    const res = await post("/transaction/withdraw", {
      date: data.withdrawalDate,
      amount: withdrawAmount,
      currency: data.currency,
      data,
    });

    console.log("res", res);
    if (res.status === 201) {
      toast.success("We received your withdrawal request.");
    } else {
      toast.error("Withdrawal request is unsuccessful. Try again later.");
    }
    history.push("/app/settings");

    setResult(res);
    setStepNumber(3);
    setWithdrawTransaction(data);
    setLoading(false);
    setTimeout(() => {
      setResult("");
      setWithdrawAmount(0);
    }, 3000);
  };

  const goBack = () => {
    history.push("/app/settings");
  };

  const alertTemplate = () => (
    <div className="p-3 pt-5 text-center">
      <p className="p-2">
        Your withdrawal request has been received. The withdrawal amount of{" "}
        <b>
          {ENV.CURRENCY}
          {currentUser.withdrawalRequestAmount}
        </b>{" "}
        will be credited by {withdrawTransaction.withdrawalDate}.
      </p>
      <button className="btn btn-sm btn-violet-rounded mb-3" onClick={goBack}>
        Done
      </button>
    </div>
  );

  return (
    <div>
      <div className="dashboard-content -xs-bg-none">
        <HeaderComponent goBack={goBack} />
        <div className="text-center">
          {result.status && (
            <div
              className={`text-${
                result.status === 200 ? "violet" : "danger"
              } my-2`}
            >
              {result.message}
            </div>
          )}
        </div>
        <div className="">
          {!withdrawTransaction ? (
            <div>
              {stepNumber === 1 && (
                <PayoutDetailsComponent
                  currentUser={currentUser}
                  wallet={selectedWallet}
                  setStepNumber={setStepNumber}
                  withdrawAmount={withdrawAmount}
                  setWithdrawAmount={setWithdrawAmount}
                  accountName={accountName}
                  setAccountName={setAccountName}
                  accountNumber={accountNumber}
                  setAccountNumber={setAccountNumber}
                  ifscCode={ifscCode}
                  setIfscCode={setIfscCode}
                  loading={loading}
                  goBack={goBack}
                />
              )}
              {stepNumber === 2 && (
                <ConfirmComponent
                  currentUser={currentUser}
                  wallet={selectedWallet}
                  setStepNumber={setStepNumber}
                  save={save}
                  withdrawAmount={withdrawAmount}
                  accountName={accountName}
                  accountNumber={accountNumber}
                  ifscCode={ifscCode}
                  loading={loading}
                />
              )}
              {stepNumber === 3 && (
                <div className="container desktop-align-center">
                  {alertTemplate()}
                </div>
              )}
            </div>
          ) : (
            <div className="container desktop-align-center">
              {alertTemplate()}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default WalletWithdrawComponent;

// Ref: https://stackoverflow.com/questions/55156572/how-to-give-automatic-spaces-in-credit-card-validation-slash-in-a-date-format-v
