import { Routes } from "constants/index";

export const CertificateTypes = [
  {
    name: "Caretaker Certificate",
    route: Routes.APP_CREATE_CT,
    state: { headerText: "Caretaker Certificate" },
    display: true,
  },
  {
    name: "Sick Leave Certificate For School",
    route: Routes.APP_CREATE_POST,
    state: {
      headerText: "Sick Leave Certificate For School",
      type: "sick_to_school",
    },
    display: true,
  },
  {
    name: "Sick Leave Certificate For Work",
    route: Routes.APP_CREATE_POST,
    state: {
      headerText: "Sick Leave Certificate For Work",
      type: "sick_to_work",
    },
    display: true,
  },
  {
    name: "Fitness Certificate: For Driving License (Form 1A)",
    route: Routes.APP_CREATE_FA,
    state: { headerText: "Form 1A Fitness Certificate" },
    display: true,
  },
  {
    name: "Fitness Certificate: For Adoption",
    route: Routes.APP_CREATE_FC,
    state: {
      headerText: "Fitness Certificate: For Adoption",
      type: "fit_to_adopt",
    },
    display: true,
  },
  {
    name: "Fitness Certificate: For School Admission",
    route: Routes.APP_CREATE_FC,
    state: {
      headerText: "Fitness Certificate For Admission",
      type: "fit_to_school",
    },
    display: true,
  },
  {
    name: "Fitness Certificate: For Hiking/Adventure Sports",
    route: Routes.APP_CREATE_FC,
    state: {
      headerText: "Fitness Certificate For Hiking",
      type: "fit_to_adventures",
    },
    display: true,
  },
  {
    name: "Fit to Work",
    route: Routes.APP_CREATE_FC,
    state: { headerText: "Fit to Work", type: "fitness_certificate" },
    display: true,
  },
  {
    name: "Fit to Travel",
    route: Routes.APP_CREATE_FC,
    state: { headerText: "Fit to Travel", type: "fit_to_travel" },
    display: true,
  },
  {
    name: "Recovery Certificate",
    route: Routes.APP_CREATE_RC,
    state: { headerText: "Recovery Certificate" },
    display: true,
  },
  {
    name: "Work From Home Certificate",
    route: Routes.APP_CREATE_CC,
    state: { headerText: "Work From Home Certificate", type: "work_from_home" },
    display: true,
  },
  {
    name: "Unfit-to-work Certificate",
    route: Routes.APP_CREATE_CC,
    state: { headerText: "Unfit-to-work Certificate", type: "unfit_to_work" },
    display: true,
  },
  {
    name: "Unfit-to-travel Certificate",
    route: Routes.APP_CREATE_CC,
    state: {
      headerText: "Unfit-to-travel Certificate",
      type: "unfit_to_travel",
    },
    display: true,
  },
  {
    name: "Custom Format Certificate",
    route: Routes.APP_CREATE_CC,
    state: { headerText: "Custom Format Certificate" },
    display: true,
  },
];
