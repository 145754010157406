import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import moment from "moment";

import { capitalizeFirstLetter } from "helpers";
import { getQuery, firestore } from "firebase_config";
import { Routes, Tables } from "constants/index";
import { HeaderComponent, LoadingComponent } from "components";

function GetMedicalRequestsComponent({ currentUser }) {
  const [loading, setLoading] = useState(false);
  const [medRequests, setMedRequests] = useState();

  useEffect(() => {
    async function getMedRequests() {
      setLoading(true);
      let requests = await getQuery(
        firestore
          .collection(Tables.MEDICAL_REQUESTS)
          .orderBy("updatedAt", "desc")
          .get()
      );
      requests = requests.sort((a, b) => b.createdAt - a.createdAt);
      setMedRequests(requests);
      setLoading(false);
    }

    if (!medRequests) {
      getMedRequests();
    }
  });

  return (
    <div className="">
      <HeaderComponent />
      <div className="container settings-wrapper desktop-align-center pt-5">
        <div className="d-flex flex-row my-2 fw-500 align-items-center">
          <div>Get Medical Requests</div>
          <Link to={Routes.APP_POSTS} className="btn btn-violet btn-sm ml-3">
            Go Back
          </Link>
        </div>
        <table className="table table-bordered table-responsive">
          <thead>
            <tr>
              <th>Request Number</th>
              <th>Certificate Type</th>
              <th>Status</th>
              <th>Organisation Name</th>
              <th>Organisation Address</th>
              <th>Resolved At</th>
            </tr>
          </thead>
          <tbody>
            {!loading && medRequests ? (
              medRequests.map((req) => (
                <tr key={req.id}>
                  <td>
                    <Link
                      to={`${Routes.APP_POSTS}/${req.id}`}
                      className="text-violet fw-500"
                    >
                      {req.id}
                    </Link>
                  </td>
                  <td>
                    {" "}
                    {req.type
                      ? capitalizeFirstLetter(req.type.replace(/_/g, " "))
                      : "Sick leave certificate"}
                  </td>
                  <td>{req.status && capitalizeFirstLetter(req.status)}</td>
                  <td>{req.orgName && capitalizeFirstLetter(req.orgName)}</td>
                  <td>
                    {req.orgAddress && capitalizeFirstLetter(req.orgAddress)}
                  </td>
                  <td>
                    {moment(req.resolvedAt || req.updatedAt).format(
                      "DD-MM-YYYY, HH:mm"
                    )}
                  </td>
                </tr>
              ))
            ) : (
              <LoadingComponent />
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default GetMedicalRequestsComponent;
