import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import moment from "moment";
import "./style.css";

import HeaderComponent from "./header";
import { add, uploadFile, removeFile } from "firebase_config";
import { Tables, Routes } from "constants/index";

const currency = "INR";
const products = [
  {
    name: "Recommended: Talk to a doctor upto 45 minutes",
    description:
      "Doctor buddy will assess your medical condition in detail and provide you a full feedback digitally after the call.",
    price: 400,
    totalPrice: 1000,
    discountPercent: 60,
    priority: "high",
    display: true,
  },
  {
    name: "Quick call: Talk to our doctor upto 10 minutes.",
    description:
      "This helps you to quickly talk to a doctor about your medical condition. You do not receive any feedback after the call.",
    price: 160,
    totalPrice: 400,
    discountPercent: 60,
    priority: "high",
    display: true,
  },
];

const CreateSecondOpinionComponent = ({ currentUser }) => {
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState({
    status: "pending",
    confirmTnc: false,
    leaveReason: "Sick Leave",
    leaveFrom: "studies",
    firstName: "",
    lastName: "",
    dob: "",
    age: "",
    address: {
      country: "India",
    },
    mobile: "",
    email: "",
    reason: "",
    otherReason: "",
    reasonDescription: "",
    leaveDateFrom: "",
    leaveDateTo: "",
    orgAddress: "",
    orgName: "",
    idFront: "",
    idBack: "",
    optionalDoc: "",
    gender: "male",
    userId: currentUser.id,
    assigned: false,
    product: "",
    paymentStatus: "pending",
    doctorRemarks: [],
    additionalInfoRequired: false,
  });
  const history = useHistory();
  const [smallDevice, setSmallDevice] = useState(false);

  useEffect(() => {
    if (window.innerWidth <= 576) {
      setSmallDevice(true);
    } else {
      setSmallDevice(false);
    }
  });

  const handleChange = (key, value, additionalField) => {
    if (additionalField) {
      setOptions({
        ...options,
        [key]: {
          ...options[key],
          [additionalField]: value,
        },
      });
    } else {
      setOptions({
        ...options,
        [key]: value,
      });
    }
  };

  const uploadImage = async (file, key) => {
    if (!file) {
      toast.error("New image required");
      return null;
    }
    setLoading(true);
    await uploadFile(file, "image", async (url, err) => {
      if (err) {
        alert(err);
        return null;
      }
      setOptions((prevState) => ({
        ...prevState,
        [key]: url,
      }));
      setLoading(false);
    });
  };

  const deleteImage = async (url, key) => {
    if (window.confirm("Are you sure to remove image?")) {
      await removeFile(url);
      setOptions((prevState) => ({
        ...prevState,
        [key]: "",
      }));
    }
  };

  const redirectToGuildelines = () => {
    if (
      window.confirm("Your form data will be lost. Are you sure to redirect?")
    ) {
      history.push({
        pathname: Routes.APP_GUIDELINES_SLC,
        state: {
          options,
        },
      });
    }
  };

  const showErrors = () => {
    let error;
    if (!options.firstName) {
      error = "First name is required";
    } else if (!options.lastName) {
      error = "Last name is required";
    } else if (!options.dob) {
      error = "Date of birth is required";
    } else if (!options.age) {
      error = "Age is required";
    } else if (!options.email) {
      error = "Email is required";
    } else if (!options.mobile) {
      error = "Mobile number is required";
    } else if (options.mobile && options.mobile.length !== 12) {
      error = "Mobile number must contain country code";
    } else if (options.address && !options.address.door) {
      error = "Door number is required in Address";
    } else if (options.address && !options.address.streetName) {
      error = "Street name is required in Address";
    } else if (options.address && !options.address.city) {
      error = "City is required in Address";
    } else if (options.address && !options.address.state) {
      error = "State is required in Address";
    } else if (options.address && !options.address.zipcode) {
      error = "Zipcode is required in Address";
    } else if (!options.reason) {
      error = "Reason is required";
    } else if (options.reason === "Other" && !options.otherReason) {
      error = "The main reason of certificate is required";
    } else if (!options.reasonDescription) {
      error = "Reason description is required";
    } else if (!options.idFront || !options.idBack) {
      error = "Proof of Identification is required";
    } else if (!options.orgName) {
      error = "Organisation name is required";
    } else if (!options.orgAddress) {
      error = "Organisation address is required";
    } else if (!options.leaveDateFrom) {
      error = "From date is required";
    } else if (!options.leaveDateTo) {
      error = "To date is required";
    } else if (!options.product) {
      error = "Choose a product";
    } else if (!options.confirmTnc) {
      error = "Please confirm our Terms and Conditions";
    } else if (!options.confirmGuidelines) {
      error = "Please confirm our Guidelines";
    }
    return error;
  };

  const saveCertificate = async () => {
    const errorMsg = await showErrors();
    if (errorMsg) {
      toast.error(errorMsg);
      return;
    }

    options.priority = options.product.priority;

    const res = await add(Tables.MEDICAL_REQUESTS, options);

    if (res.status === 200) {
      toast.success(
        "Your request has been saved. Complete the payment to proceed further."
      );
      history.push({
        pathname: Routes.APP_RECHARGE,
        state: {
          amount: options.product.price,
          requestId: res.data.id,
        },
      });
    }
  };

  return (
    <>
      <HeaderComponent headerText="Request Second Opinion" />
      <div className="dashboard-content">
        <div className="container create-post-wrapper pt-5 mt-sm-5">
          <div className="card p-2 p-sm-4 my-sm-5">
            <div className="text-center h5 mt-3 mt-sm-0">Second Opinion</div>
            <hr />
            <div className="mt-4">
              <h6>Patient name *</h6>
              <div className="row">
                <div className="col-12 col-sm-6">
                  <input
                    type="text"
                    className="form-control font-small"
                    name="form-question"
                    placeholder="First name"
                    onChange={(e) => handleChange("firstName", e.target.value)}
                  />
                  <small className="text-secondary fs-10">First name</small>
                </div>
                <div className="col-12 col-sm-6 mt-4 mt-sm-0">
                  <input
                    type="text"
                    className="form-control font-small"
                    name="form-question"
                    placeholder="Last name"
                    onChange={(e) => handleChange("lastName", e.target.value)}
                  />
                  <small className="text-secondary fs-10">Last name</small>
                </div>
              </div>
            </div>
            <div className="row mt-4">
              <div className="col-12 col-sm-6">
                <h6>Date of birth *</h6>
                <input
                  type="date"
                  className="form-control"
                  name="form-question"
                  placeholder="dob"
                  onChange={(e) => handleChange("dob", e.target.value)}
                />
              </div>
              <div className="col-12 col-sm-6 mt-3 mt-sm-0">
                <h6>Age *</h6>
                <input
                  type="number"
                  className="form-control"
                  name="form-question"
                  placeholder="Age"
                  onChange={(e) => handleChange("age", e.target.value)}
                />
              </div>
            </div>
            <div className="mt-4">
              <h6>Gender *</h6>
              <select
                className="form-control font-small"
                aria-label=".form-select-sm"
                onChange={(e) => handleChange("gender", e.target.value)}
              >
                <option value="male">Male</option>
                <option value="female">Female</option>
                <option value="other">Other</option>
              </select>
            </div>
            <div className="row mt-4">
              <div className="col-12 col-sm-6 mt-4 mt-sm-0">
                <h6 htmlFor="form-question">Mobile *</h6>
                <input
                  type="number"
                  className="form-control"
                  name="form-question"
                  placeholder="917890789090"
                  onChange={(e) => handleChange("mobile", e.target.value)}
                />
                <small className="text-secondary fs-10">
                  e.g. 917890789078
                </small>{" "}
                <br />
                <small className="text-secondary fs-10">
                  Note: Your doctor buddy will call you at this number
                </small>
              </div>
              <div className="col-12 col-sm-6">
                <h6 htmlFor="form-question">Email *</h6>
                <input
                  type="email"
                  className="form-control"
                  name="form-question"
                  placeholder="Email"
                  onChange={(e) => handleChange("email", e.target.value)}
                />
              </div>
            </div>
            {/* medical problem */}
            <div className="mt-4">
              <h6>Medical Issue *</h6>
              <textarea
                className="form-control fs-12"
                name="form-question"
                rows="4"
                placeholder="Please describe the issue for the doctor."
                onChange={(e) =>
                  handleChange("reasonDescription", e.target.value)
                }
              />
            </div>
            {/* documents */}
            <div className="mt-4">
              <h6>
                Add photos or documents
                <div className="text-secondary fs-10 pt-2 fw-400">
                  Only PNG/JPEG/PDF formats supported.
                </div>
              </h6>
              <div className="text-center image-label">
                <div className={options.optionalDoc && "d-none"}>
                  <label htmlFor="optionalDoc" className="pointer">
                    Browse File{" "}
                    <i
                      className={loading ? "fa fa-spinner fa-spin" : "d-none"}
                    ></i>
                  </label>
                  <input
                    type="file"
                    className="form-control-plaintext d-none"
                    id="optionalDoc"
                    onChange={(e) =>
                      uploadImage(e.target.files[0], "optionalDoc")
                    }
                    accept="application/pdf"
                  />
                </div>
                {options.optionalDoc && (
                  <div>
                    <button
                      className="btn btn-violet btn-sm"
                      onClick={(e) =>
                        deleteImage(options.optionalDoc, "optionalDoc")
                      }
                    >
                      Delete File
                    </button>{" "}
                    <br />
                    <object
                      data={options.optionalDoc}
                      className="camera-icon"
                      width="100%"
                      height="150px"
                    />
                  </div>
                )}
              </div>
            </div>
            <div className="text-center mt-5">
              <h5>
                Doctor details <br />
                <small className="text-secondary fs-10">
                  Atleast 24 hrs required in advance.{" "}
                </small>
              </h5>
            </div>
            <hr />
            <div className="row mt-2 align-items-center">
              <div className="col-12 col-sm-6">
                <h6 htmlFor="form-question">
                  At what time do you talk to doctor *
                </h6>
                <input
                  type="datetime-local"
                  className="form-control"
                  onChange={(e) =>
                    handleChange("leaveDateFrom", e.target.value)
                  }
                  max={moment().format("DD/MM/YYYY HH:MM")}
                />
              </div>
              <div
                className={
                  options.leaveDateFrom
                    ? "col-12 col-sm-6 text-center"
                    : "d-none"
                }
              >
                {moment(options.leaveDateFrom)
                  .utcOffset("+05:30")
                  .format("DD/MM/YYYY, HH:mm")}{" "}
                IST <br />
                <small className="text-secondary fs-10">
                  Indian Standard Time (IST){" "}
                </small>
              </div>
            </div>
            <div className="mt-4">
              <h6>Select a doctor *</h6>
              <select
                className="form-control font-small"
                aria-label=".form-select-sm"
                onChange={(e) => handleChange("gender", e.target.value)}
              >
                <option value="male">General Doctor</option>
                <option value="female">Dentist</option>
                <option value="other">Orthopedist</option>
                <option value="other">Ear Nose Tongue (ENT)</option>
              </select>
            </div>
            {/* products */}
            <div className="text-violet fw-500 mt-5 text-center">
              Doctor will call you in the next 24 hours.
            </div>
            <div className="mt-4">
              <h6>My products *</h6>
              <div className="row">
                {products.map(
                  (product, idx) =>
                    product.display && (
                      <div key={idx} className="col-12 my-2">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="product"
                          id={`product-${idx}`}
                          onChange={(e) => handleChange("product", product)}
                          checked={options.product === product}
                        />
                        <label
                          className="form-check-label"
                          htmlFor={`product-${idx}`}
                        >
                          <div className="media">
                            <div className="media-body">
                              <div className="fw-500">
                                {product.name} {smallDevice && <div></div>}
                                <div
                                  className={
                                    smallDevice ? "my-2" : "pull-right"
                                  }
                                >
                                  {product.price.toFixed(2)} {currency} &nbsp;
                                  <strike className="text-orange-600 fw-500 mt-2 fs-12">
                                    {product.totalPrice.toFixed(2)} {currency}
                                  </strike>
                                </div>
                              </div>
                              <div className="mt-1 fs-12">
                                {product.description}
                              </div>
                            </div>
                          </div>
                        </label>
                      </div>
                    )
                )}
              </div>
              <div className="mt-4 text-center fs-16 fw-500">
                Total cost:{" "}
                <span className="text-violet">
                  {options.product ? options.product.price.toFixed(2) : "0.00"}{" "}
                  {currency}
                </span>
              </div>
              <hr />
              <div className="tnc">
                <div className="pt-2">
                  <input
                    className="form-check-input ml-1 mt-2"
                    type="checkbox"
                    name="confirmGuidelines"
                    id="confirmGuidelines"
                    onChange={(e) => handleChange("confirmGuidelines", true)}
                    checked={options.confirmGuidelines}
                  />
                  <label
                    className="form-check-label ml-4 text-justify"
                    htmlFor="confirmGuidelines"
                  >
                    I adhere to all our{" "}
                    <span
                      className="text-violet px-0 mx-0"
                      onClick={redirectToGuildelines}
                    >
                      medoc guidelines.
                    </span>
                  </label>
                </div>
              </div>
              <div className="tnc">
                <div className="pt-2">
                  <input
                    className="form-check-input ml-1"
                    type="checkbox"
                    name="confirmTnc"
                    id="confirmTnc"
                    onChange={(e) => handleChange("confirmTnc", true)}
                    checked={options.confirmTnc}
                  />
                  <label
                    className="form-check-label ml-4 text-justify"
                    htmlFor="confirmTnc"
                  >
                    I confirm that I am not seriously unwell and I do NOT have
                    any of the following symptoms: chest pain, shortness of
                    breath, unable to swallow fluids or saliva, weakness or
                    numbness down one side, and slurred speech. I confirm I do
                    not think I need to see a General Practitioner. If symptoms
                    are related to a COVID vaccine, I will consult my General
                    Practitioner or the medical professional who provided the
                    immunization. I agree that the issuance of a medical
                    certificate and its duration is based on the discretion of
                    the consulting doctor. I confirm that I am not using the
                    certificate for any medico-legal purposes. In case of
                    non-issuance of the certificate due to any reason, a service
                    charge of 10% will be deducted while refunding the amount.
                    The refund will be done online and usually takes 8-10
                    working days. I also agree to the{" "}
                    <Link to="/tnc" className="text-violet">
                      Refund and cancellation policy
                    </Link>
                    . I confirm that this online consultation is not a
                    replacement for a doctor's visit. I have read, understood,
                    and agree with the information in the terms of service and
                    consent to the use of information in accordance with the
                    privacy policy.
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div
            className="complete-payment btn btn-violet text-center"
            onClick={saveCertificate}
          >
            Complete Payment
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateSecondOpinionComponent;
