export default [
  {
    name: "Get response via email",
    description:
      "This is a 1-time response. Doctor reviews your details and send you the feedback via email. No chat facility. Please elaborate your issue in detail to get quicker response.",
    cost: "49",
    price: 49,
    totalPrice: 250,
    discountPercent: 60,
    priority: "high",
    display: true,
    type: "text",
  },
  {
    name: "Get response via call (5 mins)",
    description:
      "This is a 1-time call. Doctor reviews your details and call you to discuss the issue. No chat facility. Please elaborate your issue in detail to get quicker response.",
    cost: "49",
    price: 99,
    totalPrice: 250,
    discountPercent: 60,
    priority: "high",
    display: true,
    type: "audio",
  },
];
