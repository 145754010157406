import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import moment from "moment";

import { update, uploadFile, removeFile } from "firebase_config";
import { Tables } from "constants/index";
import PaymentStatusComponent from "../payment_status/component";
import DoctorRemarksComponent from "../doctor_remarks/component";
import AssignDoctorComponent from "../assign_doctor/component";

const reasons = [
  "Migraine",
  "Period pain",
  "Common cold and flu",
  "Back pain",
  "Headache",
  "Anxiety, depression or stress",
  "Other",
];

const UpdateMedicalRequestComponent = ({
  currentUser,
  options,
  setOptions,
  searchValue,
  setSearchValue,
}) => {
  const [countChars, setCountChars] = useState(25);
  const [savingCertificate, setSavingCertificate] = useState(false);
  const [error, setError] = useState({
    message: "",
    key: "",
  });
  const [idFrontLoading, setIdFrontLoading] = useState(false);
  const [idBackLoading, setIdBackLoading] = useState(false);
  const [optionalDocLoading, setOptionalDocLoading] = useState(false);
  const history = useHistory();

  const handleChange = (key, value, additionalField) => {
    if (key === "otherReason") {
      const max = 20;
      setCountChars(max - value.length);
    }
    if (additionalField) {
      setOptions({
        ...options,
        [key]: {
          ...options[key],
          [additionalField]: value,
        },
      });
    } else {
      setOptions({
        ...options,
        [key]: value,
      });
    }
  };

  const uploadImage = async (file, key, event) => {
    if (!file) {
      toast.error("New image required");
      return null;
    }
    if (key === "idFront") {
      setIdFrontLoading(true);
    } else if (key === "idBack") {
      setIdBackLoading(true);
    } else {
      setOptionalDocLoading(true);
    }
    await uploadFile(file, "image", async (url, err) => {
      if (err) {
        alert(err);
        return null;
      }
      event.target.value = null;
      setOptions((prevState) => ({
        ...prevState,
        [key]: url,
      }));

      if (key === "idFront") {
        setIdFrontLoading(false);
      } else if (key === "idBack") {
        setIdBackLoading(false);
      } else {
        setOptionalDocLoading(false);
      }
    });
  };

  const deleteImage = async (url, key, event) => {
    if (window.confirm("Are you sure to remove image?")) {
      if (key === "idFront") {
        setIdFrontLoading(true);
      } else if (key === "idBack") {
        setIdBackLoading(true);
      } else {
        setOptionalDocLoading(true);
      }

      await removeFile(url);

      if (key === "idFront") {
        setIdFrontLoading(false);
      } else if (key === "idBack") {
        setIdBackLoading(false);
      } else {
        setOptionalDocLoading(false);
      }
      setOptions((prevState) => ({
        ...prevState,
        [key]: "",
      }));
    }
  };

  const showErrors = () => {
    let error;
    if (!options.firstName) {
      error = {
        message: "First name is required",
        key: "firstName",
      };
    } else if (!options.lastName) {
      error = {
        message: "Last name is required",
        key: "lastName",
      };
    } else if (!options.dob) {
      error = {
        message: "Date of birth is required",
        key: "dob",
      };
    } else if (!options.age) {
      error = {
        message: "Age is required",
        key: "age",
      };
    } else if (!options.email) {
      error = {
        message: "Email is required",
        key: "email",
      };
    } else if (!options.mobile) {
      error = {
        message: "Mobile number is required",
        key: "mobile",
      };
    } else if (options.mobile && options.mobile.length !== 12) {
      error = {
        message: "Mobile number must contain 12-digits",
        key: "mobile",
      };
    } else if (options.address && !options.address.door) {
      error = {
        message: "Door number is required",
        key: "door",
      };
    } else if (options.address && !options.address.streetName) {
      error = {
        message: "Street name is required",
        key: "streetName",
      };
    } else if (options.address && !options.address.city) {
      error = {
        message: "City is required",
        key: "city",
      };
    } else if (options.address && !options.address.state) {
      error = {
        message: "State is required",
        key: "state",
      };
    } else if (options.address && !options.address.zipcode) {
      error = {
        message: "Postal / Zip code is required ",
        key: "zipcode",
      };
    } else if (!options.reason) {
      error = {
        message: "Reason is required",
        key: "reason",
      };
    } else if (options.reason === "Other" && !options.otherReason) {
      error = {
        message: "Other reason is required",
        key: "otherReason",
      };
    } else if (!options.idFront) {
      error = {
        message: "Proof of Identification (Front) is required",
        key: "idFront",
      };
    } else if (!options.idBack) {
      error = {
        message: "Proof of Identification (Back) is required",
        key: "idBack",
      };
    } else if (!options.orgName) {
      error = {
        message: "Workplace name is required",
        key: "orgName",
      };
    } else if (!options.orgAddress) {
      error = {
        message: "Workplace address is required",
        key: "orgAddress",
      };
    } else if (!options.leaveDateFrom) {
      error = {
        message: "From date is required",
        key: "leaveDateFrom",
      };
    } else if (!options.leaveDateTo) {
      error = {
        message: "To date is required",
        key: "leaveDateTo",
      };
    }
    setError(error);
    return error;
  };

  const saveCertificate = async () => {
    const err = await showErrors();
    if (err && err.message) {
      toast.error(err.message);
      return;
    }
    setSavingCertificate(true);
    options.assigned = !!options.doctorId;
    const res = await update(Tables.MEDICAL_REQUESTS, searchValue, options);

    if (res.status === 200) {
      toast.success(`Request updated. ${searchValue}`);
      history.goBack();
    } else {
      setError("Something went wrong. Refresh the page");
    }
    setSavingCertificate(false);
  };

  return (
    <>
      <div className="dashboard-content">
        <div className="container create-post-wrapper mt-0 pt-0">
          {options && (
            <>
              <div className="card p-2 p-sm-4 my-sm-5">
                <div className="row">
                  <div className="col-12 mt-2 mt-sm-0">
                    <h6>Leave is from *</h6>
                    <div className="d-flex flex-inline">
                      <div className="">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="leaveFrom"
                          id="leaveFrom-1"
                          onChange={(e) => handleChange("leaveFrom", "studies")}
                          checked={options.leaveFrom === "studies"}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="leaveFrom-1"
                        >
                          Studies
                        </label>
                      </div>
                      <div className="pl-5">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="leaveFrom"
                          id="leaveFrom-2"
                          onChange={(e) => handleChange("leaveFrom", "work")}
                          checked={options.leaveFrom === "work"}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="leaveFrom-2"
                        >
                          Work
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mt-4">
                  <h6>
                    Full name of the person who requires leave * <br />
                    <small className="text-secondary fs-10">
                      *As per Govt ID proof
                    </small>
                  </h6>
                  <div className="row">
                    <div className="col-12 col-sm-6">
                      <input
                        type="text"
                        className={`form-control font-small ${
                          error?.key === "firstName" && "error-border"
                        }`}
                        name="form-question"
                        placeholder="First name"
                        value={options.firstName}
                        onChange={(e) =>
                          handleChange("firstName", e.target.value)
                        }
                      />
                      {error?.key === "firstName" ? (
                        <small className="text-danger fs-10">
                          {error.message}
                        </small>
                      ) : (
                        <small className="text-secondary fs-10">
                          First name
                        </small>
                      )}
                    </div>
                    <div className="col-12 col-sm-6 mt-4 mt-sm-0">
                      <input
                        type="text"
                        className={`form-control font-small ${
                          error?.key === "lastName" && "error-border"
                        }`}
                        name="form-question"
                        placeholder="Last name"
                        value={options.lastName}
                        onChange={(e) =>
                          handleChange("lastName", e.target.value)
                        }
                      />
                      {error?.key === "lastName" ? (
                        <small className="text-danger fs-10">
                          {error.message}
                        </small>
                      ) : (
                        <small className="text-secondary fs-10">
                          Last name
                        </small>
                      )}
                    </div>
                  </div>
                </div>
                <div className="row mt-4">
                  <div className="col-12 col-sm-6">
                    <h6>Date of birth *</h6>
                    <input
                      type="date"
                      className={`form-control ${
                        error?.key === "dob" && "error-border"
                      }`}
                      name="form-question"
                      placeholder="dob"
                      value={options.dob}
                      onChange={(e) => handleChange("dob", e.target.value)}
                    />
                    {error?.key === "dob" && (
                      <small className="text-danger fs-10">
                        {error.message}
                      </small>
                    )}
                  </div>
                  <div className="col-12 col-sm-6 mt-3 mt-sm-0">
                    <h6>Age *</h6>
                    <input
                      type="number"
                      className={`form-control ${
                        error?.key === "age" && "error-border"
                      }`}
                      name="form-question"
                      placeholder="Age"
                      value={options.age}
                      onChange={(e) => handleChange("age", e.target.value)}
                    />
                    {error?.key === "age" && (
                      <small className="text-danger fs-10">
                        {error.message}
                      </small>
                    )}
                  </div>
                </div>
                <div className="mt-4">
                  <h6>Gender *</h6>
                  <select
                    className="form-control font-small"
                    aria-label=".form-select-sm"
                    onChange={(e) => handleChange("gender", e.target.value)}
                  >
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                    <option value="other">Other</option>
                  </select>
                </div>
                <div className="row mt-4">
                  <div className="col-12 col-sm-6">
                    <h6 htmlFor="form-question">Email *</h6>
                    <input
                      type="email"
                      className={`form-control ${
                        error?.key === "email" && "error-border"
                      }`}
                      name="form-question"
                      placeholder="Email"
                      value={options.email}
                      onChange={(e) => handleChange("email", e.target.value)}
                    />
                    {error?.key === "email" && (
                      <small className="text-danger fs-10">
                        {error.message}
                      </small>
                    )}
                  </div>
                  <div className="col-12 col-sm-6 mt-4 mt-sm-0">
                    <h6 htmlFor="form-question">Mobile *</h6>
                    <input
                      type="number"
                      className={`form-control ${
                        error?.key === "mobile" && "error-border"
                      }`}
                      name="form-question"
                      placeholder="917890789090"
                      value={options.mobile}
                      onChange={(e) => handleChange("mobile", e.target.value)}
                    />
                    <small className="text-secondary fs-10">
                      e.g. 917890789078
                    </small>
                    {error?.key === "mobile" && (
                      <div className="text-danger fs-10">{error.message}</div>
                    )}
                  </div>
                </div>
                <div className="mt-4">
                  <h6>
                    Address *<br />
                    <small className="text-secondary fs-10 d-none">
                      *As per Govt ID proof
                    </small>
                  </h6>
                  <div className="row">
                    <div className="col-12 col-sm-6 my-2">
                      <input
                        type="text"
                        className={`form-control ${
                          error?.key === "door" && "error-border"
                        }`}
                        name="form-question"
                        placeholder="Door no."
                        value={options.address && options.address.door}
                        onChange={(e) =>
                          handleChange("address", e.target.value, "door")
                        }
                      />
                      {error?.key === "door" ? (
                        <small className="text-danger fs-10">
                          {error.message}
                        </small>
                      ) : (
                        <small className="text-secondary fs-10">
                          Door number
                        </small>
                      )}
                    </div>
                    <div className="col-12 col-sm-6 my-2">
                      <input
                        type="text"
                        className={`form-control ${
                          error?.key === "streetName" && "error-border"
                        }`}
                        name="form-question"
                        placeholder="Street name"
                        value={options.address && options.address.streetName}
                        onChange={(e) =>
                          handleChange("address", e.target.value, "streetName")
                        }
                      />
                      {error?.key === "streetName" ? (
                        <small className="text-danger fs-10">
                          {error.message}
                        </small>
                      ) : (
                        <small className="text-secondary fs-10">
                          Street name
                        </small>
                      )}
                    </div>
                    <div className="col-12 col-sm-6 my-2">
                      <input
                        type="text"
                        className={`form-control ${
                          error?.key === "city" && "error-border"
                        }`}
                        name="form-question"
                        placeholder="City"
                        value={options.address && options.address.city}
                        onChange={(e) =>
                          handleChange("address", e.target.value, "city")
                        }
                      />
                      {error?.key === "city" ? (
                        <small className="text-danger fs-10">
                          {error.message}
                        </small>
                      ) : (
                        <small className="text-secondary fs-10">City</small>
                      )}
                    </div>
                    <div className="col-12 col-sm-6 my-2">
                      <input
                        type="text"
                        className={`form-control ${
                          error?.key === "state" && "error-border"
                        }`}
                        name="form-question"
                        placeholder="State / Region / Province"
                        value={options.address && options.address.state}
                        onChange={(e) =>
                          handleChange("address", e.target.value, "state")
                        }
                      />
                      {error?.key === "state" ? (
                        <small className="text-danger fs-10">
                          {error.message}
                        </small>
                      ) : (
                        <small className="text-secondary fs-10">
                          State / Region / Province
                        </small>
                      )}
                    </div>
                    <div className="col-12 col-sm-6 my-2">
                      <input
                        type="text"
                        className={`form-control ${
                          error?.key === "zipcode" && "error-border"
                        }`}
                        name="form-question"
                        placeholder="Postal/Zip Code"
                        value={options.address && options.address.zipcode}
                        onChange={(e) =>
                          handleChange("address", e.target.value, "zipcode")
                        }
                      />
                      {error?.key === "zipcode" ? (
                        <small className="text-danger fs-10">
                          {error.message}
                        </small>
                      ) : (
                        <small className="text-secondary fs-10">
                          Postal / Zip code
                        </small>
                      )}
                    </div>
                    <div className="col-12 col-sm-6 my-2">
                      <select
                        className="form-control font-small"
                        aria-label=".form-select-sm"
                        value={options.address && options.address.country}
                        onChange={(e) =>
                          handleChange("address", e.target.value, "country")
                        }
                      >
                        <option value="india" defaultValue>
                          India
                        </option>
                      </select>
                      <small className="text-secondary fs-10">Country</small>
                    </div>
                  </div>
                </div>

                {/* medical problem */}
                <div className="mt-4">
                  <h6>
                    The main reason for the certificate *
                    <div className="text-secondary fs-10 pt-2 fw-400">
                      This will be written on your certificate.
                    </div>
                  </h6>
                  <div className="row">
                    {reasons.map((reason, idx) => (
                      <div key={idx} className="col-12 col-sm-6 py-1">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="reason"
                          id={`reason-${idx}`}
                          onChange={(e) => handleChange("reason", reason)}
                          checked={options.reason === reason}
                        />
                        <label
                          className="form-check-label"
                          htmlFor={`reason-${idx}`}
                        >
                          {reason}
                        </label>
                      </div>
                    ))}
                  </div>
                </div>
                {options.reason === "Other" && (
                  <div className="col-12 col-sm-6 mt-4 mt-sm-0">
                    <input
                      type="text"
                      className={`form-control font-small ${
                        error?.key === "otherReason" && "error-border"
                      }`}
                      name="form-question"
                      placeholder="eg. body pain, mild fever etc."
                      value={options.otherReason}
                      onChange={(e) =>
                        handleChange("otherReason", e.target.value)
                      }
                      maxLength="25"
                    />
                    <small className="text-secondary fs-10">
                      {countChars} chars left
                    </small>
                  </div>
                )}
                {error?.key === "reason" && (
                  <small className="text-danger fs-10">{error.message}</small>
                )}
                {error?.key === "otherReason" && (
                  <small className="text-danger fs-10">{error.message}</small>
                )}
                <div className="mt-4">
                  <h6>
                    Please describe the timeline of your symptoms, and the
                    details of your symptoms
                  </h6>
                  <textarea
                    className={`form-control fs-12 ${
                      error?.key === "reasonDescription" && "error-border"
                    }`}
                    name="form-question"
                    rows="4"
                    placeholder="Type here."
                    value={options.reasonDescription}
                    onChange={(e) =>
                      handleChange("reasonDescription", e.target.value)
                    }
                  />
                  {error?.key === "reasonDescription" && (
                    <small className="text-danger fs-10">{error.message}</small>
                  )}
                </div>
                {/* documents */}
                <div className="mt-4">
                  <h6>
                    Proof of Identification *
                    <div className="text-secondary fs-10 pt-2 fw-400">
                      Only PNG/JPEG formats supported like Driving license,
                      Aadhar, PAN, Passport etc.
                    </div>
                  </h6>
                  <div className="row container text-center pr-0">
                    <div className="col-12 col-sm-6 image-label">
                      {idFrontLoading && (
                        <i className="fa fa-spinner fa-spin" />
                      )}
                      <div
                        className={`${
                          (options.idFront || idFrontLoading) && "d-none"
                        }`}
                      >
                        <label htmlFor="idFront" className="pointer">
                          Front{" "}
                        </label>
                        <input
                          type="file"
                          className={`form-control-plaintext d-none ${
                            error?.key === "idFront" && "error-border"
                          }`}
                          id="idFront"
                          onChange={(e) =>
                            uploadImage(e.target.files[0], "idFront", e)
                          }
                          accept="image/*"
                        />
                      </div>
                      {options.idFront && !idFrontLoading && (
                        <div>
                          <button
                            className="btn btn-violet btn-sm"
                            onClick={(e) =>
                              deleteImage(options.idFront, "idFront", e)
                            }
                          >
                            Delete Image
                          </button>
                          <br />
                          <img
                            src={options.idFront}
                            className="camera-icon"
                            alt="Image"
                            width="100px"
                            height="100px"
                          />
                        </div>
                      )}
                    </div>
                    <div className="col-12 col-sm-6 image-label">
                      {idBackLoading && <i className="fa fa-spinner fa-spin" />}
                      <div
                        className={`${
                          (options.idBack || idBackLoading) && "d-none"
                        }`}
                      >
                        <label htmlFor="idBack" className="pointer">
                          Back{" "}
                        </label>
                        <input
                          type="file"
                          className={`form-control-plaintext d-none ${
                            error?.key === "idFront" && "error-border"
                          }`}
                          id="idBack"
                          onChange={(e) =>
                            uploadImage(e.target.files[0], "idBack", e)
                          }
                          accept="image/*"
                        />
                      </div>
                      {options.idBack && !idBackLoading && (
                        <div>
                          <button
                            className="btn btn-violet btn-sm"
                            onClick={(e) =>
                              deleteImage(options.idBack, "idBack", e)
                            }
                          >
                            Delete Image
                          </button>{" "}
                          <br />
                          <img
                            src={options.idBack}
                            className="camera-icon"
                            alt="Image"
                            width="100px"
                            height="100px"
                          />
                        </div>
                      )}
                    </div>
                  </div>
                  {error?.key === "idFront" && (
                    <small className="text-danger fs-10">{error.message}</small>
                  )}
                  {error?.key === "idBack" && (
                    <small className="text-danger fs-10">{error.message}</small>
                  )}
                </div>
                <div className="mt-4">
                  <h6>
                    Optional file upload
                    <div className="text-secondary fs-10 pt-2 fw-400">
                      Only PDF format supported like discharge papers, doctor's
                      letter, test report, etc
                    </div>
                  </h6>
                  <div className="text-center image-label">
                    {optionalDocLoading && (
                      <i className="fa fa-spinner fa-spin" />
                    )}
                    <div
                      className={`${
                        (options.optionalDoc || optionalDocLoading) && "d-none"
                      }`}
                    >
                      <label htmlFor="optionalDoc" className="pointer">
                        Browse File{" "}
                      </label>
                      <input
                        type="file"
                        className="form-control-plaintext d-none"
                        id="optionalDoc"
                        onChange={(e) =>
                          uploadImage(e.target.files[0], "optionalDoc", e)
                        }
                        accept="application/pdf,image/*"
                      />
                    </div>
                    {options.optionalDoc && !optionalDocLoading && (
                      <div>
                        <button
                          className="btn btn-violet btn-sm"
                          onClick={(e) =>
                            deleteImage(options.optionalDoc, "optionalDoc", e)
                          }
                        >
                          Delete File
                        </button>{" "}
                        <br />
                        <object
                          data={options.optionalDoc}
                          className="camera-icon"
                          width="100%"
                          height="150px"
                        />
                      </div>
                    )}
                  </div>
                </div>
                <hr />
                <div className="mt-3">
                  <h6>Workplace Name *</h6>
                  <input
                    type="text"
                    className={`form-control col-12 col-sm-6 ${
                      error?.key === "orgName" && "error-border"
                    }`}
                    name="form-question"
                    placeholder="Workplace Name"
                    value={options.orgName}
                    onChange={(e) => handleChange("orgName", e.target.value)}
                  />
                  {error?.key === "orgName" && (
                    <small className="text-danger fs-10">{error.message}</small>
                  )}
                </div>
                <div className="mt-4">
                  <h6>Workplace Address *</h6>
                  <textarea
                    className={`form-control fs-12 ${
                      error?.key === "orgAddress" && "error-border"
                    }`}
                    name="form-question"
                    placeholder="Complete Workplace Address"
                    value={options.orgAddress}
                    onChange={(e) => handleChange("orgAddress", e.target.value)}
                  />
                  {error?.key === "orgAddress" && (
                    <small className="text-danger fs-10">{error.message}</small>
                  )}
                </div>
                <hr />
                <div className="row mt-2">
                  <div className="col-12 col-sm-6">
                    <h6 htmlFor="form-question">From *</h6>
                    <input
                      type="date"
                      className={`form-control ${
                        error?.key === "leaveDateFrom" && "error-border"
                      }`}
                      value={options.leaveDateFrom}
                      onChange={(e) =>
                        handleChange("leaveDateFrom", e.target.value)
                      }
                      max={moment().format("DD/MM/YYYY")}
                    />
                    {error?.key === "leaveDateFrom" && (
                      <small className="text-danger fs-10">
                        {error.message}
                      </small>
                    )}
                  </div>
                  <div className="col-12 col-sm-6 mt-4 mt-sm-0">
                    <h6 htmlFor="form-question">To *</h6>
                    <input
                      type="date"
                      className={`form-control ${
                        error?.key === "leaveDateTo" && "error-border"
                      }`}
                      value={options.leaveDateTo}
                      onChange={(e) =>
                        handleChange("leaveDateTo", e.target.value)
                      }
                      max={moment().format("DD/MM/YYYY")}
                    />
                    {error?.key === "leaveDateTo" && (
                      <small className="text-danger fs-10">
                        {error.message}
                      </small>
                    )}
                  </div>
                </div>
              </div>
              <div className={`${currentUser.isDoctor && "d-none"}`}>
                <AssignDoctorComponent
                  handleChange={handleChange}
                  options={options}
                  setOptions={setOptions}
                />
                <PaymentStatusComponent
                  handleChange={handleChange}
                  options={options}
                />
                <DoctorRemarksComponent
                  handleChange={handleChange}
                  options={options}
                  setOptions={setOptions}
                />
              </div>
              <div
                className="complete-payment btn btn-violet text-center"
                onClick={saveCertificate}
              >
                {savingCertificate ? (
                  <i className="fa fa-spinner fa-spin" />
                ) : (
                  <span>Save Record</span>
                )}
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default UpdateMedicalRequestComponent;
