import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import moment from "moment";
import "./style.css";

import { post } from "services";
import { LoadingComponent, ProgressBarComponent } from "components";
import { add, getId, update, uploadFile, removeFile } from "firebase_config";
import { Routes, Tables, Doctors } from "constants/index";
import { capitalizeFirstLetter } from "helpers";
import HeaderComponent from "./header";
import CheckPaymentStatusComponent from "./check_payment_status/component";

class ShowPostComponent extends Component {
  constructor(props) {
    super(props);
    this.propsState = props.history.location.state || {};
    this.state = {
      storageUrl:
        "https://storage.googleapis.com/medoc-live.appspot.com/images/",
      postId: props.computedMatch.params.post_id,
      post: "",
      loading: true,
      postUrl:
        process.env.REACT_APP_URL + "/" + props.computedMatch.params.post_id,
      doctorRemarks: "",
      optionalDoc: "",
      currentUser: props.currentUser,
      isDoctor: !!props.currentUser.roles.find((r) => r === "doctor"),
      currency: "INR",
      favicon:
        "https://storage.googleapis.com/medoc-live.appspot.com/images/favicon.png",
    };
  }

  componentDidMount() {
    this.loadPost();
  }

  loadPost = async () => {
    const id = this.props.computedMatch.params.post_id;
    const post = await getId(Tables.MEDICAL_REQUESTS, id);
    post.id = id;
    this.setState({
      post,
      loading: false,
      doctorRemarks: "",
    });
    if (post.doctorId) {
      this.loadUser();
    }
  };

  redirectToHome = () => {
    this.props.history.push("/");
  };

  additionalInfoRequired = async () => {
    if (!this.state.doctorRemarks) {
      toast.error(
        "Your response is required to proceed further with your request"
      );
      return;
    }
    this.setState({ loading: true });

    if (
      !this.state.currentUser.email.includes("example") &&
      this.state.post.additionalInfoRequired
    ) {
      let selectedDoctor = Doctors[0];
      const dayOfWeek = moment().day(); // returns 1-7 where 1 is Monday and 7 is Sunday
      Doctors.forEach((d) => {
        if (d.availableDays.includes(dayOfWeek)) {
          selectedDoctor = d;
        }
      });

      await post("/create_post_task", {
        id: this.state.post.id,
        path: `/requests/${this.state.post.id}`,
        assignsAfter: Date.now() / 1000 + 1200, // 20 mins
      });

      if (!this.state.currentUser.email.includes("example")) {
        await post("/send_email", {
          templateName: "patient_is_waiting.html",
          email: selectedDoctor.email,
          displayName: "Doctor",
        });
      }
    }

    let dr;
    if (this.state.isDoctor) {
      dr = {
        d2p: this.state.doctorRemarks,
        createdAt: new Date().getTime(),
        doc: this.state.optionalDoc,
      };
    } else {
      dr = {
        p2d: this.state.doctorRemarks,
        createdAt: new Date().getTime(),
        doc: this.state.optionalDoc,
      };
    }

    await update(Tables.MEDICAL_REQUESTS, this.state.post.id, {
      additionalInfoRequired: false,
      doctorRemarks: [...this.state.post.doctorRemarks, dr],
    });
    await add(Tables.LOGS, {
      text: "You received a message from patient. Reply here",
      receiverId: this.state.post.doctorId || "",
      actionLink: `/app/posts/${this.state.post.id}`,
      unread: true,
    });
    toast.success(
      "Your response sent to doctor buddy. Will get back to you soon!"
    );
    this.setState({ loading: false, doctorRemarks: "", optionalDoc: "" });
    this.loadPost();
  };

  closeRequest = async () => {
    this.setState({ loading: true });
    await update(Tables.MEDICAL_REQUESTS, this.state.post.id, {
      status: "accepted",
      additionalInfoRequired: false,
    });
    await update(Tables.USERS, this.state.currentUser.id, {
      withdrawAmount:
        this.state.currentUser.withdrawAmount +
        this.state.currentUser.consultationPay,
    });
    toast.success("Congrats! You've successfully completed the request");
    this.setState({ loading: false });
    this.loadPost();
  };

  uploadImage = async (file, key) => {
    if (!file) {
      toast.error("New image required");
      return null;
    }
    this.setState({ fileUploading: true });
    await uploadFile(file, "image", async (url, err) => {
      if (err) {
        alert(err);
        return null;
      }
      this.setState((prevState) => ({
        ...prevState,
        [key]: url,
      }));
      this.setState({ fileUploading: false });
    });
  };

  deleteImage = async (url, key) => {
    if (window.confirm("Are you sure to remove file?")) {
      this.setState({ fileUploading: true });
      await removeFile(url);
      this.setState((prevState) => ({
        ...prevState,
        [key]: "",
        fileUploading: false,
      }));
    }
  };

  redirectToCheckout = (request) => {
    this.props.history.push({
      pathname: Routes.APP_RECHARGE,
      state: {
        requestId: request.id,
        amount: request.product.price,
      },
    });
  };

  saveFeedback = async () => {
    add(Tables.MEDICAL_REQUEST_FEEDBACKS, {
      type: this.state.feedbackType,
      comment: this.state.feedbackComment,
      requestId: this.state.post.id,
      userId: this.state.currentUser.id,
    });
    await update(Tables.MEDICAL_REQUESTS, this.state.post.id, {
      feedback: true,
    });
    toast.success("Thank you for your feedback!");
    this.setState({
      feedback: true,
      feedbackType: "",
      feedbackComment: "",
    });
  };

  reassign = async () => {
    await update(Tables.MEDICAL_REQUESTS, this.state.post.id, {
      status: "pending",
      reassigned: true,
    });
    toast.success(`Request reassigned - ${this.state.post.id}!`);
    this.props.history.push({
      pathname: Routes.APP_POSTS,
    });
  };

  scrollToBottom = () => {
    this.el.scrollIntoView({ behavior: "smooth" });
  };

  redirectToEditPost = () => {
    let pathname;
    if (this.state.post.type === "caretaker_certificate") {
      pathname = Routes.APP_EDIT_CT;
    } else if (this.state.post.type === "fitness_certificate") {
      pathname = Routes.APP_EDIT_FC;
    } else if (this.state.post.type === "form_1a") {
      pathname = Routes.APP_EDIT_FA;
    } else {
      pathname = Routes.APP_EDIT_POST;
    }
    this.props.history.push({
      pathname,
      state: {
        request: this.state.post,
      },
    });
  };

  loadUser = async () => {
    const doctorDetails = await getId(Tables.USERS, this.state.post.doctorId);
    this.setState({
      doctorDetails,
    });
  };

  getProgress = () => {
    if (this.state.post.paymentStatus === "pending") {
      return {
        label: "1/6: Payment Pending",
        value: "15",
        color: "danger",
      };
    } else if (
      this.state.post.paymentStatus === "completed" &&
      this.state.post.isEditRequired
    ) {
      return {
        label: "2/6: Documents Pending",
        value: "30",
        color: "danger",
      };
    } else if (this.state.post.additionalInfoRequired) {
      return {
        label: "3/6: Doctor asked more information",
        value: "45",
        color: "danger",
      };
    } else if (!this.state.post.doctorId) {
      return {
        label: "4/6: In Waiting Time",
        value: "60",
        color: "danger",
      };
    } else if (
      this.state.post.doctorId &&
      this.state.post.status === "pending"
    ) {
      return {
        label: "5/6: Review In Progress",
        value: "75",
        color: "violet",
      };
    } else if (
      this.state.post.status === "accepted" ||
      this.state.post.status === "rejected"
    ) {
      return {
        label: "Completed",
        value: "100",
        color: "violet",
      };
    } else {
      return {
        label: "0/6: Unknown",
        value: "0",
        color: "danger",
      };
    }
  };

  render() {
    return (
      <div>
        <HeaderComponent />
        <div className="mt-5">
          <div className="dashboard-content">
            <div className="feeling-wrapper">
              <button
                className="btn btn-violet-rounded px-3 btn-sm my-3 d-none d-sm-block"
                onClick={(e) => this.props.history.push(Routes.APP_REQUESTS)}
              >
                Go Back
              </button>

              {this.state.post.status === 404 && (
                <div className="text-center mt-5 pt-5 pb-3 text-gray-6">
                  <i className="fa fa-trash fa-2x"></i>
                  <br />
                  <h5 className="pt-4">Oh no! Request has been hidden.</h5>
                </div>
              )}
              {this.state.post && this.state.post.status !== 404 && (
                <div className="card p-3">
                  {this.state.post.paymentStatus === "pending" && (
                    <div
                      className="fw-500 text-danger pointer fs-sm-12"
                      onClick={(e) => this.redirectToCheckout(this.state.post)}
                    >
                      <i className="fa fa-warning pr-1" />
                      Complete payment for doctor consultation
                      <hr />
                    </div>
                  )}
                  {this.state.post.type !== "consult_doctor" &&
                    this.state.post.additionalInfoRequired && (
                      <div
                        className="fw-500 text-violet pointer fs-sm-12"
                        onClick={(e) => this.scrollToBottom()}
                      >
                        <i className="fa fa-info-circle pr-1" />
                        Your doctor buddy is waiting for more information.
                        Respond here
                        <hr />
                      </div>
                  )}
                  {this.state.post.type !== "consult_doctor" &&
                    this.state.post.paymentStatus === "completed" &&
                    !this.state.post.additionalInfoRequired &&
                    this.state.post.status === "pending" &&
                    this.state.post.isEditRequired && (
                      <>
                        <div
                          className="d-flex flex-row align-items-center fw-500 text-danger pointer fs-sm-12"
                          onClick={(e) => this.redirectToEditPost()}
                        >
                          Documents Pending <br />
                          <div className="btn btn-danger btn-sm fs-12 ml-2">
                            Upload
                          </div>
                        </div>
                        <div className="text-secondary fs-12 mt-2">
                          Note: Your request will be reviewed only after
                          documents submission.
                        </div>{" "}
                        <hr />
                      </>
                  )}
                  {this.state.post.type !== "consult_doctor" &&
                    this.state.post.paymentStatus === "completed" &&
                    !this.state.post.additionalInfoRequired &&
                    this.state.post.status === "pending" &&
                    !this.state.post.doctorId &&
                    !this.state.post.isEditRequired && (
                      <div
                        className="fw-500 text-violet pointer fs-sm-12"
                        onClick={(e) => this.scrollToBottom()}
                      >
                        <i className="fa fa-clock-o pr-1" />
                        Your request is in queue (Waiting Time)
                        <hr />
                      </div>
                  )}
                  {this.state.post.type !== "consult_doctor" &&
                    this.state.post.paymentStatus === "completed" &&
                    !this.state.post.additionalInfoRequired &&
                    this.state.post.status === "pending" &&
                    this.state.post.doctorId &&
                    !this.state.post.isEditRequired && (
                      <div
                        className="fw-500 text-violet pointer fs-sm-12"
                        onClick={(e) => this.scrollToBottom()}
                      >
                        <i className="fa fa-info-circle pr-1" />
                        Review is in progress. You will be notified via email
                        once the review is done.
                        <hr />
                      </div>
                  )}
                  {!this.state.isDoctor &&
                    this.state.post.status === "accepted" &&
                    !this.state.post.feedback && (
                      <div>
                        <a
                          href="https://www.trustpilot.com/evaluate/medoc.life"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <span className="fs-12 text-violet">
                            How's your Medoc experience?
                          </span>
                          <span className="px-2">
                            <img
                              src={`${this.state.storageUrl}${
                                this.state.feedbackType === "like"
                                  ? "like.png"
                                  : "like_outline.png"
                              }`}
                              alt="like"
                              className="feedback-img"
                              onClick={(e) =>
                                this.setState({ feedbackType: "" })
                              }
                            />
                          </span>
                          <span>
                            <img
                              src={`${this.state.storageUrl}${
                                this.state.feedbackType === "dislike"
                                  ? "dislike.png"
                                  : "dislike_outline_1.png"
                              }`}
                              alt="dislike"
                              className="feedback-img"
                              onClick={(e) =>
                                this.setState({ feedbackType: "" })
                              }
                            />
                          </span>
                        </a>
                        <br />
                        {this.state.feedbackType && (
                          <div className="fs-10">
                            <textarea
                              className="form-control font-small col-12 col-5 mt-2"
                              placeholder="Write a comment!"
                              onChange={(e) =>
                                this.setState({
                                  feedbackComment: e.target.value,
                                })
                              }
                            />
                            <div className="mt-2">
                              <button
                                className="btn btn-violet btn-sm fs-10 mr-2"
                                onClick={this.saveFeedback}
                              >
                                Submit
                              </button>
                              <span className="d-none">
                                * Use promocode <b>MEDOC_20D</b> to avail 20%
                                discount on your next purchase!
                              </span>
                            </div>
                          </div>
                        )}
                        <hr />
                      </div>
                  )}
                  <div className="d-none">
                    {this.state.post.status === "pending" && (
                      <ProgressBarComponent progress={this.getProgress()} />
                    )}
                    {this.state.post.paymentStatus === "pending" && (
                      <CheckPaymentStatusComponent
                        currentUser={this.state.currentUser}
                        request={this.state.post}
                      />
                    )}
                  </div>
                  {this.state.isDoctor &&
                    this.state.post.status === "accepted" && (
                      <div className="fs-10">
                        <div className="mt-2">
                          <button
                            className="btn btn-violet btn-sm pull-right"
                            onClick={this.reassign}
                          >
                            Re-assign
                          </button>
                        </div>
                      </div>
                  )}
                  <div className="row">
                    {this.state.post.doctorId && (
                      <>
                        <div className="mt-2 col-12">
                          <div className="font-small fw-500 text-secondary">
                            Assigned to (doctor details)
                          </div>
                          {this.state.doctorDetails && (
                            <div className="d-flex flex-row align-items-center mt-2">
                              <img
                                src={
                                  this.state.doctorDetails.photoURL ||
                                  this.state.favicon
                                }
                                className="doctor-img"
                                alt="Doctor photo"
                              />
                              <div>
                                <div className="fw-500">
                                  Dr. {this.state.doctorDetails.displayName}
                                </div>
                                <div className="fs-12 d-none">
                                  {this.state.doctorDetails.qualification} |
                                  Reg. No. {this.state.doctorDetails.nmcNumber}
                                </div>
                                <div className="fs-12 pt-1">
                                  <i className="fa fa-envelope-o pr-1" />{" "}
                                  {this.state.doctorDetails.email}
                                </div>
                                <a
                                  href={`https://medoc.life/dr/${this.state.doctorDetails.username}`}
                                  target="_blank"
                                  rel="noreferrer noopener"
                                  className="fs-12 text-violet"
                                >
                                  <i className="fa fa-hospital-o pr-1 fs-16" />{" "}
                                  Contact me at my Virtual Clinic. Many other
                                  services are available.
                                </a>
                              </div>
                            </div>
                          )}
                        </div>
                      </>
                    )}
                    <div className="mt-3 col-12 col-sm-6">
                      <div className="font-small fw-500 text-secondary">
                        Status
                      </div>
                      <div>{capitalizeFirstLetter(this.state.post.status)}</div>
                    </div>
                    <div className="mt-3 col-12 col-sm-6">
                      <div className="font-small fw-500 text-secondary">
                        Last updated
                      </div>
                      <div>
                        {moment(this.state.post.updatedAt).format(
                          "DD-MM-YYYY, HH:mm"
                        )}
                      </div>
                    </div>
                    <div className="mt-3 col-12 col-sm-6">
                      <div className="font-small fw-500 text-secondary">
                        Created At
                      </div>
                      <div>
                        {moment(this.state.post.createdAt).format(
                          "DD-MM-YYYY, HH:mm"
                        )}
                      </div>
                    </div>
                    <div className="mt-3 col-12 col-sm-6">
                      <div className="font-small fw-500 text-secondary">
                        Request type
                      </div>
                      <div>
                        {this.state.post.type
                          ? capitalizeFirstLetter(
                            this.state.post.type.replace(/_/g, " ")
                          )
                          : "Sick leave certificate"}
                      </div>
                    </div>
                    <div className="mt-3 col-12 col-sm-6">
                      <div className="font-small fw-500 text-secondary">
                        Request number
                      </div>
                      <div>{this.state.post.id}</div>
                    </div>
                    {this.state.post.certLink && (
                      <div className="mt-3 col-12 col-sm-6">
                        <div className="font-small fw-500 text-secondary">
                          Certificate Link
                        </div>
                        <a
                          href={this.state.post.certLink}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="text-violet fw-500"
                        >
                          Download here{" "}
                          <i className="fa fa-arrow-circle-o-down" />
                        </a>
                      </div>
                    )}
                    {this.state.post.prescriptionLink && (
                      <div className="mt-3 col-12 col-sm-6">
                        <div className="font-small fw-500 text-secondary">
                          Prescription Link
                        </div>
                        <a
                          href={this.state.post.prescriptionLink}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="text-violet fw-500"
                        >
                          Download here{" "}
                          <i className="fa fa-arrow-circle-o-down" />
                        </a>
                      </div>
                    )}
                    <div className="mt-3 col-12">
                      <div className="font-small fw-500 text-secondary">
                        Name
                      </div>
                      <div>
                        {capitalizeFirstLetter(this.state.post.firstName)}{" "}
                        {this.state.post.lastName}
                      </div>
                    </div>
                    <div className="mt-3 col-12 col-sm-6">
                      <div className="font-small fw-500 text-secondary">
                        Date of birth
                      </div>
                      <div>
                        {moment(this.state.post.dob).format("DD-MM-YYYY")}
                      </div>
                    </div>
                    <div className="mt-3 col-12 col-sm-6">
                      <div className="font-small fw-500 text-secondary">
                        Gender
                      </div>
                      <div>{capitalizeFirstLetter(this.state.post.gender)}</div>
                    </div>
                    <div className="mt-3 col-12 col-sm-6">
                      <div className="font-small fw-500 text-secondary">
                        Email
                      </div>
                      <div>{this.state.post.email}</div>
                    </div>
                    <div className="mt-3 col-12 col-sm-6">
                      <div className="font-small fw-500 text-secondary">
                        Mobile
                      </div>
                      <div>{this.state.post.mobile}</div>
                    </div>
                    {this.state.post.height && (
                      <div className="mt-3 col-12 col-sm-6">
                        <div className="font-small fw-500 text-secondary">
                          Height
                        </div>
                        <div>
                          {this.state.post.height}{" "}
                          {this.state.post.heightMeasurement}
                        </div>
                      </div>
                    )}
                    {this.state.post.weight && (
                      <div className="mt-3 col-12 col-sm-6">
                        <div className="font-small fw-500 text-secondary">
                          Weight
                        </div>
                        <div>
                          {this.state.post.weight}{" "}
                          {this.state.post.weightMeasurement}
                        </div>
                      </div>
                    )}
                    {!this.state.post.type && (
                      <div className="mt-3 col-12 col-sm-6">
                        <div className="font-small fw-500 text-secondary">
                          Leave reason
                        </div>
                        <div>{this.state.post.leaveReason}</div>
                      </div>
                    )}
                    {!this.state.post.type && (
                      <div className="mt-3 col-12 col-sm-6">
                        <div className="font-small fw-500 text-secondary">
                          Leave from
                        </div>
                        <div>{this.state.post.leaveFrom}</div>
                      </div>
                    )}
                    {this.state.post.leaveDateFrom && (
                      <div className="mt-3 col-6">
                        <div className="font-small fw-500 text-secondary">
                          {!this.state.post.type === "fitness_certificate"
                            ? "Fitness date from"
                            : "From"}
                        </div>
                        <div>
                          {moment(this.state.post.leaveDateFrom).format(
                            "DD-MM-YYYY"
                          )}
                        </div>
                      </div>
                    )}
                    {this.state.post.leaveDateTo && (
                      <div className="mt-3 col-6">
                        <div className="font-small fw-500 text-secondary">
                          To
                        </div>
                        <div>
                          {moment(this.state.post.leaveDateTo).format(
                            "DD-MM-YYYY"
                          )}
                        </div>
                      </div>
                    )}
                    {this.state.post.careOf && (
                      <>
                        <h5 className="col-12 mt-3 text-center">Care of</h5>
                        <hr />
                        <div className="col-12">
                          <div className="font-small fw-500 text-secondary">
                            Relationship
                          </div>
                          <div>{this.state.post.relationship}</div>
                        </div>
                        <div className="col-12">
                          <div className="font-small fw-500 text-secondary">
                            Name
                          </div>
                          <div>
                            {capitalizeFirstLetter(
                              this.state.post.careOf.firstName
                            )}{" "}
                            {this.state.post.careOf.lastName}
                          </div>
                        </div>
                        <div className="mt-3 col-12 col-sm-6">
                          <div className="font-small fw-500 text-secondary">
                            Date of birth
                          </div>
                          <div>
                            {moment(this.state.post.careOf.dob).format(
                              "DD-MM-YYYY"
                            )}
                          </div>
                        </div>
                        <div className="mt-3 col-12 col-sm-6">
                          <div className="font-small fw-500 text-secondary">
                            Gender
                          </div>
                          <div>
                            {capitalizeFirstLetter(
                              this.state.post.careOf.gender
                            )}
                          </div>
                        </div>
                      </>
                    )}
                    {this.state.post.reason && (
                      <div className="mt-3 col-12">
                        <div className="font-small fw-500 text-secondary">
                          {this.state.post.type === "recovery_certificate"
                            ? "Recovered from"
                            : "Reason for certificate"}
                        </div>
                        <div>
                          {this.state.post.reason === "Other"
                            ? this.state.post.otherReason
                            : this.state.post.reason}
                        </div>
                      </div>
                    )}
                    {this.state.post.reasonDescription && (
                      <div className="mt-3 col-12">
                        <div className="font-small fw-500 text-secondary">
                          Reason description
                        </div>
                        <div>{this.state.post.reasonDescription}</div>
                      </div>
                    )}
                    {this.state.post.orgName && (
                      <div className="mt-3 col-12">
                        <div className="font-small fw-500 text-secondary">
                          Certificate submitted to (Organisation name & address)
                        </div>
                        <div>
                          {this.state.post.orgName ||
                            "No organisation provided"}{" "}
                          <br />
                          {this.state.post.orgAddress}
                        </div>
                      </div>
                    )}
                    {this.state.post.idFront && this.state.post.idBack && (
                      <div className="mt-3 col-12">
                        <div className="font-small fw-500 text-secondary">
                          Photo Id
                        </div>
                        <a
                          href={this.state.post.idFront}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="text-violet"
                        >
                          Front side
                        </a>
                        &nbsp; &nbsp; &nbsp;
                        <a
                          href={this.state.post.idBack}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="text-violet"
                        >
                          Rear side
                        </a>
                      </div>
                    )}
                    {this.state.post.videoDoc && (
                      <div className="mt-3 col-12">
                        <div className="font-small fw-500 text-secondary">
                          Video evidence
                        </div>
                        <a
                          href={this.state.post.videoDoc}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="text-violet"
                        >
                          Click here
                        </a>
                      </div>
                    )}
                    {this.state.post.optionalDoc && (
                      <div className="mt-3 col-12">
                        <div className="font-small fw-500 text-secondary">
                          {!this.state.post.type
                            ? "Optional Document"
                            : "Photo proof of blood pressure & pulse"}
                        </div>
                        <a
                          href={this.state.post.optionalDoc}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="text-violet"
                        >
                          Click here
                        </a>
                      </div>
                    )}
                    {this.state.post.files && this.state.post.files.length > 0 && (
                      <>
                        <div className="mt-2 col-12">
                          <div className="font-small fw-500 text-secondary">
                            All Documents
                          </div>
                          {this.state.post.files.map((file, i) => (
                            <div className="mt-2" key={i}>
                              <a
                                href={file.url}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="text-violet"
                              >
                                Document {i + 1}
                              </a>
                            </div>
                          ))}
                        </div>
                      </>
                    )}
                    {!this.state.isDoctor && (
                      <>
                        <div className="mt-2 col-12">
                          <div className="font-small fw-500 text-secondary">
                            My Product
                          </div>
                          <div className="mt-2">
                            {this.state.post.product.name} -{" "}
                            {this.state.post.product.price}{" "}
                            {this.state.currency}/-
                            <br />{" "}
                            <small>
                              {" "}
                              {this.state.post.product.description}{" "}
                            </small>
                          </div>
                        </div>
                      </>
                    )}
                    {this.state.post.doctorRemarks &&
                      this.state.post.doctorRemarks.length > 0 && (
                        <div className="mt-3 col-12">
                          <div className="font-small fw-500 text-secondary">
                            Doctor remarks
                          </div>
                          {this.state.post.doctorRemarks.map((remark, idx) => (
                            <div className="my-1" key={idx}>
                              {remark.d2p && (
                                <div>
                                  <b>doctor to patient</b>: {remark.d2p}{" "}
                                  <a
                                    href={remark.doc}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className={`${!remark.doc && "d-none"}`}
                                  >
                                    <i className="fa fa-paperclip text-violet fs-20 mr-2"></i>
                                  </a>
                                  <span className="text-secondary fs-10">
                                    {moment(remark.createdAt).format(
                                      "hh:mm DD/MM/YY"
                                    )}
                                  </span>
                                </div>
                              )}
                              {remark.p2d && (
                                <div>
                                  <b>patient to doctor</b>: {remark.p2d}{" "}
                                  <a
                                    href={remark.doc}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className={`${!remark.doc && "d-none"}`}
                                  >
                                    <i className="fa fa-paperclip text-violet fs-20 mr-2"></i>
                                  </a>
                                  <span className="text-secondary fs-10">
                                    {moment(remark.createdAt).format(
                                      "hh:mm DD/MM/YY"
                                    )}
                                  </span>
                                </div>
                              )}
                            </div>
                          ))}
                        </div>
                    )}
                    {this.state.post.status === "pending" &&
                      !this.state.post.isEditRequired &&
                      this.state.post.paymentStatus === "completed" && (
                        <>
                          <div className="mt-3 col-12">
                            <div className="font-small fw-500 text-secondary">
                              Add your message to doctor (if any):
                            </div>
                            <div>
                              <textarea
                                className="form-control font-small"
                                placeholder="Type here"
                                value={this.state.doctorRemarks}
                                onChange={(e) =>
                                  this.setState({
                                    doctorRemarks: e.target.value,
                                  })
                                }
                              />
                            </div>
                            <div className="image-label">
                              <div
                                className={`${
                                  this.state.optionalDoc && "d-none"
                                }`}
                              >
                                <label
                                  htmlFor="optionalDoc"
                                  className="pointer"
                                >
                                  <div className="btn btn-violet-outline btn-sm mt-2 pointer">
                                    {this.state.fileUploading ? (
                                      <i className="fa fa-spinner fa-spin" />
                                    ) : (
                                      <i className="fa fa-paperclip" />
                                    )}
                                  </div>
                                </label>
                                <input
                                  type="file"
                                  className="form-control-plaintext d-none"
                                  id="optionalDoc"
                                  onChange={(e) =>
                                    this.uploadImage(
                                      e.target.files[0],
                                      "optionalDoc"
                                    )
                                  }
                                  accept="application/pdf,image/*,video/*"
                                />
                              </div>
                              {this.state.optionalDoc && (
                                <div className="alert alert-secondary py-1 mt-2">
                                  Document
                                  <div
                                    className="pointer pull-right"
                                    onClick={(e) =>
                                      this.deleteImage(
                                        this.state.optionalDoc,
                                        "optionalDoc"
                                      )
                                    }
                                  >
                                    {this.state.fileUploading ? (
                                      <i className="fa fa-spinner fa-spin" />
                                    ) : (
                                      <i className="fa fa-trash text-danger" />
                                    )}
                                  </div>
                                </div>
                              )}
                            </div>
                            <div className="text-center">
                              <button
                                className="btn btn-violet-outline btn-sm px-3 my-2"
                                onClick={this.additionalInfoRequired}
                                disabled={this.state.loading}
                              >
                                Submit
                              </button>
                            </div>
                          </div>
                        </>
                    )}
                    {this.state.isDoctor &&
                      this.state.post.status !== "accepted" &&
                      this.state.post.closeRequestTime < moment().valueOf() && (
                        <div className="text-center">
                          <button
                            className="btn btn-violet btn-sm px-3 m-2 ml-3"
                            onClick={this.closeRequest}
                            disabled={this.state.loading}
                          >
                            Close request
                          </button>
                        </div>
                    )}
                    <div
                      ref={(el) => {
                        this.el = el;
                      }}
                    ></div>
                  </div>
                </div>
              )}
            </div>
            {this.state.loading && <LoadingComponent />}
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(ShowPostComponent);
