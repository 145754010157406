import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

import { HeaderComponent } from "components";
import { getId } from "firebase_config";
import { Tables } from "constants/index";
import SickLeaveComponent from "./sick_leave/component";
import FitnessComponent from "./fitness/component";
import Form1aComponent from "./form_1a/component";
import CompleteDetailsComponent from "./complete_details/component";
import PaymentFixComponent from "./payment_fix/component";

const MedicalRequestsComponent = (props) => {
  const { currentUser, location } = props;
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState();
  const [searchValue, setSearchValue] = useState();
  const [doctorDetails, setDoctorDetails] = useState();
  const history = useHistory();

  useEffect(() => {
    if (location?.state?.request) {
      setOptions(location.state.request);
      setSearchValue(location.state.request.id);
    }
  }, [location]);

  const findRecord = async () => {
    setLoading(true);
    const record = await getId(Tables.MEDICAL_REQUESTS, searchValue);
    setLoading(false);
    if (record.status === 404) {
      toast.error("Request number is invalid");
    } else {
      if (record.doctorId) {
        const user = await getId(Tables.USERS, record.doctorId);
        setDoctorDetails(user);
      }
      setOptions(record);
    }
  };

  const cancelSearch = () => {
    history.goBack();
  };

  return (
    <>
      <HeaderComponent />
      <div className="dashboard-content">
        <div className="container create-post-wrapper pt-5 mt-sm-5">
          <div className={`${currentUser.isDoctor && "d-none"}`}>
            <h3>Update Record</h3>
            <div className="row mt-4">
              <div className="col-12 col-sm-12">
                <h6 htmlFor="form-question">
                  Enter Request Number (alpha-numeric) *
                </h6>
                <input
                  type="text"
                  className="form-control"
                  name="form-question"
                  placeholder="Enter Request Number"
                  value={searchValue}
                  onChange={(e) => setSearchValue(e.target.value.trim())}
                />
              </div>
            </div>
            <div className="btn btn-violet btn-sm mt-2" onClick={findRecord}>
              Search
            </div>
            <div
              className="btn btn-outline-danger btn-sm mt-2 ml-2"
              onClick={cancelSearch}
            >
              Cancel
            </div>
            {loading && <i className="fa fa-spinner fa-spin" />}
          </div>
          {searchValue && options && !loading && (
            <>
              <h6 className="mt-5 text-center">
                Request Number: {searchValue}
              </h6>
              {currentUser.isAdmin && (
                <>
                  <PaymentFixComponent
                    searchValue={searchValue}
                    options={options}
                    setOptions={setOptions}
                  />
                  <CompleteDetailsComponent
                    currentUser={currentUser}
                    options={options}
                    doctorDetails={doctorDetails}
                  />
                </>
              )}
              {!options.type && (
                <SickLeaveComponent
                  options={options}
                  setOptions={setOptions}
                  currentUser={currentUser}
                  searchValue={searchValue}
                  setSearchValue={setSearchValue}
                />
              )}
              {options.type === "fitness_certificate" && (
                <FitnessComponent
                  options={options}
                  setOptions={setOptions}
                  currentUser={currentUser}
                  searchValue={searchValue}
                  setSearchValue={setSearchValue}
                />
              )}
              {(options.type === "form_1a" ||
                options.type === "custom_certificate") && (
                <Form1aComponent
                  options={options}
                  setOptions={setOptions}
                  currentUser={currentUser}
                  searchValue={searchValue}
                  setSearchValue={setSearchValue}
                />
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default MedicalRequestsComponent;
