import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import moment from "moment";
import "./style.css";

import HeaderComponent from "../header";
import {
  add,
  update,
  uploadFile,
  removeFile,
  getQuery,
  firestore,
} from "firebase_config";
import { Tables, Routes, Products } from "constants/index";
import AcceptTermsComponent from "../accept_terms/component";

const currency = "INR";
const products = Products.custom;

const CustomCertificateComponent = ({ currentUser, location }) => {
  const [options, setOptions] = useState({
    status: "pending",
    confirmTnc: false,
    firstName: "",
    lastName: "",
    dob: "",
    age: "",
    mobile: "",
    email: "",
    address: {
      country: "India",
    },
    reasonDescription: "",
    optionalDoc: "",
    files: [],
    gender: "male",
    userId: currentUser.id,
    assigned: false,
    product: products[0],
    paymentStatus: "pending",
    doctorRemarks: [],
    isEditRequired: false,
    additionalInfoRequired: false,
    promocode: "",
    type: "custom_certificate",
    subType: location.state?.type || "",
    customTemplateExists: true,
    defaultMessage: "",
  });
  const history = useHistory();
  const [smallDevice, setSmallDevice] = useState(false);
  const [countChars, setCountChars] = useState(25);
  const [savingCertificate, setSavingCertificate] = useState(false);
  const [error, setError] = useState({
    message: "",
    key: "",
  });
  const [optionalDocLoading, setOptionalDocLoading] = useState(false);
  const [applyDiscount, setApplyDiscount] = useState();
  const [discountPrice, setDiscountPrice] = useState();
  const [files, setFiles] = useState([]);
  const [stepNumber, setStepNumber] = useState(1);

  useEffect(() => {
    if (window.innerWidth <= 576) {
      setSmallDevice(true);
    } else {
      setSmallDevice(false);
    }
  });

  const handleChange = (key, value, additionalField) => {
    if (key === "otherReason") {
      const max = 20;
      setCountChars(max - value.length);
    }
    if (additionalField) {
      setOptions({
        ...options,
        [key]: {
          ...options[key],
          [additionalField]: value,
        },
      });
    } else {
      setOptions({
        ...options,
        [key]: value,
      });
    }
    if (key === "product") {
      verifyPromoCode(value);
    }
  };

  const uploadImage = async (file, key, event) => {
    if (!file) {
      toast.error("New image required");
      return null;
    }
    setOptionalDocLoading(true);
    await uploadFile(file, "image", async (url, err) => {
      if (err) {
        alert(err);
        return null;
      }
      event.target.value = null;
      setFiles([
        ...files,
        {
          name: file.name,
          type: file.type,
          url,
        },
      ]);
      setOptionalDocLoading(false);
    });
  };

  const deleteImage = async (url, key, event) => {
    if (window.confirm("Are you sure to remove file?")) {
      setOptionalDocLoading(true);
      await removeFile(url);

      const f = files.filter((f) => f.url !== url);
      setFiles(f);
      setOptionalDocLoading(false);
    }
  };

  const showErrors = () => {
    let error;
    if (!options.firstName) {
      error = {
        message: "First name is required",
        key: "firstName",
      };
    } else if (!options.lastName) {
      error = {
        message: "Last name is required",
        key: "lastName",
      };
    } else if (!options.dob) {
      error = {
        message: "Date of birth is required",
        key: "dob",
      };
    } else if (!options.age) {
      error = {
        message: "Age is required",
        key: "age",
      };
    } else if (!options.email) {
      error = {
        message: "Email is required",
        key: "email",
      };
    } else if (!options.mobile) {
      error = {
        message: "Mobile number is required",
        key: "mobile",
      };
    } else if (options.mobile && options.mobile.length !== 12) {
      error = {
        message: "Mobile number should contain 12-digits",
        key: "mobile",
      };
    } else if (options.address && !options.address.door) {
      error = {
        message: "Door number is required",
        key: "door",
      };
    } else if (options.address && !options.address.streetName) {
      error = {
        message: "Street name is required",
        key: "streetName",
      };
    } else if (options.address && !options.address.city) {
      error = {
        message: "City is required",
        key: "city",
      };
    } else if (options.address && !options.address.state) {
      error = {
        message: "State is required",
        key: "state",
      };
    } else if (options.address && !options.address.zipcode) {
      error = {
        message: "Postal / Zip code is required ",
        key: "zipcode",
      };
    } else if (!options.customTemplateExists && !options.defaultMessage) {
      error = {
        message: "Text written on certificate is required",
        key: "defaultMessage",
      };
    } else if (
      !options.customTemplateExists &&
      options.defaultMessage &&
      options.defaultMessage.length > 300
    ) {
      error = {
        message:
          "Text written on certificate should be less than 300 characters",
        key: "defaultMessage",
      };
    } else if (options.customTemplateExists && files && files.length < 3) {
      error = {
        message:
          "Upload Govt. issued ID (Front & Back) and Certificate template",
        key: "files",
      };
    } else if (!options.customTemplateExists && files && files.length < 2) {
      error = {
        message: "Upload Govt. issued ID (Front & Back)",
        key: "files",
      };
    } else if (!options.orgName) {
      error = {
        message: "Organization name is required",
        key: "orgName",
      };
    } else if (!options.orgAddress) {
      error = {
        message: "Organization address is required",
        key: "orgAddress",
      };
    } else if (!options.product) {
      error = {
        message: "Choose a product",
        key: "product",
      };
    }
    setError(error);
    return error;
  };

  const saveCertificate = async () => {
    const err = await showErrors();
    if (err && err.message) {
      toast.error(err.message);
      return;
    }

    setSavingCertificate(true);
    options.priority = options.product.priority;

    options.product = {
      ...options.product,
      price: parseInt(totalCost()),
    };
    options.defaultMessage +=
      " I also certify that I have examined the required medical history and have taken them into consideration in arriving at my decision.";

    if (files.length > 0) {
      options.files = files;
    }

    const res = await add(Tables.MEDICAL_REQUESTS, options);
    setSavingCertificate(false);
    if (res.status === 200) {
      toast.success(
        "Your request has been saved. Complete the payment to proceed further."
      );
      history.push({
        pathname: Routes.APP_RECHARGE,
        state: {
          amount: options.product.price,
          requestId: res.data.id,
        },
      });
    }
  };

  const verifyPromoCode = async (val) => {
    const cc = await getQuery(
      firestore
        .collection(Tables.PROMOCODES)
        .where("code", "==", options.promocode)
        .get()
    );
    const pcode = cc[0];
    const unixDate = moment().unix() * 1000;
    if (
      pcode &&
      pcode.code === options.promocode &&
      unixDate < pcode.expiresOn
    ) {
      if (pcode.type === "limited" && pcode.count > 0) {
        // limited promocodes
        update(Tables.PROMOCODES, pcode.id, {
          count: pcode.count - 1,
        });
        let dp = options.product.price * (1 - pcode.discountPercent);
        if (val) {
          dp = val.price * (1 - pcode.discountPercent);
        }
        setApplyDiscount(pcode);
        setDiscountPrice(dp);
        setError({
          key: "",
          message: "",
        });
      } else if (pcode.type !== "limited") {
        // if type is influencer
        let dp = options.product.price * (1 - pcode.discountPercent);
        if (val) {
          dp = val.price * (1 - pcode.discountPercent);
        }
        setApplyDiscount(pcode);
        setDiscountPrice(dp);
        setError({
          key: "",
          message: "",
        });
      } else {
        setError({
          key: "promocode",
          message: "Code is invalid",
        });
      }
    } else {
      setError({
        key: "promocode",
        message: "Code is invalid",
      });
    }
  };

  const totalCost = () => {
    if (discountPrice) {
      let p = discountPrice;
      if (options.isPrescriptionRequired) {
        p += options.product.prescriptionPrice;
      } else {
        p = discountPrice;
      }
      return p.toFixed(2);
    } else if (options.orgLocation === "abroad") {
      let p = options.product.abroadPrice;
      if (options.isPrescriptionRequired) {
        p += options.product.prescriptionPrice;
      } else {
        p = options.product.abroadPrice;
      }
      return p.toFixed(2);
    } else {
      let p = options.product.price;
      if (options.isPrescriptionRequired) {
        p += options.product.prescriptionPrice;
      } else {
        p = options.product.price;
      }
      return p.toFixed(2);
    }
  };

  return (
    <>
      <HeaderComponent
        headerText={location.state?.headerText || "Medical Certificate"}
      />
      {stepNumber === 1 && (
        <AcceptTermsComponent
          options={options}
          setStepNumber={setStepNumber}
          refundAmount={500}
          handleChange={handleChange}
        />
      )}
      {stepNumber === 2 && (
        <>
          <div className="dashboard-content">
            <div className="container create-post-wrapper pt-5 mt-sm-5">
              <div className="card p-2 p-sm-4 my-sm-5">
                <div className="">
                  <h6>
                    Full name of the person * <br />
                    <small className="text-secondary fs-10">
                      *As per Govt ID proof
                    </small>
                  </h6>
                  <div className="row">
                    <div className="col-12 col-sm-6">
                      <input
                        type="text"
                        className={`form-control font-small ${
                          error?.key === "firstName" && "error-border"
                        }`}
                        name="form-question"
                        placeholder="First name"
                        onChange={(e) =>
                          handleChange("firstName", e.target.value)
                        }
                      />
                      {error?.key === "firstName" ? (
                        <small className="text-danger fs-10">
                          {error.message}
                        </small>
                      ) : (
                        <small className="text-secondary fs-10">
                          First name
                        </small>
                      )}
                    </div>
                    <div className="col-12 col-sm-6 mt-4 mt-sm-0">
                      <input
                        type="text"
                        className={`form-control font-small ${
                          error?.key === "lastName" && "error-border"
                        }`}
                        name="form-question"
                        placeholder="Last name"
                        onChange={(e) =>
                          handleChange("lastName", e.target.value)
                        }
                      />
                      {error?.key === "lastName" ? (
                        <small className="text-danger fs-10">
                          {error.message}
                        </small>
                      ) : (
                        <small className="text-secondary fs-10">
                          Last name
                        </small>
                      )}
                    </div>
                  </div>
                </div>
                <div className="row mt-4">
                  <div className="col-12 col-sm-6">
                    <h6>Date of birth *</h6>
                    <input
                      type="date"
                      className={`form-control ${
                        error?.key === "dob" && "error-border"
                      }`}
                      name="form-question"
                      placeholder="dob"
                      onChange={(e) => handleChange("dob", e.target.value)}
                    />
                    {error?.key === "dob" && (
                      <small className="text-danger fs-10">
                        {error.message}
                      </small>
                    )}
                  </div>
                  <div className="col-12 col-sm-6 mt-3 mt-sm-0">
                    <h6>Age *</h6>
                    <input
                      type="number"
                      className={`form-control ${
                        error?.key === "age" && "error-border"
                      }`}
                      name="form-question"
                      placeholder="Age"
                      onChange={(e) => handleChange("age", e.target.value)}
                    />
                    {error?.key === "age" && (
                      <small className="text-danger fs-10">
                        {error.message}
                      </small>
                    )}
                  </div>
                </div>
                <div className="mt-4">
                  <h6>Gender *</h6>
                  <select
                    className="form-control font-small"
                    aria-label=".form-select-sm"
                    onChange={(e) => handleChange("gender", e.target.value)}
                  >
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                    <option value="other">Other</option>
                  </select>
                </div>
                <div className="row mt-4">
                  <div className="col-12 col-sm-6">
                    <h6 htmlFor="form-question">Email *</h6>
                    <input
                      type="email"
                      className={`form-control ${
                        error?.key === "email" && "error-border"
                      }`}
                      name="form-question"
                      placeholder="Email"
                      onChange={(e) => handleChange("email", e.target.value)}
                    />
                    {error?.key === "email" && (
                      <small className="text-danger fs-10">
                        {error.message}
                      </small>
                    )}
                  </div>
                  <div className="col-12 col-sm-6 mt-4 mt-sm-0">
                    <h6 htmlFor="form-question">Mobile *</h6>
                    <input
                      type="number"
                      className={`form-control ${
                        error?.key === "mobile" && "error-border"
                      }`}
                      name="form-question"
                      placeholder="917890789090"
                      onChange={(e) => handleChange("mobile", e.target.value)}
                    />
                    <small className="text-secondary fs-10">
                      Only India(+91) numbers accepted. e.g. 917890789090.
                    </small>
                    {error?.key === "mobile" && (
                      <div className="text-danger fs-10">{error.message}</div>
                    )}
                  </div>
                </div>
                <div className="mt-4">
                  <h6>
                    Address *<br />
                    <small className="text-secondary fs-10">
                      *As per Govt ID proof.
                    </small>
                  </h6>
                  <div className="row">
                    <div className="col-12 col-sm-6 my-2">
                      <input
                        type="text"
                        className={`form-control ${
                          error?.key === "door" && "error-border"
                        }`}
                        name="form-question"
                        placeholder="Door no."
                        onChange={(e) =>
                          handleChange("address", e.target.value, "door")
                        }
                      />
                      {error?.key === "door" ? (
                        <small className="text-danger fs-10">
                          {error.message}
                        </small>
                      ) : (
                        <small className="text-secondary fs-10">
                          Door number
                        </small>
                      )}
                    </div>
                    <div className="col-12 col-sm-6 my-2">
                      <input
                        type="text"
                        className={`form-control ${
                          error?.key === "streetName" && "error-border"
                        }`}
                        name="form-question"
                        placeholder="Street name"
                        onChange={(e) =>
                          handleChange("address", e.target.value, "streetName")
                        }
                      />
                      {error?.key === "streetName" ? (
                        <small className="text-danger fs-10">
                          {error.message}
                        </small>
                      ) : (
                        <small className="text-secondary fs-10">
                          Street name
                        </small>
                      )}
                    </div>
                    <div className="col-12 col-sm-6 my-2">
                      <input
                        type="text"
                        className={`form-control ${
                          error?.key === "city" && "error-border"
                        }`}
                        name="form-question"
                        placeholder="City"
                        onChange={(e) =>
                          handleChange("address", e.target.value, "city")
                        }
                      />
                      {error?.key === "city" ? (
                        <small className="text-danger fs-10">
                          {error.message}
                        </small>
                      ) : (
                        <small className="text-secondary fs-10">City</small>
                      )}
                    </div>
                    <div className="col-12 col-sm-6 my-2">
                      <input
                        type="text"
                        className={`form-control ${
                          error?.key === "state" && "error-border"
                        }`}
                        name="form-question"
                        placeholder="State / Region / Province"
                        onChange={(e) =>
                          handleChange("address", e.target.value, "state")
                        }
                      />
                      {error?.key === "state" ? (
                        <small className="text-danger fs-10">
                          {error.message}
                        </small>
                      ) : (
                        <small className="text-secondary fs-10">
                          State / Region / Province
                        </small>
                      )}
                    </div>
                    <div className="col-12 col-sm-6 my-2">
                      <input
                        type="text"
                        className={`form-control ${
                          error?.key === "zipcode" && "error-border"
                        }`}
                        name="form-question"
                        placeholder="Postal/Zip Code"
                        onChange={(e) =>
                          handleChange("address", e.target.value, "zipcode")
                        }
                      />
                      {error?.key === "zipcode" ? (
                        <small className="text-danger fs-10">
                          {error.message}
                        </small>
                      ) : (
                        <small className="text-secondary fs-10">
                          Postal / Zip code
                        </small>
                      )}
                    </div>
                    <div className="col-12 col-sm-6 my-2">
                      <select
                        className="form-control font-small"
                        aria-label=".form-select-sm"
                        onChange={(e) =>
                          handleChange("address", e.target.value, "country")
                        }
                      >
                        <option value="india" defaultValue>
                          India
                        </option>
                      </select>
                      <small className="text-secondary fs-10">Country</small>
                    </div>
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-12 mt-2 mt-sm-0">
                    <h6>Do you have an approved certificate format? *</h6>
                    <div className="mt-2">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="leaveFrom"
                        id="leaveFrom-1"
                        onChange={(e) =>
                          handleChange("customTemplateExists", true)
                        }
                        checked={options.customTemplateExists}
                      />
                      <label className="form-check-label" htmlFor="leaveFrom-1">
                        Yes, I have an approved certificate format.
                      </label>
                    </div>
                    <div className="d-flex flex-column">
                      <div className="">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="leaveFrom"
                          id="leaveFrom-2"
                          onChange={(e) =>
                            handleChange("customTemplateExists", false)
                          }
                          checked={!options.customTemplateExists}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="leaveFrom-2"
                        >
                          No. I don't have.
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                {!options.customTemplateExists && (
                  <>
                    <div className="mt-4">
                      <h6>
                        What should be written on the certificate? * <br />
                        <small className="text-secondary fs-10">
                          * In case you don't have an approved certificate
                          format for the certificate. Maximum 300 characters.
                        </small>
                      </h6>
                      <div className={`${countChars && "d-none"}`}></div>
                      <textarea
                        className={`form-control fs-12 ${
                          error?.key === "defaultMessage" && "error-border"
                        }`}
                        name="form-question"
                        rows="4"
                        placeholder="This exact text will be printed on certificate."
                        onChange={(e) =>
                          handleChange("defaultMessage", e.target.value)
                        }
                      />
                      {error?.key === "defaultMessage" && (
                        <small className="text-danger fs-10">
                          {error.message}
                        </small>
                      )}
                    </div>
                  </>
                )}
                {/* documents */}
                <div className="mt-4">
                  <h6>
                    Add documents *
                    <div className="text-secondary fs-10 pt-2 fw-400">
                      Govt. Issued ID, Address Proof,{" "}
                      {options.customTemplateExists && "Certificate Template,"}{" "}
                      Medical reports, etc
                    </div>
                  </h6>
                  {files &&
                    files.map((file) => (
                      <div
                        className="alert alert-secondary px-2 py-2 align-items-center"
                        key={file.name}
                      >
                        <span className="font-small">{file.name}</span>
                        <i
                          className="fa fa-trash pull-right text-danger pt-1"
                          onClick={(e) => deleteImage(file.url, "files", e)}
                        />
                      </div>
                    ))}
                  {optionalDocLoading ? (
                    <i className="fa fa-spinner fa-spin" />
                  ) : (
                    <div>
                      <label
                        htmlFor="optionalDoc"
                        className="pointer btn btn-violet-outline btn-sm"
                      >
                        <i className="fa fa-plus" />
                      </label>
                      <input
                        type="file"
                        className="form-control-plaintext d-none"
                        id="optionalDoc"
                        onChange={(e) =>
                          uploadImage(e.target.files[0], "optionalDoc", e)
                        }
                        accept="application/*,image/*,video/*"
                      />
                    </div>
                  )}
                </div>
                <hr />
                <div className="text-secondary fs-10 fw-400 mt-1">
                  No medical certificate is issued as "Whomsoever It May
                  Concern" as the National Medical Council guidelines state that
                  a medical certificate has to be addressed to an organisation
                  (School/College/Company){" "}
                </div>
                <div className="mt-3">
                  <h6>Organization Name*</h6>
                  <input
                    type="text"
                    className={`form-control col-12 ${
                      error?.key === "orgName" && "error-border"
                    }`}
                    placeholder="Organization Name"
                    onChange={(e) => handleChange("orgName", e.target.value)}
                  />
                  {error?.key === "orgName" && (
                    <small className="text-danger fs-10">{error.message}</small>
                  )}
                </div>
                <div className="mt-4">
                  <h6>
                    Organization Located in *
                    <div className="text-secondary fs-10 fw-400 mt-1">
                      Organization address should match with its location{" "}
                    </div>
                  </h6>
                  <select
                    className="form-control font-small"
                    aria-label=".form-select-sm"
                    onChange={(e) =>
                      handleChange("orgLocation", e.target.value)
                    }
                  >
                    <option value="india" defaultValue>
                      India
                    </option>
                    <option value="abroad">Outside India</option>
                  </select>
                </div>
                <div className="mt-4">
                  <h6>Organization Address *</h6>
                  <textarea
                    className={`form-control fs-12 ${
                      error?.key === "orgAddress" && "error-border"
                    }`}
                    name="form-question"
                    placeholder="Complete Organization Address"
                    onChange={(e) => handleChange("orgAddress", e.target.value)}
                  />
                  {error?.key === "orgAddress" && (
                    <small className="text-danger fs-10">{error.message}</small>
                  )}
                </div>
                <div className="mt-4">
                  <h6>Do you want to mention anything to doctor buddy?</h6>
                  <div className={`${countChars && "d-none"}`}></div>
                  <textarea
                    className={`form-control fs-12 ${
                      error?.key === "reasonDescription" && "error-border"
                    }`}
                    name="form-question"
                    rows="4"
                    placeholder="Type here."
                    onChange={(e) =>
                      handleChange("reasonDescription", e.target.value)
                    }
                  />
                  {error?.key === "reasonDescription" && (
                    <small className="text-danger fs-10">{error.message}</small>
                  )}
                </div>
                {/* products */}
                <div className="mt-4">
                  <h6>My products *</h6>
                  <div className="row">
                    {products.map(
                      (product, idx) =>
                        product.display && (
                          <div key={idx} className="col-12 my-2">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="product"
                              id={`product-${idx}`}
                              onChange={(e) => handleChange("product", product)}
                              checked={options.product.name === product.name}
                            />
                            <label
                              className="form-check-label"
                              htmlFor={`product-${idx}`}
                            >
                              <div className="media">
                                <div className="media-body">
                                  <div className="fw-500">
                                    {product.name} {smallDevice && <div></div>}
                                    <div
                                      className={
                                        smallDevice ? "my-2" : "pull-right"
                                      }
                                    >
                                      {options.orgLocation === "abroad"
                                        ? product.abroadPrice.toFixed(2)
                                        : product.price.toFixed(2)}{" "}
                                      {currency} &nbsp;
                                      <strike className="text-orange-600 fw-500 mt-2 fs-12">
                                        {options.orgLocation === "abroad"
                                          ? product.abroadTotalPrice.toFixed(2)
                                          : product.totalPrice.toFixed(2)}{" "}
                                        {currency}
                                      </strike>
                                    </div>
                                  </div>
                                  <div className="mt-1 fs-12">
                                    {product.description}
                                  </div>
                                </div>
                              </div>
                            </label>
                          </div>
                        )
                    )}
                  </div>
                  <div className="mt-4 ml-4 d-none">
                    <div className="form-question fs-12">
                      Have a Promo Code?
                    </div>
                    <div className="input-group col-12 col-sm-6 pl-0">
                      <input
                        type="text"
                        className={`form-control fs-12 ${
                          error?.key === "promocode" && "error-border"
                        }`}
                        placeholder="Promocode"
                        aria-label="Promocode"
                        aria-describedby="promo-code"
                        onChange={(e) =>
                          handleChange("promocode", e.target.value)
                        }
                        disabled={discountPrice}
                      />
                      <div
                        className="input-group-append"
                        onClick={(e) => verifyPromoCode()}
                      >
                        <span
                          className="btn btn-violet btn-sm px-3"
                          id="promo-code"
                          disabled={discountPrice}
                        >
                          {applyDiscount ? "Applied!" : "Apply"}
                        </span>
                      </div>
                    </div>
                    <small className="text-secondary fs-10">
                      e.g. MEDOC_20D
                    </small>
                    {error?.key === "promocode" && (
                      <div className="text-danger fs-10">{error.message}</div>
                    )}
                  </div>
                  <div className="text-secondary fs-12 text-center mt-3 d-none">
                    Note: This service solely provides healthcare advice and
                    does not give certificates, referral letters, prescriptions,
                    or anything else.
                  </div>
                  <div className="mt-4 text-center fs-16 fw-500">
                    Total cost:{" "}
                    <span className="text-violet">
                      {totalCost()}
                      {currency}
                      {discountPrice && options.product.price && (
                        <strike className="text-orange-600 pl-2">
                          {options.product.price.toFixed(2)} {currency}
                        </strike>
                      )}
                    </span>
                  </div>
                  {discountPrice && (
                    <div className="text-orange-600 text-center pt-3 fs-12">
                      Hurray! You saved extra{" "}
                      {applyDiscount.discountPercent * 100}%
                    </div>
                  )}
                </div>
              </div>
              <div
                className="complete-payment btn-violet-rounded px-3 py-2 text-center"
                onClick={saveCertificate}
              >
                {savingCertificate ? (
                  <i className="fa fa-spinner fa-spin" />
                ) : (
                  <span>Complete Payment</span>
                )}
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default CustomCertificateComponent;
