import React from "react";

import { toast } from "react-toastify";

const PersonalDetailsComponent = ({
  options,
  setStepNumber,
  error,
  setError,
  handleChange,
}) => {
  const showErrors = () => {
    let error;
    if (!options.firstName) {
      error = {
        message: "First name is required",
        key: "firstName",
      };
    } else if (!options.lastName) {
      error = {
        message: "Last name is required",
        key: "lastName",
      };
    } else if (!options.dob) {
      error = {
        message: "Date of birth is required",
        key: "dob",
      };
    } else if (!options.age) {
      error = {
        message: "Age is required",
        key: "age",
      };
    } else if (!options.email) {
      error = {
        message: "Email is required",
        key: "email",
      };
    } else if (!options.mobile) {
      error = {
        message: "Mobile number is required",
        key: "mobile",
      };
    } else if (
      options.mobile &&
      options.orgLocation !== "abroad" &&
      options.mobile.length !== 12
    ) {
      error = {
        message: "Mobile number must contain 12-digits",
        key: "mobile",
      };
    } else if (options.address && !options.address.door) {
      error = {
        message: "Door number is required",
        key: "door",
      };
    } else if (options.address && !options.address.streetName) {
      error = {
        message: "Street name is required",
        key: "streetName",
      };
    } else if (options.address && !options.address.city) {
      error = {
        message: "City is required",
        key: "city",
      };
    } else if (options.address && !options.address.state) {
      error = {
        message: "State is required",
        key: "state",
      };
    } else if (options.address && !options.address.zipcode) {
      error = {
        message: "Postal / Zip code is required ",
        key: "zipcode",
      };
    }
    setError(error);
    return error;
  };

  const saveTnc = async () => {
    const err = await showErrors();
    if (err && err.message) {
      toast.error(err.message);
      return;
    }
    setStepNumber(3);
  };

  return (
    <>
      <div className="dashboard-content">
        <div className="container pt-5 mt-sm-5">
          <div className="mt-sm-5"></div>
          <div className="card py-4 px-2 p-sm-3">
            <h5 className="text-black text-center">Personal Information</h5>
            <div className="mt-4">
              <h6 className="fs-12">
                Full name of the person who requires leave * <br />
                <small className="text-secondary fs-10 d-none">
                  *As per Govt ID proof
                </small>
              </h6>
              <div className="row">
                <div className="col-12 col-sm-6">
                  <input
                    type="text"
                    className={`form-control font-small ${
                      error?.key === "firstName" && "error-border"
                    }`}
                    name="form-question"
                    placeholder="First name"
                    onChange={(e) => handleChange("firstName", e.target.value)}
                    value={options.firstName}
                  />
                  {error?.key === "firstName" ? (
                    <small className="text-danger fs-10">{error.message}</small>
                  ) : (
                    <small className="text-secondary fs-10">First name</small>
                  )}
                </div>
                <div className="col-12 col-sm-6 mt-4 mt-sm-0">
                  <input
                    type="text"
                    className={`form-control font-small ${
                      error?.key === "lastName" && "error-border"
                    }`}
                    name="form-question"
                    placeholder="Last name"
                    onChange={(e) => handleChange("lastName", e.target.value)}
                    value={options.lastName}
                  />
                  {error?.key === "lastName" ? (
                    <small className="text-danger fs-10">{error.message}</small>
                  ) : (
                    <small className="text-secondary fs-10">Last name</small>
                  )}
                </div>
              </div>
            </div>
            <div className="row mt-4">
              <div className="col-12 col-sm-6">
                <h6 className="fs-12">Date of birth *</h6>
                <input
                  type="date"
                  className={`form-control ${
                    error?.key === "dob" && "error-border"
                  }`}
                  name="form-question"
                  placeholder="dob"
                  onChange={(e) => handleChange("dob", e.target.value)}
                  value={options.dob}
                />
                {error?.key === "dob" && (
                  <small className="text-danger fs-10">{error.message}</small>
                )}
              </div>
              <div className="col-12 col-sm-6 mt-3 mt-sm-0">
                <h6 className="fs-12">Age *</h6>
                <input
                  type="number"
                  className={`form-control ${
                    error?.key === "age" && "error-border"
                  }`}
                  name="form-question"
                  placeholder="Age"
                  onChange={(e) => handleChange("age", e.target.value)}
                  value={options.age}
                />
                {error?.key === "age" && (
                  <small className="text-danger fs-10">{error.message}</small>
                )}
              </div>
            </div>
            <div className="mt-4">
              <h6 className="fs-12">Gender *</h6>
              <select
                className="form-control font-small"
                aria-label=".form-select-sm"
                onChange={(e) => handleChange("gender", e.target.value)}
                value={options.gender}
              >
                <option value="male">Male</option>
                <option value="female">Female</option>
                <option value="other">Other</option>
              </select>
            </div>
            <div className="row mt-4">
              <div className="col-12 col-sm-6">
                <h6 className="fs-12">Email *</h6>
                <input
                  type="email"
                  className={`form-control ${
                    error?.key === "email" && "error-border"
                  }`}
                  name="form-question"
                  placeholder="Email"
                  value={options.email}
                  onChange={(e) => handleChange("email", e.target.value)}
                />
                {error?.key === "email" && (
                  <small className="text-danger fs-10">{error.message}</small>
                )}
              </div>
              <div className="col-12 col-sm-6 mt-4 mt-sm-0">
                <h6 className="fs-12">Mobile *</h6>
                <input
                  type="number"
                  className={`form-control ${
                    error?.key === "mobile" && "error-border"
                  }`}
                  name="form-question"
                  placeholder="917890789090"
                  value={options.mobile}
                  onChange={(e) => handleChange("mobile", e.target.value)}
                />
                <small className="text-secondary fs-10">
                  e.g. 917890789078
                </small>
                {error?.key === "mobile" && (
                  <div className="text-danger fs-10">{error.message}</div>
                )}
              </div>
            </div>
            <div className="mt-4">
              <h6 className="fs-12">
                Address *<br />
                <small className="text-secondary fs-10">
                  *As per Govt ID proof
                </small>
              </h6>
              <div className="row">
                <div className="col-12 col-sm-6 my-2">
                  <input
                    type="text"
                    className={`form-control ${
                      error?.key === "door" && "error-border"
                    }`}
                    name="form-question"
                    placeholder="Door no."
                    value={options.address?.door}
                    onChange={(e) =>
                      handleChange("address", e.target.value, "door")
                    }
                  />
                  {error?.key === "door" ? (
                    <small className="text-danger fs-10">{error.message}</small>
                  ) : (
                    <small className="text-secondary fs-10">Door number</small>
                  )}
                </div>
                <div className="col-12 col-sm-6 my-2">
                  <input
                    type="text"
                    className={`form-control ${
                      error?.key === "streetName" && "error-border"
                    }`}
                    name="form-question"
                    placeholder="Street name"
                    value={options.address?.streetName}
                    onChange={(e) =>
                      handleChange("address", e.target.value, "streetName")
                    }
                  />
                  {error?.key === "streetName" ? (
                    <small className="text-danger fs-10">{error.message}</small>
                  ) : (
                    <small className="text-secondary fs-10">Street name</small>
                  )}
                </div>
                <div className="col-12 col-sm-6 my-2">
                  <input
                    type="text"
                    className={`form-control ${
                      error?.key === "city" && "error-border"
                    }`}
                    name="form-question"
                    placeholder="City"
                    value={options.address?.city}
                    onChange={(e) =>
                      handleChange("address", e.target.value, "city")
                    }
                  />
                  {error?.key === "city" ? (
                    <small className="text-danger fs-10">{error.message}</small>
                  ) : (
                    <small className="text-secondary fs-10">City</small>
                  )}
                </div>
                <div className="col-12 col-sm-6 my-2">
                  <input
                    type="text"
                    className={`form-control ${
                      error?.key === "state" && "error-border"
                    }`}
                    name="form-question"
                    placeholder="State / Region / Province"
                    value={options.address?.state}
                    onChange={(e) =>
                      handleChange("address", e.target.value, "state")
                    }
                  />
                  {error?.key === "state" ? (
                    <small className="text-danger fs-10">{error.message}</small>
                  ) : (
                    <small className="text-secondary fs-10">
                      State / Region / Province
                    </small>
                  )}
                </div>
                <div className="col-12 col-sm-6 my-2">
                  <input
                    type="text"
                    className={`form-control ${
                      error?.key === "zipcode" && "error-border"
                    }`}
                    name="form-question"
                    placeholder="Postal/Zip Code"
                    value={options.address?.zipcode}
                    onChange={(e) =>
                      handleChange("address", e.target.value, "zipcode")
                    }
                  />
                  {error?.key === "zipcode" ? (
                    <small className="text-danger fs-10">{error.message}</small>
                  ) : (
                    <small className="text-secondary fs-10">
                      Postal / Zip code
                    </small>
                  )}
                </div>
                <div className="col-12 col-sm-6 my-2">
                  <select
                    className="form-control font-small"
                    aria-label=".form-select-sm"
                    onChange={(e) =>
                      handleChange("address", e.target.value, "country")
                    }
                  >
                    <option value="india" defaultValue>
                      India
                    </option>
                  </select>
                  <small className="text-secondary fs-10">Country</small>
                </div>
              </div>
            </div>
            <div className="text-center mt-3">
              <button className="btn btn-violet-rounded px-4" onClick={saveTnc}>
                Next
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PersonalDetailsComponent;
