export const Routes = {
  DEFAULT: "/",
  HOME: "/home",
  DOCTOR_CONSULTATION: "/doctor-consultation",
  MEDICAL_CERTIFICATE: "/medical-certificate",
  MEDOC_GLOBAL: "/medoc-global",
  JOIN_INFLUENCER: "/join-influencer",
  EMPLOYER_VERIFY: "/employer-verify",
  CONTACT_US: "https://medoc.life/contact-us",
  ABOUT_US: "https://medoc.life/about-us",
  FAQ: "/faq",
  TNC: "https://medoc.life/tnc",
  PRIVACY_POLICY: "https://medoc.life/privacy_policy",
  REFUND_POLICY: "/rp",
  COOKIE_POLICY: "cookie_policy",
  LOGIN: "/login",
  LOGOUT: "/logout",
  SIGNUP: "/signup",
  SIGNUP_USERNAME: "/signup/:username",
  SIGNUP_SUCCESS: "/signup_success",
  FORGOT_PASSWORD: "/forgot_password",
  BLOG_TEMPLATE: "/products/:blogName",
  SHORT_LINKS: "/ml/:shortCode",
  APP_POSTS: "/app/posts",
  APP_POSTS_POST_ID: "/app/posts/:post_id",
  APP_CREATE_POST: "/app/create_post",
  APP_EDIT_POST: "/app/edit_post",
  APP_CREATE_FC: "/app/create_fc",
  APP_EDIT_FC: "/app/edit_fc",
  APP_CREATE_RC: "/app/create_rc",
  APP_CREATE_CT: "/app/create_ct",
  APP_EDIT_CT: "/app/edit_ct",
  APP_CREATE_FA: "/app/create_fa",
  APP_EDIT_FA: "/app/edit_fa",
  APP_CREATE_CC: "/app/create_cc",
  APP_CREATE_SECOND_OPINION: "/app/create_second_opinion",
  APP_RECHARGE: "/app/recharge",
  APP_REQUESTS: "/app/requests",
  APP_SETTINGS: "/app/settings",
  APP_ALERTS: "/app/alerts",
  APP_LEAVE_FEEDBACK: "/app/leave_feedback",
  APP_GUIDELINES_SLC: "/app/guidelines_slc",
  APP_CALL_DOCTOR: "/app/call_doctor",
  APP_CONSULT_DOCTOR: "/app/consult_doctor",
  APP_CONSULT_DOCTOR_ABROAD: "/app/consult_doctor_abroad",
  APP_CHOOSE_CERTIFICATE_TYPE: "/app/choose_certificate_type",
  APP_CREATE_DOCTOR_PRESCRIPTION: "/app/create_doctor_prescription",
  APP_DOCTOR: "/app/doctor",
  APP_ASSIGN_TO_DOCTOR: "/app/assign_to_doctor",
  APP_RECHARGE_SUCCESS: "/app/recharge_success",
  APP_ADMIN_HOME: "/app/admin_home",
  APP_GET_MEDICAL_REQUEST: "/app/get_medical_request",
  APP_GET_CLIENT_REQUEST: "/app/get_client_request",
  APP_GET_ORGANISATION_REQUEST: "/app/get_organization_request",
  APP_GET_DOCTOR_REQUEST: "/app/get_doctor_request",
  APP_GET_USER: "/app/get_user",
  APP_GET_CLINIC_APPOINTMENTS: "/app/get_doctor_appointment",
  APP_GET_CLINIC_PROFILE: "/app/get_doctor_profile",
  APP_UPDATE_MEDICAL_REQUEST: "/app/update_medical_request",
  APP_UPDATE_USER: "/app/update_user",
  APP_UPDATE_APPOINTMENT: "/app/update_appointment",
  APP_REFER_FRIENDS: "/app/invite_friends",
  APP_SETTINGS_ANALYTICS: "/app/settings/analytics",
  APP_EDIT_VIRTUAL_CLINIC: "/app/settings/edit-virtual-clinic",
  APP_DC_PUBLIC_APPOINTMENTS: "/app/appointments",
};
