import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

import { HeaderComponent } from "components";
import { getId, getQuery, firestore } from "firebase_config";
import { Tables } from "constants/index";
import CompleteDetailsComponent from "./complete_details/component";

const UpdateUsersComponent = (props) => {
  const { currentUser, location } = props;
  const [loading, setLoading] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [email, setEmail] = useState();
  const [userDetails, setUserDetails] = useState();
  const history = useHistory();

  useEffect(() => {
    if (location?.state?.request) {
      setSearchValue(location.state.request.id);
    }
  }, [location]);

  const findRecord = async () => {
    setLoading(true);
    let user;
    if (searchValue) {
      user = await getId(Tables.USERS, searchValue);
      user.id = searchValue;
    }
    if (email) {
      const res = await getQuery(
        firestore
          .collection(Tables.USERS)
          .where("email", "==", email.trim())
          .get()
      );
      if (res.length) {
        user = res[0];
      }
    }
    if (!user || user.status === 404) {
      toast.error("User is invalid");
    } else {
      setUserDetails(user);
    }
    setLoading(false);
  };

  const cancelSearch = () => {
    history.goBack();
  };

  return (
    <>
      <HeaderComponent />
      <div className="dashboard-content">
        <div className="container create-post-wrapper pt-5 mt-sm-5">
          <div className={`${currentUser.isDoctor && "d-none"}`}>
            <h3>Update Users</h3>
            <div className="row mt-4">
              <div className="col-12 col-sm-12">
                <h6 htmlFor="form-question">
                  Enter Request Number (alpha-numeric)
                </h6>
                <input
                  type="text"
                  className="form-control"
                  name="form-question"
                  placeholder="Enter Request Number"
                  value={searchValue}
                  onChange={(e) => setSearchValue(e.target.value.trim())}
                />
              </div>
            </div>
            <div className="row mt-4">
              <div className="col-12 col-sm-12">
                <h6 htmlFor="form-question">Enter email</h6>
                <input
                  type="text"
                  className="form-control"
                  name="form-question"
                  placeholder="Enter Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
            </div>
            <div className="btn btn-violet btn-sm mt-4" onClick={findRecord}>
              Search
            </div>
            <div
              className="btn btn-outline-danger btn-sm mt-4 ml-2"
              onClick={cancelSearch}
            >
              Cancel
            </div>
            {loading && <i className="fa fa-spinner fa-spin" />}
          </div>
          {userDetails && !loading && (
            <>
              <h6 className="mt-5 text-center">
                User: {searchValue} {userDetails && userDetails.email}
              </h6>
              <CompleteDetailsComponent
                currentUser={currentUser}
                userDetails={userDetails}
                setUserDetails={setUserDetails}
              />
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default UpdateUsersComponent;
