import React, { useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw } from "draft-js";
import "./style.css";

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from "draftjs-to-html";

const RichTextEditorComponent = ({ setRichText, onRichTextChange }) => {
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );

  const handleChange = () => {
    const rawContent = convertToRaw(editorState.getCurrentContent());
    const htmlContent = draftToHtml(rawContent);
    setRichText(htmlContent);
    onRichTextChange("richText", htmlContent);
  };

  return (
    <div className="col-12 editor-container">
      <Editor
        editorState={editorState}
        toolbarClassName="toolbarClassName"
        wrapperClassName="wrapperClassName"
        editorClassName="editorClassName"
        onEditorStateChange={(e) => {
          setEditorState(e);
          handleChange(editorState);
        }}
      />
    </div>
  );
};

export default RichTextEditorComponent;
